import React from "react";
import { Form, InputNumber } from "formik-antd";
import { useIntl } from "react-intl";
import { isEmpty } from "lodash";
import { ValidateField } from "@iso/validates/ValidateField";

const TableRowFeePersonAndWeekday = ({
    bgColor,
    label,
    type,
    numberUniquePeople,
    tabKey,
    isHoliday,
    disabledMode,
    apiRoomId
}) => {
    const { messages } = useIntl();
    const defaultRowspan = 3;

    return (
        <>
            <tr>
                {type === 'rakuten' && (
                    <td
                        align="center"
                        className="kihon01"
                        nowrap=""
                        rowSpan={defaultRowspan}
                        width="48"
                    >
                        <span>{messages["page.room.moneyRegister.fee"]}</span>
                    </td>
                )}
                <td
                    align="center"
                    bgcolor={bgColor}
                    className="kihon01"
                    nowrap=""
                    width="80"
                >
                    <span>{label}</span>
                </td>
                <td
                    align="center"
                    bgcolor="#F1F7E5"
                    className="kihon01"
                    nowrap=""
                >
                    <span>{numberUniquePeople}{messages["page.room.moneyRegister.more"]}</span>
                </td>
                <td
                    align="center"
                    className="kihon02"
                    nowrap=""
                >
                    <Form.Item
                        name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['${type}Normal'][${numberUniquePeople}]`}
                        validate={apiRoomId ? false : (
                            (value) => ValidateField(
                                value,
                                messages["page.room.moneyRegister.yen/person"],
                                {
                                    required: messages["form.error.mixed.required"],
                                    integer: messages["form.error.number.integer"],

                                },
                                {
                                    required : true,
                                    integer : true,
                                }
                            )
                        )}
                    >
                        <InputNumber
                            name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['${type}Normal'][${numberUniquePeople}]`}
                            maxLength={10}
                            min={0}
                            max={999999999}
                            defaultValue={0}
                            className="w-100"
                            disabled={disabledMode}
                        />
                        <span className="ml-5">{messages["page.room.moneyRegister.yen/person"]}</span>
                    </Form.Item>
                </td>
                <td
                    align="center"
                    bgcolor="#E4F1FF"
                    className="kihon02"
                    nowrap=""
                >
                    <Form.Item
                        name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['${type}Holiday'][${numberUniquePeople}]`}
                        validate={apiRoomId ? false : (
                            (value) => ValidateField(
                                value,
                                messages["page.room.moneyRegister.yen/person"],
                                {
                                    required: messages["form.error.mixed.required"],
                                    integer: messages["form.error.number.integer"],

                                },
                                {
                                    required : true,
                                    integer : true,
                                }
                            )
                        )}
                    >
                        <InputNumber
                            name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['${type}Holiday'][${numberUniquePeople}]`}
                            maxLength={10}
                            min={0}
                            max={999999999}
                            defaultValue={0}
                            className="w-100"
                            disabled={(isEmpty(isHoliday)) || disabledMode}
                        />
                        <span className="ml-5">{messages["page.room.moneyRegister.yen/person"]}</span>
                    </Form.Item>
                </td>
            </tr>
        </>
    );
};

export default TableRowFeePersonAndWeekday;
