import React, { useState, useEffect, useCallback } from "react";
import { Radio } from "antd";
import { Form } from "formik-antd";
import { useIntl } from "react-intl";
import { isEmpty } from "lodash";
import { ValidateField } from "@iso/validates/ValidateField";

const TableSettingPriceByRoom = ({
    values,
    setFieldValue,
    tabKey,
    disabledMode,
    apiRoomId
}) => {
    const { messages } = useIntl();

    const [activeJuniorHigh, setActiveJuniorHigh] = useState(messages["page.room.moneyRegister.can"]);
    const [activeJuniorLow, setActiveJuniorLow] = useState(messages["page.room.moneyRegister.can"]);
    const [activeInfrantMealPallet, setActiveInfrantMealPallet] = useState(messages["page.room.moneyRegister.can"]);
    const [activeInfrantMealOnly, setActiveInfrantMealOnly] = useState(messages["page.room.moneyRegister.can"]);
    const [activeInfrantPalletOnly, setActiveInfrantPalletOnly] = useState(messages["page.room.moneyRegister.can"]);
    const [activeInfrantNoMealPallet, setActiveInfrantNoMealPallet] = useState(messages["page.room.moneyRegister.can"]);
    const [activeInfrantBreakfastType, setActiveInfrantBreakfastType] = useState(null);
    const [activeInfrantDinnerType, setActiveInfrantDinnerType] = useState(null);
    const [activeMeal, setActiveMeal] = useState(true);

    useEffect(() => {
        if (values != undefined
            && values != null
            && values.apiRoomOtas != null
            && values.apiRoomOtas != undefined
            && values.apiRoomOtas[tabKey] != null
            && values.apiRoomOtas[tabKey] != undefined
            && !isEmpty(values.apiRoomOtas[tabKey])
            && !isEmpty(values.apiRoomOtas[tabKey].fieldContents)
            && !isEmpty(values.apiRoomOtas[tabKey].fieldContents.moneyRegister)
        ) {
            setActiveJuniorHigh(values.apiRoomOtas[tabKey].fieldContents.moneyRegister.juniorHighAcceptDate ?? messages["page.room.moneyRegister.can"])
            setActiveJuniorLow(values.apiRoomOtas[tabKey].fieldContents.moneyRegister.juniorLowAcceptDate  ?? messages["page.room.moneyRegister.can"])

            setActiveInfrantMealPallet(values.apiRoomOtas[tabKey].fieldContents.moneyRegister.infrantMealPalletAcceptDate  ?? messages["page.room.moneyRegister.can"])
            setActiveInfrantMealOnly(values.apiRoomOtas[tabKey].fieldContents.moneyRegister.infrantMealOnlyAcceptDate  ?? messages["page.room.moneyRegister.can"])

            setActiveInfrantPalletOnly(values.apiRoomOtas[tabKey].fieldContents.moneyRegister.infrantPalletOnlyAcceptDate  ?? messages["page.room.moneyRegister.can"])
            setActiveInfrantNoMealPallet(values.apiRoomOtas[tabKey].fieldContents.moneyRegister.infrantNoMealPalletAcceptDate  ?? messages["page.room.moneyRegister.can"])

            setActiveInfrantBreakfastType(values.apiRoomOtas[tabKey].fieldContents.moneyRegister.infrantBreakfastType)
            setActiveInfrantDinnerType(values.apiRoomOtas[tabKey].fieldContents.moneyRegister.infrantDinnerType)
        }
    }, [values])

    const handleChangeRadio = (e, name, stateSetter) => {
        setFieldValue(name, e.target.value);
        stateSetter(e.target.value);
    };

    const onChangeInfrantMealPallet = useCallback((e, name) => {
        const value = e.target.value;
        setActiveMeal(true)

        if (value != messages["page.room.moneyRegister.can"]
            && activeInfrantMealOnly !=  messages["page.room.moneyRegister.can"]
        ){
            setActiveMeal(false)
        }
        handleChangeRadio(e, name, setActiveInfrantMealPallet)
    }, [activeInfrantMealOnly]);

    const onChangeInfrantMealOnly = useCallback((e, name) => {
        const value = e.target.value;
        setActiveMeal(true)

        if (value != messages["page.room.moneyRegister.can"]
            && activeInfrantMealPallet !=  messages["page.room.moneyRegister.can"]
        ){
            setActiveMeal(false)
        }

        handleChangeRadio(e, name, setActiveInfrantMealOnly)
    }, [activeInfrantMealPallet]);

    const onChangeJuniorHigh = (e, name) => handleChangeRadio(e, name, setActiveJuniorHigh);
    const onChangeJuniorLow = (e, name) => handleChangeRadio(e, name, setActiveJuniorLow);
    const onChangeInfrantPalletOnly = (e, name) => handleChangeRadio(e, name, setActiveInfrantPalletOnly);
    const onChangInfrantNoMealPallet = (e, name) => handleChangeRadio(e, name, setActiveInfrantNoMealPallet);
    const onChangeInfrantBreakfastType = (e, name) => handleChangeRadio(e, name, setActiveInfrantBreakfastType);
    const onChangeInfrantDinnerType = (e, name) => handleChangeRadio(e, name, setActiveInfrantDinnerType);

    return (
        <table
            border="1"
            cellPadding="0"
            cellSpacing="0"
            width="70%"
        >
            <tbody>
                <tr
                    align="center"
                    bgcolor="#EDEDED"
                    className="kihon_c"
                >
                    <td colSpan="2">
                        {messages["page.room.moneyRegister.child"]}
                    </td>
                    <td width="45">
                        {messages["page.room.moneyRegister.capacity"]}
                    </td>
                    <td width="100">
                        {messages["page.room.moneyRegister.meal"]}
                    </td>
                    <td width="93">
                        {messages["page.room.moneyRegister.accommodation"]}
                    </td>
                </tr>
                <tr>
                    <td
                        align="center"
                        bgcolor="#FFEFBB"
                        className="kihon_c"
                        rowSpan="2"
                        width="50"
                    >
                        {messages["page.room.moneyRegister.elementarySchoolStudent"]}
                    </td>
                    <td
                        className="kihon_c"
                        width="85"
                    >
                        {messages["page.room.moneyRegister.upperGrade"]}
                    </td>
                    <td
                        align="center"
                        className="kihon_c"
                        width="36"
                    >
                        {messages["page.room.moneyRegister.contains"]}
                    </td>
                    <td
                        align="center"
                        className="kihon_c"
                        rowSpan="2"
                        width="100"
                    >
                        {messages["page.room.moneyRegister.sameAsAdult"]}
                    </td>
                    <td
                        className="kihon_c"
                        nowrap=""
                    >
                        <Form.Item
                            name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['juniorHighAcceptDate']`}
                        >
                            <Radio.Group
                                onChange={(e) => onChangeJuniorHigh(e, `apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['juniorHighAcceptDate']`)}
                                value={activeJuniorHigh}
                                name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['juniorHighAcceptDate']`}
                                disabled={disabledMode}
                            >
                                <Radio value={messages["page.room.moneyRegister.can"]}>
                                    <span>{messages["page.room.moneyRegister.can"]}</span>
                                </Radio>
                                <Radio value={messages["page.room.moneyRegister.no"]}>
                                    <span>{messages["page.room.moneyRegister.no"]}</span>
                                </Radio>
                            </Radio.Group>
                        </Form.Item>
                    </td>
                </tr>
                <tr>
                    <td
                        className="kihon_c"
                        width="85"
                    >
                        {messages["page.room.moneyRegister.lowerGrades"]}
                    </td>
                    <td
                        align="center"
                        className="kihon_c"
                        width="36"
                    >
                        {messages["page.room.moneyRegister.contains"]}
                    </td>
                    <td
                        className="kihon_c"
                        nowrap=""
                    >
                        <Form.Item
                            name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['juniorLowAcceptDate']`}
                        >
                            <Radio.Group
                                onChange={(e) => onChangeJuniorLow(e, `apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['juniorLowAcceptDate']`)}
                                value={activeJuniorLow}
                                name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['juniorLowAcceptDate']`}
                                disabled={disabledMode}
                            >
                                <Radio value={messages["page.room.moneyRegister.can"]}>
                                    <span>{messages["page.room.moneyRegister.can"]}</span>
                                </Radio>
                                <Radio value={messages["page.room.moneyRegister.no"]}>
                                    <span>{messages["page.room.moneyRegister.no"]}</span>
                                </Radio>
                            </Radio.Group>
                        </Form.Item>
                    </td>
                </tr>
                <tr>
                    <td
                        align="center"
                        bgcolor="#FFEFBB"
                        className="kihon_c"
                        rowSpan="4"
                        width="50"
                    >
                        {messages["page.room.moneyRegister.infant"]}
                    </td>
                    <td
                        className="kihon_c"
                        width="85"
                    >
                        {messages["page.room.moneyRegister.mealAndFutonIncluded"]}
                    </td>
                    <td
                        align="center"
                        className="kihon_c"
                        width="36"
                    >
                        {messages["page.room.moneyRegister.contains"]}
                    </td>
                    <td
                        align="center"
                        className="kihon_c"
                        rowSpan="2"
                        width="100"
                    >
                        <div>
                            <span>{messages["page.room.moneyRegister.meal.breakfast"]}：</span>
                            <br />
                            <Form.Item
                                name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantBreakfastType']`}
                                validate={apiRoomId ? false : (
                                    (value) => ValidateField(
                                        value,
                                        messages["page.room.moneyRegister.meal.breakfast"],
                                        {
                                            required: messages["form.error.mixed.required"]
                                        },
                                        {
                                            required: activeMeal
                                        }
                                    )
                                )}
                            >
                                <Radio.Group
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantBreakfastType']`}
                                    onChange={e => onChangeInfrantBreakfastType(e, `apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantBreakfastType']`)}
                                    value={activeInfrantBreakfastType}
                                    disabled={disabledMode}
                                >
                                    <Radio value={messages["page.room.moneyRegister.including"]}>
                                        <span>{messages["page.room.moneyRegister.including"]}</span>
                                    </Radio>
                                    <Radio value={messages["page.room.moneyRegister.another"]}>
                                        <span>{messages["page.room.moneyRegister.another"]}</span>
                                    </Radio>
                                </Radio.Group>
                            </Form.Item>
                        </div>
                        <div>
                            <span>{messages["page.room.moneyRegister.meal.dinner"]}：</span>
                            <br />
                            <Form.Item
                                name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantDinnerType']`}
                                validate={apiRoomId ? false : (
                                    (value) => ValidateField(
                                        value,
                                        messages["page.room.moneyRegister.meal.dinner"],
                                        {
                                            required: messages["form.error.mixed.required"]
                                        },
                                        {
                                            required: activeMeal
                                        }
                                    )
                                )}
                            >
                                <Radio.Group
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantDinnerType']`}
                                    onChange={e => onChangeInfrantDinnerType(e, `apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantDinnerType']`)}
                                    value={activeInfrantDinnerType}
                                    disabled={disabledMode}
                                >
                                    <Radio value={messages["page.room.moneyRegister.including"]}>
                                        <span>{messages["page.room.moneyRegister.including"]}</span>
                                    </Radio>
                                    <Radio value={messages["page.room.moneyRegister.another"]}>
                                        <span>{messages["page.room.moneyRegister.another"]}</span>
                                    </Radio>
                                </Radio.Group>
                            </Form.Item>
                        </div>
                    </td>
                    <td
                        className="kihon_c"
                        nowrap=""
                    >
                        <Form.Item
                            name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantMealPalletAcceptDate']`}
                        >
                            <Radio.Group
                                onChange={(e) => onChangeInfrantMealPallet(e, `apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantMealPalletAcceptDate']`)}
                                value={activeInfrantMealPallet}
                                name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantMealPalletAcceptDate']`}
                                disabled={disabledMode}
                            >
                                <Radio value={messages["page.room.moneyRegister.can"]}>
                                    <span>{messages["page.room.moneyRegister.can"]}</span>
                                </Radio>
                                <Radio value={messages["page.room.moneyRegister.no"]}>
                                    <span>{messages["page.room.moneyRegister.no"]}</span>
                                </Radio>
                            </Radio.Group>
                        </Form.Item>
                    </td>
                </tr>
                <tr>
                    <td
                        className="kihon_c"
                        width="85"
                    >
                        {messages["page.room.moneyRegister.mealOnly"]}
                    </td>
                    <td
                        align="center"
                        width="36"
                    >
                    </td>
                    <td
                        className="kihon_c"
                        nowrap=""
                    >
                        <Form.Item
                            name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantMealOnlyAcceptDate']`}
                        >
                            <Radio.Group
                                onChange={(e) => onChangeInfrantMealOnly(e, `apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantMealOnlyAcceptDate']`)}
                                value={activeInfrantMealOnly}
                                name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantMealOnlyAcceptDate']`}
                                disabled={disabledMode}
                            >
                                <Radio value={messages["page.room.moneyRegister.can"]}>
                                    <span>{messages["page.room.moneyRegister.can"]}</span>
                                </Radio>
                                <Radio value={messages["page.room.moneyRegister.no"]}>
                                    <span>{messages["page.room.moneyRegister.no"]}</span>
                                </Radio>
                            </Radio.Group>
                        </Form.Item>
                    </td>
                </tr>
                <tr>
                    <td
                        className="kihon_c"
                        width="85"
                    >
                        {messages["page.room.moneyRegister.futonOnly"]}
                    </td>
                    <td
                        align="center"
                        className="kihon_c"
                        width="36"
                    >
                        {messages["page.room.moneyRegister.contains"]}
                    </td>
                    <td
                        align="center"
                        className="kihon_c"
                        rowSpan="2"
                        width="100"
                    >
                        {messages["page.room.moneyRegister.none"]}
                    </td>
                    <td
                        className="kihon_c"
                        nowrap=""
                    >
                        <Form.Item
                            name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantPalletOnlyAcceptDate']`}
                        >
                            <Radio.Group
                                onChange={(e) => onChangeInfrantPalletOnly(e, `apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantPalletOnlyAcceptDate']`)}
                                value={activeInfrantPalletOnly}
                                name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantPalletOnlyAcceptDate']`}
                                disabled={disabledMode}
                            >
                                <Radio value={messages["page.room.moneyRegister.can"]}>
                                    <span>{messages["page.room.moneyRegister.can"]}</span>
                                </Radio>
                                <Radio value={messages["page.room.moneyRegister.no"]}>
                                    <span>{messages["page.room.moneyRegister.no"]}</span>
                                </Radio>
                            </Radio.Group>
                        </Form.Item>
                    </td>
                </tr>
                <tr>
                    <td
                        className="kihon_c"
                        width="85"
                    >
                        {messages["page.room.moneyRegister.noMealsOrBeddingRequired"]}
                    </td>
                    <td
                        align="center"
                        width="36"
                    >
                    </td>
                    <td
                        className="kihon_c"
                        nowrap=""
                    >
                        <Form.Item
                            name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantNoMealPalletAcceptDate']`}
                        >
                            <Radio.Group
                                onChange={(e) => onChangInfrantNoMealPallet(e, `apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantNoMealPalletAcceptDate']`)}
                                value={activeInfrantNoMealPallet}
                                name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantNoMealPalletAcceptDate']`}
                                disabled={disabledMode}
                            >
                                <Radio value={messages["page.room.moneyRegister.can"]}>
                                    <span>{messages["page.room.moneyRegister.can"]}</span>
                                </Radio>
                                <Radio value={messages["page.room.moneyRegister.no"]}>
                                    <span>{messages["page.room.moneyRegister.no"]}</span>
                                </Radio>
                            </Radio.Group>
                        </Form.Item>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

export default TableSettingPriceByRoom;
