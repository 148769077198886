import React, { memo, useState, useEffect} from "react";
import { Col, Row, DatePicker } from "antd";
import { Form, Select } from "formik-antd";
import { useIntl } from "react-intl";
import { isEmpty } from "lodash";
import moment from "moment";
import { MODE_SCREEN } from "@iso/constants/common.constant";

import {
    colFull,
    colSingleUnit,
    customRadioGroupFullRow,
    inputColLeft,
} from "@iso/assets/styles/form.style";
import { MoneyRegisterStyle } from "./MoneyRegisterStyle";
import PriceSettingByPersonAndWeekday from "./Mode/PriceSettingByPersonAndWeekday";
import PriceSettingByPersonAndDayOfWeek from "./Mode/PriceSettingByPersonAndDayOfWeek";
import PriceSettingByRoomAndWeekday from "./Mode/PriceSettingByRoomAndWeekday";
import PriceSettingByRoomAndDayOfWeek from "./Mode/PriceSettingByRoomAndDayOfWeek";
import { ValidateField } from "@iso/validates/ValidateField";
import {searchSelect} from "@iso/constants/api.constant";

const MoneyRegister = ({
    values,
    dataOta,
    setFieldValue,
    tabKey,
    apiRoomId,
    imageByStoreAndOta,
    optionValues,
    dataRoom,
    mode
}) => {
    const { messages } = useIntl();
    const { Option } = Select;
    const [monthOptions, setMonthOptions] = useState([]);
    const [priceTypeFormat, setPriceTypeFormat] = useState(null);
    const [priceDayFormat, setPriceDayFormat] = useState(null);
    const [settingPeriodFrom, setSettingPeriodFrom] = useState(null);
    const [settingPeriodTo, setSettingPeriodTo] = useState(null);
    const [disableMoneyRegister, setDisableMoneyRegister] = useState(false);

    const onChangePriceTypeFormat = (values, name) => {
        setFieldValue(name, values)
        setPriceTypeFormat(values)
        setFieldValue(`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']`, [])
    };

    const onChangePriceDayFormat = (values, name) => {
        setFieldValue(name, values)
        setPriceDayFormat(values)
        setFieldValue(`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']`, [])
    };

    useEffect(() => {
        setDisableMoneyRegister(true)
        
        const shouldEnableMoneyRegister = (
            dataRoom &&
            !isEmpty(dataRoom) &&
            !isEmpty(dataRoom.apiRoomOtas[tabKey]) &&
            isEmpty(dataRoom.apiRoomOtas[tabKey].fieldContents.moneyRegister)
        );
        
        if (shouldEnableMoneyRegister || !apiRoomId || dataRoom.apiRoomOtas[tabKey] == null || dataRoom.apiRoomOtas[tabKey] == undefined) {
            setDisableMoneyRegister(false);
        }

        if ((mode == MODE_SCREEN.COPY)) {
            setDisableMoneyRegister(false);
        }
        
    }, [dataRoom, mode])

    useEffect(() => {
        if (apiRoomId
            && !isEmpty(values)
            && !isEmpty(values.apiRoomOtas[tabKey])
            && !isEmpty(values.apiRoomOtas[tabKey].fieldContents)
        ) {
            setPriceTypeFormat(values.apiRoomOtas[tabKey].fieldContents.priceTypeFormat)
            setPriceDayFormat(values.apiRoomOtas[tabKey].fieldContents.priceDayFormat)
            setSettingPeriodFrom(values.apiRoomOtas[tabKey].fieldContents.settingPeriodFromYear ? moment(values.apiRoomOtas[tabKey].fieldContents.settingPeriodFromYear, 'YYYY') : null)
            setSettingPeriodTo(values.apiRoomOtas[tabKey].fieldContents.settingPeriodToYear ? moment(values.apiRoomOtas[tabKey].fieldContents.settingPeriodToYear, 'YYYY') : null)
        }
    }, [values])

    useState(() => {
        const options = [];
        for (let i = 1; i <= 12; i++) {
            options.push(<Option key={i} value={i}>{i}</Option>);
        }
        setMonthOptions(options);
    }, []);

    const disabledDate = (current) => {
        const today = moment();
        const nextYear = moment().add(1, 'year').endOf('year');
        return current && (current < today.startOf('year') || current > nextYear);
    };

    const renderItem = (
        values
    ) => {
        if (priceTypeFormat == null || priceDayFormat == null) {
            return "";
        }

        if (priceTypeFormat == "１人あたり料金設定" && priceDayFormat == "平日/休前日") {
            return (
                <PriceSettingByPersonAndWeekday
                    values={values}
                    dataOta={dataOta}
                    setFieldValue={setFieldValue}
                    tabKey={tabKey}
                    apiRoomId={apiRoomId}
                    imageByStoreAndOta={imageByStoreAndOta}
                    optionValues={optionValues}
                    disabledMode={disableMoneyRegister}
                    dataRoom={dataRoom}
                />
            )
        }

        if (priceTypeFormat == "１人あたり料金設定" && priceDayFormat == "曜日/休前日・祝祭日") {
            return (
                <PriceSettingByPersonAndDayOfWeek
                    values={values}
                    dataOta={dataOta}
                    setFieldValue={setFieldValue}
                    tabKey={tabKey}
                    apiRoomId={apiRoomId}
                    imageByStoreAndOta={imageByStoreAndOta}
                    optionValues={optionValues}
                    disabledMode={disableMoneyRegister}
                    dataRoom={dataRoom}
                />
            )
        }

        if (priceTypeFormat == "1室あたり料金設定" && priceDayFormat == "平日/休前日") {
            return (
                <PriceSettingByRoomAndWeekday
                    values={values}
                    dataOta={dataOta}
                    setFieldValue={setFieldValue}
                    tabKey={tabKey}
                    apiRoomId={apiRoomId}
                    imageByStoreAndOta={imageByStoreAndOta}
                    optionValues={optionValues}
                    disabledMode={disableMoneyRegister}
                    dataRoom={dataRoom}
                />
            )
        }

        if (priceTypeFormat == "1室あたり料金設定" && priceDayFormat == "曜日/休前日・祝祭日") {
            return (
                <PriceSettingByRoomAndDayOfWeek
                    values={values}
                    dataOta={dataOta}
                    setFieldValue={setFieldValue}
                    tabKey={tabKey}
                    apiRoomId={apiRoomId}
                    imageByStoreAndOta={imageByStoreAndOta}
                    optionValues={optionValues}
                    disabledMode={disableMoneyRegister}
                    dataRoom={dataRoom}
                />
            )
        }
    }

    const onChangeSettingPeriodFrom = (date, dateString) => {
        if (date == null) {
            setSettingPeriodFrom(moment([]))
        } else {
            setFieldValue(`apiRoomOtas[${tabKey}]['fieldContents']['settingPeriodFromYear']`, dateString)
            setSettingPeriodFrom(moment(dateString, 'YYYY'))
        }
    }

    const onChangeSettingPeriodTo = (date, dateString) => {
        if (date == null) {
            setSettingPeriodTo(moment([]))
        } else {
            setFieldValue(`apiRoomOtas[${tabKey}]['fieldContents']['settingPeriodToYear']`, dateString)
            setSettingPeriodTo(moment(dateString, 'YYYY'))
        }
    }

    return (
        <MoneyRegisterStyle>
            <Row justify="start" className="mb-25">
                <Col  {...colFull}>
                    <Form.Item
                        label={messages["page.room.moneyRegister.period"]}
                        name={`apiRoomOtas[${tabKey}]['fieldContents']['filterConditions']`}
                        className="required"
                        {...customRadioGroupFullRow}
                    >
                        <Row justify="start" style={{marginLeft: 15}}>
                            <Col>
                                <Form.Item
                                    className="row-reverse mt-5px mr-1"
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['settingPeriodFromYear']`}
                                    validate={(value) => ValidateField(
                                        value,
                                        messages["page.room.moneyRegister.year"],
                                        {
                                            required: messages["form.error.mixed.required"],
            
                                        },
                                        {
                                            required : true,
                                        }
                                    )}
                                >
                                    <div className="d-flex align-items-center">
                                        <DatePicker
                                            picker="year"
                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['settingPeriodFromYear']`}
                                            onChange={onChangeSettingPeriodFrom}
                                            value={settingPeriodFrom}
                                            disabledDate={disabledDate}
                                        />
                                        <span style={{ marginLeft: "5px", whiteSpace: 'nowrap' }} className="item-center">
                                            {messages["page.room.moneyRegister.year"]}
                                        </span>
                                    </div>
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item
                                    className="mt-5px"
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['settingPeriodFromMonth']`}
                                    validate={(value) => ValidateField(
                                        value,
                                        messages["page.room.moneyRegister.month"],
                                        {
                                            required: messages["form.error.mixed.required"],
            
                                        },
                                        {
                                            required : true,
                                        }
                                    )}
                                >
                                    <div className="d-flex align-items-center">
                                        <Select
                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['settingPeriodFromMonth']`}
                                            listHeight={320}
                                            style={{ width: 70 }}
                                            {...searchSelect}
                                        >
                                            {monthOptions}
                                        </Select>
                                        <span style={{ marginLeft: "5px", whiteSpace: 'nowrap' }} className="item-center">
                                            {messages["page.room.moneyRegister.month"]}
                                        </span>
                                        <span style={{ marginLeft: "2rem", marginRight: "2rem" }} className="item-center">～</span>
                                    </div>
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item
                                    className="row-reverse mt-5px mr-1"
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['settingPeriodToYear']`}
                                    validate={(value) => ValidateField(
                                        value,
                                        messages["page.room.moneyRegister.year"],
                                        {
                                            required: messages["form.error.mixed.required"],
            
                                        },
                                        {
                                            required : true,
                                        }
                                    )}
                                >
                                    <div className="d-flex align-items-center">
                                        <DatePicker
                                            picker="year"
                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['settingPeriodToYear']`}
                                            onChange={onChangeSettingPeriodTo}
                                            value={settingPeriodTo}
                                            disabledDate={disabledDate}
                                        />
                                        <span style={{ marginLeft: "5px", whiteSpace: 'nowrap' }} className="item-center">
                                            {messages["page.room.moneyRegister.year"]}
                                        </span>
                                    </div>
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item
                                    className="mt-5px"
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['settingPeriodToMonth']`}
                                    validate={(value) => ValidateField(
                                        value,
                                        messages["page.room.moneyRegister.month"],
                                        {
                                            required: messages["form.error.mixed.required"],
            
                                        },
                                        {
                                            required : true,
                                        }
                                    )}
                                >
                                    <div className="d-flex align-items-center">
                                        <Select
                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['settingPeriodToMonth']`}
                                            listHeight={320}
                                            style={{ width: 70 }}
                                            {...searchSelect}
                                        >
                                            {monthOptions}
                                        </Select>
                                        <span style={{ marginLeft: "5px", whiteSpace: 'nowrap' }} className="item-center">
                                            {messages["page.room.moneyRegister.month"]}
                                        </span>
                                        <span style={{ marginLeft: "10px", whiteSpace: 'nowrap' }} className="item-center">
                                            {messages["page.room.moneyRegister.to"]}
                                        </span>
                                    </div>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form.Item>
                </Col>
            </Row>

            <Row justify="start" className="mb-10">
                <Col style={{ fontWeight: 800 }}>
                    <span className={"custom-title"}>{messages["page.room.moneyRegister.basicPriceSetting"]}</span>
                </Col>
            </Row>

            <Row justify="start" className="mb-25">
                <Col  {...colSingleUnit}>
                    <Form.Item
                        label={
                            <div>
                                <div className="custom-required">{messages["page.room.moneyRegister.roomPriceFormat"]}</div>
                                <div>
                                    <span className="text-note-row">{messages["page.room.moneyRegister.roomPriceFormatNote"]}</span>
                                </div>
                            </div>
                        }
                        name={`apiRoomOtas[${tabKey}]['fieldContents']['priceTypeFormat']`}
                        className="custom-label"
                        {...inputColLeft}
                        validate={(value) => ValidateField(
                            value,
                            messages["page.room.moneyRegister.roomPriceFormat"],
                            {
                                required: messages["form.error.mixed.required"]
                            },
                            {
                                required: true
                            }
                        )}
                    >
                        <Select
                            name={`apiRoomOtas[${tabKey}]['fieldContents']['priceTypeFormat']`}
                            onChange={(values) => onChangePriceTypeFormat(values, `apiRoomOtas[${tabKey}]['fieldContents']['priceTypeFormat']`)}
                            disabled={disableMoneyRegister}
                            {...searchSelect}
                        >
                            {optionValues.map((priceFormat, index) => {
                                if (priceFormat.fieldKey == "room_price_type_format") {
                                    return (
                                        <Select.Option
                                            key={index}
                                            value={priceFormat.value}
                                            {...searchSelect}
                                        >
                                            {priceFormat.value}
                                        </Select.Option>
                                    );
                                }
                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <Row justify="start" className="mb-25">
                <Col  {...colSingleUnit}>
                    <Form.Item
                        label={messages["page.room.moneyRegister.roomRateFormat"]}
                        name={`apiRoomOtas[${tabKey}]['fieldContents']['priceDayFormat']`}
                        className="required"
                        {...inputColLeft}
                        validate={(value) => ValidateField(
                            value,
                            messages["page.room.moneyRegister.roomRateFormat"],
                            {
                                required: messages["form.error.mixed.required"]
                            },
                            {
                                required: true
                            }
                        )}
                    >
                        <Select
                            name={`apiRoomOtas[${tabKey}]['fieldContents']['priceDayFormat']`}
                            onChange={(values) => onChangePriceDayFormat(values, `apiRoomOtas[${tabKey}]['fieldContents']['priceDayFormat']`)}
                            disabled={disableMoneyRegister}
                            {...searchSelect}
                        >
                            {optionValues.map((roomFormat, index) => {
                                if (roomFormat.fieldKey == "room_price_day_format") {
                                    return (
                                        <Select.Option
                                            key={index}
                                            value={roomFormat.value}
                                        >
                                            {roomFormat.value}
                                        </Select.Option>
                                    );
                                }
                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <div style={{ width: "100%", overflow: 'auto', marginBottom: '25px' }}>
                {renderItem(
                    values
                )}
            </div>
        </MoneyRegisterStyle >
    )
}

export default memo(MoneyRegister);
