import React, { useState, useEffect, useCallback } from "react";
import { Radio, Form, Button, Row, Checkbox, Col, Modal } from "antd";
import { useIntl } from "react-intl";
import { isEmpty } from "lodash";
import { MoneyRegisterStyle } from "@iso/components/RoomAddAndEditComponent/components/MoneyRegister/MoneyRegisterStyle";
import CheckBoxOnly from "../../MoneyRegisters/CheckBoxOnly";
import { colFull } from "@iso/assets/styles/form.style";
import { arrayOptionDay, PRICE_DAY_FORMAT_DAY_OFF_WEEK, PRICE_DAY_FORMAT_WEEKDAY } from "@iso/constants/api.constant";
import InputPoint from "../../MoneyRegisters/InputPoint";

const TableSettingPriceByRoom = ({
    setFieldValue,
    dataRoom,
    tabKey,
    dataOtaTab,
    closeMoneyRegisterPopup,
    setEventKodomoSubmit,
    isCancelInsertKodomo,
    selectedRowKeys,
    listApiRoom
}) => {
    const { messages } = useIntl();
    const [form] = Form.useForm();

    const [initialValueFormKodomo, setInitialValueFormKodomo] = useState(kodomoModeRoomInitialValue)
    const [infrantMealPalletAcceptDate, setInfrantMealPalletAcceptDate] = useState(messages["page.room.moneyRegister.can"])
    const [infrantMealOnlyAcceptDate, setInfrantMealOnlyAcceptDate] = useState(messages["page.room.moneyRegister.can"])
    const [activeMeal, setActiveMeal] = useState(true);
    const [priceDayFormat, setPriceDayFormat] = useState(null);
    const [isHoliday, setIsHoliday] = useState([]);
    const [dataKodomoByRoom, setDataKodomoByRoom] = useState({})

    useEffect(() => {
        form.resetFields();
        const dataKodomo = dataOtaTab.kodomo[dataRoom.siteRoomId];
        if (!isEmpty(dataKodomo)) {
            setDataKodomoByRoom(dataKodomo)
            form.setFieldsValue(dataKodomo)
            setPriceDayFormat(dataKodomo.priceDayFormat)
            setIsHoliday(dataKodomo.publicHoliday)
            const valuesToCheck = messages["page.room.moneyRegister.can"];
            if (dataKodomo.infrantMealPalletAcceptDate !== valuesToCheck
                && dataKodomo.infrantMealOnlyAcceptDate !== valuesToCheck
            ) {
                setActiveMeal(false)
            }
        } else {
            form.setFieldsValue(initialValueFormKodomo)
        }
    }, [dataOtaTab.kodomo, isCancelInsertKodomo])

    const onChangeInfrantMealPallet = useCallback((e, form) => {
        const value = e.target.value;
        setInfrantMealPalletAcceptDate(value)
        setActiveMeal(true)

        const valuesToCheck = messages["page.room.moneyRegister.can"];
        if (value !== valuesToCheck && infrantMealOnlyAcceptDate !== valuesToCheck) {
            setActiveMeal(false)
            form.resetFields(["infrantMealPalletAcceptDate"]);
            form.resetFields(["infrantDinnerType"]);
        }

    }, [infrantMealOnlyAcceptDate]);

    const onChangeInfrantMealOnly = useCallback((e, form) => {
        const value = e.target.value;
        setInfrantMealOnlyAcceptDate(value)
        setActiveMeal(true)

        const valuesToCheck = messages["page.room.moneyRegister.can"];
        if (value !== valuesToCheck && infrantMealPalletAcceptDate !== valuesToCheck) {
            setActiveMeal(false)
            form.resetFields(["infrantBreakfastType"]);
            form.resetFields(["infrantDinnerType"]);
        }

    }, [infrantMealPalletAcceptDate]);

    const handleSubmitKodomo = (data) => {
        const arrayKodomo = dataOtaTab.kodomo;
        if (!isEmpty(selectedRowKeys) && selectedRowKeys.length > 1) {
            const listRoomCheckbox = listApiRoom.filter(item => selectedRowKeys.includes(item.siteRoomId));
            let checkRoomType = true;
            for (let apiRoom of listRoomCheckbox) {
                if (apiRoom.roomPriceTypeFormat !== dataRoom.roomPriceTypeFormat) {
                    checkRoomType = false;
                    Modal.error({
                        title: messages["page.apiPlan.modal.errorTitle"],
                        content: messages["page.apiPlanAddAndEdit.apiRoom.roomType.error"],
                    });
                    break;
                }
            }

            if (checkRoomType) {
                selectedRowKeys.map((val) => {
                    arrayKodomo[`${val}`] = data;
                    arrayKodomo[`${val}`]['priceTypeFormat'] = dataRoom.roomPriceTypeFormat
                })
                setFieldValue(`otaFieldContent.${tabKey}.kodomo`, arrayKodomo)
            }
            
        } else {
            arrayKodomo[`${dataRoom.siteRoomId}`] = data;
            arrayKodomo[`${dataRoom.siteRoomId}`]['priceTypeFormat'] = dataRoom.roomPriceTypeFormat
            setFieldValue(`otaFieldContent.${tabKey}.kodomo`, arrayKodomo)
        }
        closeMoneyRegisterPopup();
    }

    return (
        <MoneyRegisterStyle>
            <Form
                onFinish={handleSubmitKodomo}
                colon={false}
                form={form}
                initialValues={initialValueFormKodomo}
            >
                <Row justify="start">
                    <Col {...colFull}>
                        <Form.Item
                            label={messages["page.room.moneyRegister.roomRateFormat"]}
                            name="priceDayFormat"
                            className="required rate-format"
                            labelCol={{ lg: 6, xs: 24 }}
                            wrapperCol={{ lg: 18, xs: 24 }}
                        >
                            <Radio.Group
                                name="priceDayFormat"
                                onChange={(e) => (setPriceDayFormat(e.target.value))}
                            >
                                <Radio value={PRICE_DAY_FORMAT_WEEKDAY}>
                                    <span>{PRICE_DAY_FORMAT_WEEKDAY}</span>
                                </Radio>

                                <Radio value={PRICE_DAY_FORMAT_DAY_OFF_WEEK}>
                                    <span>{PRICE_DAY_FORMAT_DAY_OFF_WEEK}</span>
                                </Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>

                <table className="w-full table-point">
                    {priceDayFormat == PRICE_DAY_FORMAT_DAY_OFF_WEEK && (
                        <tbody>
                            <tr>
                                <td
                                    align="center"
                                    bgcolor="#EDEDED"
                                    className="kihon01"
                                    colSpan="3"
                                >
                                    <span>{messages["page.room.moneyRegister.window"]}</span>
                                </td>
                                <td
                                    align="center"
                                    bgcolor="#EDEDED"
                                    className="kihon01"
                                >
                                    <font color="#FF0000">
                                        <b>
                                            {messages["page.room.moneyRegister.sunday"]}
                                        </b>
                                    </font>
                                </td>
                                <td
                                    align="center"
                                    bgcolor="#EDEDED"
                                    className="kihon01"
                                >
                                    <b>
                                        {messages["page.room.moneyRegister.monday"]}
                                    </b>
                                </td>
                                <td
                                    align="center"
                                    bgcolor="#EDEDED"
                                    className="kihon01"
                                >
                                    <b>
                                        {messages["page.room.moneyRegister.tuesday"]}
                                    </b>
                                </td>
                                <td
                                    align="center"
                                    bgcolor="#EDEDED"
                                    className="kihon01"
                                >
                                    <b>
                                        {messages["page.room.moneyRegister.wednesday"]}
                                    </b>
                                </td>
                                <td
                                    align="center"
                                    bgcolor="#EDEDED"
                                    className="kihon01"
                                >
                                    <b>
                                        {messages["page.room.moneyRegister.thursday"]}
                                    </b>
                                </td>
                                <td
                                    align="center"
                                    bgcolor="#EDEDED"
                                    className="kihon01"
                                >
                                    <b>
                                        {messages["page.room.moneyRegister.friday"]}
                                    </b>
                                </td>
                                <td
                                    align="center"
                                    bgcolor="#EDEDED"
                                >
                                    <table border="0" style={{ border: "none" }}>
                                        <tbody>
                                            <tr>
                                                <td style={{ border: "none" }}>
                                                    <Form.Item
                                                        name="stockType"
                                                    >
                                                        <Radio.Group
                                                            name="stockType"
                                                        >
                                                            <Radio value={messages["page.room.moneyRegister.saturday"]}>
                                                                <span>{messages["page.room.moneyRegister.saturday"]}</span>
                                                            </Radio>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ border: "none" }}>
                                                    <Form.Item
                                                        name="stockType"
                                                    >
                                                        <Radio.Group
                                                            name="stockType"
                                                        >
                                                            <Radio value={messages["page.room.moneyRegister.dayBeforeHoliday"]}>
                                                                <span>{messages["page.room.moneyRegister.dayBeforeHoliday"]}</span>
                                                            </Radio>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                                <td
                                    align="center"
                                    bgcolor="#EDEDED"
                                    className="kihon01"
                                >
                                    <div className="flex-group-checkbox">
                                        <Form.Item
                                            name="publicHoliday"
                                        >
                                            <Checkbox.Group
                                                name="publicHoliday"
                                                onChange={(e) => setIsHoliday(e)}
                                            >
                                                <Checkbox
                                                    value={messages["page.room.moneyRegister.publicHoliday"]}
                                                />
                                            </Checkbox.Group>
                                        </Form.Item>
                                        <span className="ml-5 item-center">
                                            <font color="#FF9933">
                                                <b>
                                                    {messages["page.room.moneyRegister.publicHoliday"]}
                                                </b>
                                            </font>
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td
                                    align="center"
                                    className="kihon01"
                                    rowSpan="2"
                                    width="43"
                                >
                                    <span>{messages["page.room.moneyRegister.meal"]}</span>
                                </td>
                                <td
                                    align="center"
                                    className="kihon01"
                                    rowSpan="2"
                                >
                                    <span>{messages["page.room.moneyRegister.adult"]}</span>
                                    <br />
                                    <span>{messages["page.room.moneyRegister.elementarySchoolStudent"]}</span>
                                </td>
                                <td
                                    align="center"
                                    className="kihon02"
                                >
                                    <span>{messages["page.room.moneyRegister.meal.breakfast"]}</span>
                                </td>
                                {arrayOptionDay.map((option, key) => {
                                    return (
                                        <td
                                            align="center"
                                            className="kihon01"
                                            bgcolor={
                                                (option == 'sunday')
                                                    ? "#FFEBE8"
                                                    : (
                                                        ((option == 'saturday') ? "#E4F1FF" : '') || ((option == 'holiday') ? "#FFE8C7" : '')
                                                    )
                                            }
                                            key={key}
                                        >
                                            <CheckBoxOnly
                                                label={messages["page.room.moneyRegister.including"]}
                                                name={`${option}HasBreakfast`}
                                                dataKodomoByRoom={dataKodomoByRoom}
                                                form={form}
                                                isHoliday={isHoliday}
                                                option={option}
                                            />
                                        </td>
                                    )
                                })}
                            </tr>
                            <tr>
                                <td
                                    align="center"
                                    className="kihon02"
                                >
                                    <span>{messages["page.room.moneyRegister.meal.dinner"]}</span>
                                </td>
                                {arrayOptionDay.map((option, key) => {
                                    return (
                                        <td
                                            align="center"
                                            className="kihon01"
                                            bgcolor={
                                                (option == 'sunday')
                                                    ? "#FFEBE8"
                                                    : (
                                                        ((option == 'saturday') ? "#E4F1FF" : '') || ((option == 'holiday') ? "#FFE8C7" : '')
                                                    )
                                            }
                                            key={key}
                                        >
                                            <CheckBoxOnly
                                                label={messages["page.room.moneyRegister.including"]}
                                                name={`${option}HasDinner`}
                                                dataKodomoByRoom={dataKodomoByRoom}
                                                form={form}
                                                isHoliday={isHoliday}
                                                option={option}
                                            />
                                        </td>
                                    )
                                })}
                            </tr>
                            <tr>
                                <td
                                    align="center"
                                    className="kihon01"
                                    colSpan="3"
                                >
                                    <span>{messages["page.room.moneyRegister.point"]}</span>
                                </td>
                                {arrayOptionDay.map((option, key) => {
                                    return (
                                        <td
                                            align="center"
                                            className="kihon01"
                                            bgcolor={
                                                (option == 'sunday')
                                                    ? "#FFEBE8"
                                                    : (
                                                        ((option == 'saturday') ? "#E4F1FF" : '') || ((option == 'holiday') ? "#FFE8C7" : '')
                                                    )
                                            }
                                            key={key}
                                        >
                                            <InputPoint
                                                name={`${option}Point`}
                                                maxValueCondition={20}
                                                maxLengthCondition={2}
                                                isHoliday={isHoliday}
                                                option={option}
                                            />
                                        </td>
                                    )
                                })}
                            </tr>
                        </tbody>
                    )}

                    {priceDayFormat == PRICE_DAY_FORMAT_WEEKDAY && (
                        <table className="w-full table-point">
                            <tbody>
                                <tr>
                                    <td
                                        colSpan={3}
                                        align="center"
                                        bgcolor="#EDEDED"
                                    >
                                        {messages["page.room.moneyRegister.window"]}
                                    </td>
                                    <td
                                        bgcolor="#EDEDED"
                                        align="center"
                                    >
                                        {messages["page.room.moneyRegister.weekdays"]}
                                    </td>
                                </tr>

                                <tr>
                                    <td
                                        align="center"
                                        className="kihon01"
                                        width="48"
                                    >
                                        <span>{messages["page.room.moneyRegister.meal"]}</span>
                                    </td>
                                    <td
                                        align="center"
                                        className="kihon01"
                                    >
                                        <span>{messages["page.room.moneyRegister.adult"]}</span>
                                        <br />
                                        <span>{messages["page.room.moneyRegister.elementarySchoolStudent"]}</span>
                                    </td>
                                    <td
                                        align="center"
                                        className="kihon01"
                                    >
                                        <span>{messages["page.room.moneyRegister.mealCategory"]}</span>
                                    </td>
                                    <td
                                        align="center"
                                        className="kihon02 flex checkbox-meal"
                                    >
                                        <div className="flex-group-checkbox">
                                            <div className="mr-10">
                                                <CheckBoxOnly
                                                    label={messages["page.room.moneyRegister.breakfastIncluded"]}
                                                    name="normalHasBreakfast"
                                                    dataKodomoByRoom={dataKodomoByRoom}
                                                    form={form}
                                                />
                                            </div>
                                            <div>
                                                <CheckBoxOnly
                                                    label={messages["page.room.moneyRegister.dinnerIncluded"]}
                                                    name="normalHasDinner"
                                                    dataKodomoByRoom={dataKodomoByRoom}
                                                    form={form}
                                                />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        align="center"
                                        className="kihon01"
                                        colSpan="3"
                                        width="200"
                                    >
                                        <span>{messages["page.room.moneyRegister.point"]}</span>
                                    </td>
                                    <td
                                        align="center"
                                        className="kihon02"
                                    >
                                        <InputPoint
                                            name="normalPoint"
                                            maxValueCondition={20}
                                            maxLengthCondition={2}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                </table>
                <table
                    border="1"
                    cellPadding="0"
                    cellSpacing="0"
                    width="100%"
                    className="kodomo-table w-full"
                >
                    <tbody>
                        <tr
                            align="center"
                            bgcolor="#EDEDED"
                            className="kihon_c"
                        >
                            <td colSpan="2">
                                {messages["page.room.moneyRegister.child"]}
                            </td>
                            <td width="45">
                                {messages["page.room.moneyRegister.capacity"]}
                            </td>
                            <td width="100">
                                {messages["page.room.moneyRegister.meal"]}
                            </td>
                            <td width="93">
                                {messages["page.room.moneyRegister.accommodation"]}
                            </td>
                        </tr>
                        <tr>
                            <td
                                align="center"
                                bgcolor="#FFEFBB"
                                className="kihon_c"
                                rowSpan="2"
                                width="50"
                            >
                                {messages["page.room.moneyRegister.elementarySchoolStudent"]}
                            </td>
                            <td
                                className="kihon_c"
                                width="85"
                            >
                                {messages["page.room.moneyRegister.upperGrade"]}
                            </td>
                            <td
                                align="center"
                                className="kihon_c"
                                width="36"
                            >
                                {messages["page.room.moneyRegister.contains"]}
                            </td>
                            <td
                                align="center"
                                className="kihon_c"
                                rowSpan="2"
                                width="100"
                            >
                                {messages["page.room.moneyRegister.sameAsAdult"]}
                            </td>
                            <td
                                className="kihon_c accommodation"
                                nowrap=""
                            >
                                <Form.Item
                                    name="juniorHighAcceptDate"
                                >
                                    <Radio.Group
                                        name="juniorHighAcceptDate"
                                    >
                                        <Radio value={messages["page.room.moneyRegister.can"]}>
                                            <span>{messages["page.room.moneyRegister.can"]}</span>
                                        </Radio>
                                        <Radio value={messages["page.room.moneyRegister.no"]}>
                                            <span>{messages["page.room.moneyRegister.no"]}</span>
                                        </Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </td>
                        </tr>
                        <tr>
                            <td
                                className="kihon_c"
                                width="85"
                            >
                                {messages["page.room.moneyRegister.lowerGrades"]}
                            </td>
                            <td
                                align="center"
                                className="kihon_c"
                                width="36"
                            >
                                {messages["page.room.moneyRegister.contains"]}
                            </td>
                            <td
                                className="kihon_c accommodation"
                                nowrap=""
                            >
                                <Form.Item
                                    name="juniorLowAcceptDate"
                                >
                                    <Radio.Group
                                        name="juniorLowAcceptDate"
                                    >
                                        <Radio value={messages["page.room.moneyRegister.can"]}>
                                            <span>{messages["page.room.moneyRegister.can"]}</span>
                                        </Radio>
                                        <Radio value={messages["page.room.moneyRegister.no"]}>
                                            <span>{messages["page.room.moneyRegister.no"]}</span>
                                        </Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </td>
                        </tr>
                        <tr>
                            <td
                                align="center"
                                bgcolor="#FFEFBB"
                                className="kihon_c"
                                rowSpan="4"
                                width="50"
                            >
                                {messages["page.room.moneyRegister.infant"]}
                            </td>
                            <td
                                className="kihon_c"
                                width="85"
                            >
                                {messages["page.room.moneyRegister.mealAndFutonIncluded"]}
                            </td>
                            <td
                                align="center"
                                className="kihon_c"
                                width="36"
                            >
                                {messages["page.room.moneyRegister.contains"]}
                            </td>
                            <td
                                align="center"
                                className="kihon_c"
                                rowSpan="2"
                                width="100"
                            >
                                <div>
                                    <span>{messages["page.room.moneyRegister.meal.breakfast"]}：</span>
                                    <br />
                                    <Form.Item
                                        name="infrantBreakfastType"
                                        rules={[
                                            {
                                                validator(_, value) {
                                                    if (!value && activeMeal) {
                                                        return Promise.reject(
                                                            messages["form.error.mixed.required"].replace(
                                                                "${path}",
                                                                messages["page.room.moneyRegister.meal.breakfast"]
                                                            )
                                                        );
                                                    }
                                                    return Promise.resolve();
                                                },
                                            },
                                        ]}
                                    >
                                        <Radio.Group
                                            name="infrantBreakfastType"
                                        >
                                            <Radio value={messages["page.room.moneyRegister.including"]}>
                                                <span>{messages["page.room.moneyRegister.including"]}</span>
                                            </Radio>
                                            <Radio value={messages["page.room.moneyRegister.another"]}>
                                                <span>{messages["page.room.moneyRegister.another"]}</span>
                                            </Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </div>
                                <div>
                                    <span>{messages["page.room.moneyRegister.meal.dinner"]}：</span>
                                    <br />
                                    <Form.Item
                                        name="infrantDinnerType"
                                        rules={[
                                            {
                                                validator(_, value) {
                                                    if (!value && activeMeal) {
                                                        return Promise.reject(
                                                            messages["form.error.mixed.required"].replace(
                                                                "${path}",
                                                                messages["page.room.moneyRegister.meal.dinner"]
                                                            )
                                                        );
                                                    }

                                                    return Promise.resolve();
                                                },
                                            },
                                        ]}
                                    >
                                        <Radio.Group
                                            name="infrantDinnerType"
                                        >
                                            <Radio value={messages["page.room.moneyRegister.including"]}>
                                                <span>{messages["page.room.moneyRegister.including"]}</span>
                                            </Radio>
                                            <Radio value={messages["page.room.moneyRegister.another"]}>
                                                <span>{messages["page.room.moneyRegister.another"]}</span>
                                            </Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </div>
                            </td>
                            <td
                                className="kihon_c accommodation"
                                nowrap=""
                            >
                                <Form.Item
                                    name="infrantMealPalletAcceptDate"
                                >
                                    <Radio.Group
                                        name="infrantMealPalletAcceptDate"
                                        onChange={(e) => onChangeInfrantMealPallet(e, form)}
                                    >
                                        <Radio value={messages["page.room.moneyRegister.can"]}>
                                            <span>{messages["page.room.moneyRegister.can"]}</span>
                                        </Radio>
                                        <Radio value={messages["page.room.moneyRegister.no"]}>
                                            <span>{messages["page.room.moneyRegister.no"]}</span>
                                        </Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </td>
                        </tr>
                        <tr>
                            <td
                                className="kihon_c"
                                width="85"
                            >
                                {messages["page.room.moneyRegister.mealOnly"]}
                            </td>
                            <td
                                align="center"
                                width="36"
                            >
                            </td>
                            <td
                                className="kihon_c accommodation"
                                nowrap=""
                            >
                                <Form.Item
                                    name="infrantMealOnlyAcceptDate"
                                >
                                    <Radio.Group
                                        name="infrantMealOnlyAcceptDate"
                                        onChange={(e) => onChangeInfrantMealOnly(e, form)}
                                    >
                                        <Radio value={messages["page.room.moneyRegister.can"]}>
                                            <span>{messages["page.room.moneyRegister.can"]}</span>
                                        </Radio>
                                        <Radio value={messages["page.room.moneyRegister.no"]}>
                                            <span>{messages["page.room.moneyRegister.no"]}</span>
                                        </Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </td>
                        </tr>
                        <tr>
                            <td
                                className="kihon_c"
                                width="85"
                            >
                                {messages["page.room.moneyRegister.futonOnly"]}
                            </td>
                            <td
                                align="center"
                                className="kihon_c"
                                width="36"
                            >
                                {messages["page.room.moneyRegister.contains"]}
                            </td>
                            <td
                                align="center"
                                className="kihon_c"
                                rowSpan="2"
                                width="100"
                            >
                                {messages["page.room.moneyRegister.none"]}
                            </td>
                            <td
                                className="kihon_c accommodation"
                                nowrap=""
                            >
                                <Form.Item
                                    name="infrantPalletOnlyAcceptDate"
                                >
                                    <Radio.Group
                                        name="infrantPalletOnlyAcceptDate"
                                    >
                                        <Radio value={messages["page.room.moneyRegister.can"]}>
                                            <span>{messages["page.room.moneyRegister.can"]}</span>
                                        </Radio>
                                        <Radio value={messages["page.room.moneyRegister.no"]}>
                                            <span>{messages["page.room.moneyRegister.no"]}</span>
                                        </Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </td>
                        </tr>
                        <tr>
                            <td
                                className="kihon_c"
                                width="85"
                            >
                                {messages["page.room.moneyRegister.noMealsOrBeddingRequired"]}
                            </td>
                            <td
                                align="center"
                                width="36"
                            >
                            </td>
                            <td
                                className="kihon_c accommodation"
                                nowrap=""
                            >
                                <Form.Item
                                    name="infrantNoMealPalletAcceptDate"
                                >
                                    <Radio.Group
                                        name="infrantNoMealPalletAcceptDate"
                                    >
                                        <Radio value={messages["page.room.moneyRegister.can"]}>
                                            <span>{messages["page.room.moneyRegister.can"]}</span>
                                        </Radio>
                                        <Radio value={messages["page.room.moneyRegister.no"]}>
                                            <span>{messages["page.room.moneyRegister.no"]}</span>
                                        </Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <Row justify="center" style={{ marginTop: "20px" }}>
                    <Button onClick={closeMoneyRegisterPopup} style={{ marginRight: "10px" }}>
                        {messages["page.Account.buttonCancel"]}
                    </Button>
                    <Button type="primary" htmlType="submit" onClick={() => setEventKodomoSubmit(true)}>
                        {messages["popup.selectStore.decision"]}
                    </Button>
                </Row>
            </Form>
        </MoneyRegisterStyle>
    );
};

const kodomoModeRoomInitialValue = {
    priceDayFormat: null,

    normalHasBreakfast: [],
    normalHasDinner: [],
    normalPoint: 1,

    stockType: null,
    publicHoliday: [],
    sundayPoint: 1,
    sundayHasBreakfast: [],
    sundayHasDinner: [],
    mondayPoint: 1,
    mondayHasBreakfast: [],
    mondayHasDinner: [],
    tuesdayPoint: 1,
    tuesdayHasBreakfast: [],
    tuesdayHasDinner: [],
    wednesdayPoint: 1,
    wednesdayHasBreakfast: [],
    wednesdayHasDinner: [],
    thursdayPoint: 1,
    thursdayHasBreakfast: [],
    thursdayHasDinner: [],
    fridayPoint: 1,
    fridayHasBreakfast: [],
    fridayHasDinner: [],
    saturdayPoint: 1,
    saturdayHasBreakfast: [],
    saturdayHasDinner: [],
    holidayPoint: 1,
    holidayHasBreakfast: [],
    holidayHasDinner: [],
    
    infrantBreakfastType: null,
    infrantDinnerType: null,
    juniorHighAcceptDate: "可",
    juniorLowAcceptDate: "可",
    infrantMealPalletAcceptDate: "可",
    infrantMealOnlyAcceptDate: "可",
    infrantPalletOnlyAcceptDate: "可",
    infrantNoMealPalletAcceptDate: "可"
}

export default TableSettingPriceByRoom;
