import { API_ENDPOINTS } from "@iso/constants/apiEndpoints.constant";
import BaseService from "./base.service";

export default class apiCrawlOptionService extends BaseService {
  getListCrawlOption = (values) => {
    return this.axios.post(API_ENDPOINTS.GET_OPTION, values);
  };

  crawlDataOption = (data) => {
    return this.axios.post(API_ENDPOINTS.CRAWL_OPTION, data);
  }
}