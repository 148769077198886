import React, { memo, useState, useEffect, useCallback } from "react";
import { Checkbox, Radio } from "antd";
import { Form, Select, InputNumber, Input } from "formik-antd";
import { useIntl } from "react-intl";
import { isEmpty } from "lodash";
import { CaretRightOutlined } from '@ant-design/icons';
import MealOptionSelect from "./components/MealOptionSelect";
import CheckBoxOnly from "./components/CheckBoxOnly";
import TableSettingPriceByRoom from "./components/TableSettingPriceByRoom";
import { ValidateField } from "@iso/validates/ValidateField";
import {searchSelect} from "@iso/constants/api.constant";

const PriceSettingByRoomAndWeekday = ({
    values,
    dataOta,
    setFieldValue,
    tabKey,
    apiRoomId,
    optionValues,
    disabledMode,
    dataRoom
}) => {
    const { messages } = useIntl();
    const [isHoliday, setIsHoliday] = useState([]);
    const [activeState, setActiveState] = useState({
        activeAmPk: false,
        activeCmPk: false,
        activeSyosai: false
    });

    const [adultMealBreakfast, setAdultMealBreakfast] = useState([]);
    const [adultMealDinner, setAdultMealDinner] = useState([]);
    const [childMealBreakfast, setChildMealBreakfast] = useState([]);
    const [childMealDinner, setChildMealDinner] = useState([]);
    const [adultMealBreakfastHoliday, setAdultMealBreakfastHoliday] = useState([]);
    const [adultMealDinnerHoliday, setAdultMealDinnerHoliday] = useState([]);
    const [childMealBreakfastHoliday, setChildMealBreakfastHoliday] = useState([]);
    const [childMealDinnerHoliday, setChildMealDinnerHoliday] = useState([]);

    useEffect(() => {
        if (dataRoom != undefined
            && dataRoom != null
            && dataRoom.apiRoomOtas != null
            && dataRoom.apiRoomOtas != undefined
            && dataRoom.apiRoomOtas[tabKey] != null
            && dataRoom.apiRoomOtas[tabKey] != undefined
            && !isEmpty(dataRoom.apiRoomOtas[tabKey])
            && !isEmpty(dataRoom.apiRoomOtas[tabKey].fieldContents)
            && !isEmpty(dataRoom.apiRoomOtas[tabKey].fieldContents.moneyRegister)
        ) {
            const newValues = dataRoom.apiRoomOtas[tabKey].fieldContents.moneyRegister;
            const updatedPriceSetting = { ...priceSettingByRoomAndWeekdayInitialValue, ...newValues };

            setFieldValue(`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']`, updatedPriceSetting);
        }
    }, [dataRoom])

    useEffect(() => {
        if (values != undefined
            && values != null
            && values.apiRoomOtas != null
            && values.apiRoomOtas != undefined
            && values.apiRoomOtas[tabKey] != null
            && values.apiRoomOtas[tabKey] != undefined
            && !isEmpty(values.apiRoomOtas[tabKey])
            && !isEmpty(values.apiRoomOtas[tabKey].fieldContents)
        ) {
            const mealTypes = [
                { stateSetter: setAdultMealBreakfast, label: 'meal.breakfast', name: 'adultBreakfastPlace', holiday: false },
                { stateSetter: setAdultMealDinner, label: 'meal.dinner', name: 'adultDinerPlace', holiday: false },
                { stateSetter: setChildMealBreakfast, label: 'meal.breakfast', name: 'childBreakfastPlace', holiday: false },
                { stateSetter: setChildMealDinner, label: 'meal.dinner', name: 'childDinerPlace', holiday: false },
                { stateSetter: setAdultMealBreakfastHoliday, label: 'meal.breakfast', name: 'adultBreakfastHolidayPlace', holiday: true },
                { stateSetter: setAdultMealDinnerHoliday, label: 'meal.dinner', name: 'adultDinerHolidayPlace', holiday: true },
                { stateSetter: setChildMealBreakfastHoliday, label: 'meal.breakfast', name: 'childBreakfastHolidayPlace', holiday: true },
                { stateSetter: setChildMealDinnerHoliday, label: 'meal.dinner', name: 'childDinerHolidayPlace', holiday: true }
            ];

            let totalMeal = 5
            mealTypes.forEach(({ stateSetter, label, name, holiday }) => {
                const content = Array.from({ length: totalMeal }, (_, i) => (
                    <MealOptionSelect
                        key={`${label}${i + 1}`}
                        label={`${messages[`page.room.moneyRegister.${label}`]}${i + 1}`}
                        name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister'][${name}${i + 1}]`}
                        holidayCondition={holiday}
                        isHoliday={isHoliday}
                        disabledMode={disabledMode}
                    />
                ));
                stateSetter(content);
            });
        }

        if (!isEmpty(values.apiRoomOtas[tabKey].fieldContents.moneyRegister)) {
            setIsHoliday(values.apiRoomOtas[tabKey].fieldContents.moneyRegister.isHoliday);
            setActiveState({
                activeAmPk: values.apiRoomOtas[tabKey].fieldContents.moneyRegister.ampkCollapse,
                activeCmPk: values.apiRoomOtas[tabKey].fieldContents.moneyRegister.cmpkCollapse,
                activeSyosai: values.apiRoomOtas[tabKey].fieldContents.moneyRegister.syosaiCollapse
            })
        } else {
            setFieldValue(`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']`, priceSettingByRoomAndWeekdayInitialValue)
            setFieldValue(`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['normalTaxType']`, messages["page.room.moneyRegister.taxIncluded"])
            setFieldValue(`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['holidayTaxType']`, messages["page.room.moneyRegister.taxIncluded"])
            let totalMeal = 5
            for (let index = 1; index <= totalMeal; index++) {
                setFieldValue(`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['adultBreakfastPlace${index}']`, null)
                setFieldValue(`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['adultDinerPlace${index}']`, null)
                setFieldValue(`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['childBreakfastPlace${index}']`, null)
                setFieldValue(`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['childDinerPlace${index}']`, null)
                setFieldValue(`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['adultBreakfastHolidayPlace${index}']`, null)
                setFieldValue(`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['adultDinerHolidayPlace${index}']`, null)
                setFieldValue(`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['childBreakfastHolidayPlace${index}']`, null)
                setFieldValue(`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['childDinerHolidayPlace${index}']`, null)
            }
        }

    }, [values, isHoliday])

    const onchangeActive = useCallback((id) => {
        if (id === "am_pk") {
            setActiveState(prevState => ({ ...prevState, activeAmPk: !prevState.activeAmPk }));
            setFieldValue(`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['ampkCollapse']`, !activeState.activeAmPk)
        } else if (id === "cm_pk") {
            setActiveState(prevState => ({ ...prevState, activeCmPk: !prevState.activeCmPk }));
            setFieldValue(`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['cmpkCollapse']`, !activeState.activeCmPk)
        } else if (id === "syosai") {
            setActiveState(prevState => ({ ...prevState, activeSyosai: !prevState.activeSyosai }));
            setFieldValue(`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['syosaiCollapse']`, !activeState.activeSyosai)
        }
    }, [activeState]);

    const onChangeCheckBoxHoliday = (name, value) => {
        setFieldValue(name, value)
        setIsHoliday(value)
    }

    return (
        <table
            bgcolor="#FFFFFF"
            border="1"
            bordercolor="#666666"
            cellPadding="2"
            cellSpacing="0"
        >
            <tbody>
                <tr>
                    <td
                        align="center"
                        bgcolor="#EDEDED"
                        className="kihon01"
                        colSpan="3"
                        nowrap=""
                        width="230"
                    >
                        {messages["page.room.moneyRegister.window"]}
                    </td>
                    <td
                        align="center"
                        bgcolor="#EDEDED"
                        className="kihon01"
                        nowrap=""
                        width="320"
                    >
                        {messages["page.room.moneyRegister.weekdays"]}
                    </td>
                    <td
                        align="center"
                        bgcolor="#EDEDED"
                        className="kihon01"
                        nowrap=""
                        width="320"
                    >
                        <div className="flex-group-checkbox">
                            <Form.Item
                                name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['isHoliday']`}
                            >
                                <Checkbox.Group 
                                    onChange={(value) => onChangeCheckBoxHoliday(`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['isHoliday']`, value)}
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['isHoliday']`}
                                    value={isHoliday}
                                    disabled={disabledMode}
                                >
                                    <Checkbox
                                        value={messages["page.room.moneyRegister.dayBeforeHoliday"]}
                                    />
                                </Checkbox.Group>
                            </Form.Item>
                            <span className="ml-5 item-center">{messages["page.room.moneyRegister.dayBeforeHoliday"]}</span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td
                        align="center"
                        className="kihon01"
                        colSpan="3"
                        nowrap=""
                        width="230"
                    >
                        {messages["page.room.moneyRegister.stockNumber"]}
                    </td>
                    <td
                        align="center"
                        className="kihon02"
                        nowrap=""
                    >
                        <Form.Item
                            name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['stockNormal']`}
                            validate={(value) => ValidateField(
                                value,
                                messages["page.room.moneyRegister.room"],
                                {
                                    required: messages["form.error.mixed.required"],
                                    integer: messages["form.error.number.integer"],
    
                                },
                                {
                                    required : true,
                                    integer : true,
                                }
                            )}
                        >
                            <InputNumber
                                name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['stockNormal']`}
                                maxLength={3}
                                min={0}
                                max={999}
                                defaultValue={0}
                                className="w-50"
                                disabled={disabledMode}
                            />
                            <span className="ml-5">{messages["page.room.moneyRegister.room"]}</span>
                        </Form.Item>
                    </td>
                    <td
                        align="center"
                        bgcolor="#E4F1FF"
                        className="kihon02"
                        nowrap=""
                    >
                        <Form.Item
                            name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['stockHoliday']`}
                            validate={(value) => ValidateField(
                                value,
                                messages["page.room.moneyRegister.room"],
                                {
                                    required: messages["form.error.mixed.required"],
                                    integer: messages["form.error.number.integer"],
    
                                },
                                {
                                    required : true,
                                    integer : true,
                                }
                            )}
                        >
                            <InputNumber
                                name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['stockHoliday']`}
                                maxLength={3}
                                min={0}
                                max={999}
                                defaultValue={0}
                                className="w-50"
                                disabled={isEmpty(isHoliday) || disabledMode}
                            />
                            <span className="ml-5">{messages["page.room.moneyRegister.room"]}</span>
                        </Form.Item>
                    </td>
                </tr>
                <tr>
                    <td
                        align="center"
                        className="kihon01"
                        nowrap=""
                        rowSpan="3"
                        width="48"
                    >
                        {messages["page.room.moneyRegister.fee"]}
                    </td>
                    <td
                        align="center"
                        bgcolor="#BDDA84"
                        className="kihon01"
                        nowrap=""
                    >
                        {messages["page.room.moneyRegister.rakutenTravel"]}
                    </td>
                    <td
                        align="center"
                        className="kihon01"
                        nowrap=""
                    >
                        {messages["page.room.moneyRegister.1roomTotal"]}
                    </td>
                    <td
                        align="center"
                        nowrap=""
                    >
                        <Form.Item
                            name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['rakutenNormal']`}
                            validate={(value) => ValidateField(
                                value,
                                messages["page.room.moneyRegister.fee"],
                                {
                                    required: messages["form.error.mixed.required"],
                                    integer: messages["form.error.number.integer"],
    
                                },
                                {
                                    required : true,
                                    integer : true,
                                }
                            )}
                        >
                            <InputNumber
                                name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['rakutenNormal']`}
                                maxLength={10}
                                min={0}
                                max={9999999999}
                                defaultValue={0}
                                className="w-100"
                                disabled={disabledMode}
                            />
                        </Form.Item>
                    </td>
                    <td
                        align="center"
                        bgcolor="#E4F1FF"
                        nowrap=""
                    >
                        <Form.Item
                            name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['rakutenHoliday']`}
                            validate={(value) => ValidateField(
                                value,
                                messages["page.room.moneyRegister.fee"],
                                {
                                    required: messages["form.error.mixed.required"],
                                    integer: messages["form.error.number.integer"],
    
                                },
                                {
                                    required : true,
                                    integer : true,
                                }
                            )}
                        >
                            <InputNumber
                                name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['rakutenHoliday']`}
                                maxLength={10}
                                min={0}
                                max={9999999999}
                                defaultValue={0}
                                className="w-100"
                                disabled={isEmpty(isHoliday) || disabledMode}
                            />
                        </Form.Item>
                    </td>
                </tr>
                <tr>
                    <td
                        align="center"
                        bgcolor="#f7dbdb"
                        className="kihon01"
                        nowrap=""
                    >
                        {messages["page.room.moneyRegister.package"]}
                    </td>
                    <td
                        align="center"
                        className="kihon01"
                        nowrap=""
                    >
                        {messages["page.room.moneyRegister.1roomTotal"]}
                    </td>
                    <td
                        align="center"
                        nowrap=""
                    >
                        <Form.Item
                            name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['packageNormal']`}
                            validate={(value) => ValidateField(
                                value,
                                messages["page.room.moneyRegister.fee"],
                                {
                                    required: messages["form.error.mixed.required"],
                                    integer: messages["form.error.number.integer"],
    
                                },
                                {
                                    required : true,
                                    integer : true,
                                }
                            )}
                        >
                            <InputNumber
                                name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['packageNormal']`}
                                maxLength={10}
                                min={0}
                                max={9999999999}
                                defaultValue={0}
                                className="w-100"
                                disabled={disabledMode}
                            />
                        </Form.Item>
                    </td>
                    <td
                        align="center"
                        bgcolor="#E4F1FF"
                        nowrap=""
                    >
                        <Form.Item
                            name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['packageHoliday']`}
                            validate={(value) => ValidateField(
                                value,
                                messages["page.room.moneyRegister.fee"],
                                {
                                    required: messages["form.error.mixed.required"],
                                    integer: messages["form.error.number.integer"],
    
                                },
                                {
                                    required : true,
                                    integer : true,
                                }
                            )}
                        >
                            <InputNumber
                                name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['packageHoliday']`}
                                maxLength={10}
                                min={0}
                                max={9999999999}
                                defaultValue={0}
                                className="w-100"
                                disabled={isEmpty(isHoliday) || disabledMode}
                            />
                        </Form.Item>
                    </td>
                </tr>
                <tr>
                    <td
                        align="center"
                        bgcolor="#CCCCCC"
                        className="kihon01"
                        nowrap=""
                    >
                        {messages["page.room.moneyRegister.regularPrice"]}
                    </td>
                    <td
                        align="center"
                        className="kihon01"
                        nowrap=""
                    >
                        {messages["page.room.moneyRegister.1roomTotal"]}
                    </td>
                    <td
                        align="center"
                        nowrap=""
                    >
                        <Form.Item
                            name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['regularNormal']`}
                            validate={(value) => ValidateField(
                                value,
                                messages["page.room.moneyRegister.fee"],
                                {
                                    required: messages["form.error.mixed.required"],
                                    integer: messages["form.error.number.integer"],
    
                                },
                                {
                                    required : true,
                                    integer : true,
                                }
                            )}
                        >
                            <InputNumber
                                name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['regularNormal']`}
                                maxLength={10}
                                min={0}
                                max={9999999999}
                                defaultValue={0}
                                className="w-100"
                                disabled={disabledMode}
                            />
                        </Form.Item>
                    </td>
                    <td
                        align="center"
                        bgcolor="#E4F1FF"
                        nowrap=""
                    >
                        <Form.Item
                            name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['regularHoliday']`}
                            validate={(value) => ValidateField(
                                value,
                                messages["page.room.moneyRegister.fee"],
                                {
                                    required: messages["form.error.mixed.required"],
                                    integer: messages["form.error.number.integer"],
    
                                },
                                {
                                    required : true,
                                    integer : true,
                                }
                            )}
                        >
                            <InputNumber
                                name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['regularHoliday']`}
                                maxLength={10}
                                min={0}
                                max={9999999999}
                                defaultValue={0}
                                className="w-100"
                                disabled={isEmpty(isHoliday) || disabledMode}
                            />
                        </Form.Item>
                    </td>
                </tr>
                <tr>
                    <td
                        align="center"
                        className="kihon01"
                        nowrap=""
                        width="48"
                    >
                        {messages["page.room.moneyRegister.meal"]}
                    </td>
                    <td
                        align="center"
                        className="kihon01"
                        nowrap=""
                    >
                        {messages["page.room.moneyRegister.adult"]}
                        <br />
                        {messages["page.room.moneyRegister.elementarySchoolStudent"]}
                    </td>
                    <td
                        align="center"
                        className="kihon01"
                        nowrap=""
                    >
                        {messages["page.room.moneyRegister.mealCategory"]}
                    </td>
                    <td
                        align="center"
                        className="kihon02"
                        nowrap=""
                    >
                        <div className="flex-group-checkbox">
                            <div className="mr-10">
                                <CheckBoxOnly
                                    label={messages["page.room.moneyRegister.breakfastIncluded"]}
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['normalHasBreakfast']`}
                                    holidayCondition={false}
                                    isHoliday={isHoliday}
                                    setFieldValue={setFieldValue}
                                    disabledMode={disabledMode}
                                />
                            </div>
                            <div>
                                <CheckBoxOnly
                                    label={messages["page.room.moneyRegister.dinnerIncluded"]}
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['normalHasDinner']`}
                                    holidayCondition={false}
                                    isHoliday={isHoliday}
                                    setFieldValue={setFieldValue}
                                    disabledMode={disabledMode}
                                />
                            </div>
                        </div>
                    </td>
                    <td
                        align="center"
                        bgcolor="#E4F1FF"
                        className="kihon02"
                        nowrap=""
                    >
                        <div className="flex-group-checkbox">
                            <div className="mr-10">
                                <CheckBoxOnly
                                    label={messages["page.room.moneyRegister.breakfastIncluded"]}
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['holidayHasBreakfast']`}
                                    holidayCondition={true}
                                    isHoliday={isHoliday}
                                    setFieldValue={setFieldValue}
                                    disabledMode={disabledMode}
                                />
                            </div>
                            <div>
                                <CheckBoxOnly
                                    label={messages["page.room.moneyRegister.dinnerIncluded"]}
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['holidayHasDinner']`}
                                    holidayCondition={true}
                                    isHoliday={isHoliday}
                                    setFieldValue={setFieldValue}
                                    disabledMode={disabledMode}
                                />
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td
                        align="center"
                        className="kihon01"
                        colSpan="3"
                        nowrap=""
                        width="200"
                    >
                        {messages["page.room.moneyRegister.point"]}
                    </td>
                    <td
                        align="center"
                        className="kihon02"
                        nowrap=""
                    >
                        <Form.Item
                            name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['normalPoint']`}
                            validate={(value) => ValidateField(
                                value,
                                messages["page.room.moneyRegister.point"],
                                {
                                    required: messages["form.error.mixed.required"],
                                    integer: messages["form.error.number.integer"],
    
                                },
                                {
                                    required : true,
                                    integer : true,
                                }
                            )}
                        >
                            <InputNumber
                                name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['normalPoint']`}
                                maxLength={2}
                                min={1}
                                max={10}
                                defaultValue={1}
                                className="w-50"
                                disabled={disabledMode}
                            />
                            {messages["page.room.moneyRegister.percent"]}
                        </Form.Item>
                    </td>
                    <td
                        align="center"
                        bgcolor="#E4F1FF"
                        className="kihon02"
                        nowrap=""
                    >
                        <Form.Item
                            name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['holidayPoint']`}
                            validate={(value) => ValidateField(
                                value,
                                messages["page.room.moneyRegister.point"],
                                {
                                    required: messages["form.error.mixed.required"],
                                    integer: messages["form.error.number.integer"],
    
                                },
                                {
                                    required : true,
                                    integer : true,
                                }
                            )}
                        >
                            <InputNumber
                                name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['holidayPoint']`}
                                maxLength={2}
                                defaultValue={1}
                                min={1}
                                max={10}
                                className="w-50"
                                disabled={isEmpty(isHoliday) || disabledMode}
                            />
                            {messages["page.room.moneyRegister.percent"]}
                        </Form.Item>
                    </td>
                </tr>
                <tr onClick={() => onchangeActive("am_pk")}>
                    <td
                        bgcolor="#FBF2C7"
                        className="kihon03"
                        colSpan="5"
                        nowrap=""
                        width="800"
                    >
                        <div className="c-poiter noselect">
                            <CaretRightOutlined rotate={!activeState.activeAmPk ? 90 : 0} />
                            <span>{messages["page.room.moneyRegister.mealDetailNote"]}</span>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tbody id="am_pk" className={activeState.activeAmPk ? 'display-item' : ''}>
                <tr className="dis">
                    <td
                        align="center"
                        className="kihon01"
                        nowrap=""
                        rowSpan="3"
                        width="48"
                    >
                        {messages["page.room.moneyRegister.meal"]}
                    </td>
                    <td
                        align="center"
                        className="kihon01"
                        nowrap=""
                        rowSpan="3"
                    >
                        {messages["page.room.moneyRegister.adult"]}
                        <br />
                        {messages["page.room.moneyRegister.elementarySchoolStudent"]}
                    </td>
                    <td
                        align="center"
                        className="kihon01"
                        nowrap=""
                    >
                        {messages["page.room.moneyRegister.mealSelection"]}
                    </td>
                    <td
                        align="center"
                        className="kihon02"
                        nowrap=""
                    >
                        <div className="flex-group-checkbox">
                            <div className="mr-10">
                                <CheckBoxOnly
                                    label={messages["page.room.moneyRegister.breakfastAvailable"]}
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['adultNormalHasBreakfast']`}
                                    holidayCondition={false}
                                    isHoliday={isHoliday}
                                    setFieldValue={setFieldValue}
                                    disabledMode={disabledMode}
                                />
                            </div>
                            <div>
                                <CheckBoxOnly
                                    label={messages["page.room.moneyRegister.dinnerAvailable"]}
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['adultNormalHasDinner']`}
                                    holidayCondition={false}
                                    isHoliday={isHoliday}
                                    setFieldValue={setFieldValue}
                                    disabledMode={disabledMode}
                                />
                            </div>
                        </div>
                    </td>
                    <td
                        align="center"
                        bgcolor="#E4F1FF"
                        className="kihon02"
                        nowrap=""
                    >
                        <div className="flex-group-checkbox">
                            <div className="mr-10">
                                <CheckBoxOnly
                                    label={messages["page.room.moneyRegister.breakfastAvailable"]}
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['adultHolidayHasBreakfast']`}
                                    holidayCondition={true}
                                    isHoliday={isHoliday}
                                    setFieldValue={setFieldValue}
                                    disabledMode={disabledMode}
                                />
                            </div>
                            <div>
                                <CheckBoxOnly
                                    label={messages["page.room.moneyRegister.dinnerAvailable"]}
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['adultHolidayHasDinner']`}
                                    holidayCondition={true}
                                    isHoliday={isHoliday}
                                    setFieldValue={setFieldValue}
                                    disabledMode={disabledMode}
                                />
                            </div>
                        </div>
                    </td>
                </tr>
                <tr className="dis">
                    <td
                        align="center"
                        className="kihon01"
                        nowrap=""
                        width="80"
                    >
                        {messages["page.room.moneyRegister.contentSelection"]}
                        <br />
                        【{messages["page.room.moneyRegister.meal.breakfast"]}】
                    </td>
                    <td nowrap="">
                        <table
                            border=""
                            cellSpacing="0"
                            nowrap="nowrap"
                        >
                            <tbody>
                                {adultMealBreakfast}
                            </tbody>
                        </table>
                    </td>
                    <td
                        bgcolor="#E4F1FF"
                        nowrap=""
                    >
                        <table
                            border=""
                            cellSpacing="0"
                            nowrap="nowrap"
                        >
                            <tbody>
                                {adultMealBreakfastHoliday}
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr className="dis">
                    <td
                        align="center"
                        className="kihon01"
                        nowrap=""
                        width="80"
                    >
                        {messages["page.room.moneyRegister.contentSelection"]}
                        <br />
                        【{messages["page.room.moneyRegister.meal.dinner"]}】
                    </td>
                    <td nowrap="">
                        <table
                            border=""
                            cellSpacing="0"
                            nowrap="nowrap"
                        >
                            <tbody>
                                {adultMealDinner}
                            </tbody>
                        </table>
                    </td>
                    <td
                        bgcolor="#E4F1FF"
                        nowrap=""
                    >
                        <table
                            border=""
                            cellSpacing="0"
                            nowrap="nowrap"
                        >
                            <tbody>
                                {adultMealDinnerHoliday}
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
            <tbody>
                <tr>
                    <td
                        align="center"
                        className="kihon01"
                        colSpan="2"
                        width="120"
                    >
                        {messages["page.room.moneyRegister.childSetting"]}
                    </td>
                    <td
                        colSpan="3"
                        nowrap=""
                    >
                        <TableSettingPriceByRoom
                            values={values}
                            setFieldValue={setFieldValue}
                            tabKey={tabKey}
                            apiRoomId={apiRoomId}
                            disabledMode={disabledMode}
                        />
                    </td>
                </tr>
                <tr onClick={() => onchangeActive("cm_pk")}>
                    <td
                        bgcolor="#FBF2C7"
                        className="kihon03"
                        colSpan="5"
                        nowrap=""
                        width="800"
                    >
                        <div className="c-poiter noselect">
                            <CaretRightOutlined rotate={!activeState.activeCmPk ? 90 : 0} />
                            <span>{messages["page.room.moneyRegister.mealDetailTitle"]}</span>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tbody id="cm_pk" className={activeState.activeCmPk ? 'display-item' : ''}>
                <tr className="dis">
                    <td
                        align="center"
                        className="kihon01"
                        nowrap=""
                        rowSpan="3"
                        width="48"
                    >
                        {messages["page.room.moneyRegister.meal"]}
                    </td>
                    <td
                        align="center"
                        className="kihon01"
                        nowrap=""
                        rowSpan="3"
                        width="80"
                    >
                        {messages["page.room.moneyRegister.infant"]}
                    </td>
                    <td
                        align="center"
                        className="kihon01"
                        nowrap=""
                    >
                        {messages["page.room.moneyRegister.mealSelection"]}
                    </td>
                    <td
                        align="center"
                        className="kihon02"
                        nowrap=""
                    >
                        <div className="flex-group-checkbox">
                            <div className="mr-10">
                                <CheckBoxOnly
                                    label={messages["page.room.moneyRegister.breakfastAvailable"]}
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['childNormalHasBreakfast']`}
                                    holidayCondition={false}
                                    isHoliday={isHoliday}
                                    setFieldValue={setFieldValue}
                                    disabledMode={disabledMode}
                                />
                            </div>
                            <div>
                                <CheckBoxOnly
                                    label={messages["page.room.moneyRegister.dinnerAvailable"]}
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['childNormalHasDinner']`}
                                    holidayCondition={false}
                                    isHoliday={isHoliday}
                                    setFieldValue={setFieldValue}
                                    disabledMode={disabledMode}
                                />
                            </div>
                        </div>
                    </td>
                    <td
                        align="center"
                        bgcolor="#E4F1FF"
                        className="kihon02"
                        nowrap=""
                    >
                        <div className="flex-group-checkbox">
                            <div className="mr-10">
                                <CheckBoxOnly
                                    label={messages["page.room.moneyRegister.breakfastAvailable"]}
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['childHolidayHasBreakfast']`}
                                    holidayCondition={true}
                                    isHoliday={isHoliday}
                                    setFieldValue={setFieldValue}
                                    disabledMode={disabledMode}
                                />
                            </div>
                            <div>
                                <CheckBoxOnly
                                    label={messages["page.room.moneyRegister.dinnerAvailable"]}
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['childHolidayHasDinner']`}
                                    holidayCondition={true}
                                    isHoliday={isHoliday}
                                    setFieldValue={setFieldValue}
                                    disabledMode={disabledMode}
                                />
                            </div>
                        </div>
                    </td>
                </tr>
                <tr className="dis">
                    <td
                        align="center"
                        className="kihon01"
                        nowrap=""
                        width="80"
                    >
                        {messages["page.room.moneyRegister.contentSelection"]}
                        <br />
                        【{messages["page.room.moneyRegister.meal.breakfast"]}】
                    </td>
                    <td nowrap="">
                        <table
                            border=""
                            cellSpacing="0"
                            nowrap="nowrap"
                        >
                            <tbody>
                                {childMealBreakfast}
                            </tbody>
                        </table>
                    </td>
                    <td
                        bgcolor="#E4F1FF"
                        nowrap=""
                    >
                        <table
                            border=""
                            cellSpacing="0"
                            nowrap="nowrap"
                        >
                            <tbody>
                                {childMealBreakfastHoliday}
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr className="dis">
                    <td
                        align="center"
                        className="kihon01"
                        nowrap=""
                        width="80"
                    >
                        {messages["page.room.moneyRegister.contentSelection"]}
                        <br />
                        【{messages["page.room.moneyRegister.meal.dinner"]}】
                    </td>
                    <td nowrap="">
                        <table
                            border=""
                            cellSpacing="0"
                            nowrap="nowrap"
                        >
                            <tbody>
                                {childMealDinner}
                            </tbody>
                        </table>
                    </td>
                    <td
                        bgcolor="#E4F1FF"
                        nowrap=""
                    >
                        <table
                            border=""
                            cellSpacing="0"
                            nowrap="nowrap"
                        >
                            <tbody>
                                {childMealDinnerHoliday}
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
            <tbody>
                <tr onClick={() => onchangeActive("syosai")}>
                    <td
                        bgcolor="#FBF2C7"
                        className="kihon03"
                        colSpan="5"
                        nowrap=""
                        width="800"
                    >
                        <div className="c-poiter noselect">
                            <CaretRightOutlined rotate={!activeState.activeSyosai ? 90 : 0} />
                            <span>{messages["page.room.moneyRegister.otherDetailTitle"]}</span>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tbody id="syosai">
                <tr className={activeState.activeSyosai ? 'display-item' : ''}>
                    <td
                        align="center"
                        className="kihon01"
                        colSpan="3"
                        nowrap=""
                        width="200"
                    >
                        {messages["page.room.moneyRegister.taxDistinction"]}
                    </td>
                    <td
                        align="center"
                        nowrap=""
                    >
                        <Form.Item
                            name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['normalTaxType']`}
                        >
                            <Select
                                name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['normalTaxType']`}
                                className="w-100"
                                defaultValue={messages["page.room.moneyRegister.taxIncluded"]}
                                disabled={disabledMode}
                                {...searchSelect}
                            >
                                {optionValues.map((option, index) => {
                                    const shouldRenderOption = apiRoomId
                                        ? option.fieldKey === "room_tax_included"
                                        : option.fieldKey === "room_tax_included" && option.value === messages["page.room.moneyRegister.taxIncluded"];

                                    return shouldRenderOption && (
                                        <Select.Option key={index} value={option.value}>
                                            {option.value}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </td>
                    <td
                        align="center"
                        bgcolor="#E4F1FF"
                        nowrap=""
                    >
                        <Form.Item
                            name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['holidayTaxType']`}
                        >
                            <Select
                                name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['holidayTaxType']`}
                                className="w-100"
                                disabled={isEmpty(isHoliday) || disabledMode}
                                defaultValue={messages["page.room.moneyRegister.taxIncluded"]}
                                {...searchSelect}
                            >
                                {optionValues.map((option, index) => {
                                    const shouldRenderOption = apiRoomId
                                        ? option.fieldKey === "room_tax_included"
                                        : option.fieldKey === "room_tax_included" && option.value === messages["page.room.moneyRegister.taxIncluded"];

                                    return shouldRenderOption && (
                                        <Select.Option key={index} value={option.value}>
                                            {option.value}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </td>
                </tr>
            </tbody>
        </table >
    )
}
const priceSettingByRoomAndWeekdayInitialValue = {
    isHoliday: [],
    stockNormal: 0,
    stockHoliday: 0,
    normalPoint: 1,
    holidayPoint: 1,
    rakutenNormal: 0,
    rakutenHoliday: 0,
    packageNormal: 0,
    packageHoliday: 0,
    regularNormal: 0,
    regularHoliday: 0,
    normalHasBreakfast: [],
    normalHasDinner: [],
    holidayHasBreakfast: [],
    holidayHasDinner: [],
    adultNormalHasBreakfast: [],
    adultNormalHasDinner: [],
    adultHolidayHasBreakfast: [],
    adultHolidayHasDinner: [],
    childNormalHasBreakfast: [],
    childNormalHasDinner: [],
    childHolidayHasBreakfast: [],
    childHolidayHasDinner: [],
    infrantBreakfastType: null,
    infrantDinnerType: null,
    juniorHighAcceptDate: "可",
    juniorLowAcceptDate: "可",
    infrantMealPalletAcceptDate: "可",
    infrantMealOnlyAcceptDate: "可",
    infrantPalletOnlyAcceptDate: "可",
    infrantNoMealPalletAcceptDate: "可"
}
export default memo(PriceSettingByRoomAndWeekday);