import React, {useState, useEffect, memo, useCallback} from "react";
import { useIntl } from "react-intl";
import {Col, Row, Space, Button, Checkbox, Radio, Modal, message, Empty} from "antd";
import {Form, Input, Select, DatePicker, InputNumber} from "formik-antd";
import { LayoutContentWrapper } from "./PlanOta.styles";
import {DATE_FORMAT} from "@iso/constants/common.constant";
import PropTypes from "prop-types";
import RoomRakutenExtendFields from "@iso/containers/ApiPlanAddAndEdit/Components/Rakuten/RoomExtendFields";
import UploadFileImageOta from "@iso/containers/ApiPlanAddAndEdit/extend-fields/UploadFileImageOta";
import {
    ERROR_STATUS,
    fieldKeyRakutenApiOption,
    LIST_CODE_STATUS_SHOW_MESSAGE,
    LIST_CODE_STATUS_COMMON_MESSAGE,
    LIST_STATUS_CRAWL,
    LIST_STATUS_SYNC,
    searchSelect, TYPE_CREATE_BY_CRAWL, UPDATE_STATUS_SUCCESS,
    LIST_OPEN_SALE_STATUS,
    LIST_STATUS_SALE_PROCESSING
} from "@iso/constants/api.constant";
import {useDispatch, useSelector} from "react-redux";
import {colLeft, itemLeftLabel} from "@iso/assets/styles/form.style";
import {colRight} from "@iso/containers/TaskLog";
import apiPlanAction from "@iso/redux/apiPlan/actions";
import apiCancelPolicyAction from "@iso/redux/apiCancelPolicy/actions";
import {ReloadOutlined} from "@ant-design/icons";
import moment from "moment/moment";

const Rakuten = (
    {
        tabKey,
        setFieldValue,
        setFieldTouched,
        values,
        otaId,
        storeId,
        apiOptionValue,
        apiPlanId,
        setEventKodomoSubmit,
        isCloneData,
        activeTabKey,
        dataPlanOta
    }) => {
    const { messages } = useIntl();
    const { Option } = Select;
    const { RangePicker } = DatePicker;
    const dispatch = useDispatch();
    const {
        ApiPlan: { loadingSyncStatus, syncPlanStatus },
        ApiCancelPolicy: { listCancelPolicy, statusCrawlCancelPolicy },
    } = useSelector((state) => state);
    const [hourOptions, setHourOptions] = useState([]);

    const [voucherSettingValue, setVoucherSettingValue] = useState([]);
    const [secretSettingPlanValue, setSecretSettingPlanValue] = useState([]);
    const [publishPersonalPageValue, setPublishPersonalPageValue] = useState("");
    const [featureValue, setFeatureValue] = useState([]);
    const [eatingPlaceValue, setEatingPlaceValue] = useState([]);
    const [supperDealValue, setSupperDealValue] = useState([]);
    const [inboundValue, setInboundValue] = useState([]);
    const [inboundEasySetType, setInboundEasySetType] = useState("");
    const [planPaymentMethod, setPlanPaymentMethod] = useState("");
    const [memberRankBenefit, setMemberRankBenefit] = useState("");
    const [acceptStartType, setAcceptStartType] = useState("");
    const [acceptEndType, setAcceptEndType] = useState("");
    const [featureOptions, setFeatureOptions] = useState([]);
    const [eatingPlaceOptions, setEatingPlaceOptions] = useState([]);
    const [inboundTypeOptions, setInboundTypeOptions] = useState([]);
    const [inboundTypeSelectOptions, setInboundTypeSelectOptions] = useState([]);
    const [acceptStartTypeOptions, setAcceptStartTypeOptions] = useState([]);
    const [acceptStartDayValues, setAcceptStartDayValues] = useState([]);
    const [acceptStartHourValues, setAcceptStartHourValues] = useState([]);
    const [acceptEndDayValues, setAcceptEndDayValues] = useState([]);
    const [acceptEndHourValues, setAcceptEndHourValues] = useState([]);
    const [acceptEndTypeOptions, setAcceptEndTypeOptions] = useState([]);
    const [paymentMethodOptions, setPaymentMethodOptions] = useState([]);
    const [memberRankBenefitOptions, setMemberRankBenefitOptions] = useState([]);
    const [isCheckedSupperDeal, setIsCheckedSupperDeal] = useState(false);
    const [isCheckedInbound, setIsCheckedInbound] = useState(false);
    const [publishFlagValue, setPublishFlagValue] = useState([]);
    const [secretSettingPlan, setSecretSettingPlan] = useState([]);
    const [publishPersonalPage, setPublishPersonalPage] = useState([]);
    const [publishFlagOptions, setPublishFlagOptions] = useState([]);
    const [checkinHourOptions, setCheckinHourOptions] = useState([]);
    const publishFlagPackage = messages["page.apiPlanAddAndEdit.Rakuten.publishFlagPackageCheckbox"];
    const acceptNoLimited = messages["page.apiPlanAddAndEdit.Rakuten.acceptStartType.noLimited"];
    const acceptLimited = messages["page.apiPlanAddAndEdit.Rakuten.limited"];
    const sameDayLimited = messages["page.apiPlanAddAndEdit.Rakuten.sameDayLimited"];
    const midnightLimited = messages["page.apiPlanAddAndEdit.Rakuten.midnightLimited"];
    const acceptEndNoLimited = messages["page.apiPlanAddAndEdit.Rakuten.acceptEndType.noLimited"];
    const acceptEndRestrict = messages["page.apiPlanAddAndEdit.Rakuten.restrict"];
    const acceptEndRestrictAfterMidnight = messages["page.apiPlanAddAndEdit.Rakuten.restrictAfterMidnight"];
    const [isAcceptEndRestrict, setIsAcceptEndRestrict] = useState(false);
    const [isDisableAcceptStart, setIsDisableAcceptStart] = useState(false);
    const [isDisableAcceptEnd, setIsDisableAcceptEnd] = useState(false);
    const [isCheckedSecretSettingPlan, setIsCheckedSecretSettingPlan] = useState(false);
    const [isCheckedDontPublishPersonPage, setIsCheckedDontPublishPersonPage] = useState(false);
    const [isSyncDataToSite, setIsSyncDataToSite] = useState(false)
    const [apiPlanOtaId, setApiPlanOtaId] = useState(null);
    const [showLog, setShowLog] = useState(false);
    const [syncErrorContent, setSyncErrorContent] = useState([])
    const [isUpdateDataFromSite, setIsUpdateDataFromSite] = useState(false)
    const [isDisableButtonSync, setIsDisableButtonSync] = useState(false)
    const [superDealPointOption, setSuperDealPointOption] = useState([])
    const [superDealPointDisable, setSuperDealPointDisable] = useState(false)

    const lastThreePublishFlagCheckboxes = [
        messages["page.apiPlanAddAndEdit.Rakuten.publishFlagPackageANACheckbox"],
        messages["page.apiPlanAddAndEdit.Rakuten.publishFlagPackageJALCheckbox"],
        messages["page.apiPlanAddAndEdit.Rakuten.publishFlagPackageJDCheckbox"],
    ];

    const findValueByKey = (key) => apiOptionValue.filter((value) => value.fieldKey === key);

    useEffect(() => {
        if (isCheckedSupperDeal) {
            if (apiPlanId) {
                if (isCloneData) {
                    setSuperDealPointDisable(false);
                }
    
                if (dataPlanOta.otaFieldContent[tabKey].sitePlanId == null) {
                    setSuperDealPointDisable(false)
                }
            } else {
                setSuperDealPointDisable(false)
            }
        } else {
            setSuperDealPointDisable(true);
        }
    }, [dataPlanOta, tabKey, apiPlanId, isCheckedSupperDeal])
    
    useEffect(() => {
        handleAcceptStartValueChange(acceptStartType)
    }, [apiOptionValue, acceptStartType])

    useEffect(() => {
        handleAcceptEndValueChange(acceptEndType)
    }, [apiOptionValue, acceptEndType])

    useEffect(() => {
        if (storeId !== null && storeId !== undefined && otaId !== null && otaId !== undefined) {
            dispatch(apiCancelPolicyAction.getListCancelPolicy({storeId, otaId}))
        }
    }, [storeId, otaId])

    useEffect(() => {
        refreshSyncPlan();
    }, [dispatch])

    useEffect(() => {
        if (syncPlanStatus !== null && syncPlanStatus !== undefined && !isCloneData && apiPlanOtaId) {
            for (let i = 0; i < syncPlanStatus.length; i++) {
                if (syncPlanStatus[i].otaId === tabKey) {
                    if (LIST_CODE_STATUS_SHOW_MESSAGE.includes(syncPlanStatus[i].codeStatus)) {
                        let syncErrorContentData = syncPlanStatus[i].syncErrorContent ?? '';
                        syncErrorContentData = syncPlanStatus[i]?.codeStatus === ERROR_STATUS.LOGIN_ERROR ? messages['page.apiPlanAddAndEdit.loginFail'] : syncErrorContentData;
                        if (syncErrorContentData !== '' && syncErrorContentData !== null && syncErrorContentData !== undefined) {
                            let syncErrorContentArray = syncErrorContentData.trim().split("\n").map(item => item.trim());
                            setSyncErrorContent(syncErrorContentArray);
                        } else {
                            setSyncErrorContent([])
                        }
                    } else if (LIST_CODE_STATUS_COMMON_MESSAGE.includes(syncPlanStatus[i].codeStatus)) {
                        setSyncErrorContent([messages['page.apiPlanAddAndEdit.somethingWrong']])
                    } else {
                        setSyncErrorContent([])
                    }
                    setFieldValue(`otaFieldContent.${tabKey}[syncStatus]`, syncPlanStatus[i].syncStatus);
                    setFieldValue(`otaFieldContent.${tabKey}[updateStatus]`, syncPlanStatus[i].updateStatus);
                    setFieldValue(`otaFieldContent.${tabKey}[openSaleStatus]`, syncPlanStatus[i].openSaleStatus);
                }
            }
        }
    }, [syncPlanStatus])

    useEffect(() => {

        let publishFlgValue = findValueByKey(fieldKeyRakutenApiOption.publishFlg);
        const featureValue = findValueByKey(fieldKeyRakutenApiOption.feature);
        const eatingPlaceValue = findValueByKey(fieldKeyRakutenApiOption.eatPlace);
        const inboundTypeOptionValue = findValueByKey(fieldKeyRakutenApiOption.inboundEasySetType);
        const inboundTypeOptionSelectValue = findValueByKey(fieldKeyRakutenApiOption.inboundType);
        const paymentMethodOptionValue = findValueByKey(fieldKeyRakutenApiOption.planPaymentMethod);
        const memberRankBenefitOptionValue = findValueByKey(fieldKeyRakutenApiOption.memberRankBenefit);
        const acceptStartTypeOptionValue = findValueByKey(fieldKeyRakutenApiOption.acceptStartType);
        const acceptEndTypeOptionValue = findValueByKey(fieldKeyRakutenApiOption.acceptEndType);
        const checkinHourOptionValue = findValueByKey(fieldKeyRakutenApiOption.checkinHourType);
        const hourOptionValue = findValueByKey(fieldKeyRakutenApiOption.hourOption);
        const secretSettingPlanValue = findValueByKey(fieldKeyRakutenApiOption.secretSettingPlan);
        const publishPersonalPageValue = findValueByKey(fieldKeyRakutenApiOption.publishPersonalPage);

        publishFlgValue = publishFlgValue.map((publishPlg) => {

            if (lastThreePublishFlagCheckboxes.includes(publishPlg.value) && !publishFlagValue.includes(publishFlagPackage)) {
                return {...publishPlg, disabled: true}
            } else {
                return {...publishPlg, disabled: false}
            }
        })

        setPublishFlagOptions(publishFlgValue)
        setFeatureOptions(featureValue)
        setEatingPlaceOptions(eatingPlaceValue)
        setInboundTypeSelectOptions(inboundTypeOptionSelectValue)
        setInboundTypeOptions(inboundTypeOptionValue)
        setPaymentMethodOptions(paymentMethodOptionValue)
        setMemberRankBenefitOptions(memberRankBenefitOptionValue)
        setAcceptStartTypeOptions(acceptStartTypeOptionValue)
        setAcceptEndTypeOptions(acceptEndTypeOptionValue)
        setCheckinHourOptions(checkinHourOptionValue)
        setHourOptions(hourOptionValue)
        setSecretSettingPlan(secretSettingPlanValue)
        setPublishPersonalPage(publishPersonalPageValue)

    }, [apiOptionValue])

    useEffect(() => {
        if (
            values.otaFieldContent[tabKey] === null ||
            values.otaFieldContent[tabKey] === undefined ||
            (values.otaFieldContent[tabKey] !== null && values.otaFieldContent[tabKey] !== undefined && Object.keys(values.otaFieldContent[tabKey]).length === 0)
        ) {
            rakutenInitialValue.otaId = otaId;
            values.otaFieldContent[tabKey] = rakutenInitialValue;
        } else {

            const publishFlag = Array.isArray(values.otaFieldContent[tabKey].publishFlag) ? values.otaFieldContent[tabKey].publishFlag : [];
            if (publishFlag.includes(publishFlagPackage)) {
                let publishFlagOptionTemp = publishFlagOptions.map(x => {
                    return lastThreePublishFlagCheckboxes.includes(x.value) ? {...x, disabled: false} : x;
                })

                setPublishFlagOptions(publishFlagOptionTemp);
            }

            if (values.otaFieldContent[tabKey].supperDeal != null && values.otaFieldContent[tabKey].supperDeal.length > 0) {
                setIsCheckedSupperDeal(true)
            }

            if (values.otaFieldContent[tabKey].inbound != null && values.otaFieldContent[tabKey].inbound.length > 0) {
                setIsCheckedInbound(true)
            }

            if (values.otaFieldContent[tabKey].secretSettingPlan !== null &&
                values.otaFieldContent[tabKey].secretSettingPlan !== undefined &&
                values.otaFieldContent[tabKey].secretSettingPlan.length > 0 &&
                values.otaFieldContent[tabKey].secretSettingPlan.includes(messages['page.apiPlanAddAndEdit.Rakuten.secretSettingPlanOption'])
            ) {
                setIsCheckedSecretSettingPlan(true)
            } else {
                setIsCheckedSecretSettingPlan(false)
            }

            if (values.otaFieldContent[tabKey].publishPersonalPage !== null &&
                values.otaFieldContent[tabKey].publishPersonalPage !== undefined &&
                values.otaFieldContent[tabKey].publishPersonalPage === messages['page.apiPlanAddAndEdit.Rakuten.dontPublishPersonalPage']
            ) {
                setIsCheckedDontPublishPersonPage(true)
            } else {
                setIsCheckedDontPublishPersonPage(false)
            }

            if (values.otaFieldContent[tabKey].typePlan === TYPE_CREATE_BY_CRAWL && values.otaFieldContent[tabKey]?.updateStatus != UPDATE_STATUS_SUCCESS) {
                setIsDisableButtonSync(true)
            } else {
                setIsDisableButtonSync(false)
            }

            setPublishFlagValue(publishFlag);
            setVoucherSettingValue(values.otaFieldContent[tabKey].voucherSetting);
            setFeatureValue(values.otaFieldContent[tabKey].feature);
            setEatingPlaceValue(values.otaFieldContent[tabKey].eatingPlace);
            setSupperDealValue(values.otaFieldContent[tabKey].supperDeal);
            setInboundValue(values.otaFieldContent[tabKey].inbound);
            setInboundEasySetType(values.otaFieldContent[tabKey].inboundEasySetType);
            setPlanPaymentMethod(values.otaFieldContent[tabKey].planPaymentMethod);
            setMemberRankBenefit(values.otaFieldContent[tabKey].memberRankBenefit);
            setAcceptStartType(values.otaFieldContent[tabKey].acceptStartType);
            setAcceptEndType(values.otaFieldContent[tabKey].acceptEndType);
            setSecretSettingPlanValue(values.otaFieldContent[tabKey].secretSettingPlan);
            setPublishPersonalPageValue(values.otaFieldContent[tabKey].publishPersonalPage);
            setApiPlanOtaId(values.otaFieldContent[tabKey].apiPlanOtaId)
        }
    }, [values])

    const changeRadioButtonValue = (value, name) => {
        setFieldValue(name, value.target.value);
    };

    const changeSelectValue = (name, value) => {
        setFieldValue(name, value);
    }

    const changeCheckBoxValue = (name, value) => {
        setFieldValue(name, value);
    }

    const onChangeDate = (name, value) => {
        if (value) {
            setFieldValue(name, moment(value).format(DATE_FORMAT))
            setFieldTouched(name, false);
        } else {
            setFieldValue(name, '')
        }
    }

    const changeCheckBoxPublishFlagValue = (name, value) => {
        let listPublishFlag = [...value];
        if (!value.includes(publishFlagPackage)) {
            listPublishFlag = value.filter(x => !lastThreePublishFlagCheckboxes.includes(x));
        }
        setFieldValue(name, listPublishFlag);
    }

    const changeSecretSettingPlanValue = (name, value) => {
        let listSecretSettingPlan = [...value];
        setFieldValue(name, listSecretSettingPlan);
    }

    const onChangePublishFlag = useCallback((e) => {
        let publishFlagIdChecked = e.target.value
        if (publishFlagIdChecked === publishFlagPackage){
            let publishFlagOptionTemp = publishFlagOptions.map(x => {
                if (e.target.checked === true) {
                    return lastThreePublishFlagCheckboxes.includes(x.value) ? {...x, disabled: false} : x;
                } else {
                    return lastThreePublishFlagCheckboxes.includes(x.value) ? {...x, disabled: true} : x;
                }
            })

            setPublishFlagOptions(publishFlagOptionTemp);
        }
    },[setPublishFlagOptions, publishFlagOptions])

    const changeCheckBoxSupperDeal = useCallback((e) => {
        const supperDealChecked = e.target.checked;
        if (supperDealChecked) {
            const fieldsToClear = [
                'packageName',
                'packageDescription',
                'inbound',
                'inboundType',
                'inboundEasySetType',
                'inboundEasySetTitle',
                'inboundOffConditionDays',
                'inboundOffPercent',
                'inboundEasySetContent',
                'packageSaleLimitSetting'
            ];
            clearFieldInTab(fieldsToClear)
            setFieldValue(`otaFieldContent.${tabKey}.superDealPoint`, 30)
        } else {
            clearFieldInTab(['superDealPoint'])
        }
        setIsCheckedSupperDeal(supperDealChecked);
    }, [isCheckedSupperDeal, setFieldValue]);

    const changeCheckBoxInbound = useCallback((e) => {
        const inboundChecked = e.target.checked;
        if (inboundChecked === false) {
            const fieldsToClear = [
                'inboundType',
                'inboundEasySetType',
                'inboundEasySetTitle',
                'inboundOffConditionDays',
                'inboundOffPercent',
                'inboundEasySetContent',
            ];
            clearFieldInTab(fieldsToClear)
        }
        setIsCheckedInbound(inboundChecked);
    }, [isCheckedInbound, setFieldValue]);

    const clearFieldInTab = (fieldsToClear) => {
        fieldsToClear.forEach(field => {
            setFieldValue(`otaFieldContent.${tabKey}[${field}]`, null);
        });
    }

    const changeRadioAcceptStartValue = useCallback( (value, name) => {
        let acceptStartValue = value.target.value;
        let fieldsToClear = [
            'acceptStartDay',
            'acceptStartHour',
        ];
        handleAcceptStartValueChange(acceptStartValue);
        clearFieldInTab(fieldsToClear)
        setFieldValue(name, acceptStartValue);
    }, [apiOptionValue, isDisableAcceptStart]);

    const handleAcceptStartValueChange = (acceptStartValue) => {
        let acceptStartDayValue;
        let acceptStartHourValue;

        switch (acceptStartValue) {

            case acceptNoLimited:
                acceptStartDayValue = [];
                acceptStartHourValue = [];
                setIsDisableAcceptStart(true)
                break;

            case acceptLimited:
                acceptStartDayValue = findValueByKey("accept_start_day_type1");
                acceptStartHourValue = findValueByKey("hour_option_one_digit");
                setIsDisableAcceptStart(false)
                break;

            case sameDayLimited:
                acceptStartDayValue = findValueByKey("accept_start_day_type2");
                acceptStartHourValue = findValueByKey("hour_option_one_digit");
                setIsDisableAcceptStart(false)
                break;

            case midnightLimited:
                acceptStartDayValue = findValueByKey("accept_start_day_type3");
                acceptStartHourValue = findValueByKey("hour_option_night");
                setIsDisableAcceptStart(false)
                break;

            default:
                acceptStartDayValue = [];
                acceptStartHourValue = [];
                setIsDisableAcceptStart(true)
                break;
        }

        setAcceptStartDayValues(acceptStartDayValue)
        setAcceptStartHourValues(acceptStartHourValue)
    }

    const handleAcceptEndValueChange = (acceptEndValue) => {

        let acceptEndDayValue;
        let acceptEndHourValue;

        switch (acceptEndValue) {
            case acceptEndNoLimited:
                acceptEndDayValue = [];
                acceptEndHourValue = [];
                setIsDisableAcceptEnd(true);
                setIsAcceptEndRestrict(false);
                break;

            case acceptEndRestrict:
                acceptEndDayValue = [];
                acceptEndHourValue = findValueByKey("hour_option_one_digit");
                setIsDisableAcceptEnd(false);
                setIsAcceptEndRestrict(true);
                break;

            case acceptEndRestrictAfterMidnight:
                acceptEndDayValue = findValueByKey("accept_start_day_type3");
                acceptEndHourValue = findValueByKey("hour_option_night");
                setIsDisableAcceptEnd(false);
                setIsAcceptEndRestrict(false);
                break;

            default:
                acceptEndDayValue = [];
                acceptEndHourValue = [];
                setIsDisableAcceptEnd(true);
                setIsAcceptEndRestrict(false);
                break;
        }

        setAcceptEndDayValues(acceptEndDayValue);
        setAcceptEndHourValues(acceptEndHourValue);
    };

    const changeRadioAcceptEndValue = useCallback((value, name) => {
        const acceptEndValue = value.target.value;
        let fieldsToClear = [
            'acceptEndDay',
            'acceptEndHour'
        ];
        handleAcceptEndValueChange(acceptEndValue);
        clearFieldInTab(fieldsToClear);
        setFieldValue(name, acceptEndValue);
    }, [apiOptionValue, isDisableAcceptEnd]);

    const syncDataToSite = useCallback(() => {
        new Promise((resolve, reject) => {
            setIsSyncDataToSite(true)
            return dispatch(apiPlanAction.syncDataPlanToSite({apiPlanOtaId, resolve, reject}));
        }).then(() => {
            Modal.success({
                title: messages["page.apiPlanAddAndEdit.modal.addSuccessTitle"],
                content: messages[`page.apiPlanAddAndEdit.updateSuccess`],
            });
        }).catch((e) => {
            let messageError = e.response != null && e.response.status === ERROR_STATUS.IN_PROGRESS_SYNC
                ? messages[`page.apiPlanAddAndEdit.inProgressSyncError`]
                : messages[`page.apiPlanAddAndEdit.updateError`]
            Modal.error({
                title: messages["page.apiPlanAddAndEdit.modal.errorTitle"],
                content: messageError,
            });
        }).finally(() => {
            setIsSyncDataToSite(false)
        })
    });
    const validationRequired = (value, message) => {
           return (value === "" || value === null || value === undefined) ?
                messages["form.error.mixed.required"].replace("${path}", message) : null
    }

    const crawlCancelPolicy = () => {
        if (otaId === null || otaId === undefined) {
            Modal.error({
                title: messages["page.apiPlanAddAndEdit.modal.errorTitle"],
                content: messages["form.error.mixed.required"].replace(
                    "${path}",
                    messages["page.apiPlanAddAndEdit.otas"]
                ),
            });
            return;
        }
        if (storeId === null || storeId === undefined) {
            Modal.error({
                title: messages["page.apiPlanAddAndEdit.modal.errorTitle"],
                content: messages["form.error.mixed.required"].replace(
                    "${path}",
                    messages["page.apiPlanAddAndEdit.storeId"]
                ),
            });
            return;
        }
        new Promise((resolve, reject) => {
            return dispatch(apiCancelPolicyAction.crawlDataCancelPolicy({data: {storeId: storeId, otaId: otaId}, resolve, reject}))
        }).then(() => {
            message.success(messages["page.apiPlanAddAndEdit.crawlCancelPolicy.success"]);
        }).catch((e) => {
            let messageError = e.response != null && e.response.status === ERROR_STATUS.IN_PROGRESS_SYNC
                ? messages[`page.apiPlanAddAndEdit.inProgressSyncError`]
                : messages[`page.apiPlanAddAndEdit.crawlCancelPolicy.failed`]
            message.error(messageError);
        })
    }

    const refreshCancelPolicy = () => {
        if (otaId === null || otaId === undefined) {
            return;
        }
        if (storeId === null || storeId === undefined) {
            return;
        }
        dispatch(apiCancelPolicyAction.getListCancelPolicy({storeId, otaId}))
    }

    const refreshSyncPlan = () => {
        if (apiPlanId === null || apiPlanId === undefined) {
            return;
        }
        dispatch(apiPlanAction.getSyncPlanStatus({apiPlanId}))
    }

    const handleInputBlur  = (event) => {
        const trimmedValue = trimSpaces(event.target.value);
        setFieldValue(event.target.name, trimmedValue)
    };

    const trimSpaces = (inputString) => {
        if (typeof inputString !== 'string') {
            return '';
        }
        let elements = inputString.split(',');
        let trimmedElements = elements.map(element => element.trim());
        return trimmedElements.join(',');
    }
    
    const updateDataFromSite = useCallback(() => {
        new Promise((resolve, reject) => {
            setIsUpdateDataFromSite(true)
            return dispatch(apiPlanAction.updateDataFromSite({ apiPlanOtaId, resolve, reject }));
        }).then(() => {
            Modal.success({
                title: messages["page.apiPlan.modal.updateSuccessTitle"],
                content: messages[`page.apiPlan.modal.updateSuccess`],
            });
        }).catch((e) => {
            let messageError = e.response != null && e.response.status === ERROR_STATUS.IN_PROGRESS_SYNC
                ? messages[`page.apiPlan.modal.inProgressSyncError`]
                : messages[`page.apiPlan.modal.updateError`]
            Modal.error({
                title: messages["page.apiPlan.modal.errorTitle"],
                content: messageError,
            });
        }).finally(() => {
            setIsUpdateDataFromSite(false)
        })
    });

    useState(() => {
        const options = [];
        for (let i = 30; i <= 40; i++) {
            options.push(<Option key={i} value={i}>{i}</Option>);
        }
        setSuperDealPointOption(options);
    }, []);

    return (
      <LayoutContentWrapper>
          <Row justify="start" className="mb-25px">
              <Col xs={24} lg={{ span: 24 }}>
                  <Row>
                      <Col {...colLeft}>
                          <Form.Item
                              label={messages["page.apiPlanAddAndEdit.Rakuten.sitePlanId"]}
                              name={`otaFieldContent.${tabKey}[sitePlanId]`}
                              {...itemLeftLabel}
                          >
                              <Input
                                  name={`otaFieldContent.${tabKey}[sitePlanId]`}
                                  disabled={true}
                              />
                          </Form.Item>
                      </Col>
                      <Col {...colRight} style={{display: 'flex' , justifyContent: 'end'}}>
                          <Button
                              type="primary"
                              className="mr-10 mb-0 button"
                              block
                              hidden={apiPlanOtaId === null || apiPlanOtaId === undefined}
                              loading={isSyncDataToSite}
                              disabled={isDisableButtonSync}
                              onClick={syncDataToSite}
                          >
                              {messages["page.apiPlanAddAndEdit.buttonSync"]}
                          </Button>

                          <Button
                              type="primary"
                              className="mr-10 button"
                              block
                              hidden={apiPlanOtaId === null || apiPlanOtaId === undefined}
                              loading={isUpdateDataFromSite}
                              onClick={updateDataFromSite}
                          >
                              {messages["page.apiPlan.crawlUpdate"]}
                          </Button>
                      </Col>
                  </Row>
              </Col>
          </Row>
          <Row justify="start" className="mb-25px">
              <Col xs={24} lg={12}>
                  <Form.Item
                      label={messages["page.apiPlanAddAndEdit.Rakuten.syncStatus"]}
                      name={`otaFieldContent.${tabKey}[syncStatus]`}
                      labelCol={{ lg: 8, xs: 24 }}
                      wrapperCol={{ lg: 12, xs: 24 }}
                  >
                      <Select
                          className="select"
                          name={`otaFieldContent.${tabKey}['syncStatus']`}
                          disabled={true}
                      >
                          {LIST_STATUS_SYNC.map((select, index) => {
                              return (
                                  <Select.Option
                                      key={index}
                                      value={select.id}
                                  >
                                      {select.name}
                                  </Select.Option>
                              );
                          })}
                      </Select>
                  </Form.Item>
              </Col>
              <Col xs={24} lg={12} className={'position-error-message'}>
                  <Row>
                      <Col xs={24} lg={2}>
                          <Button
                              type="primary"
                              className="mr-2"
                              loading={loadingSyncStatus}
                              icon={<ReloadOutlined />}
                              disabled={isCloneData}
                              onClick={() => refreshSyncPlan()}>
                          </Button>
                      </Col>
                      <Col xs={24} lg={12}>
                          <Form.Item
                              name={`otaFieldContent[${tabKey}]['syncErrorContent']`}
                          >
                              <Input
                                  className="styleInputLog"
                                  onClick={() => setShowLog(true)}
                                  name={`otaFieldContent[${tabKey}]['syncErrorContent']`}
                                  value={
                                    syncErrorContent !== null &&
                                    syncErrorContent !== undefined &&
                                    syncErrorContent.length > 0 ? syncErrorContent[0] : ''
                                  }
                                  readOnly
                              />
                          </Form.Item>
                      </Col>
                  </Row>
              </Col>
          </Row>
          <Row justify="start" className="mb-25px">
                <Col xs={24} lg={{ span: 24 }}>
                    <Form.Item
                        labelCol={{ lg: 4, xs: 24 }}
                        wrapperCol={{ lg: 6, xs: 24 }}
                        label={messages["page.apiPlan.ota.updateStatus"]}
                        name={`otaFieldContent[${tabKey}]['updateStatus']`}
                    >
                        <Select
                            className="select"
                            name={`otaFieldContent[${tabKey}]['updateStatus']`}
                            disabled={true}
                        >
                            {LIST_STATUS_SYNC.map((select, index) => {
                                return (
                                    <Select.Option
                                        key={index}
                                        value={select.id}
                                    >
                                        {select.name}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
          </Row>
          <Row justify="start" className="mb-25px">
                <Col xs={24} lg={{ span: 24 }}>
                    <Form.Item
                        labelCol={{ lg: 4, xs: 24 }}
                        wrapperCol={{ lg: 6, xs: 24 }}
                        label={messages["page.apiPlanAddAndEdit.openSale"]}
                        name={`otaFieldContent[${tabKey}]['openSaleStatus']`}
                    >
                        <Select
                            className="select"
                            name={`otaFieldContent[${tabKey}]['openSaleStatus']`}
                            disabled={true}
                        >
                            {LIST_STATUS_SALE_PROCESSING.map((select, index) => {
                                return (
                                    <Select.Option
                                        key={index}
                                        value={select.id}
                                    >
                                        {select.name}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
          </Row>
          <Row justify="start" className="mb-25px">
                <Col xs={24} lg={{ span: 24 }}>
                    <Form.Item
                        labelCol={{ lg: 4, xs: 24 }}
                        wrapperCol={{ lg: 6, xs: 24 }}
                        label={messages["page.crawlPlan.openSaleType"]}
                        name={`otaFieldContent[${tabKey}]['openSaleType']`}
                    >
                        <Select
                            className="select"
                            name={`otaFieldContent[${tabKey}]['openSaleType']`}
                            disabled={true}
                        >
                            {LIST_OPEN_SALE_STATUS.map((select, index) => {
                                return (
                                    <Select.Option
                                        key={index}
                                        value={select.id}
                                    >
                                        {select.name}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
          </Row>
          <Row justify="start" className="mb-25px">
              <Col xs={24} lg={{ span: 24 }}>
                  <Form.Item
                      label={
                        <div>
                            <div className="custom-required">{messages["page.apiPlanAddAndEdit.Rakuten.planName"]}</div>
                            <div>
                                <span className="text-note-row">{messages["page.apiPlanAddAndEdit.Rakuten.50WordAttention"]}</span>
                            </div>
                        </div>
                      }
                      name={`otaFieldContent.${tabKey}[planName]`}
                      validate={(value) => validationRequired(value, messages["page.apiPlanAddAndEdit.Rakuten.planName"])}
                      labelCol={{ lg: 4, xs: 24 }}
                      wrapperCol={{ lg: 6, xs: 24 }}
                      className="custom-label"
                  >
                      <Input name={`otaFieldContent.${tabKey}[planName]`}
                             maxLength={50}
                             onBlur={handleInputBlur}
                      />
                  </Form.Item>
              </Col>
          </Row>
          <Row justify="start">
              <Col xs={24} lg={{ span: 24 }}>
                  <Form.Item
                      labelCol={{ lg: 4, xs: 24 }}
                      wrapperCol={{ lg: 5, xs: 24 }}
                      label={messages["page.apiPlanAddAndEdit.Rakuten.secretSettingPlan"]}
                      name={`otaFieldContent.${tabKey}[secretSettingPlan]`}
                  >
                      <Checkbox.Group
                          onChange={(value) => changeSecretSettingPlanValue(`otaFieldContent.${tabKey}.secretSettingPlan`, value)}
                          value={secretSettingPlanValue}
                      >
                          <Row>
                              {secretSettingPlan.map((option, key) => (
                                  <Checkbox
                                      key={key}
                                      value={option.value}
                                      className="mr-1"
                                  >
                                      {option.value}
                                  </Checkbox>
                              ))}
                          </Row>
                      </Checkbox.Group>
                  </Form.Item>
              </Col>
          </Row>
          <Row justify="start" className="mb-25px">
              <Col xs={24} lg={{ span: 24, offset: 4 }}>
                  <Form.Item
                      labelCol={{ lg: 4, xs: 24 }}
                      wrapperCol={{ lg: 20, xs: 24 }}
                      label={""}
                      name={`otaFieldContent.${tabKey}.publishPersonalPage`}
                  >
                      <Radio.Group
                          name={`otaFieldContent.${tabKey}.publishPersonalPage`}
                          value={publishPersonalPageValue}
                          disabled={!isCheckedSecretSettingPlan}
                          onChange={(value) => changeRadioButtonValue(value, `otaFieldContent.${tabKey}.publishPersonalPage`)}
                      >
                          <Row justify="start">
                              <Col style={{ display: "flex" }}>
                                  {publishPersonalPage.map((item, index) => {
                                      return (
                                          <Radio
                                              className={"d-flex align-items-center mr-1"}
                                              value={item.value}
                                              key={item.value}
                                              disabled={!isCheckedSecretSettingPlan}
                                          >{item.value}</Radio>
                                      );
                                  })}
                                  <Form.Item
                                      name={`otaFieldContent.${tabKey}[passwordDontPublish]`}
                                  >
                                      <Input
                                          name={`otaFieldContent.${tabKey}[passwordDontPublish]`}
                                          maxLength={50}
                                          disabled={!isCheckedSecretSettingPlan || !isCheckedDontPublishPersonPage}
                                          onBlur={handleInputBlur}
                                      />
                                  </Form.Item>
                                  <span style={{ marginLeft: "5px", whiteSpace: 'nowrap' }} className={"d-flex align-items-center"}>
                                    {messages["page.apiPlanAddAndEdit.Rakuten.passwordDontPublish"]}
                                  </span>
                              </Col>
                          </Row>
                      </Radio.Group>
                  </Form.Item>
              </Col>
          </Row>
          <Row justify="start" className="mb-25px">
              <Col xs={24} lg={{ span: 24 }}>
                  <Form.Item
                      labelCol={{ lg: 4, xs: 24 }}
                      wrapperCol={{ lg: 16, xs: 24 }}
                      label={messages["page.apiPlanAddAndEdit.Rakuten.publishFlag"]}
                      name={`otaFieldContent.${tabKey}[publishFlag]`}
                  >
                      <Checkbox.Group
                          onChange={(value) => changeCheckBoxPublishFlagValue(`otaFieldContent.${tabKey}.publishFlag`, value)}
                          value={publishFlagValue}
                      >
                          <Row>
                              {publishFlagOptions.map((option, key) => (
                                  <Checkbox
                                      key={key}
                                      value={option.value}
                                      disabled={option.disabled}
                                      onChange={onChangePublishFlag}
                                      className="mr-1"
                                  >
                                      {option.value}
                                  </Checkbox>
                              ))}
                          </Row>
                      </Checkbox.Group>
                  </Form.Item>
              </Col>
          </Row>
          <Row justify="start" className="mb-25px">
              <Col xs={24} lg={{ span: 24 }}>
                  <Form.Item
                      labelCol={{ lg: 4, xs: 24 }}
                      wrapperCol={{ lg: 5, xs: 24 }}
                      label={messages["page.apiPlanAddAndEdit.Rakuten.voucherSettingTitle"]}
                      name={`otaFieldContent.${tabKey}[voucherSetting]`}
                  >
                      <Checkbox.Group
                          onChange={(value) => changeCheckBoxValue(`otaFieldContent.${tabKey}.voucherSetting`, value)}
                          value={voucherSettingValue}
                          options={[
                            { label: messages["page.apiPlanAddAndEdit.Rakuten.voucherSettingCheckbox"], value: messages["page.apiPlanAddAndEdit.Rakuten.voucherSettingCheckbox"] },
                          ]}
                      />
                  </Form.Item>
              </Col>
          </Row>
          <Row justify="start" className="mb-25px">
              <Col xs={24} lg={{ span: 24 }}>
                  <Form.Item
                      labelCol={{ lg: 4, xs: 24 }}
                      wrapperCol={{ lg: 12, xs: 24 }}
                      label={messages["page.apiPlanAddAndEdit.Rakuten.feature"]}
                      name={`otaFieldContent.${tabKey}[feature]`}
                  >
                      <Checkbox.Group
                          onChange={(value) => changeCheckBoxValue(`otaFieldContent.${tabKey}.feature`, value)}
                          value={featureValue}
                      >
                          <Row>
                              {featureOptions.map((option, key) => (
                                  <Checkbox
                                      key={key}
                                      value={option.value}
                                      className="mr-1"
                                  >
                                      {option.value}
                                  </Checkbox>
                              ))}
                          </Row>
                      </Checkbox.Group>
                  </Form.Item>
              </Col>
          </Row>
          <Row justify="start" className="mb-25px">
              <Col xs={24} lg={{ span: 24 }}>
                  <Form.Item
                      labelCol={{ lg: 4, xs: 24 }}
                      wrapperCol={{ lg: 12, xs: 24 }}
                      label={messages["page.apiPlanAddAndEdit.Rakuten.eatingPlace"]}
                      name="eatingPlace"
                  >
                      <Checkbox.Group
                          onChange={(value) => changeCheckBoxValue(`otaFieldContent.${tabKey}.eatingPlace`, value)}
                          value={eatingPlaceValue}
                      >
                          <Row>
                              {eatingPlaceOptions.map((option, key) => (
                                  <Checkbox
                                      key={key}
                                      value={option.value}
                                      className="mr-1"
                                  >
                                      {option.value}
                                  </Checkbox>
                              ))}
                          </Row>
                      </Checkbox.Group>
                  </Form.Item>
              </Col>
          </Row>
          <Row justify="start" className="mb-25px">
              <Col xs={24} lg={{ span: 24 }}>
                  <Form.Item
                      labelCol={{ lg: 4, xs: 24 }}
                      wrapperCol={{ lg: 12, xs: 24 }}
                      label={messages["page.apiPlanAddAndEdit.Rakuten.superDEAL"]}
                      name={`otaFieldContent.${tabKey}.supperDeal`}
                  >
                      <Checkbox.Group
                          onChange={(value) => changeCheckBoxValue(`otaFieldContent.${tabKey}.supperDeal`, value)}
                          value={supperDealValue}
                      >
                          <Row style={{ alignItems: 'center' }}>
                              <Checkbox
                                  onChange={changeCheckBoxSupperDeal}
                                  value={messages["page.apiPlanAddAndEdit.Rakuten.settingSuperDEALPoint"]}
                              >
                                  {messages["page.apiPlanAddAndEdit.Rakuten.settingSuperDEALPoint"]}
                              </Checkbox>

                              <Form.Item
                                className="ml-2"
                                name={`otaFieldContent.${tabKey}.superDealPoint`}
                              >
                                <div className="d-flex align-items-center">
                                    <Select
                                        name={`otaFieldContent.${tabKey}.superDealPoint`}
                                        listHeight={320}
                                        style={{ width: 70 }}
                                        onChange={(value) => changeSelectValue(`otaFieldContent.${tabKey}.superDealPoint`, value)}
                                        {...searchSelect}
                                        disabled={superDealPointDisable}
                                    >
                                        {superDealPointOption}
                                    </Select>
                                    <p className="ml-1">{messages["page.apiPlanAddAndEdit.Rakuten.percent"]}</p>
                                </div>
                                </Form.Item>
                          </Row>
                      </Checkbox.Group>
                  </Form.Item>
              </Col>
          </Row>
          <Row justify="start" className="mb-25px">
              <Col xs={24} lg={{ span: 24 }}>
                  <Form.Item
                      labelCol={{ lg: 4, xs: 24 }}
                      wrapperCol={{ lg: 6, xs: 24 }}
                      className="custom-label"
                      label={
                        <div>
                            <div>{messages["page.apiPlanAddAndEdit.Rakuten.packageName"]}</div>
                            <div>
                                <span className="text-note-row">{messages["page.apiPlanAddAndEdit.Rakuten.50WordAttention"]}</span>
                            </div>
                        </div>
                      }
                      name={`otaFieldContent.${tabKey}[packageName]`}
                  >
                      <Input name={`otaFieldContent.${tabKey}[packageName]`}
                             maxLength={50}
                             disabled={isCheckedSupperDeal}
                             onBlur={handleInputBlur}
                      />
                  </Form.Item>
              </Col>
          </Row>
          <Row justify="start" className="mb-25px">
              <Col xs={24} lg={{ span: 24 }}>
                  <Form.Item
                      labelCol={{ lg: 4, xs: 24 }}
                      wrapperCol={{ lg: 6, xs: 24 }}
                      className="custom-label"
                      label={
                        <div className="mb-25px pb-25px">
                            <div className="custom-required">{messages["page.apiPlanAddAndEdit.Rakuten.description"]}</div>
                            <div>
                                <span className="text-note-row">{messages["page.apiPlanAddAndEdit.Rakuten.1000WordAttention"]}</span>
                            </div>
                            <div>
                                <span className="text-note-row">{messages["page.apiPlanAddAndEdit.Rakuten.Attention.date"]}</span>
                            </div>
                            <div>
                                <span className="text-note-row">{messages["page.apiPlanAddAndEdit.Rakuten.Attention"]}</span>
                            </div>
                        </div>
                      }
                      name={`otaFieldContent.${tabKey}[description]`}
                      validate={(value) => !isCheckedSupperDeal
                          ? validationRequired(value, messages["page.apiPlanAddAndEdit.Rakuten.description"])
                          : ''}
                  >
                      <Input name={`otaFieldContent.${tabKey}[description]`}
                             maxLength={1000}
                             onBlur={handleInputBlur}
                      />
                  </Form.Item>
              </Col>
          </Row>
          <Row justify="start" className="mb-25px">
              <Col xs={24} lg={{ span: 24 }}>
                  <Form.Item
                      labelCol={{ lg: 4, xs: 24 }}
                      wrapperCol={{ lg: 6, xs: 24 }}
                      className="custom-label"
                      label={
                            <div className="mb-25px">
                                <div>{messages["page.apiPlanAddAndEdit.Rakuten.packageDescription"]}</div>
                                <div>
                                    <span className="text-note-row">{messages["page.apiPlanAddAndEdit.Rakuten.1000WordAttention"]}</span>
                                </div>
                                <div>
                                    <span className="text-note-row">{messages["page.apiPlanAddAndEdit.Rakuten.Attention.date"]}</span>
                                </div>
                                <div>
                                    <span className="text-note-row">{messages["page.apiPlanAddAndEdit.Rakuten.Attention"]}</span>
                                </div>
                            </div>
                      }
                      name={`otaFieldContent.${tabKey}[packageDescription]`}
                  >
                      <Input
                          name={`otaFieldContent.${tabKey}[packageDescription]`}
                          maxLength={1000}
                          disabled={isCheckedSupperDeal}
                          onBlur={handleInputBlur}
                      />
                  </Form.Item>
              </Col>
          </Row>
          <Row justify="start" className="mb-25px">
              <Col xs={24} lg={{ span: 24 }}>
                  <Form.Item
                      labelCol={{ lg: 4, xs: 24 }}
                      wrapperCol={{ lg: 5, xs: 24 }}
                      label={messages["page.apiPlanAddAndEdit.Rakuten.inbound"]}
                      name={`otaFieldContent.${tabKey}[inbound]`}
                  >
                      <Checkbox.Group
                          onChange={(value) => changeCheckBoxValue(`otaFieldContent.${tabKey}.inbound`, value)}
                          value={inboundValue}
                          disabled={isCheckedSupperDeal}
                      >
                          <Row>
                              <Checkbox
                                  onChange={changeCheckBoxInbound}
                                  value={messages["page.apiPlanAddAndEdit.Rakuten.starInboundSale"]}
                              >
                                  {messages["page.apiPlanAddAndEdit.Rakuten.starInboundSale"]}
                              </Checkbox>
                          </Row>
                      </Checkbox.Group>
                  </Form.Item>
              </Col>
          </Row>
          <Row justify="start" className="mb-25px">
              <Col xs={24} lg={{ span: 24, offset: 4 }}>
                  <Form.Item
                      labelCol={{ lg: 4, xs: 24 }}
                      wrapperCol={{ lg: 6, xs: 24 }}
                      label={""}
                      name={`otaFieldContent.${tabKey}[inboundType]`}
                  >
                      <Select
                          name={`otaFieldContent.${tabKey}[inboundType]`}
                          listHeight={320}
                          onChange={(value) => changeSelectValue(`otaFieldContent.${tabKey}.inboundType`, value)}
                          showSearch
                          allowClear
                          disabled={isCheckedSupperDeal || !isCheckedInbound}
                          {...searchSelect}
                      >
                          {inboundTypeSelectOptions.map((option, index) => {
                              return (
                                  <Option key={index} value={option.value}>
                                      {option.value}
                                  </Option>
                              )
                          })}
                      </Select>
                  </Form.Item>
              </Col>
          </Row>
          <Row justify="start" className="mb-25px">
              <Col xs={12} lg={{ span: 7, offset: 4 }}>
                  <Form.Item
                      labelCol={{ lg: 4, xs: 24 }}
                      wrapperCol={{ lg: 5, xs: 24 }}
                      label={""}
                      name={`otaFieldContent.${tabKey}[inboundEasySetType]`}
                  >
                      <Radio.Group
                          name={`otaFieldContent.${tabKey}[inboundEasySetType]`}
                          disabled={isCheckedSupperDeal || !isCheckedInbound}
                          value={inboundEasySetType} onChange={(value) => changeRadioButtonValue(value, `otaFieldContent.${tabKey}.inboundEasySetType`)}
                      >
                          <Space direction="vertical">
                              {inboundTypeOptions.map((item) => {
                                  return (
                                      <Radio value={item.value} key={item.value}>{item.value}</Radio>
                                  );
                              })}
                              <Radio value={"none"}></Radio>
                              <Form.Item
                                  wrapperCol={{ lg: 21, xs: 21 }}
                                  name={`otaFieldContent.${tabKey}[inboundEasySetTitle]`}
                              >
                                  <Input name={`otaFieldContent.${tabKey}[inboundEasySetTitle]`}
                                         disabled={isCheckedSupperDeal || !isCheckedInbound}
                                         maxLength={50}
                                         onBlur={handleInputBlur}
                                  />
                              </Form.Item>
                          </Space>
                      </Radio.Group>
                  </Form.Item>
              </Col>
              <Col xs={11} lg={{ span: 7, offset: 2 }} className="d-flex flex-column">
                  <span className="mb-8px">{messages["page.apiPlanAddAndEdit.Rakuten.standardPlan.remark"]}</span>
                  <span className="mb-8px">{messages["page.apiPlanAddAndEdit.Rakuten.noMealPlan.remark"]}</span>
                  <span className="mb-8px">{messages["page.apiPlanAddAndEdit.Rakuten.planWithBreakfast.remark"]}</span>
                  <span className="mb-8px">{messages["page.apiPlanAddAndEdit.Rakuten.planWithDinnerAndBreakfast.remark"]}</span>
                  <div className="d-flex">
                      <div className="d-flex align-item-baseline">
                          <Form.Item
                              wrapperCol={{ lg: 22, xs: 22 }}
                              name={`otaFieldContent.${tabKey}[inboundOffConditionDays]`}
                          >
                              <InputNumber
                                  name={`otaFieldContent.${tabKey}[inboundOffConditionDays]`}
                                  disabled={isCheckedSupperDeal || !isCheckedInbound}
                                  maxLength={3}
                                  style={styleInputCommon}
                              />
                          </Form.Item>
                          <span className="mb-8px min-width-60px">{messages["page.apiPlanAddAndEdit.Rakuten.consecutiveNightDiscountPlan.remark"]}</span>
                      </div>
                      <div className="d-flex align-item-baseline">
                          <Form.Item
                              wrapperCol={{ lg: 22, xs: 22 }}
                              name={`otaFieldContent.${tabKey}[inboundOffPercent]`}
                          >
                              <InputNumber
                                  name={`otaFieldContent.${tabKey}[inboundOffPercent]`}
                                  disabled={isCheckedSupperDeal || !isCheckedInbound}
                                  maxLength={3}
                                  style={styleInputCommon}
                              />
                          </Form.Item>
                          <span className="mb-8px min-width-60px">{messages["page.apiPlanAddAndEdit.Rakuten.consecutiveNightDiscountPlan.discount"]}</span>
                      </div>
                  </div>
                  <span className="mb-8px">{messages["page.apiPlanAddAndEdit.Rakuten.hotSpringPlan.remark"]}</span>
                  <span className="mb-8px">{messages["page.apiPlanAddAndEdit.Rakuten.skiPlan.remark"]}</span>
                  <span className="mb-8px">{messages["page.apiPlanAddAndEdit.Rakuten.freeUpgradePlan.remark"]}</span>
                  <span className="mb-8px">{messages["page.apiPlanAddAndEdit.Rakuten.earlyBirdPlan.remark"]}</span>
                  <br/>
                  <div className="d-flex align-item-baseline">
                      <Form.Item
                          wrapperCol={{ lg: 24, xs: 24 }}
                          name={`otaFieldContent.${tabKey}[inboundEasySetContent]`}
                      >
                          <Input name={`otaFieldContent.${tabKey}[inboundEasySetContent]`}
                                 disabled={isCheckedSupperDeal || !isCheckedInbound}
                                 maxLength={2000}
                                 onBlur={handleInputBlur}
                          />
                      </Form.Item>
                  </div>
              </Col>
          </Row>
          <Row justify="start" className="mb-25px">
              <Col xs={24} lg={{ span: 24 }}>
                  <Form.Item
                      labelCol={{ lg: 4, xs: 24 }}
                      wrapperCol={{ lg: 20, xs: 24 }}
                      className="required"
                      label={messages["page.apiPlanAddAndEdit.Rakuten.postPeriodTime"]}
                      name={`otaFieldContent.${tabKey}.postPeriodTime`}
                  >
                      <Col xs={24} md={24} lg={{ span: 24, offset: 0 }} className="d-flex flex-wrap">
                          <Form.Item
                              className="row-reverse mt-5px mr-1"
                              name={`otaFieldContent.${tabKey}.postPeriodDateFrom`}
                              validate={(value) => validationRequired(value, messages["page.apiPlanAddAndEdit.Rakuten.postPeriodTime"])}
                          >
                              <DatePicker
                                  format={DATE_FORMAT}
                                  name={`otaFieldContent.${tabKey}.postPeriodDateFrom`}
                                  onChange={(date) => onChangeDate(`otaFieldContent.${tabKey}.postPeriodDateFrom`, date)}
                              />
                          </Form.Item>
                          <Form.Item
                              className="mt-5px"
                              name={`otaFieldContent.${tabKey}[postPeriodHourFrom]`}
                              validate={(value) => validationRequired(value, messages["page.apiPlanAddAndEdit.Rakuten.postPeriodTime"])}
                          >
                              <div className="d-flex align-items-center">
                                  <Select
                                      name={`otaFieldContent.${tabKey}[postPeriodHourFrom]`}
                                      listHeight={320}
                                      style={{ width: 70 }}
                                      onChange={(value) => changeSelectValue(`otaFieldContent.${tabKey}.postPeriodHourFrom`, value)}
                                      showSearch allowClear
                                      {...searchSelect}
                                  >
                                      {hourOptions.map((option, index) => {
                                          return (
                                              <Option key={index} value={option.value}>
                                                  {option.value}
                                              </Option>
                                          )
                                      })}
                                  </Select>
                                  <p className="m-2">{messages["page.apiPlanAddAndEdit.Rakuten.hourAndStartMinutes"]}</p>
                              </div>
                          </Form.Item>
                          <Form.Item
                              className="row-reverse mt-5px mr-1"
                              name={`otaFieldContent.${tabKey}.postPeriodDateTo`}
                              validate={(value) => validationRequired(value, messages["page.apiPlanAddAndEdit.Rakuten.postPeriodTime"])}
                          >
                              <DatePicker
                                  format={DATE_FORMAT}
                                  name={`otaFieldContent.${tabKey}.postPeriodDateTo`}
                                  onChange={(date) => onChangeDate(`otaFieldContent.${tabKey}.postPeriodDateTo`, date)}
                              />
                          </Form.Item>
                          <Form.Item
                              className="mt-5px"
                              name={`otaFieldContent.${tabKey}[postPeriodHourTo]`}
                              validate={(value) => validationRequired(value, messages["page.apiPlanAddAndEdit.Rakuten.postPeriodTime"])}
                          >
                              <div className="d-flex align-items-center">
                                  <Select
                                      name={`otaFieldContent.${tabKey}[postPeriodHourTo]`}
                                      listHeight={320}
                                      style={{ width: 70, marginRight: 15 }}
                                      showSearch allowClear
                                      onChange={(value) => changeSelectValue(`otaFieldContent.${tabKey}.postPeriodHourTo`, value)}
                                      {...searchSelect}
                                  >
                                      {hourOptions.map((option, index) => {
                                          return (
                                              <Option key={index} value={option.value}>
                                                  {option.value}
                                              </Option>
                                          )
                                      })}
                                  </Select>
                                  <p>{messages["page.apiPlanAddAndEdit.Rakuten.hourAndEndMinutes"]}</p>
                              </div>
                          </Form.Item>
                      </Col>
                  </Form.Item>
              </Col>
          </Row>
          <Row justify="start" className="mb-25px d-flex align-item-baseline">
              <Col xs={24} lg={{ span: 24 }}>
                  <Form.Item
                      labelCol={{ lg: 4, xs: 24 }}
                      wrapperCol={{ lg: 20, xs: 24 }}
                      className="required"
                      label={messages["page.apiPlanAddAndEdit.Rakuten.implementationPeriod"]}
                      name={`otaFieldContent.${tabKey}.implementationPeriod`}
                  >
                      <Col xs={24} md={24} lg={{ span: 24, offset: 0 }} className="d-flex flex-wrap">
                          <Form.Item
                              className="row-reverse mt-5px"
                              name={`otaFieldContent.${tabKey}.implementPeriodDateFrom`}
                              validate={(value) => validationRequired(value, messages["page.apiPlanAddAndEdit.Rakuten.implementationPeriod"])}
                          >
                              <div className="d-flex align-items-center">
                                  <DatePicker
                                      format={DATE_FORMAT}
                                      name={`otaFieldContent.${tabKey}.implementPeriodDateFrom`}
                                      onChange={(date) => onChangeDate(`otaFieldContent.${tabKey}.implementPeriodDateFrom`, date)}
                                  />
                                  <p className="m-2">{messages["page.apiPlanAddAndEdit.Rakuten.specialChar"]}</p>
                              </div>
                          </Form.Item>
                          <Form.Item
                              className="row-reverse mt-5px"
                              name={`otaFieldContent.${tabKey}.implementPeriodDateTo`}
                              validate={(value) => validationRequired(value, messages["page.apiPlanAddAndEdit.Rakuten.implementationPeriod"])}
                          >
                              <DatePicker
                                  format={DATE_FORMAT}
                                  name={`otaFieldContent.${tabKey}.implementPeriodDateTo`}
                                  onChange={(date) => onChangeDate(`otaFieldContent.${tabKey}.implementPeriodDateTo`, date)}
                              />
                          </Form.Item>
                      </Col>
                  </Form.Item>
              </Col>
          </Row>
          <Row justify="start" className="mb-25px">
              <Col xs={24} lg={{ span: 24, offset: 0 }}>
                  <Form.Item
                      labelCol={{ lg: 4, xs: 24 }}
                      wrapperCol={{ lg: 5, xs: 24 }}
                      className="required"
                      label={messages["page.apiPlanAddAndEdit.Rakuten.planPaymentMethod"]}
                      name={`otaFieldContent.${tabKey}[planPaymentMethod]`}
                      validate={(value) => validationRequired(value, messages["page.apiPlanAddAndEdit.Rakuten.planPaymentMethod"])}
                  >
                      <Radio.Group
                          name={`otaFieldContent.${tabKey}[planPaymentMethod]`}
                          value={planPaymentMethod} onChange={(value) => changeRadioButtonValue(value, `otaFieldContent.${tabKey}.planPaymentMethod`)}
                      >
                          <Space direction="vertical">
                              {paymentMethodOptions.map((item) => {
                                  return (
                                      <Radio value={item.value} key={item.value}>{item.value}</Radio>
                                  );
                              })}
                          </Space>
                      </Radio.Group>
                  </Form.Item>
              </Col>
          </Row>
          <Row justify="start" className="mb-25px">
              <Col xs={24} lg={{ span: 24, offset: 0 }}>
                  <Form.Item
                      labelCol={{ lg: 4, xs: 24 }}
                      wrapperCol={{ lg: 5, xs: 24 }}
                      className="required"
                      label={messages["page.apiPlanAddAndEdit.Rakuten.memberRankBenefit"]}
                      name={`otaFieldContent.${tabKey}[memberRankBenefit]`}
                      validate={(value) => validationRequired(value, messages["page.apiPlanAddAndEdit.Rakuten.memberRankBenefit"])}
                  >
                      <Radio.Group
                          name={`otaFieldContent.${tabKey}[memberRankBenefit]`}
                          value={memberRankBenefit}
                          onChange={(value) => changeRadioButtonValue(value, `otaFieldContent.${tabKey}.memberRankBenefit`)}
                      >
                          <Space direction="vertical">
                              {memberRankBenefitOptions.map((item) => {
                                  return (
                                      <Radio value={item.value} key={item.value}>{item.value}</Radio>
                                  );
                              })}
                          </Space>
                      </Radio.Group>
                  </Form.Item>
              </Col>
          </Row>
          <Row justify="start" className="mb-25px">
              <Col xs={24} lg={{ span: 4, offset: 0 }}>
                  <label>{messages["page.apiPlanAddAndEdit.Rakuten.consecutiveDay"]}</label>
              </Col>
              <Col xs={24} lg={{ span: 20, offset: 0 }} className="d-flex">
                  <Col xs={24} md={24} lg={{ span: 24, offset: 0 }} className="d-flex">
                      <div className="d-flex align-item-baseline">
                          <span className="mb-8px" style={{ minWidth: 40 }}>{messages["page.apiPlanAddAndEdit.Rakuten.shortest"]}</span>
                          <Form.Item
                              wrapperCol={{ lg: 22, xs: 22 }}
                              name={`otaFieldContent.${tabKey}[consecutiveDayShortest]`}
                          >
                              <InputNumber
                                  name={`otaFieldContent.${tabKey}[consecutiveDayShortest]`}
                                  maxLength={2}
                                  style={styleInputCommon}
                              />
                          </Form.Item>
                          <span className="mb-8px min-width-60px">{messages["page.apiPlanAddAndEdit.Rakuten.consecutiveDayShortest"]}</span>
                      </div>
                      <div className="d-flex align-item-baseline">
                          <span className="mb-8px" style={{ minWidth: 40 }}>{messages["page.apiPlanAddAndEdit.Rakuten.longest"]}</span>
                          <Form.Item
                              wrapperCol={{ lg: 22, xs: 22 }}
                              name={`otaFieldContent.${tabKey}[consecutiveDayLongest]`}
                          >
                              <InputNumber
                                  name={`otaFieldContent.${tabKey}[consecutiveDayLongest]`}
                                  maxLength={2}
                                  style={styleInputCommon}
                              />
                          </Form.Item>
                          <span className="mb-8px min-width-60px">{messages["page.apiPlanAddAndEdit.Rakuten.consecutiveDayLongest"]}</span>
                      </div>
                  </Col>
              </Col>
          </Row>
          <Row justify="start" className="mb-25px">
              <Col xs={24} lg={{ span: 24, offset: 0 }}>
                  <Col xs={24} lg={{ span: 24, offset: 0 }}>
                      <Form.Item
                          labelCol={{ lg: 4, xs: 24 }}
                          wrapperCol={{ lg: 15, xs: 24 }}
                          className="required"
                          validate={(value) => validationRequired(value, messages["page.apiPlanAddAndEdit.Rakuten.acceptStartType"])}
                          label={messages["page.apiPlanAddAndEdit.Rakuten.acceptStartType"]}
                          name={`otaFieldContent.${tabKey}[acceptStartType]`}
                      >
                          <Radio.Group
                              name={`otaFieldContent.${tabKey}[acceptStartType]`}
                              value={acceptStartType}
                              onChange={(value) => changeRadioAcceptStartValue(value, `otaFieldContent.${tabKey}.acceptStartType`)}
                          >
                              {acceptStartTypeOptions.map((item) => {
                                  return (
                                      <Radio value={item.value} key={item.value}>{item.value}</Radio>
                                  );
                              })}
                          </Radio.Group>
                      </Form.Item>
                  </Col>
                  <Col xs={24} lg={{ span: 24, offset: 4 }} className="d-flex align-item-baseline">
                      <span style={{ minWidth: 65 }}>{messages["page.apiPlanAddAndEdit.Rakuten.accommodationDay"]}</span>
                      <Form.Item
                          wrapperCol={{ lg: 22, xs: 22 }}
                          validate={(value) => !isDisableAcceptStart ?  validationRequired(value, messages["page.apiPlanAddAndEdit.Rakuten.accommodationDay"]) : ''}
                          name={`otaFieldContent.${tabKey}[acceptStartDay]`}
                      >
                          <Select
                              name={`otaFieldContent.${tabKey}[acceptStartDay]`}
                              listHeight={320}
                              style={styleInputCommon}
                              showSearch
                              allowClear
                              disabled={isDisableAcceptStart}
                              onChange={(value) => changeSelectValue(`otaFieldContent.${tabKey}[acceptStartDay]`, value)}
                              {...searchSelect}
                          >
                              {acceptStartDayValues.map((option, index) => {
                                  return (
                                      <Option key={index} value={option.value}>
                                          {option.value}
                                      </Option>
                                  )
                              })}
                          </Select>
                      </Form.Item>
                      <span className="mb-8px min-width-30px">{messages["page.apiPlanAddAndEdit.Rakuten.of"]}</span>
                      <Form.Item
                          wrapperCol={{ lg: 22, xs: 22 }}
                          validate={(value) => !isDisableAcceptStart ?  validationRequired(value, messages["page.apiPlanAddAndEdit.Rakuten.fromTime"]) : ''}
                          name={`otaFieldContent.${tabKey}[acceptStartHour]`}
                      >
                          <Select
                              name={`otaFieldContent.${tabKey}[acceptStartHour]`}
                              listHeight={320}
                              style={styleInputCommon}
                              showSearch
                              allowClear
                              disabled={isDisableAcceptStart}
                              onChange={(value) => changeSelectValue(`otaFieldContent.${tabKey}[acceptStartHour]`, value)}
                              {...searchSelect}
                          >
                              {acceptStartHourValues.map((option, index) => {
                                  return (
                                      <Option key={index} value={option.value}>
                                          {option.value}
                                      </Option>
                                  )
                              })}
                          </Select>
                      </Form.Item>
                      <span className="mb-8px min-width-60px">{messages["page.apiPlanAddAndEdit.Rakuten.fromTime"]}</span>
                  </Col>
              </Col>
          </Row>
          <Row justify="start" className="mb-25px">
              <Col xs={24} lg={{ span: 24, offset: 0 }}>
                  <Col xs={24} lg={{ span: 24, offset: 0 }}>
                      <Form.Item
                          labelCol={{ lg: 4, xs: 24 }}
                          wrapperCol={{ lg: 15, xs: 24 }}
                          className="required"
                          validate={(value) => validationRequired(value, messages["page.apiPlanAddAndEdit.Rakuten.acceptEndType"])}
                          label={messages["page.apiPlanAddAndEdit.Rakuten.acceptEndType"]}
                          name={`otaFieldContent.${tabKey}[acceptEndType]`}
                      >
                          <Radio.Group
                              name={`otaFieldContent.${tabKey}[acceptEndType]`} value={acceptEndType}
                              onChange={(value) => changeRadioAcceptEndValue(value, `otaFieldContent.${tabKey}.acceptEndType`)}
                          >
                              {acceptEndTypeOptions.map((item) => {
                                  return (
                                      <Radio value={item.value} key={item.value}>{item.value}</Radio>
                                  );
                              })}
                          </Radio.Group>
                      </Form.Item>
                  </Col>
                  <Col xs={24} lg={{ span: 24, offset: 4 }} className="d-flex align-item-baseline">
                      <span style={{ minWidth: 65 }}>{messages["page.apiPlanAddAndEdit.Rakuten.accommodationDay"]}</span>
                      <Form.Item
                          wrapperCol={{ lg: 22, xs: 22 }}
                          validate={(value) => !isDisableAcceptEnd ?  validationRequired(value, messages["page.apiPlanAddAndEdit.Rakuten.accommodationDay"]) : ''}
                          name={`otaFieldContent.${tabKey}[acceptEndDay]`}
                      >
                          {isAcceptEndRestrict ? (
                              <Input
                                  name={`otaFieldContent.${tabKey}[acceptEndDay]`}
                                  disabled={isDisableAcceptEnd}
                                  onBlur={handleInputBlur}
                              />
                          ) : (
                              <Select
                                  name={`otaFieldContent.${tabKey}[acceptEndDay]`}
                                  listHeight={320}
                                  style={styleInputCommon}
                                  showSearch
                                  allowClear
                                  disabled={isDisableAcceptEnd}
                                  onChange={(value) => changeSelectValue(`otaFieldContent.${tabKey}[acceptEndDay]`, value)}
                                  {...searchSelect}
                              >
                                  {acceptEndDayValues.map((option, index) => {
                                      return (
                                          <Option key={index} value={option.value}>
                                              {option.value}
                                          </Option>
                                      )
                                  })}
                              </Select>
                          )}
                      </Form.Item>
                      <span className="mb-8px min-width-30px">{messages["page.apiPlanAddAndEdit.Rakuten.of"]}</span>
                      <Form.Item
                          wrapperCol={{ lg: 22, xs: 22 }}
                          validate={(value) => !isDisableAcceptEnd ?  validationRequired(value, messages["page.apiPlanAddAndEdit.Rakuten.fromTime"]) : ''}
                          name={`otaFieldContent.${tabKey}[acceptEndHour]`}
                      >
                          <Select
                              name={`otaFieldContent.${tabKey}[acceptEndHour]`}
                              listHeight={320}
                              style={styleInputCommon}
                              showSearch
                              allowClear
                              disabled={isDisableAcceptEnd}
                              onChange={(value) => changeSelectValue(`otaFieldContent.${tabKey}[acceptEndHour]`, value)}
                              {...searchSelect}
                          >
                              {acceptEndHourValues.map((option, index) => {
                                  return (
                                      <Option key={index} value={option.value}>
                                          {option.value}
                                      </Option>
                                  )
                              })}
                          </Select>
                      </Form.Item>
                      <span className="mb-8px min-width-60px">{messages["page.apiPlanAddAndEdit.Rakuten.fromTime"]}</span>
                  </Col>
              </Col>
          </Row>
          <Row justify="start" className="mb-25px">
              <Col xs={24} lg={{ span: 4, offset: 0 }}>
                  <label>{messages["page.apiPlanAddAndEdit.Rakuten.checkInTime"]}</label>
              </Col>
              <Col xs={24} lg={{ span: 20, offset: 0 }} className="d-flex">
                  <Col xs={24} md={24} lg={{ span: 24, offset: 0 }} className="d-flex">
                      <div className="d-flex align-item-baseline">
                          <span style={{ minWidth: 120 }}>{messages["page.apiPlanAddAndEdit.Rakuten.checkInTime"]}</span>
                          <Form.Item
                              wrapperCol={{ lg: 22, xs: 22 }}
                              name={`otaFieldContent.${tabKey}[checkinHourStart]`}
                          >
                              <Select
                                  name={`otaFieldContent.${tabKey}[checkinHourStart]`}
                                  listHeight={320}
                                  style={styleInputCommon}
                                  allowClear
                                  showSearch
                                  onChange={(value) => changeSelectValue(`otaFieldContent.${tabKey}.checkinHourStart`, value)}
                                  {...searchSelect}
                              >
                                  {checkinHourOptions.map((option, index) => {
                                      return (
                                          <Option key={index} value={option.value}>
                                              {option.value}
                                          </Option>
                                      )
                                  })}
                              </Select>
                          </Form.Item>
                          <span className="mb-8px min-width-30px">{messages["page.apiPlanAddAndEdit.Rakuten.time"]}～</span>
                      </div>
                      <div className="d-flex align-item-baseline">
                          <Form.Item
                              wrapperCol={{ lg: 22, xs: 22 }}
                              name={`otaFieldContent.${tabKey}[checkinHourEnd]`}
                          >
                              <Select
                                  name={`otaFieldContent.${tabKey}[checkinHourEnd]`}
                                  listHeight={320}
                                  style={styleInputCommon}
                                  allowClear
                                  showSearch
                                  onChange={(value) => changeSelectValue(`otaFieldContent.${tabKey}.checkinHourEnd`, value)}
                                  {...searchSelect}
                              >
                                  {checkinHourOptions.map((option, index) => {
                                      return (
                                          <Option key={index} value={option.value}>
                                              {option.value}
                                          </Option>
                                      )
                                  })}
                              </Select>
                          </Form.Item>
                          <span className="mb-8px min-width-60px">{messages["page.apiPlanAddAndEdit.Rakuten.time"]}</span>
                      </div>
                  </Col>
              </Col>
          </Row>
          <Row justify="start" className="mb-25px">
              <Col xs={24} lg={{ span: 4, offset: 0 }}>
                  <label>{messages["page.apiPlanAddAndEdit.Rakuten.cancelPolicy"]}</label>
              </Col>
              <Col xs={24} lg={{ span: 20, offset: 0 }} className="d-flex">
                  <Form.Item
                      wrapperCol={{ lg: 6, xs: 24 }}
                      label={""}
                      name={`otaFieldContent.${tabKey}[cancelPolicy]`}
                  >
                      <Select
                          name={`otaFieldContent.${tabKey}[cancelPolicy]`}
                          listHeight={320}
                          style={styleSelectCommon}
                          showSearch
                          allowClear
                          onChange={(value) => changeSelectValue(`otaFieldContent.${tabKey}.cancelPolicy`, value)}
                          {...searchSelect}
                      >
                          {listCancelPolicy.map((select, index) => {
                              return (
                                  <Select.Option
                                      key={index}
                                      value={select.value}
                                  >
                                      {select.value}
                                  </Select.Option>
                              );
                          })}
                      </Select>
                  </Form.Item>
                  <Form.Item
                      wrapperCol={{ lg: 4, xs: 24, offset: 1 }}
                      label={""}
                      name={`otaFieldContent.${tabKey}[cancelPolicyStatus]`}
                  >
                      <Select
                          name={`otaFieldContent.${tabKey}[cancelPolicyStatus]`}
                          listHeight={320}
                          style={styleSelectCommon}
                          disabled={true}
                          value={statusCrawlCancelPolicy}
                      >
                          {LIST_STATUS_CRAWL.map((select, index) => {
                              return (
                                  <Select.Option
                                      key={index}
                                      value={select.id}
                                  >
                                      {select.name}
                                  </Select.Option>
                              );
                          })}
                      </Select>
                  </Form.Item>
                  <Button type="primary"
                          style={{ marginLeft: "15px", width: "32px"}}
                          block
                          icon={<ReloadOutlined />}
                          onClick={() => refreshCancelPolicy()}
                  ></Button>
                  <Button type="primary"
                          style={{ marginLeft: "10px", width: "120px"}}
                          block
                          onClick={() => crawlCancelPolicy()}
                  >{messages["page.apiPlanAddAndEdit.buttonReload"]}
                  </Button>
              </Col>
          </Row>
          <Row justify="start" className="mb-25px">
              <Col xs={24} lg={{ span: 4, offset: 0 }}>
                  <label>{messages["page.apiPlanAddAndEdit.Rakuten.limitedSaleSetting"]}</label>
              </Col>
              <Col xs={24} lg={{ span: 20, offset: 0 }} className="d-flex">
                  <Col xs={24} md={24} lg={{ span: 24, offset: 0 }} className="d-flex">
                      <div className="d-flex align-item-baseline">
                          <span className="mb-8px min-width-60px" style={{ minWidth: 85 }}>{messages["page.apiPlanAddAndEdit.Rakuten.limitedNumberOfSales"]}</span>
                          <Form.Item
                              wrapperCol={{ lg: 22, xs: 22 }}
                              name={`otaFieldContent.${tabKey}[saleLimitSetting]`}
                          >
                              <InputNumber
                                  name={`otaFieldContent.${tabKey}[saleLimitSetting]`}
                                  maxLength={3}
                                  style={styleInputCommon}
                              />
                          </Form.Item>
                          <span className="mb-8px min-width-60px">{messages["page.apiPlanAddAndEdit.Rakuten.room"]}</span>
                      </div>
                  </Col>
              </Col>
          </Row>
          <Row justify="start" className="mb-25px">
              <Col xs={24} lg={{ span: 4, offset: 0 }}>
                  <label>{messages["page.apiPlanAddAndEdit.Rakuten.limitedSaleSettingPackage"]}</label>
              </Col>
              <Col xs={24} lg={{ span: 20, offset: 0 }} className="d-flex">
                  <Col xs={24} md={24} lg={{ span: 24, offset: 0 }} className="d-flex">
                      <div className="d-flex align-item-baseline">
                          <span className="mb-8px min-width-60px" style={{ minWidth: 175 }}>{messages["page.apiPlanAddAndEdit.Rakuten.limitedNumberOfSale"]}</span>
                          <Form.Item
                              wrapperCol={{ lg: 22, xs: 22 }}
                              name={`otaFieldContent.${tabKey}[packageSaleLimitSetting]`}
                          >
                              <InputNumber
                                  name={`otaFieldContent.${tabKey}[packageSaleLimitSetting]`}
                                  maxLength={3}
                                  style={styleInputCommon}
                                  disabled={isCheckedSupperDeal}
                              />
                          </Form.Item>
                          <span className="mb-8px min-width-60px">{messages["page.apiPlanAddAndEdit.Rakuten.room"]}</span>
                      </div>
                  </Col>
              </Col>
          </Row>
          <Row justify="start" className="mb-25px">
              <RoomRakutenExtendFields
                  dataOtas={values}
                  setFieldValue={setFieldValue}
                  otaId={otaId}
                  storeId={storeId}
                  tabKey={tabKey}
                  apiPlanOtaId={apiPlanOtaId}
                  setEventKodomoSubmit={setEventKodomoSubmit}
                  isCloneData={isCloneData}
                  activeTabKey={activeTabKey}
              />
          </Row>
          <Row justify="start" className="mb-25px">
              <UploadFileImageOta
                  values={values}
                  setFieldValue={setFieldValue}
                  otaId={otaId}
                  storeId={storeId}
                  tabKey={tabKey}
                  isCloneData={isCloneData}
              ></UploadFileImageOta>
          </Row>
          <Modal
              title={messages["page.apiPlanAddAndEdit.titleLog"]}
              style={{ top: 20 }}
              width={700}
              visible={showLog}
              footer={null}
              onCancel={() => setShowLog(false)}
          >
              <Row style={{maxHeight: '80vh', overflowY: 'auto', display: 'block'}}>
                  {syncErrorContent != null && syncErrorContent.length > 0 && syncErrorContent.map((x, index) => (
                      <Row style={{marginTop: '10px' }} key={index}>
                          <p key={index}>{x}</p>
                      </Row>
                  ))}
              </Row>
              {syncErrorContent == null || syncErrorContent.length <= 0 && (
                  <Row  style={{ padding: '10px', justifyContent: 'center' }}>
                      <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={
                              <span>{messages["antTable.emptyData"]}</span>
                          }
                      >
                      </Empty>
                  </Row>
              )}
          </Modal>
      </LayoutContentWrapper>
  );
};
Rakuten.propTypes = {
    setFieldValue: PropTypes.any,
    apiPlanId: PropTypes.any,
};

const rakutenInitialValue = {
    planName: null,
    publishFlag: [],
    secretSettingPlan: [],
    publishPersonalPage: null,
    passwordDontPublish: null,
    voucherSetting: [],
    feature: [],
    eatingPlace: [],
    packageName: null,
    supperDeal: null,
    description: null,
    packageDescription: null,
    inbound: [],
    inboundType: null,
    inboundEasySetTitle: null,
    inboundOffConditionDays: null,
    inboundOffPercent: null,
    inboundEasySetContent: null,
    postPeriodDateFrom: null,
    postPeriodHourFrom: null,
    postPeriodDateTo: null,
    postPeriodHourTo: null,
    implementPeriodDateFrom: null,
    implementPeriodDateTo: null,
    planPaymentMethod: null,
    memberRankBenefit: null,
    consecutiveDayShortest: null,
    consecutiveDayLongest: null,
    acceptStartType: null,
    acceptStartDay: null,
    acceptStartHour: null,
    acceptEndType: null,
    acceptEndDay: null,
    acceptEndHour: null,
    checkinHourStart: null,
    checkinHourEnd: null,
    cancelPolicy: null,
    status: null,
    saleLimitSetting: null,
    packageSaleLimitSetting: null,
    roomOtaToAdd: [],
    roomOtaToDelete: [],
    imageOtaToAdd: [],
    imageOtaToDelete: [],
    imageOtaToUpdateUrl: [],
}

const styleInputCommon = {
    minWidth: 168,
    marginRight: 15
}

const styleSelectCommon = {
    width: 168,
    marginRight: 15
}

export default memo(Rakuten);
