import React, { useState, useEffect, useCallback } from "react";
import { Col, Radio, Form, Button, Row, Checkbox, Modal } from "antd";
import { useIntl } from "react-intl";
import { MoneyRegisterStyle } from "@iso/components/RoomAddAndEditComponent/components/MoneyRegister/MoneyRegisterStyle";
import { isEmpty, map, set } from "lodash";
import CheckBoxOnly from "../../MoneyRegisters/CheckBoxOnly";
import InputSettingPrice from "../../MoneyRegisters/InputSettingPrice";
import { colFull } from "@iso/assets/styles/form.style";
import { arrayOptionDay, PRICE_DAY_FORMAT_DAY_OFF_WEEK, PRICE_DAY_FORMAT_WEEKDAY } from "@iso/constants/api.constant";
import InputPoint from "../../MoneyRegisters/InputPoint";

const TableSettingPriceByPerson = ({
    setFieldValue,
    dataRoom,
    tabKey,
    dataOtaTab,
    closeMoneyRegisterPopup,
    setEventKodomoSubmit,
    isCancelInsertKodomo,
    selectedRowKeys,
    listApiRoom
}) => {
    const { messages } = useIntl();
    const [form] = Form.useForm();
    const [initialValueFormKodomo, setInitialValueFormKodomo] = useState(kodomoModePersonInitialValue)
    const [juniorHighAcceptDate, setJuniorHighAcceptDate] = useState(messages["page.room.moneyRegister.percent"]);
    const [juniorLowAcceptDate, setJuniorLowAcceptDate] = useState(messages["page.room.moneyRegister.percent"]);
    const [infrantMealPalletAcceptDate, setInfrantMealPalletAcceptDate] = useState(messages["page.room.moneyRegister.percent"]);
    const [infrantMealOnlyAcceptDate, setInfrantMealOnlyAcceptDate] = useState(messages["page.room.moneyRegister.percent"]);
    const [infrantPalletOnlyAcceptDate, setInfrantPalletOnlyAcceptDate] = useState(messages["page.room.moneyRegister.percent"]);
    const [infrantNoMealPalletAcceptDate, setInfrantNoMealPalletAcceptDate] = useState(messages["page.room.moneyRegister.percent"]);
    const [priceDayFormat, setPriceDayFormat] = useState(null);
    const [isHoliday, setIsHoliday] = useState([]);
    const [dataKodomoByRoom, setDataKodomoByRoom] = useState({})
    const [activeMeal, setActiveMeal] = useState(true);

    const handlers = {
        juniorHighAcceptDate: setJuniorHighAcceptDate,
        juniorLowAcceptDate: setJuniorLowAcceptDate,
        infrantMealPalletAcceptDate: setInfrantMealPalletAcceptDate,
        infrantMealOnlyAcceptDate: setInfrantMealOnlyAcceptDate,
        infrantPalletOnlyAcceptDate: setInfrantPalletOnlyAcceptDate,
        infrantNoMealPalletAcceptDate: setInfrantNoMealPalletAcceptDate,
    };

    useEffect(() => {
        form.resetFields();
        const dataKodomo = dataOtaTab.kodomo[dataRoom.siteRoomId];
        if (!isEmpty(dataKodomo)) {
            setDataKodomoByRoom(dataKodomo)
            form.setFieldsValue(dataKodomo)

            const valuesToCheck = messages["page.room.moneyRegister.can"];
            if (dataKodomo.infrantMealPalletAcceptDate !== valuesToCheck
                && dataKodomo.infrantMealOnlyAcceptDate !== valuesToCheck
            ) {
                setActiveMeal(false)
            }

            setJuniorHighAcceptDate(dataKodomo.juniorHighAcceptDate);
            setJuniorLowAcceptDate(dataKodomo.juniorLowAcceptDate);
            setInfrantMealPalletAcceptDate(dataKodomo.infrantMealPalletAcceptDate);
            setInfrantMealOnlyAcceptDate(dataKodomo.infrantMealOnlyAcceptDate);
            setInfrantPalletOnlyAcceptDate(dataKodomo.infrantPalletOnlyAcceptDate);
            setInfrantNoMealPalletAcceptDate(dataKodomo.infrantNoMealPalletAcceptDate);
            setPriceDayFormat(dataKodomo.priceDayFormat);
            setIsHoliday(dataKodomo.publicHoliday);
        } else {
            form.setFieldsValue(initialValueFormKodomo)
        }
    }, [dataOtaTab.kodomo, isCancelInsertKodomo])

    const handleChangeRadio = (e) => {
        const { name, value } = e.target;

        if (handlers[name]) {
            handlers[name](value);
        }
    };

    const onChangeInfrantMealPallet = useCallback((e, form) => {
        const value = e.target.value;
        setActiveMeal(true)

        if (value == messages["page.room.moneyRegister.notAcceptable"]
            && infrantMealOnlyAcceptDate == messages["page.room.moneyRegister.notAcceptable"]
        ) {
            setActiveMeal(false)
            form.resetFields(["infrantBreakfastType"]);
            form.resetFields(["infrantDinnerType"]);
        }

        handleChangeRadio(e)
    }, [infrantMealOnlyAcceptDate]);

    const onChangeInfrantMealOnly = useCallback((e, form) => {
        const value = e.target.value;
        setActiveMeal(true)

        if (value == messages["page.room.moneyRegister.notAcceptable"]
            && infrantMealPalletAcceptDate == messages["page.room.moneyRegister.notAcceptable"]
        ) {
            setActiveMeal(false)
            form.resetFields(["infrantBreakfastType"]);
            form.resetFields(["infrantDinnerType"]);
        }

        handleChangeRadio(e)
    }, [infrantMealPalletAcceptDate]);


    const handleSubmitKodomo = (data) => {
        const arrayKodomo = dataOtaTab.kodomo;
        if (!isEmpty(selectedRowKeys) && selectedRowKeys.length > 1) {
            const listRoomCheckbox = listApiRoom.filter(item => selectedRowKeys.includes(item.siteRoomId));
            let checkRoomType = true;
            for (let apiRoom of listRoomCheckbox) {
                if (apiRoom.roomPriceTypeFormat !== dataRoom.roomPriceTypeFormat) {
                    checkRoomType = false;
                    Modal.error({
                        title: messages["page.apiPlan.modal.errorTitle"],
                        content: messages["page.apiPlanAddAndEdit.apiRoom.roomType.error"],
                    });
                    break;
                }
            }

            if (checkRoomType) {
                selectedRowKeys.map((val) => {
                    arrayKodomo[`${val}`] = data;
                    arrayKodomo[`${val}`]['priceTypeFormat'] = dataRoom.roomPriceTypeFormat
                })
                setFieldValue(`otaFieldContent.${tabKey}.kodomo`, arrayKodomo)
            }
            
        } else {
            arrayKodomo[`${dataRoom.siteRoomId}`] = data;
            arrayKodomo[`${dataRoom.siteRoomId}`]['priceTypeFormat'] = dataRoom.roomPriceTypeFormat
            setFieldValue(`otaFieldContent.${tabKey}.kodomo`, arrayKodomo)
        }
        closeMoneyRegisterPopup();
    }

    return (
        <MoneyRegisterStyle>
            <Form
                onFinish={handleSubmitKodomo}
                colon={false}
                form={form}
                initialValues={initialValueFormKodomo}
            >
                <Row justify="start">
                    <Col {...colFull}>
                        <Form.Item
                            label={messages["page.room.moneyRegister.roomRateFormat"]}
                            name="priceDayFormat"
                            className="required rate-format"
                            labelCol={{ lg: 6, xs: 24 }}
                            wrapperCol={{ lg: 18, xs: 24 }}
                        >
                            <Radio.Group
                                name="priceDayFormat"
                                onChange={(e) => (setPriceDayFormat(e.target.value))}
                            >
                                <Radio value={PRICE_DAY_FORMAT_WEEKDAY}>
                                    <span>{PRICE_DAY_FORMAT_WEEKDAY}</span>
                                </Radio>

                                <Radio value={PRICE_DAY_FORMAT_DAY_OFF_WEEK}>
                                    <span>{PRICE_DAY_FORMAT_DAY_OFF_WEEK}</span>
                                </Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>

                <table className="w-full table-point">
                    {priceDayFormat == PRICE_DAY_FORMAT_DAY_OFF_WEEK && (
                        <tbody>
                            <tr>
                                <td
                                    align="center"
                                    bgcolor="#EDEDED"
                                    className="kihon01"
                                    colSpan="3"
                                >
                                    <span>{messages["page.room.moneyRegister.window"]}</span>
                                </td>
                                <td
                                    align="center"
                                    bgcolor="#EDEDED"
                                    className="kihon01"
                                >
                                    <font color="#FF0000">
                                        <b>
                                            {messages["page.room.moneyRegister.sunday"]}
                                        </b>
                                    </font>
                                </td>
                                <td
                                    align="center"
                                    bgcolor="#EDEDED"
                                    className="kihon01"
                                >
                                    <b>
                                        {messages["page.room.moneyRegister.monday"]}
                                    </b>
                                </td>
                                <td
                                    align="center"
                                    bgcolor="#EDEDED"
                                    className="kihon01"
                                >
                                    <b>
                                        {messages["page.room.moneyRegister.tuesday"]}
                                    </b>
                                </td>
                                <td
                                    align="center"
                                    bgcolor="#EDEDED"
                                    className="kihon01"
                                >
                                    <b>
                                        {messages["page.room.moneyRegister.wednesday"]}
                                    </b>
                                </td>
                                <td
                                    align="center"
                                    bgcolor="#EDEDED"
                                    className="kihon01"
                                >
                                    <b>
                                        {messages["page.room.moneyRegister.thursday"]}
                                    </b>
                                </td>
                                <td
                                    align="center"
                                    bgcolor="#EDEDED"
                                    className="kihon01"
                                >
                                    <b>
                                        {messages["page.room.moneyRegister.friday"]}
                                    </b>
                                </td>
                                <td
                                    align="center"
                                    bgcolor="#EDEDED"
                                >
                                    <table border="0" style={{ border: "none" }}>
                                        <tbody>
                                            <tr>
                                                <td style={{ border: "none" }}>
                                                    <Form.Item
                                                        name="stockType"
                                                    >
                                                        <Radio.Group
                                                            name="stockType"
                                                        >
                                                            <Radio value={messages["page.room.moneyRegister.saturday"]}>
                                                                <span>{messages["page.room.moneyRegister.saturday"]}</span>
                                                            </Radio>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ border: "none" }}>
                                                    <Form.Item
                                                        name="stockType"
                                                    >
                                                        <Radio.Group
                                                            name="stockType"
                                                        >
                                                            <Radio value={messages["page.room.moneyRegister.dayBeforeHoliday"]}>
                                                                <span>{messages["page.room.moneyRegister.dayBeforeHoliday"]}</span>
                                                            </Radio>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                                <td
                                    align="center"
                                    bgcolor="#EDEDED"
                                    className="kihon01"
                                >
                                    <div className="flex-group-checkbox">
                                        <Form.Item
                                            name="publicHoliday"
                                        >
                                            <Checkbox.Group
                                                name="publicHoliday"
                                                onChange={(e) => setIsHoliday(e)}
                                            >
                                                <Checkbox
                                                    value={messages["page.room.moneyRegister.publicHoliday"]}
                                                />
                                            </Checkbox.Group>
                                        </Form.Item>
                                        <span className="ml-5 item-center">
                                            <font color="#FF9933">
                                                <b>
                                                    {messages["page.room.moneyRegister.publicHoliday"]}
                                                </b>
                                            </font>
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td
                                    align="center"
                                    className="kihon01"
                                    rowSpan="2"
                                    width="43"
                                >
                                    <span>{messages["page.room.moneyRegister.meal"]}</span>
                                </td>
                                <td
                                    align="center"
                                    className="kihon01"
                                    rowSpan="2"
                                >
                                    <span>{messages["page.room.moneyRegister.adult"]}</span>
                                    <br />
                                    <span>{messages["page.room.moneyRegister.elementarySchoolStudent"]}</span>
                                </td>
                                <td
                                    align="center"
                                    className="kihon02"
                                >
                                    <span>{messages["page.room.moneyRegister.meal.breakfast"]}</span>
                                </td>
                                {arrayOptionDay.map((option, key) => {
                                    return (
                                        <td
                                            align="center"
                                            className="kihon01"
                                            bgcolor={
                                                (option == 'sunday')
                                                    ? "#FFEBE8"
                                                    : (
                                                        ((option == 'saturday') ? "#E4F1FF" : '') || ((option == 'holiday') ? "#FFE8C7" : '')
                                                    )
                                            }
                                            key={key}
                                        >
                                            <CheckBoxOnly
                                                label={messages["page.room.moneyRegister.including"]}
                                                name={`${option}HasBreakfast`}
                                                dataKodomoByRoom={dataKodomoByRoom}
                                                form={form}
                                                isHoliday={isHoliday}
                                                option={option}
                                            />
                                        </td>
                                    )
                                })}
                            </tr>
                            <tr>
                                <td
                                    align="center"
                                    className="kihon02"
                                >
                                    <span>{messages["page.room.moneyRegister.meal.dinner"]}</span>
                                </td>
                                {arrayOptionDay.map((option, key) => {
                                    return (
                                        <td
                                            align="center"
                                            className="kihon01"
                                            bgcolor={
                                                (option == 'sunday')
                                                    ? "#FFEBE8"
                                                    : (
                                                        ((option == 'saturday') ? "#E4F1FF" : '') || ((option == 'holiday') ? "#FFE8C7" : '')
                                                    )
                                            }
                                            key={key}
                                        >
                                            <CheckBoxOnly
                                                label={messages["page.room.moneyRegister.including"]}
                                                name={`${option}HasDinner`}
                                                dataKodomoByRoom={dataKodomoByRoom}
                                                form={form}
                                                isHoliday={isHoliday}
                                                option={option}
                                            />
                                        </td>
                                    )
                                })}
                            </tr>
                            <tr>
                                <td
                                    align="center"
                                    className="kihon01"
                                    colSpan="3"
                                >
                                    <span>{messages["page.room.moneyRegister.point"]}</span>
                                </td>
                                {arrayOptionDay.map((option, key) => {
                                    return (
                                        <td
                                            align="center"
                                            className="kihon01"
                                            bgcolor={
                                                (option == 'sunday')
                                                    ? "#FFEBE8"
                                                    : (
                                                        ((option == 'saturday') ? "#E4F1FF" : '') || ((option == 'holiday') ? "#FFE8C7" : '')
                                                    )
                                            }
                                            key={key}
                                        >
                                            <InputPoint
                                                name={`${option}Point`}
                                                maxValueCondition={20}
                                                maxLengthCondition={2}
                                                isHoliday={isHoliday}
                                                option={option}
                                            />
                                        </td>
                                    )
                                })}
                            </tr>
                        </tbody>
                    )}

                    {priceDayFormat == PRICE_DAY_FORMAT_WEEKDAY && (
                        <table className="w-full table-point">
                            <tbody>
                                <tr>
                                    <td
                                        colSpan={3}
                                        align="center"
                                        bgcolor="#EDEDED"
                                    >
                                        {messages["page.room.moneyRegister.window"]}
                                    </td>
                                    <td
                                        bgcolor="#EDEDED"
                                        align="center"
                                    >
                                        {messages["page.room.moneyRegister.weekdays"]}
                                    </td>
                                </tr>

                                <tr>
                                    <td
                                        align="center"
                                        className="kihon01"
                                        width="48"
                                    >
                                        <span>{messages["page.room.moneyRegister.meal"]}</span>
                                    </td>
                                    <td
                                        align="center"
                                        className="kihon01"
                                    >
                                        <span>{messages["page.room.moneyRegister.adult"]}</span>
                                        <br />
                                        <span>{messages["page.room.moneyRegister.elementarySchoolStudent"]}</span>
                                    </td>
                                    <td
                                        align="center"
                                        className="kihon01"
                                    >
                                        <span>{messages["page.room.moneyRegister.mealCategory"]}</span>
                                    </td>
                                    <td
                                        align="center"
                                        className="kihon02 flex checkbox-meal"
                                    >
                                        <div className="flex-group-checkbox">
                                            <div className="mr-10">
                                                <CheckBoxOnly
                                                    label={messages["page.room.moneyRegister.breakfastIncluded"]}
                                                    name="normalHasBreakfast"
                                                    dataKodomoByRoom={dataKodomoByRoom}
                                                    form={form}
                                                />
                                            </div>
                                            <div>
                                                <CheckBoxOnly
                                                    label={messages["page.room.moneyRegister.dinnerIncluded"]}
                                                    name="normalHasDinner"
                                                    dataKodomoByRoom={dataKodomoByRoom}
                                                    form={form}
                                                />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        align="center"
                                        className="kihon01"
                                        colSpan="3"
                                        width="200"
                                    >
                                        <span>{messages["page.room.moneyRegister.point"]}</span>
                                    </td>
                                    <td
                                        align="center"
                                        className="kihon02"
                                    >
                                        <InputPoint
                                            name={`normalPoint`}
                                            maxValueCondition={20}
                                            maxLengthCondition={2}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    )}

                </table>
                <table
                    cellPadding="2"
                    cellSpacing="0"
                    className="kodomo-table w-full"
                >
                    <tbody>
                        <tr
                            align="center"
                            bgcolor="#EDEDED"
                            className="kihon_c"
                        >
                            <td colSpan="2">
                                <span>{messages["page.room.moneyRegister.child"]}</span>
                            </td>
                            <td width="45">
                                <span>{messages["page.room.moneyRegister.capacity"]}</span>
                            </td>
                            <td width="100">
                                <span>{messages["page.room.moneyRegister.meal"]}</span>
                            </td>
                            <td width="301">
                                <span>{messages["page.room.moneyRegister.setting"]}</span>
                            </td>
                            <td width="93">
                                <span className="text-inline">{messages["page.room.moneyRegister.priceCalculationNumber"]}</span>
                            </td>
                        </tr>
                        <tr>
                            <td
                                align="center"
                                bgcolor="#FFEFBB"
                                className="kihon_c"
                                rowSpan="2"
                                width="50"
                            >
                                <span className="text-inline">{messages["page.room.moneyRegister.elementarySchoolStudent"]}</span>
                            </td>
                            <td
                                className="kihon_c"
                                width="85"
                            >
                                <span>{messages["page.room.moneyRegister.upperGrade"]}</span>
                            </td>
                            <td
                                align="center"
                                className="kihon_c"
                                width="36"
                            >
                                <span>{messages["page.room.moneyRegister.contains"]}</span>
                            </td>
                            <td
                                align="center"
                                className="kihon_c"
                                rowSpan="2"
                                width="100"
                            >
                                <span>{messages["page.room.moneyRegister.sameAsAdult"]}</span>
                            </td>
                            <td nowrap="">
                                <table className="kihon_c" style={{ border: "none" }}>
                                    <tbody>
                                        <tr>
                                            <td
                                                rowSpan="2"
                                                width="50"
                                            >
                                                <InputSettingPrice
                                                    name="juniorHighRate"
                                                    valueInput={juniorHighAcceptDate}
                                                    maxValueCondition={juniorHighAcceptDate == messages["page.room.moneyRegister.percent"]}
                                                    form={form}
                                                />
                                            </td>
                                            <td width="54">
                                                <Form.Item
                                                    name="juniorHighAcceptDate"
                                                >
                                                    <Radio.Group
                                                        name="juniorHighAcceptDate"
                                                        onChange={(e) => (handleChangeRadio(e))}
                                                    >
                                                        <Radio value={messages["page.room.moneyRegister.percent"]}>
                                                            <span>{messages["page.room.moneyRegister.percent"]}</span>
                                                        </Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </td>
                                            <td
                                                nowrap=""
                                                rowSpan="2"
                                            >
                                                <Form.Item
                                                    name="juniorHighAcceptDate"
                                                >
                                                    <Radio.Group
                                                        name="juniorHighAcceptDate"
                                                        onChange={(e) => (handleChangeRadio(e))}
                                                    >
                                                        <Radio value={messages["page.room.moneyRegister.sameAsAdult"]}>
                                                            <span>{messages["page.room.moneyRegister.sameAsAdult"]}</span>
                                                        </Radio>
                                                        <Radio value={messages["page.room.moneyRegister.notAcceptable"]}>
                                                            <span>{messages["page.room.moneyRegister.notAcceptable"]}</span>
                                                        </Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Form.Item
                                                    name="juniorHighAcceptDate"
                                                >
                                                    <Radio.Group
                                                        name="juniorHighAcceptDate"
                                                        onChange={(e) => (handleChangeRadio(e))}
                                                    >
                                                        <Radio value={messages["page.room.moneyRegister.yen"]}>
                                                            <span>{messages["page.room.moneyRegister.yen"]}</span>
                                                        </Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td
                                className="kihon_c"
                                width="83"
                                align="center"
                            >
                                <div className="flex-group-checkbox">
                                    <CheckBoxOnly
                                        label={messages["page.room.moneyRegister.include"]}
                                        name="juniorHightMoney"
                                        dataKodomoByRoom={dataKodomoByRoom}
                                        form={form}
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td
                                className="kihon_c"
                                width="85"
                            >
                                <span>{messages["page.room.moneyRegister.lowerGrades"]}</span>
                            </td>
                            <td
                                align="center"
                                className="kihon_c"
                                width="36"
                            >
                                <span>{messages["page.room.moneyRegister.contains"]}</span>
                            </td>
                            <td nowrap="">
                                <table className="kihon_c" style={{ border: "none" }}>
                                    <tbody>
                                        <tr>
                                            <td
                                                rowSpan="2"
                                                width="50"
                                            >
                                                <InputSettingPrice
                                                    name="juniorLowRate"
                                                    valueInput={juniorLowAcceptDate}
                                                    maxValueCondition={juniorLowAcceptDate == messages["page.room.moneyRegister.percent"]}
                                                    form={form}
                                                />
                                            </td>
                                            <td width="54">
                                                <Form.Item
                                                    name="juniorLowAcceptDate"
                                                >
                                                    <Radio.Group
                                                        name="juniorLowAcceptDate"
                                                        onChange={(e) => (handleChangeRadio(e))}
                                                    >
                                                        <Radio value={messages["page.room.moneyRegister.percent"]}>
                                                            <span>{messages["page.room.moneyRegister.percent"]}</span>
                                                        </Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </td>
                                            <td
                                                nowrap=""
                                                rowSpan="2"
                                            >
                                                <Form.Item
                                                    name="juniorLowAcceptDate"
                                                >
                                                    <Radio.Group
                                                        name="juniorLowAcceptDate"
                                                        onChange={(e) => (handleChangeRadio(e))}
                                                    >
                                                        <Radio value={messages["page.room.moneyRegister.sameAsAdult"]}>
                                                            <span>{messages["page.room.moneyRegister.sameAsAdult"]}</span>
                                                        </Radio>
                                                        <Radio value={messages["page.room.moneyRegister.notAcceptable"]}>
                                                            <span>{messages["page.room.moneyRegister.notAcceptable"]}</span>
                                                        </Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Form.Item
                                                    name="juniorLowAcceptDate"
                                                >
                                                    <Radio.Group
                                                        name="juniorLowAcceptDate"
                                                        onChange={(e) => (handleChangeRadio(e))}
                                                    >
                                                        <Radio value={messages["page.room.moneyRegister.yen"]}>
                                                            <span>{messages["page.room.moneyRegister.yen"]}</span>
                                                        </Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td
                                className="kihon_c"
                                width="83"
                                align="center"
                            >
                                <div className="flex-group-checkbox">
                                    <CheckBoxOnly
                                        label={messages["page.room.moneyRegister.include"]}
                                        name="juniorLowMoney"
                                        dataKodomoByRoom={dataKodomoByRoom}
                                        form={form}
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td
                                align="center"
                                bgcolor="#FFEFBB"
                                className="kihon_c"
                                rowSpan="4"
                                width="50"
                            >
                                <span className="text-inline">{messages["page.room.moneyRegister.infant"]}</span>
                            </td>
                            <td
                                className="kihon_c"
                                width="85"
                            >
                                <span className="text-inline">{messages["page.room.moneyRegister.mealAndFutonIncluded"]}</span>
                            </td>
                            <td
                                align="center"
                                className="kihon_c"
                                width="36"
                            >
                                <span>{messages["page.room.moneyRegister.contains"]}</span>
                            </td>
                            <td
                                align="center"
                                className="kihon_c"
                                rowSpan="2"
                                width="100"
                            >
                                <div>
                                    <span>{messages["page.room.moneyRegister.meal.breakfast"]}：</span>
                                    <br />
                                    <Form.Item
                                        name="infrantBreakfastType"
                                        rules={[
                                            {
                                                validator(_, value) {
                                                    if (!value && activeMeal) {
                                                        return Promise.reject(
                                                            messages["form.error.mixed.required"].replace(
                                                                "${path}",
                                                                messages["page.room.moneyRegister.meal.breakfast"]
                                                            )
                                                        );
                                                    }

                                                    return Promise.resolve();
                                                },
                                            },
                                        ]}
                                    >
                                        <Radio.Group
                                            name="infrantBreakfastType"
                                            style={{ display: "flex" }}
                                        >
                                            <Radio value={messages["page.room.moneyRegister.including"]}>
                                                <span>{messages["page.room.moneyRegister.including"]}</span>
                                            </Radio>
                                            <Radio value={messages["page.room.moneyRegister.another"]}>
                                                <span>{messages["page.room.moneyRegister.another"]}</span>
                                            </Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </div>
                                <div>
                                    <span>{messages["page.room.moneyRegister.meal.dinner"]}：</span>
                                    <br />
                                    <Form.Item
                                        name="infrantDinnerType"
                                        rules={[
                                            {
                                                validator(_, value) {
                                                    if (!value && activeMeal) {
                                                        return Promise.reject(
                                                            messages["form.error.mixed.required"].replace(
                                                                "${path}",
                                                                messages["page.room.moneyRegister.meal.dinner"]
                                                            )
                                                        );
                                                    }

                                                    return Promise.resolve();
                                                },
                                            },
                                        ]}
                                    >
                                        <Radio.Group
                                            name="infrantDinnerType"
                                            style={{ display: "flex" }}
                                        >
                                            <Radio value={messages["page.room.moneyRegister.including"]}>
                                                <span>{messages["page.room.moneyRegister.including"]}</span>
                                            </Radio>
                                            <Radio value={messages["page.room.moneyRegister.another"]}>
                                                <span>{messages["page.room.moneyRegister.another"]}</span>
                                            </Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </div>
                            </td>
                            <td nowrap="">
                                <table className="kihon_c" style={{ border: "none" }}>
                                    <tbody>
                                        <tr>
                                            <td
                                                rowSpan="2"
                                                width="50"
                                            >
                                                <InputSettingPrice
                                                    name="infrantMealPalletRate"
                                                    valueInput={infrantMealPalletAcceptDate}
                                                    maxValueCondition={infrantMealPalletAcceptDate == messages["page.room.moneyRegister.percent"]}
                                                    form={form}
                                                />
                                            </td>
                                            <td width="54">
                                                <Form.Item
                                                    name="infrantMealPalletAcceptDate"
                                                >
                                                    <Radio.Group
                                                        name="infrantMealPalletAcceptDate"
                                                        onChange={(e) => onChangeInfrantMealPallet(e, form)}
                                                    >
                                                        <Radio value={messages["page.room.moneyRegister.percent"]}>
                                                            <span>{messages["page.room.moneyRegister.percent"]}</span>
                                                        </Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </td>
                                            <td
                                                nowrap=""
                                                rowSpan="2"
                                            >
                                                <Form.Item
                                                    name="infrantMealPalletAcceptDate"
                                                >
                                                    <Radio.Group
                                                        name="infrantMealPalletAcceptDate"
                                                        onChange={(e) => onChangeInfrantMealPallet(e, form)}
                                                    >
                                                        <Radio value={messages["page.room.moneyRegister.sameAsAdult"]}>
                                                            <span>{messages["page.room.moneyRegister.sameAsAdult"]}</span>
                                                        </Radio>
                                                        <Radio value={messages["page.room.moneyRegister.notAcceptable"]}>
                                                            <span>{messages["page.room.moneyRegister.notAcceptable"]}</span>
                                                        </Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Form.Item
                                                    name="infrantMealPalletAcceptDate"
                                                >
                                                    <Radio.Group
                                                        name="infrantMealPalletAcceptDate"
                                                        onChange={(e) => onChangeInfrantMealPallet(e, form)}
                                                    >
                                                        <Radio value={messages["page.room.moneyRegister.yen"]}>
                                                            <span>{messages["page.room.moneyRegister.yen"]}</span>
                                                        </Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td
                                className="kihon_c"
                                width="83"
                                align="center"
                            >
                                <div className="flex-group-checkbox">
                                    <CheckBoxOnly
                                        label={messages["page.room.moneyRegister.include"]}
                                        name="infrantMealPalletMoney"
                                        dataKodomoByRoom={dataKodomoByRoom}
                                        form={form}
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td
                                className="kihon_c"
                                width="85"
                            >
                                <span className="text-inline">{messages["page.room.moneyRegister.mealOnly"]}</span>
                            </td>
                            <td
                                align="center"
                                width="36"
                            >
                            </td>
                            <td nowrap="">
                                <table className="kihon_c" style={{ border: "none" }}>
                                    <tbody>
                                        <tr>
                                            <td
                                                rowSpan="2"
                                                width="50"
                                            >
                                                <InputSettingPrice
                                                    name="infrantMealOnlyRate"
                                                    valueInput={infrantMealOnlyAcceptDate}
                                                    maxValueCondition={infrantMealOnlyAcceptDate == messages["page.room.moneyRegister.percent"]}
                                                    form={form}
                                                />
                                            </td>
                                            <td width="54">
                                                <Form.Item
                                                    name="infrantMealOnlyAcceptDate"
                                                >
                                                    <Radio.Group
                                                        name="infrantMealOnlyAcceptDate"
                                                        onChange={(e) => onChangeInfrantMealOnly(e, form)}
                                                    >
                                                        <Radio value={messages["page.room.moneyRegister.percent"]}>
                                                            <span>{messages["page.room.moneyRegister.percent"]}</span>
                                                        </Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </td>
                                            <td
                                                nowrap=""
                                                rowSpan="2"
                                            >
                                                <Form.Item
                                                    name="infrantMealOnlyAcceptDate"
                                                >
                                                    <Radio.Group
                                                        name="infrantMealOnlyAcceptDate"
                                                        onChange={(e) => onChangeInfrantMealOnly(e, form)}
                                                    >
                                                        <Radio value={messages["page.room.moneyRegister.sameAsAdult"]}>
                                                            <span>{messages["page.room.moneyRegister.sameAsAdult"]}</span>
                                                        </Radio>
                                                        <Radio value={messages["page.room.moneyRegister.notAcceptable"]}>
                                                            <span>{messages["page.room.moneyRegister.notAcceptable"]}</span>
                                                        </Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Form.Item
                                                    name="infrantMealOnlyAcceptDate"
                                                >
                                                    <Radio.Group
                                                        name="infrantMealOnlyAcceptDate"
                                                        onChange={(e) => onChangeInfrantMealOnly(e, form)}
                                                    >
                                                        <Radio value={messages["page.room.moneyRegister.yen"]}>
                                                            <span>{messages["page.room.moneyRegister.yen"]}</span>
                                                        </Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td
                                className="kihon_c"
                                width="83"
                                align="center"
                            >
                                <div className="flex-group-checkbox">
                                    <CheckBoxOnly
                                        label={messages["page.room.moneyRegister.include"]}
                                        name="infrantMealOnlyMoney"
                                        dataKodomoByRoom={dataKodomoByRoom}
                                        form={form}
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td
                                className="kihon_c"
                                width="85"
                            >
                                <span className="text-inline">{messages["page.room.moneyRegister.futonOnly"]}</span>
                            </td>
                            <td
                                align="center"
                                className="kihon_c"
                                width="36"
                            >
                                <span className="text-inline">{messages["page.room.moneyRegister.contains"]}</span>
                            </td>
                            <td
                                align="center"
                                className="kihon_c"
                                rowSpan="2"
                                width="100"
                            >
                                <span>{messages["page.room.moneyRegister.none"]}</span>
                            </td>
                            <td nowrap="">
                                <table className="kihon_c" style={{ border: "none" }}>
                                    <tbody>
                                        <tr>
                                            <td
                                                rowSpan="2"
                                                width="50"
                                            >
                                                <InputSettingPrice
                                                    name="infrantPalletOnlyRate"
                                                    valueInput={infrantPalletOnlyAcceptDate}
                                                    maxValueCondition={infrantPalletOnlyAcceptDate == messages["page.room.moneyRegister.percent"]}
                                                    form={form}
                                                />
                                            </td>

                                            <td width="54">
                                                <Form.Item
                                                    name="infrantPalletOnlyAcceptDate"
                                                >
                                                    <Radio.Group
                                                        name="infrantPalletOnlyAcceptDate"
                                                        onChange={(e) => handleChangeRadio(e)}
                                                    >
                                                        <Radio value={messages["page.room.moneyRegister.percent"]}>
                                                            <span>{messages["page.room.moneyRegister.percent"]}</span>
                                                        </Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </td>
                                            <td
                                                nowrap=""
                                                rowSpan="2"
                                            >
                                                <Form.Item
                                                    name="infrantPalletOnlyAcceptDate"
                                                >
                                                    <Radio.Group
                                                        name="infrantPalletOnlyAcceptDate"
                                                        onChange={(e) => (handleChangeRadio(e))}
                                                    >
                                                        <Radio value={messages["page.room.moneyRegister.sameAsAdult"]}>
                                                            <span>{messages["page.room.moneyRegister.sameAsAdult"]}</span>
                                                        </Radio>
                                                        <Radio value={messages["page.room.moneyRegister.notAcceptable"]}>
                                                            <span>{messages["page.room.moneyRegister.notAcceptable"]}</span>
                                                        </Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Form.Item
                                                    name="infrantPalletOnlyAcceptDate"
                                                >
                                                    <Radio.Group
                                                        name="infrantPalletOnlyAcceptDate"
                                                        onChange={(e) => (handleChangeRadio(e))}
                                                    >
                                                        <Radio value={messages["page.room.moneyRegister.yen"]}>
                                                            <span>{messages["page.room.moneyRegister.yen"]}</span>
                                                        </Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td
                                className="kihon_c"
                                width="83"
                                align="center"
                            >
                                <div className="flex-group-checkbox">
                                    <CheckBoxOnly
                                        label={messages["page.room.moneyRegister.include"]}
                                        name="infrantPalletOnlyMoney"
                                        dataKodomoByRoom={dataKodomoByRoom}
                                        form={form}
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td
                                className="kihon_c"
                                width="85"
                            >
                                <span className="text-inline">{messages["page.room.moneyRegister.noMealsOrBeddingRequired"]}</span>
                            </td>
                            <td
                                align="center"
                                width="36"
                            >
                            </td>
                            <td nowrap="">
                                <table className="kihon_c" style={{ border: "none" }}>
                                    <tbody>
                                        <tr>
                                            <td
                                                rowSpan="2"
                                                width="50"
                                            >
                                                <InputSettingPrice
                                                    name="infrantNoMealPalletRate"
                                                    valueInput={infrantNoMealPalletAcceptDate}
                                                    maxValueCondition={infrantNoMealPalletAcceptDate == messages["page.room.moneyRegister.percent"]}
                                                    form={form}
                                                />
                                            </td>
                                            <td width="54">
                                                <Form.Item
                                                    name="infrantNoMealPalletAcceptDate"
                                                >
                                                    <Radio.Group
                                                        name="infrantNoMealPalletAcceptDate"
                                                        onChange={(e) => (handleChangeRadio(e))}
                                                    >
                                                        <Radio value={messages["page.room.moneyRegister.percent"]}>
                                                            <span>{messages["page.room.moneyRegister.percent"]}</span>
                                                        </Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </td>
                                            <td
                                                nowrap=""
                                                rowSpan="2"
                                            >
                                                <Form.Item
                                                    name="infrantNoMealPalletAcceptDate"
                                                >
                                                    <Radio.Group
                                                        name="infrantNoMealPalletAcceptDate"
                                                        onChange={(e) => (handleChangeRadio(e))}
                                                    >
                                                        <Radio value={messages["page.room.moneyRegister.sameAsAdult"]}>
                                                            <span>{messages["page.room.moneyRegister.sameAsAdult"]}</span>
                                                        </Radio>
                                                        <Radio value={messages["page.room.moneyRegister.notAcceptable"]}>
                                                            <span>{messages["page.room.moneyRegister.notAcceptable"]}</span>
                                                        </Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Form.Item
                                                    name="infrantNoMealPalletAcceptDate"
                                                >
                                                    <Radio.Group
                                                        name="infrantNoMealPalletAcceptDate"
                                                        onChange={(e) => (handleChangeRadio(e))}
                                                    >
                                                        <Radio value={messages["page.room.moneyRegister.yen"]}>
                                                            <span>{messages["page.room.moneyRegister.yen"]}</span>
                                                        </Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td
                                className="kihon_c"
                                width="83"
                                align="center"
                            >
                                <div className="flex-group-checkbox">
                                    <CheckBoxOnly
                                        label={messages["page.room.moneyRegister.include"]}
                                        name="infrantNoMealPalletMoney"
                                        dataKodomoByRoom={dataKodomoByRoom}
                                        form={form}
                                    />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <Row justify="center" style={{ marginTop: "20px" }}>
                    <Button onClick={closeMoneyRegisterPopup} style={{ marginRight: "10px" }}>
                        {messages["page.Account.buttonCancel"]}
                    </Button>
                    <Button type="primary" htmlType="submit" onClick={() => setEventKodomoSubmit(true)}>
                        {messages["popup.selectStore.decision"]}
                    </Button>
                </Row>
            </Form>
        </MoneyRegisterStyle>
    );
};

const kodomoModePersonInitialValue = {
    priceDayFormat: null,

    normalHasBreakfast: [],
    normalHasDinner: [],
    normalPoint: 1,

    stockType: null,
    publicHoliday: [],
    sundayPoint: 1,
    sundayHasBreakfast: [],
    sundayHasDinner: [],
    mondayPoint: 1,
    mondayHasBreakfast: [],
    mondayHasDinner: [],
    tuesdayPoint: 1,
    tuesdayHasBreakfast: [],
    tuesdayHasDinner: [],
    wednesdayPoint: 1,
    wednesdayHasBreakfast: [],
    wednesdayHasDinner: [],
    thursdayPoint: 1,
    thursdayHasBreakfast: [],
    thursdayHasDinner: [],
    fridayPoint: 1,
    fridayHasBreakfast: [],
    fridayHasDinner: [],
    saturdayPoint: 1,
    saturdayHasBreakfast: [],
    saturdayHasDinner: [],
    holidayPoint: 1,
    holidayHasBreakfast: [],
    holidayHasDinner: [],
    
    juniorHighRate: null,
    juniorLowRate: null,
    infrantMealPalletRate: null,
    infrantMealOnlyRate: null,
    infrantPalletOnlyRate: null,
    infrantNoMealPalletRate: null,
    juniorHighAcceptDate: "％",
    juniorLowAcceptDate: "％",
    infrantMealPalletAcceptDate: "％",
    infrantMealOnlyAcceptDate: "％",
    infrantPalletOnlyAcceptDate: "％",
    infrantNoMealPalletAcceptDate: "％",
    juniorHightMoney: [],
    juniorLowMoney: [],
    infrantMealPalletMoney: [],
    infrantMealOnlyMoney: [],
    infrantPalletOnlyMoney: [],
    infrantNoMealPalletMoney: [],
    infrantBreakfastType: null,
    infrantDinnerType: null
}

export default TableSettingPriceByPerson;
