import React from "react";
import { Form, Input, InputNumber } from "formik-antd";
import { useIntl } from "react-intl";
import { isEmpty } from "lodash";
import { ValidateField } from "@iso/validates/ValidateField";

const TableRowFeePersonAndDayOfWeek = ({
    bgColor,
    bgChildrenColor,
    label,
    type,
    numberUniquePeople,
    tabKey,
    isHoliday,
    disabledMode,
    apiRoomId
}) => {
    const { messages } = useIntl();
    const defaultRowspan = 3;
    const arrayOptionDayPrice = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Holiday']

    return (
        <>
            <tr>
                {type === 'rakuten' && (
                    <td
                        align="center"
                        className="kihon01"
                        nowrap=""
                        rowSpan={defaultRowspan}
                        width="48"
                    >
                        <span>{messages["page.room.moneyRegister.fee"]}</span>
                    </td>
                )}
                <td
                    align="center"
                    bgcolor={bgColor}
                    className="kihon01"
                    nowrap=""
                    width="80"
                >
                    <span>{label}</span>
                </td>
                <td
                    align="center"
                    bgcolor={bgChildrenColor}
                    className="kihon01"
                    nowrap=""
                >
                    <span>{numberUniquePeople}{messages["page.room.moneyRegister.more"]}</span>
                </td>
                {arrayOptionDayPrice.map((option, index) => {
                    return (
                        <td
                            align="center"
                            bgcolor={
                                (option == 'Sunday')
                                    ? "#FFEBE8"
                                    : (
                                        ((option == 'Saturday') ? "#E4F1FF" : '') || ((option == 'Holiday') ? "#FFE8C7" : '')
                                    )
                            }
                        >
                            <Form.Item
                                name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['${type + option}'][${numberUniquePeople}]`}
                                validate={apiRoomId ? false : (
                                    (value) => ValidateField(
                                        value,
                                        messages["page.room.moneyRegister.fee"],
                                        {
                                            required: messages["form.error.mixed.required"],
                                            integer: messages["form.error.number.integer"],
            
                                        },
                                        {
                                            required : true,
                                            integer : true,
                                        }
                                    )
                                )}
                            >
                                <InputNumber
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['${type + option}'][${numberUniquePeople}]`}
                                    maxLength={10}
                                    min={0}
                                    className="w-100"
                                    disabled={(option == 'Holiday' ? isEmpty(isHoliday) : false) || disabledMode}
                                />
                            </Form.Item>
                        </td>
                    )
                })}
            </tr>
        </>
    );
};

export default TableRowFeePersonAndDayOfWeek;
