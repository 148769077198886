import React from "react";
import { Checkbox } from "formik-antd";
import { isEmpty } from "lodash";

const CheckBoxOnly = ({ label, name, holidayCondition, isHoliday, setFieldValue, disabledMode }) => {
    return (
        <Checkbox.Group
            onChange={(value) => setFieldValue(name, value)}
            name={name}
            disabled={(holidayCondition ? isEmpty(isHoliday) : false) || disabledMode}
        >
            <Checkbox
                value={label}
            />
            <span className="ml-5">{label}</span>
        </Checkbox.Group>
    );
};

export default CheckBoxOnly;
