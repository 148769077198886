import styled from "styled-components";

export const TabStyles = styled.div`
  .custom-label .ant-form-item-label label{
    padding-bottom: 35px;
  }

  .label-room-condition-2 .ant-form-item-label label {
    padding-bottom: 0px;
  }

  .border-required-none .ant-select:not(.ant-select-borderless) .ant-select-selector {
    border-color: #d9d9d9 !important;
  }
  
  .border-required-none .ant-input {
    border-color: #d9d9d9 !important;
  }

  .ant-form-item {
    margin-bottom: 0px;
  }

  .text-note-row {
    color: #f22929;
    font-size: 14px;
  }

  .pb-15 {
    padding-bottom: 15px
  }
  
  .mb-0 {
    margin-bottom: 0px;
  }

  .mb-5 {
    margin-bottom: 5px;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .mb-25 {
    margin-bottom: 25px;
  }

  .my-5 {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .my-10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .mx-10 {
    margin-left: 10px;
    margin-right: 10px;
  }

  .my-20 {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .my-30 {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  
  .mx-20 {
    margin-left: 20px;
    margin-right: 20px;
  }

  .mt-7 {
    margin-top: 7px;
  }

  .mt-10 {
    margin-top: 10px;
  }
  
  .mt-100 {
    margin-top: 100px;
  }
  
  .ml-5 {
    margin-left: 5px;
  }

  .mr-10 {
    margin-right: 10px;
  }
  
  .m-2 {
    margin-left: 2em;
    margin-right: 2em;
  }

  .pb-25px {
    padding-bottom: 25px;
  }

  .button-center {
    display: flex;
    justify-content: center;
  }

  .content-end {
    display: flex;
    justify-content: end;
  }

  .content-between {
    display: flex;
    justify-content: space-between;
  }

  .item-center {
    display: flex;
    margin-top: 9px;
  }

  .select_room_type_option {
    width: 200px;
  }
  
  .d-flex {
    display: flex;
  }

  .d-grid {
    display: grid;
  }

  .flex-wrap {
    flex-wrap: wrap;
  }
  
  .align-items-center {
    align-items: center;
  }

  .align-item-baseline {
    align-items: baseline;
  }
  
  .mr-1 {
    margin-right: 1rem;
  }

  .ml-1 {
    margin-left: 1rem;
  }
  
  .mr-2 {
    margin-right: 2rem;
  }
  
  .styleInputLog {
    background: #f5f5f5;
    cursor: pointer
  }

  .days-row {
    border: 2px dashed blue;
    padding: 10px;
  }

  .day-col {
    text-align: center;
  }

  .day-header {
    background-color: lightgray;
    padding: 5px;
    border-bottom: 1px solid gray;
    margin-bottom: 5px;
  }

  .day-input {
    width: 100%;
    height: 30px;
    text-align: center;
  }

  .day-0 { background-color: lightpink; }
  .day-1, .day-2, .day-3, .day-4, .day-5 { background-color: lightgray; }
  .day-6 { background-color: lightblue; }
  
  .label-custom {
    position: relative;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
    height: 32px;
    color: rgba(0, 0, 0, 0.85);
  }

  .label-value-custom {
    position: relative;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
    height: 32px;
    color: rgba(0, 0, 0, 0.65);
  }


  .label-grid-custom {
    color: rgba(0, 0, 0, 0.65);
  }

  .roomName-custom {
    min-width: 50px;
    margin-left: 15px;
    display: inline-flex;
    align-items: center;
  }

  .ant-form-item-no-colon {
    white-space: normal;
  }
    
  @media only screen and (min-width: 992px) {
    .button {
      width: 70px;
    }
    .custom-max-width {
      max-width: 168px;
    }
  }

  @media only screen and (min-width: 1200px) {
    .button {
      width: 80px;
    }
  }

  @media only screen and (min-width: 1600px) {
    .button {
      width: 120px;
    }
  }
  .button {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 25px;
  }
  @media only screen and (min-width: 992px) {
    .button {
      width: 100px;
    }
  }

  @media only screen and (min-width: 1200px) {
    .button {
      width: 100px;
    }
  }

  @media only screen and (min-width: 1600px) {
    .button {
      width: 120px;
    }
  }
`;