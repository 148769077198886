import React, {memo, useCallback, useEffect, useState} from "react";
import {Button, Checkbox, Col, Empty, Modal, Row} from "antd";
import {Form, Input} from "formik-antd";
import {CloseOutlined} from '@ant-design/icons';
import {useIntl} from "react-intl";
import {UploadFileImageOtaStyle} from "./UploadFileImageOta.style";
import {isEmpty} from "lodash";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import apiImageActions from "@iso/redux/apiImage/actions";
import { ValidateField } from "@iso/validates/ValidateField";
import {IMAGE_DEFAULT, OTA_PLAN_JALAN} from "@iso/constants/api.constant";

const UploadFileImageOta = ({
    storeId,
    otaId,
    values,
    setFieldValue,
    tabKey,
    isCloneData
}) => {
    const { messages } = useIntl();
    const dispatch = useDispatch();
    const {
        ApiImage: { imageStoreAndOta }
    } = useSelector((state) => state);
    const [previewOpenImageStorage, setPreviewOpenImageStorage] = useState(false);
    const [previewImage, setPreviewImage] = useState([]);
    const [arrayIdImageCreate, setArrayIdImageCreate] = useState([]);
    const [arrayIdImageDelete, setArrayIdImageDelete] = useState([]);
    const [arrayIdImageUrlUpdate, setArrayIdImageUrlUpdate] = useState([]);
    const [urlImage, setUrlImage] = useState([{
        apiPlanOtaImageId: '',
        url: ''
    }]);
    const [imageStorageCurrent, setImageStorageCurrent] = useState([]);
    const [previewTitle, setPreviewTitle] = useState('');
    const [valueCheckboxImageStorage, setValueCheckboxImageStorage] = useState([])
    const [validImages, setValidImages] = useState({});

    useEffect(() => {
        if (previewOpenImageStorage) {
            dispatch(apiImageActions.getApiImageStoreAndOta({
                storeId: storeId,
                otaId: otaId
            }));
        }
    }, [dispatch, previewOpenImageStorage])

    const addElementImageUrl = () => {
        let arrayUrlImage = [...urlImage, {
            apiPlanOtaImageId: '',
            url: ''
        }];
        setFieldValue(`otaFieldContent[${tabKey}]['imageUrl']`, arrayUrlImage)
        setUrlImage(arrayUrlImage);
    }

    useEffect(() => {
        validateImages();
    }, [urlImage]);

    const validateImages = async () => {
        const validationResults = await Promise.all(
            urlImage.map(async (image, index) => {
                const isValid = await checkImageValid(image.url);
                return { index, isValid };
            })
        );

        const validImagesMap = validationResults.reduce((acc, { index, isValid }) => {
            acc[index] = isValid;
            return acc;
        }, {});

        setValidImages(validImagesMap);
    };

    const checkImageValid = (url) => {
        return new Promise((resolve) => {
            const img = new Image();
            img.onload = () => resolve(true);
            img.onerror = () => resolve(false);
            img.src = url;
        });
    };

    const deleteElementImageUrl = (id, index) => {
        let arrayUrlImage = [...urlImage];
        arrayUrlImage.splice(index, 1);
        setUrlImage(arrayUrlImage);
        setFieldValue(`otaFieldContent[${tabKey}]['imageUrl']`, arrayUrlImage)

        if (id) {
            setArrayIdImageDelete([...arrayIdImageDelete, id]);
            setFieldValue(`otaFieldContent.${tabKey}.imageOtaToDelete`, [...arrayIdImageDelete, id])
        }
    }

    const onchangeLinkImage = (id) => {
        let idUrlUpdate = [...arrayIdImageUrlUpdate];

        if (!idUrlUpdate.includes(id)) {
            let arrayUrlImageUpdate = [...idUrlUpdate, id];
            setArrayIdImageUrlUpdate(arrayUrlImageUpdate);
            setFieldValue(`otaFieldContent.${tabKey}.imageOtaToUpdateUrl`, arrayUrlImageUpdate)
        }
    }

    const removeImage = (idImage) => {
        let arrayPreviewImage = [...previewImage];
        let arrayValueCheckedImage = [...valueCheckboxImageStorage];
        const index = arrayValueCheckedImage.indexOf(idImage);
        if (index > -1) {
            arrayValueCheckedImage.splice(index, 1);
        }

        setValueCheckboxImageStorage(arrayValueCheckedImage);

        const indexPreviewImage = arrayPreviewImage.map(imagePreview => {
            return imagePreview.imageOtaId;
        }).indexOf(idImage);

        arrayPreviewImage.splice(indexPreviewImage, 1)
        setPreviewImage(arrayPreviewImage)

        if (values.apiPlanId && !isEmpty(values) && !isEmpty(values.otaFieldContent[tabKey])) {

            let checkImageExiyInArray = imageStorageCurrent.find(image => image.imageOtaId == idImage);
            if (!checkImageExiyInArray) {
                let arrayValueCheckedCreate = [...arrayIdImageCreate];
                const index = arrayValueCheckedCreate.indexOf(idImage);
                if (index > -1) {
                    arrayValueCheckedCreate.splice(index, 1);
                }

                setArrayIdImageCreate(arrayValueCheckedCreate);
                setFieldValue(`otaFieldContent.${tabKey}.imageOtaToAdd`, arrayValueCheckedCreate)
            } else {
                const listDelete = [...arrayIdImageDelete, checkImageExiyInArray.apiPlanOtaImageId];
                setArrayIdImageDelete(listDelete);
                setFieldValue(`otaFieldContent.${tabKey}.imageOtaToDelete`, listDelete)
            }
        } else {
            let arrayValueCheckedCreate = [...arrayIdImageCreate];
            const index = arrayValueCheckedCreate.indexOf(idImage);
            if (index > -1) {
                arrayValueCheckedCreate.splice(index, 1);
            }

            setArrayIdImageCreate(arrayValueCheckedCreate);
            setFieldValue(`otaFieldContent.${tabKey}.imageOtaToAdd`, arrayValueCheckedCreate)
        }
    }

    useEffect(() => {

        if (values.otaFieldContent
            && values.otaFieldContent[tabKey]
            && typeof values.otaFieldContent[tabKey] === "object"
            && values.otaFieldContent[tabKey] !== null
            && values.otaFieldContent[tabKey] !== undefined) {

            let arrayImageStorage = values.otaFieldContent[tabKey].imageStorage;
            let arrayImageUrl = values.otaFieldContent[tabKey].imageUrl;
            if (arrayImageStorage != null && arrayImageStorage.length > 0) {
                delete values.otaFieldContent[tabKey].imageStorage;
                setPreviewImage(arrayImageStorage);
                setImageStorageCurrent(arrayImageStorage);

                let arrayIdImage = []
                arrayImageStorage.map((image, index) => {
                    arrayIdImage.push(image.imageOtaId)
                })

                if (isCloneData) {
                    setArrayIdImageCreate(arrayIdImage)
                    setFieldValue(`otaFieldContent.${tabKey}.imageOtaToAdd`, arrayIdImage)
                }
                setValueCheckboxImageStorage(arrayIdImage)
            }

            if (arrayImageUrl != null && arrayImageUrl.length > 0) {
                setUrlImage(arrayImageUrl)
            }

        }
    }, [values.otaFieldContent[tabKey]]);

    const onChangeCheckboxImageStorage = useCallback((e) => {
        let imageOtaIdChecked = e.target.value
        if (e.target.checked === true) {
            const listCheckboxImageStorage = [...valueCheckboxImageStorage, imageOtaIdChecked];
            setValueCheckboxImageStorage(listCheckboxImageStorage)
            let findDetailImage = imageStoreAndOta.find((image) => image.apiImageOtaId === imageOtaIdChecked);
            if (findDetailImage) {
                let newImage = {
                    'apiPlanOtaImageId': null,
                    'imageOtaId': findDetailImage.apiImageOtaId,
                    'name': findDetailImage.name,
                    'planOtaId': null,
                    's3Url': findDetailImage.s3Url,
                    'type': 1,
                    'url': null
                }

                setPreviewImage([...previewImage, newImage])
            }

            if (values.apiPlanId && !isEmpty(values) && !isEmpty(values.otaFieldContent[tabKey])) {
                let checkImageExiyInArray = imageStorageCurrent.find(image => image.imageOtaId == imageOtaIdChecked);
                if (!checkImageExiyInArray) {
                    setArrayIdImageCreate([...arrayIdImageCreate, imageOtaIdChecked]);
                    setFieldValue(`otaFieldContent.${tabKey}.imageOtaToAdd`, [...arrayIdImageCreate, imageOtaIdChecked])
                } else {
                    let arrayValueCheckedDelete = [...arrayIdImageDelete];
                    const index = arrayValueCheckedDelete.indexOf(checkImageExiyInArray.apiPlanOtaImageId);
                    if (index > -1) {
                        arrayValueCheckedDelete.splice(index, 1);
                    }

                    setArrayIdImageDelete(arrayValueCheckedDelete);
                    setFieldValue(`otaFieldContent.${tabKey}.imageOtaToDelete`, arrayValueCheckedDelete)
                }
            } else {
                setArrayIdImageCreate([...arrayIdImageCreate, imageOtaIdChecked]);
                setFieldValue(`otaFieldContent.${tabKey}.imageOtaToAdd`, [...arrayIdImageCreate, imageOtaIdChecked])
            }
        } else {
            let arrayPreviewImage = [...previewImage];
            let arrayValueCheckedImage = [...valueCheckboxImageStorage];
            const index = arrayValueCheckedImage.indexOf(imageOtaIdChecked);
            if (index > -1) {
                arrayValueCheckedImage.splice(index, 1);
            }

            setValueCheckboxImageStorage(arrayValueCheckedImage);

            const indexPreviewImage = arrayPreviewImage.map(imagePreview => {
                return imagePreview.imageOtaId;
            }).indexOf(imageOtaIdChecked);

            arrayPreviewImage.splice(indexPreviewImage, 1)
            setPreviewImage(arrayPreviewImage)

            if (values.apiPlanId && !isEmpty(values) && !isEmpty(values.otaFieldContent[tabKey])) {
                let checkImageExiyInArray = imageStorageCurrent.find(image => image.imageOtaId == imageOtaIdChecked);
                if (!checkImageExiyInArray) {
                    let arrayValueCheckedCreate = [...arrayIdImageCreate];
                    const index = arrayValueCheckedCreate.indexOf(imageOtaIdChecked);
                    if (index > -1) {
                        arrayValueCheckedCreate.splice(index, 1);
                    }

                    setArrayIdImageCreate(arrayValueCheckedCreate);
                    setFieldValue(`otaFieldContent.${tabKey}.imageOtaToAdd`, arrayValueCheckedCreate)
                } else {
                    setArrayIdImageDelete([...arrayIdImageDelete, checkImageExiyInArray.apiPlanOtaImageId]);
                    setFieldValue(`otaFieldContent.${tabKey}.imageOtaToDelete`, [...arrayIdImageDelete, checkImageExiyInArray.apiPlanOtaImageId])
                }
            } else {
                let arrayValueCheckedCreate = [...arrayIdImageCreate];
                const index = arrayValueCheckedCreate.indexOf(imageOtaIdChecked);
                if (index > -1) {
                    arrayValueCheckedCreate.splice(index, 1);
                }

                setArrayIdImageCreate(arrayValueCheckedCreate);
                setFieldValue(`otaFieldContent.${tabKey}.imageOtaToAdd`, arrayValueCheckedCreate)
            }
        }
    })

    const handleInputBlur  = (event) => {
        const trimmedValue = trimSpaces(event.target.value);
        setFieldValue(event.target.name, trimmedValue)
    };

    const trimSpaces = (inputString) => {
        if (typeof inputString !== 'string') {
            return '';
        }
        let elements = inputString.split(',');
        let trimmedElements = elements.map(element => element.trim());
        return trimmedElements.join(',');
    }

    const handleCancelModalImageStorage = () => setPreviewOpenImageStorage(false);
    return (<UploadFileImageOtaStyle style={{width: '100%'}}>
            <Row>
                <Col xs={24} lg={{span: 24}} style={{marginBottom: '20px'}}>
                    <span className={"custom-title"}>{messages["page.apiPlanAddAndEdit.image"]}</span>
                </Col>
            </Row>
            <div style={{marginLeft: '30px'}}>
                <Row>
                    <Col xs={24} lg={{span: 24}} className="mb-10">
                        <span className={"custom-title"}>{messages["page.apiPlanAddAndEdit.uploadOrStore"]}</span>
                    </Col>
                </Row>
                <Row>
                    {previewImage.length > 0 &&
                        previewImage.map((image, key) => {
                            return (<Col key={key} xs={24} lg={{span: 3}}>
                                <div className="container"
                                     style={{padding: '10px', position: 'relative'}}>
                                    <img
                                        className="image"
                                        alt={image.name}
                                        style={{
                                            width: '100%', height: '150px', objectFit: 'cover'
                                        }}
                                        src={image.s3Url}
                                    />

                                    <div className="middle">
                                        <button
                                            className="icon-remove"
                                            onClick={() => removeImage(image.imageOtaId)}
                                            type="button"
                                        >
                                            <CloseOutlined/>
                                        </button>
                                    </div>
                                </div>
                            </Col>)
                        })}
                </Row>

                <Row>
                    <Col xs={24} lg={{span: 10}} style={{marginBottom: '10px'}}>
                        <Form.Item
                            name={`otaFieldContent[${tabKey}]['image']`}
                        >
                            <Button type="primary"
                                    htmlType="button"
                                    onClick={() => setPreviewOpenImageStorage(true)}>
                                {messages['page.apiPlanAddAndEdit.chooseImage']}
                            </Button>
                            <Modal visible={previewOpenImageStorage} title={previewTitle} footer={null}
                                   onCancel={handleCancelModalImageStorage}>
                                <Checkbox.Group
                                    name={`otaFieldContent[${tabKey}]['imageStorage]`}
                                    value={valueCheckboxImageStorage}
                                    style={{display: 'flex', justifyContent: 'center'}}
                                >
                                    {imageStoreAndOta.length <= 0 && (
                                        <Row  style={{ padding: '10px' }}>
                                            <Empty
                                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                                description={
                                                    <span>{messages["antTable.emptyData"]}</span>
                                                }
                                            >
                                            </Empty>
                                        </Row>
                                    )}

                                    <Row  style={{ padding: '10px',maxHeight: '80vh', overflowY: 'auto' ,marginTop: '20px' }}>
                                        {imageStoreAndOta.length > 0 && imageStoreAndOta.map((image, key) => {
                                            return (<Col xs={24} lg={{span: 12}} key={key}>
                                                <div  style={{ padding: '10px' }}>
                                                    <Checkbox
                                                        value={image.apiImageOtaId}
                                                        style={{
                                                            position: 'absolute', left: '20px', top: '15px'
                                                        }}
                                                        onChange={(e) => {
                                                            onChangeCheckboxImageStorage(e)
                                                        }}
                                                    />
                                                    <div>
                                                        <img
                                                            alt={image.name}
                                                            style={{
                                                                width: '100%',
                                                                height: '200px',
                                                                objectFit: 'cover'
                                                            }}
                                                            src={image.s3Url}
                                                        />
                                                        <div>
                                                            {image.name}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>)
                                        })}
                                    </Row>
                                </Checkbox.Group>
                            </Modal>
                        </Form.Item>
                    </Col>
                </Row>

                {(otaId != OTA_PLAN_JALAN) && (
                    <div>
                        <Row>
                            <Col className="mb-10">
                                <span className={"custom-title"}>{messages["page.apiPlanAddAndEdit.Rakuten.urlImage"]}</span>
                            </Col>
                        </Row>
                        <Row style={styleComponentUrl}>
                            {urlImage.length > 0 && urlImage.map((image, index) => (
                                <Col xs={24} lg={{span: 24}} key={index}>
                                    <Row key={index} className="mb-10">
                                        <Col lg={18} xs={16}>
                                            <Form.Item
                                                name={`otaFieldContent[${tabKey}]['imageUrl'][${index}]['url']`}
                                                validate={(value) => ValidateField(
                                                    value,
                                                    messages["page.apiPlanAddAndEdit.Rakuten.urlImage"],
                                                    {
                                                        required: messages["form.error.mixed.required"],
                                                        valueInvalid: messages["page.room.valueInvalid"]
                                                    },
                                                    {
                                                        space: true,
                                                        imageUrl: true
                                                    }
                                                )}
                                            >
                                                <Input
                                                    name={`otaFieldContent[${tabKey}]['imageUrl'][${index}]['url']`}
                                                    onChange={e => onchangeLinkImage(image.apiPlanOtaImageId)}
                                                    onBlur={handleInputBlur}
                                                />
                                                <img
                                                    alt={image.url}
                                                    style={{
                                                        borderRadius: '10px',
                                                        marginTop: '10px',
                                                        width: '100px',
                                                        height: '100px',
                                                        objectFit: 'cover'
                                                    }}
                                                    src={validImages[index] ? image.url : IMAGE_DEFAULT}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col lg={6} xs={8}>
                                            <Button
                                                type="dashed"
                                                disabled={urlImage.length <= 1}
                                                style={{marginRight: 4, marginLeft: 4}}
                                                onClick={() => deleteElementImageUrl(image.apiPlanOtaImageId, index)}
                                            >
                                                -
                                            </Button>
                                            <Button
                                                type="dashed"
                                                onClick={() => addElementImageUrl()}
                                            >
                                                +
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            ))}
                        </Row>
                    </div>
                )}
            </div>
        </UploadFileImageOtaStyle>
    )
}

UploadFileImageOta.propTypes = {
    storeId: PropTypes.any,
    otaId: PropTypes.any,
    values: PropTypes.object,
    previewMode: PropTypes.bool,
    setFieldValue: PropTypes.any,
    tabKey: PropTypes.any,
    isCloneData: PropTypes.any,
};

const styleComponentUrl = {
    padding: '10px',
    maxHeight: '465px',
    maxWidth: '500px',
    overflowY: 'auto',
    marginTop: '20px'
}
export default memo(UploadFileImageOta);
