const actions = {
  GET_TASKS_REQUEST: "TASKS/GET_TASKS_REQUEST",
  GET_TASKS_SUCCESS: "TASKS/GET_TASKS_SUCCESS",
  GET_TASKS_ERROR: "TASKS/GET_TASKS_ERROR",

  ADD_DATA_TASKS_REQUEST: "ADD_DATA_TASKS_REQUEST",
  ADD_DATA_TASKS_SUCCESS: "ADD_DATA_TASKS_SUCCESS",
  ADD_DATA_TASKS_ERROR: "ADD_DATA_TASKS_ERROR",

  GET_TASKS_METADATA_REQUEST: "TASKS/GET_TASKS_METADATA_REQUEST",
  GET_TASKS_METADATA_SUCCESS: "TASKS/GET_TASKS_METADATA_SUCCESS",
  GET_TASKS_METADATA_ERROR: "TASKS/GET_TASKS_METADATA_ERROR",

  GET_CHILD_TASKS_REQUEST: "TASKS/GET_CHILD_TASKS_REQUEST",
  GET_CHILD_TASKS_SUCCESS: "TASKS/GET_CHILD_TASKS_SUCCESS",
  GET_CHILD_TASKS_ERROR: "TASKS/GET_CHILD_TASKS_ERROR",

  GET_TASK_LOG_REQUEST: "GET_TASK_LOG_REQUEST",
  GET_TASK_LOG_SUCCESS: "GET_TASK_LOG_SUCCESS",
  GET_TASK_LOG_ERROR: "GET_TASK_LOG_ERROR",

  GET_TASKLOG_TASKS_REQUEST: "GET_TASKLOG_TASKS_REQUEST",
  GET_TASKLOG_TASKS_SUCCESS: "GET_TASKLOG_TASKS_SUCCESS",
  GET_TASKLOG_TASKS_ERROR: "GET_TASKLOG_TASKS_ERROR",

  RESET_TASK_STORE: "TASKS/RESET_TASK_STORE",

  LOGOUT_RESET_LIST_TASK_SEARCH: "LOGOUT_RESET_LIST_TASK_SEARCH",

  GET_LIST_TASK_GANTT_CHART_REQUEST: "GET_LIST_TASK_GANTT_CHART_REQUEST",
  GET_LIST_TASK_GANTT_CHART_SUCCESS: "GET_LIST_TASK_GANTT_CHART_SUCCESS",
  GET_LIST_TASK_GANTT_CHART_ERROR: "GET_LIST_TASK_GANTT_CHART_ERROR",

  GET_TASK_ASSIGN_REQUEST: "TASK_ASSIGN/GET_TASK_ASSIGN_REQUEST",
  GET_TASK_ASSIGN_SUCCESS: "TASK_ASSIGN/GET_TASK_ASSIGN_SUCCESS",
  GET_TASK_ASSIGN_ERROR: "TASK_ASSIGN/GET_TASK_ASSIGN_ERROR",

  GET_TASK_ACHIEVEMENT_REQUEST: "TASK_ACHIEVEMENT/GET_TASK_ACHIEVEMENT_REQUEST",
  GET_TASK_ACHIEVEMENT_SUCCESS: "TASK_ACHIEVEMENT/GET_TASK_ACHIEVEMENT_SUCCESS",
  GET_TASK_ACHIEVEMENT_ERROR: "TASK_ACHIEVEMENT/GET_TASK_ACHIEVEMENT_ERROR",

  CHANGE_TASK_FIXED_DEADLINE_REQUEST: "CHANGE_TASK_FIXED_DEADLINE_REQUEST",
  CHANGE_TASK_FIXED_DEADLINE_SUCCESS: "CHANGE_TASK_FIXED_DEADLINE_SUCCESS",
  CHANGE_TASK_FIXED_DEADLINE_ERROR: "CHANGE_TASK_FIXED_DEADLINE_ERROR",

  REFRESH_LIST_TASK_REQUEST: "REFRESH_LIST_TASK_REQUEST",
  REFRESH_LIST_TASK_SUCCESS: "REFRESH_LIST_TASK_SUCCESS",
  REFRESH_LIST_TASK_ERROR: "REFRESH_LIST_TASK_ERROR",

  //Export content achievement
  GET_OTA_REQUEST: "GET_OTA_REQUEST",
  GET_OTA_SUCCESS: "GET_OTA_SUCCESS",
  GET_OTA_ERROR: "GET_OTA_ERROR",

  GET_CONTENT_ACHIEVEMENT_REQUEST: "GET_CONTENT_ACHIEVEMENT_REQUEST",
  GET_CONTENT_ACHIEVEMENT_SUCCESS: "GET_CONTENT_ACHIEVEMENT_SUCCESS",
  GET_CONTENT_ACHIEVEMENT_ERROR: "GET_CONTENT_ACHIEVEMENT_ERROR",

  GET_TASK_DELIVERY_ACHIEVEMENT_REQUEST: "GET_TASK_DELIVERY_ACHIEVEMENT_REQUEST",
  GET_TASK_DELIVERY_ACHIEVEMENT_SUCCESS: "GET_TASK_DELIVERY_ACHIEVEMENT_SUCCESS",
  GET_TASK_DELIVERY_ACHIEVEMENT_ERROR: "GET_TASK_DELIVERY_ACHIEVEMENT_ERROR",
  TASK_DELIVERY_ACHIEVEMENT_RESET_TABLE: "TASK_DELIVERY_ACHIEVEMENT_RESET_TABLE",

  GET_TASK_ANALYSIS_ACHIEVEMENT_REQUEST: "GET_TASK_ANALYSIS_ACHIEVEMENT_REQUEST",
  GET_TASK_ANALYSIS_ACHIEVEMENT_SUCCESS: "GET_TASK_ANALYSIS_ACHIEVEMENT_SUCCESS",
  GET_TASK_ANALYSIS_ACHIEVEMENT_ERROR: "GET_TASK_ANALYSIS_ACHIEVEMENT_ERROR",

  ADD_SEARCH_CONDITION_REQUEST: "ADD_SEARCH_CONDITION_REQUEST",
  ADD_SEARCH_CONDITION_SUCCESS: "ADD_SEARCH_CONDITION_SUCCESS",
  ADD_SEARCH_CONDITION_ERROR: "ADD_SEARCH_CONDITION_ERROR",

  GET_SEARCH_CONDITION_REQUEST: "GET_SEARCH_CONDITION_REQUEST",
  GET_SEARCH_CONDITION_SUCCESS: "GET_SEARCH_CONDITION_SUCCESS",
  GET_SEARCH_CONDITION_ERROR: "GET_SEARCH_CONDITION_ERROR",

  GET_TASKS_CSV_REQUEST: "TASKS/GET_TASKS_CSV_REQUEST",
  GET_TASKS_CSV_SUCCESS: "TASKS/GET_TASKS_CSV_SUCCESS",
  GET_TASKS_CSV_ERROR: "TASKS/GET_TASKS_CSV_ERROR",

  RESET_CONTENT_ACHIEVEMENT_TABLE: "TASKS/RESET_CONTENT_ACHIEVEMENT_TABLE",
  RESET_TASK_ACHIEVEMENT_TABLE: "TASKS/RESET_TASK_ACHIEVEMENT_TABLE",

  getTasks: (payload) => ({ type: actions.GET_TASKS_REQUEST, payload }),

  addDataTasks: (payload) => ({ type: actions.ADD_DATA_TASKS_REQUEST, payload }),

  getTaskLog: (payload) => ({ type: actions.GET_TASK_LOG_REQUEST, payload }),

  getTasklogTasks: (payload) => ({
    type: actions.GET_TASKLOG_TASKS_REQUEST,
    payload,
  }),

  getChildTasks: (payload) => ({ type: actions.GET_CHILD_TASKS_REQUEST, payload }),

  getTasksMetaData: (payload) => ({
    type: actions.GET_TASKS_METADATA_REQUEST,
    payload,
  }),

  getListTaskGanttChart: (payload) => ({ type: actions.GET_LIST_TASK_GANTT_CHART_REQUEST, payload }),
  getTaskAssign: (payload) => ({ type: actions.GET_TASK_ASSIGN_REQUEST, payload }),
  getTaskAchievement: (payload) => ({ type: actions.GET_TASK_ACHIEVEMENT_REQUEST, payload }),

  getAllOtas: (payload) => ({
    type: actions.GET_OTA_REQUEST,
    payload,
  }),
  getContentAchievement: (payload) => ({ type: actions.GET_CONTENT_ACHIEVEMENT_REQUEST, payload }),

  getTaskDeliveryAchievement: (payload) => ({ type: actions.GET_TASK_DELIVERY_ACHIEVEMENT_REQUEST, payload }),
  taskDeliveryAchievementResetTable: () => ({ type: actions.TASK_DELIVERY_ACHIEVEMENT_RESET_TABLE }),

  getTaskAnalysis: (payload) => ({ type: actions.GET_TASK_ANALYSIS_ACHIEVEMENT_REQUEST, payload }),
  addSearchCondition: (payload) => ({
    type: actions.ADD_SEARCH_CONDITION_REQUEST,
    payload: payload,
  }),
  getSearchCondition: (payload) => ({
    type: actions.GET_SEARCH_CONDITION_REQUEST,
    payload: payload,
  }),
  changeTaskFixedDeadline: (payload) => ({ type: actions.CHANGE_TASK_FIXED_DEADLINE_REQUEST, payload }),
  refreshListTask: (payload) => ({ type: actions.REFRESH_LIST_TASK_REQUEST, payload }),
  getTasksCsv: (payload) => ({ type: actions.GET_TASKS_CSV_REQUEST, payload }),
  resetContentAchievementTable: () => ({ type: actions.RESET_CONTENT_ACHIEVEMENT_TABLE }),
  resetTaskAchievementTable: () => ({ type: actions.RESET_TASK_ACHIEVEMENT_TABLE }),
};

export default actions;
