import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col, Radio, Input, Checkbox, InputNumber, Select } from "antd";
import { useIntl } from "react-intl";
import { colFullApi} from "@iso/assets/styles/form.style";
import { LayoutContentWrapper } from "@iso/components/PlanOta/PlanOta.styles";
import {
    ACCEPTABLE,
    fieldKeyIkkyuApiPlanOption,
} from "@iso/constants/api.constant";
import { isEmpty, values } from "lodash";

const KodomoIkkyu = ({
    setFieldValue,
    dataRoom,
    tabKey,
    dataOtaTab,
    closeMoneyRegisterPopup,
    setEventKodomoSubmit,
    optionValues,
    selectedRowKeys
}) => {
    const { messages } = useIntl();
    const [form] = Form.useForm();
    const { Option } = Select;

    const [initialValueFormKodomo, setInitialValueFormKodomo] = useState(KodomoIkkyuInitialValue)
    const [kodomoContentBody, setKodomoContentBody] = useState([]);
    const [usageValue, setUsageValue] = useState({
        upperElementaryUsage: KodomoIkkyuInitialValue.upperElementaryUsage,
        lowerElementaryUsage: KodomoIkkyuInitialValue.lowerElementaryUsage,
        infantsMealsAndBeddingUsage: KodomoIkkyuInitialValue.infantsMealsAndBeddingUsage,
        infantsBeddingOnlyUsage: KodomoIkkyuInitialValue.infantsBeddingOnlyUsage,
        infantsMealsOnlyUsage: KodomoIkkyuInitialValue.infantsMealsOnlyUsage,
        infantsNoMealsOrBeddingUsage: KodomoIkkyuInitialValue.infantsNoMealsOrBeddingUsage
    });

    useEffect(() => {
        const dataKodomo = dataOtaTab.kodomo[dataRoom.siteRoomId];

        if (!isEmpty(dataKodomo)) {
            form.resetFields();
            form.setFieldsValue(dataKodomo);
            setUsageValue({
                upperElementaryUsage: dataKodomo.upperElementaryUsage,
                lowerElementaryUsage: dataKodomo.lowerElementaryUsage,
                infantsMealsAndBeddingUsage: dataKodomo.infantsMealsAndBeddingUsage,
                infantsBeddingOnlyUsage: dataKodomo.infantsBeddingOnlyUsage,
                infantsMealsOnlyUsage: dataKodomo.infantsMealsOnlyUsage,
                infantsNoMealsOrBeddingUsage: dataKodomo.infantsNoMealsOrBeddingUsage
            });
        } else {
            form.setFieldsValue(initialValueFormKodomo)
        }
    }, [dataOtaTab, dataRoom.siteRoomId])

    useEffect(() => {
        const arrayKey = [
            "upperElementary",
            "lowerElementary",
            "infantsMealsAndBedding",
            "infantsBeddingOnly",
            "infantsMealsOnly",
            "infantsNoMealsOrBedding"
        ];
        const contentBody = arrayKey.map((key, index) => (
            <tr key={index}>
                <th>
                    <p>{messages[`page.apiPlanAddAndEdit.ikkyu.kodomo.${key}.title`]}</p>
                </th>
                <td>
                    <Form.Item
                        name={`${key}Usage`}
                        style={{ margin: "0px", padding: "0px" }}
                        rules={[
                            {
                                validator(_, value) {
                                    if (!value) {
                                        return Promise.reject(
                                            messages["form.error.mixed.required"].replace(
                                                "${path}",
                                                messages["page.apiPlanAddAndEdit.ikkyu.kodomo.table.th.availability.title"]
                                            )
                                        );
                                    }
                                    return Promise.resolve();
                                },
                            },
                        ]}
                    >
                        <Select
                            name={`${key}Usage`}
                            listHeight={320}
                            showSearch
                            onChange={(value) => { handleChangeSelect(value, `${key}Usage`) }}
                        >
                            {optionValues.map((option, index) => {
                                if ((option.fieldKey === fieldKeyIkkyuApiPlanOption.kodomoUsage)) {
                                    return (
                                        <Option key={index} value={option.value}>
                                            {option.value}
                                        </Option>
                                    )
                                }
                            })}
                        </Select>
                    </Form.Item>
                </td>
                <td>
                    <Form.Item
                        name={`${key}Fee`}
                        style={{ margin: "0px", padding: "0px" }}
                        rules={[
                            {
                                validator(_, value) {
                                    if (!value && (usageValue[`${key}Usage`] == ACCEPTABLE)) {
                                        return Promise.reject(
                                            messages["form.error.mixed.required"].replace(
                                                "${path}",
                                                messages["page.apiPlanAddAndEdit.ikkyu.kodomo.table.th.fee.title"]
                                            )
                                        );
                                    }
                                    return Promise.resolve();
                                },
                            },
                        ]}
                    >
                        <InputNumber
                            name={`${key}Fee`}
                            style={{ width: "60px" }}
                            maxLength={6}
                            disabled={usageValue[`${key}Usage`] != ACCEPTABLE}
                        />
                    </Form.Item>
                </td>
                <td style={{ textAlign: "left" }}>
                    <Form.Item
                        name={`${key}Unit`}
                        style={{ margin: "0px", padding: "0px" }}
                        rules={[
                            {
                                validator(_, value) {
                                    if (!value && (usageValue[`${key}Usage`] == ACCEPTABLE)) {
                                        return Promise.reject(
                                            messages["form.error.mixed.required"].replace(
                                                "${path}",
                                                messages["page.apiPlanAddAndEdit.ikkyu.kodomo.table.th.unit.title"]
                                            )
                                        );
                                    }
                                    return Promise.resolve();
                                },
                            },
                        ]}
                    >
                        <Radio.Group
                            name={`${key}Unit`}
                            onChange={(e) => (e.target.value)}
                            disabled={usageValue[`${key}Usage`] != ACCEPTABLE}
                        >
                            <Row>
                                {optionValues.map((option, index) => {
                                    if (option.fieldKey === fieldKeyIkkyuApiPlanOption.kodomoUnit) {
                                        return (
                                            <Col key={index} lg={12}>
                                                <Radio value={option.value}>
                                                    {option.value}
                                                </Radio>
                                            </Col>
                                        )
                                    }
                                }
                                )}
                            </Row>
                        </Radio.Group>
                    </Form.Item>
                </td>
                <td>
                    <Form.Item
                        name={`${key}FeePeople`}
                        style={{ margin: "0px", padding: "0px" }}
                        rules={[
                            {
                                validator(_, value) {
                                    if (!value) {
                                        return Promise.reject(
                                            messages["form.error.mixed.required"].replace(
                                                "${path}",
                                                messages["page.apiPlanAddAndEdit.ikkyu.kodomo.table.th.numberOfPeopleForFeeCalculation.title"]
                                            )
                                        );
                                    }
                                    return Promise.resolve();
                                },
                            },
                        ]}
                    >
                        <Radio.Group
                            name={`${key}FeePeople`}
                            onChange={(e) => (e.target.value)}
                        >
                            <Row>
                                {optionValues.map((option, index) => {
                                    if (option.fieldKey === fieldKeyIkkyuApiPlanOption.kodomoFeePeople) {
                                        return (
                                            <Col key={index} lg={12}>
                                                <Radio value={option.value}>
                                                    {option.value}
                                                </Radio>
                                            </Col>
                                        )
                                    }
                                }
                                )}
                            </Row>
                        </Radio.Group>
                    </Form.Item>
                </td>
                <td>
                    <p>
                        {(key != "infantsNoMealsOrBedding" && key != "infantsMealsOnly")
                            ? messages["page.apiPlanAddAndEdit.ikkyu.kodomo.capacity.include"]
                            : messages["page.apiPlanAddAndEdit.ikkyu.kodomo.capacity.notInclude"]}
                    </p>
                </td>
            </tr>
        ));

        setKodomoContentBody(contentBody)
    }, [usageValue]);

    const handleChangeSelect = (value, field) => {
        setUsageValue((prevUsageValue) => ({
            ...prevUsageValue,
            [field]: value,
        }));
        form.setFieldsValue(field, value);
        if (field == "upperElementaryUsage") {
            form.resetFields(["upperElementaryFee"]);
            form.resetFields(["upperElementaryUnit"]);
        }

        if (field == "lowerElementaryUsage") {
            form.resetFields(["lowerElementaryFee"]);
            form.resetFields(["lowerElementaryUnit"]);
        }

        if (field == "infantsMealsAndBeddingUsage") {
            form.resetFields(["infantsMealsAndBeddingFee"]);
            form.resetFields(["infantsMealsAndBeddingUnit"]);
        }

        if (field == "infantsBeddingOnlyUsage") {
            form.resetFields(["infantsBeddingOnlyFee"]);
            form.resetFields(["infantsBeddingOnlyUnit"]);
        }

        if (field == "infantsMealsOnlyUsage") {
            form.resetFields(["infantsMealsOnlyFee"]);
            form.resetFields(["infantsMealsOnlyUnit"]);
        }

        if (field == "infantsNoMealsOrBeddingUsage") {
            form.resetFields(["infantsNoMealsOrBeddingFee"]);
            form.resetFields(["infantsNoMealsOrBeddingUnit"]);
        }
    }

    const handleSubmitKodomo = (data) => {
        const arrayKodomo = dataOtaTab.kodomo;
        const dataSubmit = { ...initialValueFormKodomo, ...data };
        if (!isEmpty(selectedRowKeys) && selectedRowKeys.length > 1) {
            selectedRowKeys.map((val) => {
                arrayKodomo[`${val}`] = dataSubmit;
            })
        } else {
            arrayKodomo[`${dataRoom.siteRoomId}`] = dataSubmit;
        }

        setFieldValue(`otaFieldContent.${tabKey}.kodomo`, arrayKodomo)
        closeMoneyRegisterPopup();
    }

    return (
        <LayoutContentWrapper>
            <Form
                onFinish={handleSubmitKodomo}
                colon={false}
                form={form}
                initialValues={initialValueFormKodomo}
            >
                <Row justify="start" className="mb-25px">
                    <Col {...colFullApi}>
                        <table
                            cellSpacing="0"
                            className="kodomo-ikkyu-table"
                        >
                            <thead>
                                <tr>
                                    <th
                                        style={{
                                            width: '20%'
                                        }}
                                    >
                                        <p>
                                            {messages["page.apiPlanAddAndEdit.ikkyu.kodomo.table.th.type.title"]}
                                        </p>
                                    </th>
                                    <th
                                        style={{
                                            width: '20%'
                                        }}
                                    >
                                        <p>
                                            {messages["page.apiPlanAddAndEdit.ikkyu.kodomo.table.th.availability.title"]}
                                        </p>
                                    </th>
                                    <th
                                        style={{
                                            width: '12%'
                                        }}
                                    >
                                        <p>
                                            {messages["page.apiPlanAddAndEdit.ikkyu.kodomo.table.th.fee.title"]}
                                        </p>
                                    </th>
                                    <th
                                        style={{
                                            width: '14%'
                                        }}
                                    >
                                        <p>
                                            {messages["page.apiPlanAddAndEdit.ikkyu.kodomo.table.th.unit.title"]}
                                        </p>
                                    </th>
                                    <th
                                        style={{
                                            width: '22%'
                                        }}
                                    >
                                        <p>
                                            {messages["page.apiPlanAddAndEdit.ikkyu.kodomo.table.th.numberOfPeopleForFeeCalculation.title"]}
                                        </p>
                                    </th>
                                    <th
                                        style={{
                                            width: '12%'
                                        }}
                                    >
                                        <p>
                                            {messages["page.apiPlanAddAndEdit.ikkyu.kodomo.table.th.capacity.title"]}
                                        </p>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {kodomoContentBody}
                            </tbody>
                        </table>
                    </Col>
                </Row>

                <Row justify="center" style={{ marginTop: "20px" }}>
                    <Button onClick={closeMoneyRegisterPopup} style={{ marginRight: "10px" }}>
                        {messages["page.Account.buttonCancel"]}
                    </Button>
                    <Button type="primary" htmlType="submit" onClick={() => setEventKodomoSubmit(true)}>
                        {messages["popup.selectStore.decision"]}
                    </Button>
                </Row>
            </Form>
        </LayoutContentWrapper>
    );
};

const KodomoIkkyuInitialValue = {
    upperElementaryUsage: "受入不可",
    upperElementaryFee: null,
    upperElementaryUnit: "円",
    upperElementaryFeePeople: "含める",
    lowerElementaryUsage: "受入不可",
    lowerElementaryFee: null,
    lowerElementaryUnit: "円",
    lowerElementaryFeePeople: "含める",
    infantsMealsAndBeddingUsage: "受入不可",
    infantsMealsAndBeddingFee: null,
    infantsMealsAndBeddingUnit: "円",
    infantsMealsAndBeddingFeePeople: "含める",
    infantsBeddingOnlyUsage: "受入不可",
    infantsBeddingOnlyFee: null,
    infantsBeddingOnlyUnit: "円",
    infantsBeddingOnlyFeePeople: "含める",
    infantsMealsOnlyUsage: "受入不可",
    infantsMealsOnlyFee: null,
    infantsMealsOnlyUnit: "円",
    infantsMealsOnlyFeePeople: "含めない",
    infantsNoMealsOrBeddingUsage: "受入不可",
    infantsNoMealsOrBeddingFee: null,
    infantsNoMealsOrBeddingUnit: "円",
    infantsNoMealsOrBeddingFeePeople: "含めない"
}

export default KodomoIkkyu;
