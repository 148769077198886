import React from "react";
import { Form, Select } from "formik-antd";
import { isEmpty } from "lodash";
import {searchSelect} from "@iso/constants/api.constant";

const MealOptionSelect = ({ label, name, holidayCondition, isHoliday, disabledMode }) => {
    const { Option } = Select;

    return (
        <tr className="dis">
            <td
                className="kihon01"
                nowrap=""
            >
                <b>
                    {label}
                </b>
            </td>
            <td nowrap="">
                <Form.Item
                    name={name}
                >
                    <Select
                        name={name}
                        className="w-50"
                        disabled={(holidayCondition ? isEmpty(isHoliday) : false) || disabledMode}
                         {...searchSelect}
                    >
                        <Option></Option>
                    </Select>
                </Form.Item>
            </td>
        </tr>
    );
};

export default MealOptionSelect;
