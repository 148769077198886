import React, { memo, useState, useEffect, useCallback } from "react";
import { Col, Row, Button, Modal, Checkbox, DatePicker, Radio } from "antd";
import { Form, Input, Select, InputNumber } from "formik-antd";
import { useIntl } from "react-intl";
import { isEmpty } from "lodash";
import TableSettingPriceByRoom from "./components/TableSettingPriceByRoom";
import CheckBoxOnly from "./components/CheckBoxOnly";
import { CaretRightOutlined } from '@ant-design/icons';
import { ValidateField } from "@iso/validates/ValidateField";
import {searchSelect} from "@iso/constants/api.constant";

const PriceSettingByRoomAndDayOfWeek = ({
    values,
    setFieldValue,
    tabKey,
    apiRoomId,
    optionValues,
    disabledMode,
    dataRoom
}) => {
    const { messages } = useIntl();

    const [activeStockType, setActiveStockType] = useState(messages["page.room.moneyRegister.saturday"]);
    const [isHoliday, setIsHoliday] = useState([]);

    const [activeSyosai, setActiveSyosai] = useState(false);
    
    const arrayOptionDayPrice = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Holiday']
    const arrayOptionDay = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'holiday']

    useEffect(() => {
        if (dataRoom != undefined
            && dataRoom != null
            && dataRoom.apiRoomOtas != null
            && dataRoom.apiRoomOtas != undefined
            && dataRoom.apiRoomOtas[tabKey] != null
            && dataRoom.apiRoomOtas[tabKey] != undefined
            && !isEmpty(dataRoom.apiRoomOtas[tabKey])
            && !isEmpty(dataRoom.apiRoomOtas[tabKey].fieldContents)
            && !isEmpty(dataRoom.apiRoomOtas[tabKey].fieldContents.moneyRegister)
        ) {
            const newValues = dataRoom.apiRoomOtas[tabKey].fieldContents.moneyRegister;
            const updatedPriceSetting = { ...priceSettingByRoomAndDayOfWeekInitialValue, ...newValues };

            setFieldValue(`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']`, updatedPriceSetting);
        }
    }, [dataRoom])

    useEffect(() => {
        if (values != undefined
            && values != null
            && values.apiRoomOtas != null
            && values.apiRoomOtas != undefined
            && values.apiRoomOtas[tabKey] != null
            && values.apiRoomOtas[tabKey] != undefined
            && !isEmpty(values.apiRoomOtas[tabKey])
            && !isEmpty(values.apiRoomOtas[tabKey].fieldContents)
            && !isEmpty(values.apiRoomOtas[tabKey].fieldContents.moneyRegister)
        ) {
            setIsHoliday(values.apiRoomOtas[tabKey].fieldContents.moneyRegister.isHoliday);
            setActiveStockType(values.apiRoomOtas[tabKey].fieldContents.moneyRegister.stockType);
            setActiveSyosai(values.apiRoomOtas[tabKey].fieldContents.moneyRegister.syosaiCollapse);
        } else {
            setInitialValueMoneyRegisterCreate();
        }
    }, [values])

    const setInitialValueMoneyRegisterCreate = () => {
        setFieldValue(`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']`, priceSettingByRoomAndDayOfWeekInitialValue)
        setFieldValue(`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['stockType']`, messages["page.room.moneyRegister.saturday"])

        arrayOptionDayPrice.map((option, index) => {
            setFieldValue(`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister'][stock${option}]`, 0);
            setFieldValue(`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister'][rakuten${option}]`, 0);
            setFieldValue(`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister'][package${option}]`, 0);
            setFieldValue(`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister'][regular${option}]`, 0);
        })

        arrayOptionDay.map((option, index) => {
            setFieldValue(`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister'][${option}Point]`, 1);
            setFieldValue(`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister'][${option}HasBreakfast]`, []);
            setFieldValue(`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister'][${option}HasDinner]`, []);
            setFieldValue(`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister'][${option}TaxType]`, messages["page.room.moneyRegister.taxIncluded"]);
        })
    }

    const onChangeCheckBoxHoliday = (name, value) => {
        setFieldValue(name, value)
        setIsHoliday(value)
    }

    const onChangeStockType = (e, name) => {
        setFieldValue(name, e.target.value);
        setActiveStockType(e.target.value);
    };

    const onChangeActiveSyosai = useCallback(() => {
        setActiveSyosai(!activeSyosai);
        setFieldValue(`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['syosaiCollapse']`, !activeSyosai);
    }, [activeSyosai]);

    return (
        <table>
            <tbody>
                <tr>
                    <td
                        align="center"
                        bgcolor="#EDEDED"
                        className="kihon01"
                        colSpan="3"
                    >
                        {messages["page.room.moneyRegister.window"]}
                    </td>
                    <td
                        align="center"
                        bgcolor="#EDEDED"
                        className="kihon01"
                    >
                        <font color="#FF0000">
                            <b>
                            {messages["page.room.moneyRegister.sunday"]}
                            </b>
                        </font>
                    </td>
                    <td
                        align="center"
                        bgcolor="#EDEDED"
                        className="kihon01"
                    >
                        <b>
                        {messages["page.room.moneyRegister.monday"]}
                        </b>
                    </td>
                    <td
                        align="center"
                        bgcolor="#EDEDED"
                        className="kihon01"
                    >
                        <b>
                        {messages["page.room.moneyRegister.tuesday"]}
                        </b>
                    </td>
                    <td
                        align="center"
                        bgcolor="#EDEDED"
                        className="kihon01"
                    >
                        <b>
                        {messages["page.room.moneyRegister.wednesday"]}
                        </b>
                    </td>
                    <td
                        align="center"
                        bgcolor="#EDEDED"
                        className="kihon01"
                    >
                        <b>
                        {messages["page.room.moneyRegister.thursday"]}
                        </b>
                    </td >
                    <td
                        align="center"
                        bgcolor="#EDEDED"
                        className="kihon01"
                    >
                        <b>
                        {messages["page.room.moneyRegister.friday"]}
                        </b>
                    </td >
                    <td
                        align="center"
                        bgcolor="#EDEDED"
                    >
                        <table border="0" style={{ border: "none" }}>
                            <tbody>
                            <tr>
                                    <td style={{ border: "none" }}>
                                        <Form.Item
                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['stockType']`}
                                        >
                                            <Radio.Group
                                                onChange={(e) => onChangeStockType(e, `apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['stockType']`)}
                                                value={activeStockType}
                                                name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['stockType']`}
                                                disabled={disabledMode}
                                            >
                                                <Radio value={messages["page.room.moneyRegister.saturday"]}>
                                                    <span>{messages["page.room.moneyRegister.saturday"]}</span>
                                                </Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ border: "none" }}>
                                        <Form.Item
                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['stockType']`}
                                        >
                                            <Radio.Group
                                                onChange={(e) => onChangeStockType(e, `apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['stockType']`)}
                                                value={activeStockType}
                                                name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['stockType']`}
                                                disabled={disabledMode}
                                            >
                                                <Radio value={messages["page.room.moneyRegister.dayBeforeHoliday"]}>
                                                    <span>{messages["page.room.moneyRegister.dayBeforeHoliday"]}</span>
                                                </Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                    <td
                        align="center"
                        bgcolor="#EDEDED"
                        className="kihon01"
                    >
                        <div className="flex-group-checkbox">
                            <Form.Item
                                name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['isHoliday']`}
                            >
                                <Checkbox.Group 
                                    onChange={(value) => onChangeCheckBoxHoliday(`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['isHoliday']`, value)}
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['isHoliday']`}
                                    value={isHoliday}
                                    disabled={disabledMode}
                                >
                                    <Checkbox
                                        value={messages["page.room.moneyRegister.publicHoliday"]}
                                    />
                                </Checkbox.Group>
                            </Form.Item>
                            <span className="ml-5 item-center">
                                <font color="#FF9933">
                                    <b>
                                    {messages["page.room.moneyRegister.publicHoliday"]}
                                    </b>
                                </font>
                            </span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td
                        align="center"
                        className="kihon01"
                        colSpan="3"
                    >
                        {messages["page.room.moneyRegister.stockNumber"]}
                    </td>
                    {arrayOptionDayPrice.map((option, key) => {
                        return (
                            <td
                                align="center"
                                className="kihon01"
                                bgcolor={
                                    (option == 'Sunday')
                                        ? "#FFEBE8"
                                        : (
                                            ((option == 'Saturday') ? "#E4F1FF" : '') || ((option == 'Holiday') ? "#FFE8C7" : '')
                                        )
                                }
                                key={key}
                            >
                                <Form.Item
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['stock${option}']`}
                                    validate={(value) => ValidateField(
                                        value,
                                        messages["page.room.moneyRegister.room"],
                                        {
                                            required: messages["form.error.mixed.required"],
                                            integer: messages["form.error.number.integer"],
            
                                        },
                                        {
                                            required : true,
                                            integer : true,
                                        }
                                    )}
                                >
                                    <InputNumber
                                        name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['stock${option}']`}
                                        maxLength={3}
                                        min={0}
                                        max={999}
                                        defaultValue={0}
                                        className="w-50"
                                        disabled={(option == 'Holiday' ? isEmpty(isHoliday) : false) || disabledMode}
                                    />
                                    <span className="ml-5">
                                    {messages["page.room.moneyRegister.room"]}
                                        </span>
                                </Form.Item>
                            </td>
                        )
                    })}
                </tr>
                <tr>
                    <td
                        align="center"
                        className="kihon01"
                        nowrap=""
                        rowSpan="3"
                        width="43"
                    >
                        {messages["page.room.moneyRegister.fee"]}
                    </td>
                    <td
                        align="center"
                        bgcolor="#BDDA84"
                        className="kihon01"
                        colSpan="2"
                    >
                        {messages["page.room.moneyRegister.rakutenTravel"]}
                        <br />
                        {messages["page.room.moneyRegister.1roomTotal"]}
                    </td>
                    {arrayOptionDayPrice.map((option, index) => {
                        return (
                            <td
                                align="center"
                                bgcolor={
                                    (option == 'Sunday')
                                        ? "#FFEBE8"
                                        : (
                                            ((option == 'Saturday') ? "#E4F1FF" : '') || ((option == 'Holiday') ? "#FFE8C7" : '')
                                        )
                                }
                                key={index}
                            >
                                <Form.Item
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['rakuten${option}']`}
                                    validate={(value) => ValidateField(
                                        value,
                                        messages["page.room.moneyRegister.fee"],
                                        {
                                            required: messages["form.error.mixed.required"],
                                            integer: messages["form.error.number.integer"],
            
                                        },
                                        {
                                            required : true,
                                            integer : true,
                                        }
                                    )}
                                >
                                    <InputNumber
                                        name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['rakuten${option}']`}
                                        maxLength={10}
                                        min={0}
                                        max={9999999999}
                                        defaultValue={0}
                                        className="w-100"
                                        disabled={(option == 'Holiday' ? isEmpty(isHoliday) : false) || disabledMode}
                                    />
                                </Form.Item>
                            </td>
                        )
                    })}
                </tr>
                <tr>
                    <td
                        align="center"
                        bgcolor="#f7dbdb"
                        className="kihon01"
                        colSpan="2"
                    >
                        {messages["page.room.moneyRegister.package"]}
                        <br />
                        {messages["page.room.moneyRegister.1roomTotal"]}
                    </td>
                    {arrayOptionDayPrice.map((option, index) => {
                        return (
                            <td
                                align="center"
                                bgcolor={
                                    (option == 'Sunday')
                                        ? "#FFEBE8"
                                        : (
                                            ((option == 'Saturday') ? "#E4F1FF" : '') || ((option == 'Holiday') ? "#FFE8C7" : '')
                                        )
                                }
                                key={index}
                            >
                                <Form.Item
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['package${option}']`}
                                    validate={(value) => ValidateField(
                                        value,
                                        messages["page.room.moneyRegister.fee"],
                                        {
                                            required: messages["form.error.mixed.required"],
                                            integer: messages["form.error.number.integer"],
            
                                        },
                                        {
                                            required : true,
                                            integer : true,
                                        }
                                    )}
                                >
                                    <InputNumber
                                        name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['package${option}']`}
                                        maxLength={10}
                                        min={0}
                                        max={999999999}
                                        defaultValue={0}
                                        className="w-100"
                                        disabled={(option == 'Holiday' ? isEmpty(isHoliday) : false) || disabledMode}
                                    />
                                </Form.Item>
                            </td>
                        )
                    })}
                </tr>
                <tr>
                    <td
                        align="center"
                        bgcolor="#CCCCCC"
                        className="kihon01"
                        colSpan="2"
                    >
                        {messages["page.room.moneyRegister.regularPrice"]}
                        <br />
                        {messages["page.room.moneyRegister.1roomTotal"]}
                    </td>
                    {arrayOptionDayPrice.map((option, index) => {
                        return (
                            <td
                                align="center"
                                bgcolor={
                                    (option == 'Sunday')
                                        ? "#FFEBE8"
                                        : (
                                            ((option == 'Saturday') ? "#E4F1FF" : '') || ((option == 'Holiday') ? "#FFE8C7" : '')
                                        )
                                }
                                key={index}
                            >
                                <Form.Item
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['regular${option}']`}
                                    validate={(value) => ValidateField(
                                        value,
                                        messages["page.room.moneyRegister.fee"],
                                        {
                                            required: messages["form.error.mixed.required"],
                                            integer: messages["form.error.number.integer"],
            
                                        },
                                        {
                                            required : true,
                                            integer : true,
                                        }
                                    )}
                                >
                                    <InputNumber
                                        name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['regular${option}']`}
                                        maxLength={10}
                                        min={0}
                                        max={9999999999}
                                        defaultValue={0}
                                        className="w-100"
                                        disabled={(option == 'Holiday' ? isEmpty(isHoliday) : false) || disabledMode}
                                    />
                                </Form.Item>
                            </td>
                        )
                    })}
                </tr>
                <tr>
                    <td
                        align="center"
                        className="kihon01"
                        rowSpan="2"
                        width="43"
                    >
                        {messages["page.room.moneyRegister.meal"]}
                    </td>
                    <td
                        align="center"
                        className="kihon01"
                        rowSpan="2"
                    >
                        {messages["page.room.moneyRegister.adult"]}
                        <br />
                        {messages["page.room.moneyRegister.elementarySchoolStudent"]}
                    </td>
                    <td
                        align="center"
                        className="kihon02"
                    >
                        {messages["page.room.moneyRegister.meal.breakfast"]}
                    </td>
                    {arrayOptionDay.map((option, key) => {
                        return (
                            <td
                                align="center"
                                className="kihon01"
                                bgcolor={
                                    (option == 'sunday')
                                        ? "#FFEBE8"
                                        : (
                                            ((option == 'saturday') ? "#E4F1FF" : '') || ((option == 'holiday') ? "#FFE8C7" : '')
                                        )
                                }
                                key={key}
                            >
                                <CheckBoxOnly
                                    label={messages["page.room.moneyRegister.including"]}
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['${option}HasBreakfast']`}
                                    holidayCondition={option == 'holiday' ? true : false}
                                    isHoliday={isHoliday}
                                    setFieldValue={setFieldValue}
                                    disabledMode={disabledMode}
                                />
                            </td>
                        )
                    })}
                </tr>
                <tr>
                    <td
                        align="center"
                        className="kihon02"
                    >
                        {messages["page.room.moneyRegister.meal.dinner"]}
                    </td>
                    {arrayOptionDay.map((option, key) => {
                        return (
                            <td
                                align="center"
                                className="kihon01"
                                bgcolor={
                                    (option == 'sunday')
                                        ? "#FFEBE8"
                                        : (
                                            ((option == 'saturday') ? "#E4F1FF" : '') || ((option == 'holiday') ? "#FFE8C7" : '')
                                        )
                                }
                                key={key}
                            >
                                <CheckBoxOnly
                                    label={messages["page.room.moneyRegister.including"]}
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['${option}HasDinner']`}
                                    holidayCondition={option == 'holiday' ? true : false}
                                    isHoliday={isHoliday}
                                    setFieldValue={setFieldValue}
                                    disabledMode={disabledMode}
                                />
                            </td>
                        )
                    })}
                </tr>
                <tr>
                    <td
                        align="center"
                        className="kihon01"
                        colSpan="3"
                    >
                        {messages["page.room.moneyRegister.point"]}
                    </td>
                    {arrayOptionDay.map((option, key) => {
                        return (
                            <td
                                align="center"
                                className="kihon01"
                                bgcolor={
                                    (option == 'sunday')
                                        ? "#FFEBE8"
                                        : (
                                            ((option == 'saturday') ? "#E4F1FF" : '') || ((option == 'holiday') ? "#FFE8C7" : '')
                                        )
                                }
                                key={key}
                            >
                                <Form.Item
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['${option}Point']`}
                                    validate={(value) => ValidateField(
                                        value,
                                        messages["page.room.moneyRegister.point"],
                                        {
                                            required: messages["form.error.mixed.required"],
                                            integer: messages["form.error.number.integer"],
            
                                        },
                                        {
                                            required : true,
                                            integer : true,
                                        }
                                    )}
                                >
                                    <InputNumber
                                        name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['${option}Point']`}
                                        maxLength={2}
                                        min={1}
                                        max={10}
                                        defaultValue={1}
                                        className="w-50"
                                        disabled={(option == 'Holiday' ? isEmpty(isHoliday) : false) || disabledMode}
                                    />
                                    <span className="ml-5">{messages["page.room.moneyRegister.percent"]}</span>
                                </Form.Item>
                            </td>
                        )
                    })}
                </tr>
                <tr>
                    <td
                        align="center"
                        className="kihon01"
                        colSpan="3"
                        nowrap=""
                    >
                        {messages["page.room.moneyRegister.childSetting"]}
                    </td>
                    <td colSpan="8">
                        <TableSettingPriceByRoom
                            values={values}
                            setFieldValue={setFieldValue}
                            tabKey={tabKey}
                            apiRoomId={apiRoomId}
                            disabledMode={disabledMode}
                        />
                    </td>
                </tr>
                <tr onClick={() => onChangeActiveSyosai()}>
                    <td
                        bgcolor="#FBF2C7"
                        className="kihon03"
                        colSpan="11"
                    >
                        <div className="c-poiter noselect">
                            <CaretRightOutlined rotate={!activeSyosai ? 90 : 0} />
                            <span>{messages["page.room.moneyRegister.otherDetailTitle"]}</span>
                        </div>
                    </td>
                </tr>
            </tbody>

            <tbody id="syosai" className={activeSyosai ? 'display-item' : ''}>
                <tr className="dis">
                    <td
                        align="center"
                        className="kihon01"
                        colSpan="3"
                    >
                        {messages["page.room.moneyRegister.taxDistinction"]}
                    </td>
                    {arrayOptionDay.map((option, key) => {
                        return (
                            <td
                                align="center"
                                className="kihon01"
                                bgcolor={
                                    (option == 'sunday')
                                        ? "#FFEBE8"
                                        : (
                                            ((option == 'saturday') ? "#E4F1FF" : '') || ((option == 'holiday') ? "#FFE8C7" : '')
                                        )
                                }
                                key={key}
                            >
                                <Form.Item
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['${option}TaxType']`}
                                >
                                    <Select
                                        name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister'][${option}'TaxType']`}
                                        className="w-100"
                                        disabled={(option == 'Holiday' ? isEmpty(isHoliday) : false) || disabledMode}
                                        defaultValue={messages["page.room.moneyRegister.taxIncluded"]}
                                        {...searchSelect}
                                    >
                                        {optionValues.map((option, index) => {
                                            if (option.fieldKey == "room_tax_included") {
                                                return (
                                                    <Select.Option
                                                        key={index}
                                                        value={option.value}
                                                    >
                                                        {option.value}
                                                    </Select.Option>
                                                );
                                            }
                                        })}
                                    </Select>
                                </Form.Item>
                            </td>
                        )
                    })}
                </tr>
            </tbody>
        </table>
    )
}

const priceSettingByRoomAndDayOfWeekInitialValue = {
    isHoliday: [],
    infrantBreakfastType: null,
    infrantDinnerType: null,
    juniorHighAcceptDate: "可",
    juniorLowAcceptDate: "可",
    infrantMealPalletAcceptDate: "可",
    infrantMealOnlyAcceptDate: "可",
    infrantPalletOnlyAcceptDate: "可",
    infrantNoMealPalletAcceptDate: "可",
    stockType: "土曜日",
    stockSunday: 0,
    rakutenSunday: 0,
    packageSunday: 0,
    regularSunday: 0,
    stockMonday: 0,
    rakutenMonday: 0,
    packageMonday: 0,
    regularMonday: 0,
    stockTuesday: 0,
    rakutenTuesday: 0,
    packageTuesday: 0,
    regularTuesday: 0,
    stockWednesday: 0,
    rakutenWednesday: 0,
    packageWednesday: 0,
    regularWednesday: 0,
    stockThursday: 0,
    rakutenThursday: 0,
    packageThursday: 0,
    regularThursday: 0,
    stockFriday: 0,
    rakutenFriday: 0,
    packageFriday: 0,
    regularFriday: 0,
    stockSaturday: 0,
    rakutenSaturday: 0,
    packageSaturday: 0,
    regularSaturday: 0,
    stockHoliday: 0,
    rakutenHoliday: 0,
    packageHoliday: 0,
    regularHoliday: 0,
    sundayPoint: 1,
    sundayHasBreakfast: [],
    sundayHasDinner: [],
    sundayTaxType: "税サ込",
    mondayPoint: 1,
    mondayHasBreakfast: [],
    mondayHasDinner: [],
    mondayTaxType: "税サ込",
    tuesdayPoint: 1,
    tuesdayHasBreakfast: [],
    tuesdayHasDinner: [],
    tuesdayTaxType: "税サ込",
    wednesdayPoint: 1,
    wednesdayHasBreakfast: [],
    wednesdayHasDinner: [],
    wednesdayTaxType: "税サ込",
    thursdayPoint: 1,
    thursdayHasBreakfast: [],
    thursdayHasDinner: [],
    thursdayTaxType: "税サ込",
    fridayPoint: 1,
    fridayHasBreakfast: [],
    fridayHasDinner: [],
    fridayTaxType: "税サ込",
    saturdayPoint: 1,
    saturdayHasBreakfast: [],
    saturdayHasDinner: [],
    saturdayTaxType: "税サ込",
    holidayPoint: 1,
    holidayHasBreakfast: [],
    holidayHasDinner: [],
    holidayTaxType: "税サ込"
}

export default memo(PriceSettingByRoomAndDayOfWeek);