const actions = {
    GET_LIST_MEAL_REQUEST: "MEAL/GET_LIST_MEAL_REQUEST",
    GET_LIST_MEAL_SUCCESS: "MEAL/GET_LIST_MEAL_SUCCESS",
    GET_LIST_MEAL_ERROR: "MEAL/GET_LIST_MEAL_ERROR",

    GET_LIST_FEATURE_REQUEST: "FEATURE/GET_LIST_FEATURE_REQUEST",
    GET_LIST_FEATURE_SUCCESS: "FEATURE/GET_LIST_FEATURE_SUCCESS",
    GET_LIST_FEATURE_ERROR: "FEATURE/GET_LIST_FEATURE_ERROR",

    GET_LIST_OPTIONAL_REQUEST: "OPTIONAL/GET_LIST_OPTIONAL_REQUEST",
    GET_LIST_OPTIONAL_SUCCESS: "OPTIONAL/GET_LIST_OPTIONAL_SUCCESS",
    GET_LIST_OPTIONAL_ERROR: "OPTIONAL/GET_LIST_OPTIONAL_ERROR",

    CRAWL_DATA_MEAL_REQUEST: "MEAL/CRAWL_DATA_MEAL_REQUEST",
    CRAWL_DATA_MEAL_SUCCESS: "MEAL/CRAWL_DATA_MEAL_SUCCESS",
    CRAWL_DATA_MEAL_ERROR: "MEAL/CRAWL_DATA_MEAL_ERROR",

    CRAWL_DATA_FEATURE_REQUEST: "FEATURE/CRAWL_DATA_FEATURE_REQUEST",
    CRAWL_DATA_FEATURE_SUCCESS: "FEATURE/CRAWL_DATA_FEATURE_SUCCESS",
    CRAWL_DATA_FEATURE_ERROR: "FEATURE/CRAWL_DATA_FEATURE_ERROR",

    CRAWL_DATA_OPTIONAL_REQUEST: "OPTIONAL/CRAWL_DATA_OPTIONAL_REQUEST",
    CRAWL_DATA_OPTIONAL_SUCCESS: "OPTIONAL/CRAWL_DATA_OPTIONAL_SUCCESS",
    CRAWL_DATA_OPTIONAL_ERROR: "OPTIONAL/CRAWL_DATA_OPTIONAL_ERROR",
    
    getListMeal: (payload) => ({
        type: actions.GET_LIST_MEAL_REQUEST,
        payload: payload,
    }),

    crawlDataMeal: (payload) => ({
        type: actions.CRAWL_DATA_MEAL_REQUEST,
        payload,
    }),

    getListFeature: (payload) => ({
        type: actions.GET_LIST_FEATURE_REQUEST,
        payload: payload,
    }),

    crawlDataFeature: (payload) => ({
        type: actions.CRAWL_DATA_FEATURE_REQUEST,
        payload,
    }),

    getListOptional: (payload) => ({
        type: actions.GET_LIST_OPTIONAL_REQUEST,
        payload: payload,
    }),

    crawlDataOptional: (payload) => ({
        type: actions.CRAWL_DATA_OPTIONAL_REQUEST,
        payload,
    }),
};

export default actions;
