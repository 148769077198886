import styled from "styled-components";
import withDirection from "../../library/helpers/rtl";

const TaskLogStyle = styled.div`
  @media only screen and (min-width: 992px) {
    .button {
      width: 130px;
    }
  }

  @media only screen and (min-width: 1200px) {
    .button {
      width: 150px;
    }
  }

  @media only screen and (min-width: 1600px) {
    .button {
      width: 200px;
    }
  }

  .space-btn {
    width: 100%;
  }

  .mb-5 {
    margin-bottom: 5em;
  }
  .rangeDatePicker .ant-picker-separator span {
      display: flex;
      align-items: center;
   }
    
    .rangeDatePicker .ant-picker-separator span:before {
      display: inline-block;
      content: "~";
      margin-top: 7px;
      width: 10px;
    }
    .rangeDatePicker .ant-picker-separator svg {display: none;}
`;

export default withDirection(TaskLogStyle);
