import RakutenRoomTab from "@iso/components/RoomAddAndEditComponent/tabs/RakutenTab";
import IkkyuRoomTab from "@iso/components/RoomAddAndEditComponent/tabs/IkkyuTab";
import RakutenPlanTab from "@iso/components/PlanOta/Rakuten";
import JalanPlanTab from "@iso/components/PlanOta/Jalan";
import IkkyuPlanTab from "@iso/components/PlanOta/Ikkyu";
import JalanRoomTab from "@iso/components/RoomAddAndEditComponent/tabs/JalanTab";

export const OTA_ROOM_TABS = {
    10011: RakutenRoomTab,
    10013: IkkyuRoomTab,
    10012: JalanRoomTab,
};

export const OTA_PLAN_TABS = {
    10011: RakutenPlanTab,
    10012: JalanPlanTab,
    10013: IkkyuPlanTab,
};

export const OTA_PLAN_RAKUTEN = 10011;
export const OTA_PLAN_JALAN = 10012;
export const OTA_PLAN_IKKYU = 10013;

export const searchSelect = {
    showSearch: true,
    optionFilterProp: "children",
};

export const typeApiOption = {
    apiPlan: 1,
    apiRoom: 2,
}

export const ERROR_STATUS = {
    IN_PROGRESS_SYNC: 105,
    LOGIN_ERROR: 101,
};

export const fieldKeyRakutenApiOption = {
    publishFlg: "publish_flg",
    feature: "feature",
    eatPlace: "eat_place",
    inboundEasySetType: "inbound_easy_set_type",
    inboundType: "inbound_type",
    planPaymentMethod: "plan_payment_method",
    memberRankBenefit: "member_rank_benefit",
    acceptStartType: "accept_start_type",
    acceptEndType: "accept_end_type",
    checkinHourType: "checkin_hour_type",
    hourOption: "hour_option",
    secretSettingPlan: "secret_setting_plan",
    publishPersonalPage: "publish_personal_page",
}

export const API_CRAWL_TYPE_OPTION = [
    { id: 1, name: 'ID' },
    { id: 2, name: '名前' }
];

export const LIST_STATUS_CRAWL = [
    {id: 1, name: 'クロール成功'},
    {id: 2, name: 'クロール失敗'},
    {id: 3, name: 'クロール進行中'},
];

export const LIST_STATUS_IMPORT = [
    {id: 1, name: '取り込み成功'},
    {id: 2, name: '取り込み失敗'},
    {id: 3, name: '取り込み進行中'},
];

export const LIST_STATUS_SYNC = [
    {id: 0, name: '' },
    {id: 1, name: '成功'},
    {id: 2, name: 'エラー'},
    {id: 3, name: '進行中'}
];
export const typeStatusCrawlOption = {
    crawlSuccess: 1,
    crawlFailed: 2,
    crawlInProgress: 3,
}

export const typeCrawlOption = {
    crawlNew: 1,
    crawlAgain: 2,
}

export const typeDownloadExcel = {
    downloadCrawl: 1,
    downloadImport: 2,
}

export const LIST_CODE_STATUS_SHOW_MESSAGE = [
    101, 104, 200, 105
];

export const LIST_CODE_STATUS_COMMON_MESSAGE = [
    103, 106, 500, 102, 108, 107
];

export const ROOM_CRAWL_MIN = 1;
export const ROOM_CRAWL_MAX = 10;

export const TYPE_ROOM_FORM = 1;
export const TYPE_ROOM_CRAWL = 2;

export const LIST_STATUS_UPDATE = [
    {id: 0, name: '明細ない' },
    {id: 1, name: '成功'},
    {id: 2, name: 'エラー'},
    {id: 3, name: '進行中'},
];

export const MAX_RECORD_UPDATE = 10;
export const MIN_RECORD_UPDATE = 1;

export const TYPE_CREATE_BY_HLS = 1;
export const TYPE_CREATE_BY_CRAWL = 2;

export const UPDATE_STATUS_SUCCESS = 1;

export const URL_API_PLAN_CLONE = 'api-plans/clone';
export const IMAGE_DEFAULT = process.env.PUBLIC_URL + process.env.REACT_APP_DEFAULT_IMAGE;

export const LIST_REGISTRATION_SYSTEM = [
    {id: 1, name: 'HLS'},
    {id: 2, name: 'OTA'},
];

export const apiMaxlength = {
    maxFiftyCharacter: 50,
    maxThirtyCharacter: 30,
    maxFourHundredCharacter: 400,
}

export const fieldKeyJalanApiRoomOption = {
    buildingAndLocation: 'buildingAndLocation',
    floor: 'floor',
    grade: 'grade',
    view: 'view',
    roomAndFacilityDetail: 'roomAndFacilityDetail',
    tobacco: 'tobacco',
    internet: 'internet',
    specialRoomFeatureOffer: 'specialRoomFeatureOffer',
    acceptanceCondition: 'acceptanceCondition',
    roomTypeWestern: 'roomTypeWestern',
    roomTypeJapanese: 'roomTypeJapanese',
    roomFeature: 'roomFeature',
}

export const daysOfWeek = [
    {key: 'roomAvailableOnSunday', value: '日'},
    {key: 'roomAvailableOnMonday', value: '月'},
    {key: 'roomAvailableOnTuesday', value: '火'},
    {key: 'roomAvailableOnWednesday', value: '水'},
    {key: 'roomAvailableOnThursday', value: '木'},
    {key: 'roomAvailableOnFriday', value: '金'},
    {key: 'roomAvailableOnSaturday', value: '土'},
]

export const fieldKeyJalanApiPlanOption = {
    postingPeriodSetting: 'postingPeriodSetting',
    ngRemoval: 'ngRemoval',
    ngRemovalDetail: 'ngRemovalDetail',
    breakfastGenreFeature: 'breakfastGenreFeature',
    dinnerGenreFeature: 'dinnerGenreFeature',
    dinnerMainIngredientFeature: 'dinnerMainIngredientFeature',
    otherFeature: 'otherFeature',
    acceptanceConditionTypeFeature: 'acceptanceConditionTypeFeature',
    dayOptions: 'dayOptions',
    hourOptions: 'hourOptions',
    minuteOptions: 'minuteOptions',
    reservationCancellationDeadline: 'reservationCancellationDeadline',
    minimumConsecutiveNightType : 'minimumConsecutiveNightType',
    maximumConsecutiveNightType : 'maximumConsecutiveNightType',
    mealOptionBreakfast : 'mealOptionBreakfast',
    mealOptionBreakfastType : 'mealOptionBreakfastType',
    mealOptionDinner : 'mealOptionDinner',
    mealOptionDinnerType : 'mealOptionDinnerType',
    askCustomer : 'askCustomer',
    globalCustomerService : 'globalCustomerService',
    accommodationRequesting : 'accommodationRequesting',
    listingOrder : 'listingOrder',
    automaticPostingAccommodationLog : 'automaticPostingAccommodationLog',
    paymentMethod : 'paymentMethod',
    cancelPolicyType : 'cancelPolicyType',
    jalanPackApply : 'jalanPackApply',
    settingCancelPolicy : 'settingCancelPolicy',
    dayOfStayFrom : 'dayOfStayFrom',
    dayOfStayTo : 'dayOfStayTo',
    settingFee : 'settingFee',
    childRateAccepted : 'childRateAccepted',
    childRateCalculatingAdult : 'childRateCalculatingAdult',
    childRateUnits : 'childRateUnits',
    serviceCharge : 'serviceCharge',
    package : 'package',
}

export const fieldKeyIkkyuApiRoomTypeOption = {
    roomType: 'roomType',
    roomListDisplayCategory: 'roomListDisplayCategory',
    roomFloorClassification: 'roomFloorClassification',
    childCosleeping: 'childCosleeping',
    amenityDisplay: 'amenityDisplay',
    amenityEquipment: 'amenityEquipment',
    roomAttribute: 'roomAttribute',
    serviceAttribute: 'serviceAttribute',
    checkinTimeOptions: 'checkinTimeOptions',
    checkoutTimeOptions: 'checkoutTimeOptions',
}

export const fieldKeyIkkyuApiPlanOption = {
    ikkyuExclusive: 'ikkyuExclusive',
    usageCategory: 'usageCategory',
    feeRegistrationUnit: 'feeRegistrationUnit',
    paymentMethod: 'paymentMethod',
    includePaypay: 'includePaypay',
    closingCheckinBeforeDate: 'closingCheckinBeforeDate',
    closingCheckinBeforeTime: 'closingCheckinBeforeTime',
    periodTime: 'periodTime',
    advanceForDiamondMember: 'advanceForDiamondMember',
    planInventoryType: 'planInventoryType',
    limitedNightsType: 'limitedNightsType',
    rangeLimitedNight: 'rangeLimitedNight',
    limitedRoomsType: 'limitedRoomsType',
    rangeLimitedRoom: 'rangeLimitedRoom',
    settingCheckinCheckoutTime: 'settingCheckinCheckoutTime',
    checkinTime: 'checkinTime',
    checkoutTime: 'checkoutTime',
    mealCategory: 'mealCategory',
    usagePackageCategory: 'usagePackageCategory',
    salePackageCategory: 'salePackageCategory',
    planAttributes: 'planAttributes',
    amenityAndEquipment: 'amenityAndEquipment',
    featureSettings: 'featureSettings',
    packageExclusivePricing: 'packageExclusivePricing',
    kodomoUsage: 'kodomoUsage',
    kodomoUnit: 'kodomoUnit',
    kodomoFeePeople: 'kodomoFeePeople',
}

export const arrayOptionDay = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'holiday'];
export const PRICE_DAY_FORMAT_WEEKDAY = "平日/休前日";
export const PRICE_DAY_FORMAT_DAY_OFF_WEEK = "曜日/休前日・祝祭日";

export const POSTING_PERIOD_SETTING_SET_UP = "設定する"
export const POSTING_PERIOD_SETTING_NOT_SET = "設定しない"
export const LIMITED = "制限あり"
export const WITH = "付き"
export const POST_THE_INN_LOG_SPECIFY_CONTENT = "宿ログに投稿する（掲載内容を指定する）"
export const ONLINE_CARD_PAYMENT_ONLY = "オンラインカード決済のみ"
export const INDIVIDUAL_CANCELLATION_POLICY_FOR_PLAN = "このプランに個別のキャンセル規定を設定する"
export const CANCEL_POLICY_SET_UP = "設定する"
export const CANCEL_POLICY_NOT_SET = "設定しない"
export const DIFFERENT_TIME_DEADLINE = "予約受付・予約変更締切時間と別の時間を設定する"
export const PRICE_SET_PER_ROOM = "一室あたり料金で設定"
export const PRICE_SET_PER_PERSON = "一人あたり料金で設定"
export const SERVICE_NOT_INCLUDED = "サ別"
export const SERVICE_INCLUDED = "サ込"
export const NONE_ACCEPT = "なし"
export const PERCENT = "％"
export const BAD_REQUEST_DELETE_ROOM = "ROOM_IS_IN_USE"
export const BAD_REQUEST_DELETE_IMAGE = "IMAGE_IS_IN_USE"
export const BAD_REQUEST_DELETE_CATEGORY = "CATEGORY_IS_IN_USE"
export const AUTO_COPY_STANDARD_RATE = "通常料金を自動コピーする"
export const SET_IN_PLAN = "プランで設定する"
export const FREE_TEXT = "フリーテキスト"
export const USED = "利用する"
export const ALL = "全て"
export const SELECT = "選択"
export const TOTAL_NUMBER_SETTING = "総数設定"
export const DO_NOT_SET = "設定しない"
export const ACCEPTABLE = "受入可"
export const CHECK_IN_BEFORE_TIME_HIDDEN_OPTION = [
    "25:00",
    "26:00",
    "27:00",
    "28:00",
    "29:00",
]

export const LIST_OPEN_SALE_STATUS = [
    {id: 1, name: '販売中'},
    {id: 2, name: '停止中'},
];
export const NOT_FOR_SALE = 2

export const typeStatusStopSellingOption = {
    success: 4,
    failed: 5,
    inProgress: 6,
}

export const LIST_STATUS_SALE_PROCESSING = [
    {id: 0, name: '' },
    {id: 1, name: '販売成功'},
    {id: 2, name: '販売失敗'},
    {id: 3, name: '販売中'},
    {id: 4, name: '停止成功'},
    {id: 5, name: '停止失敗'},
    {id: 6, name: '停止中'},
];

export const LIST_STATUS_SALE_PROCESSING_SEARCH = [
    {id: 0, name: '明細ない' },
    {id: 1, name: '販売成功'},
    {id: 2, name: '販売失敗'},
    {id: 3, name: '販売中'},
    {id: 4, name: '停止成功'},
    {id: 5, name: '停止失敗'},
    {id: 6, name: '停止中'},
];

export const fieldKeyPublicFlagOption = {
    accommodationOnly: "楽天トラベル[宿泊のみ]",
    package: "楽天トラベル［パッケージ (ANA／JAL) ]",
}

export const LIST_COLUMN_CRAWL_ROOM_RAKUTEN = [
    {id: 0, value: 'name', name: '部屋タイプ名称' },
    {id: 1, value: 'packageName', name: '部屋タイプ名称 (パッケージ)' },
    {id: 2, value: 'memo', name: '備考'},
    {id: 3, value: 'packageMemo', name: '備考（パッケージ）'},
    {id: 4, value: 'paymentMethods', name: '決済方法'},
    {id: 5, value: 'image', name: '部屋画像設定'},
    {id: 6, value: 'totalPeople', name: '受け入れ人数'},
    {id: 7, value: 'acreage', name: '部屋の広さ'},
    {id: 8, value: 'roomConditions', name: '部屋条件'},
    {id: 9, value: 'roomConditionType1', name: '部屋条件1'},
    {id: 10, value: 'roomConditionType2', name: '部屋条件2'},
    {id: 11, value: 'roomEquiment', name: '部屋設備条件'},
    {id: 12, value: 'filterConditions', name: '絞り込み条件'},
    {id: 13, value: 'timePeriod', name: '期間'},
];

export const LIST_COLUMN_CRAWL_ROOM_JALAN = [
    {id: 0, value: 'name', name: '部屋タイプ名' },
    {id: 1, value: 'description', name: '部屋タイプ説明' },
    {id: 2, value: 'image', name: '部屋の代表写真設定'},
    {id: 3, value: 'capacity', name: '部屋定員'},
    {id: 4, value: 'acreage', name: '広さ'},
    {id: 5, value: 'roomType', name: 'ルームタイプ'},
    {id: 6, value: 'roomFeature', name: '部屋の特徴'},
    {id: 7, value: 'settingPeriod', name: '期間の設定'},
];

export const LIST_COLUMN_CRAWL_ROOM_IKKYU = [
    {id: 0, value: 'name', name: '部屋名称' },
    {id: 1, value: 'packageName', name: 'ヤフーパック部屋名称' },
    {id: 2, value: 'roomPR', name: '部屋PR文'},
    {id: 3, value: 'packageRoomPR', name: 'ヤフーパック部屋PR文'},
    {id: 4, value: 'imageRoom', name: '部屋画像'},
    {id: 5, value: 'capacity', name: '定員'},
    {id: 6, value: 'square', name: '平米数'},
    {id: 7, value: 'roomType', name: '部屋タイプ'},
    {id: 8, value: 'amenityEquipment', name: 'アメニティ・備品'},
    {id: 9, value: 'roomAttribute', name: '部屋属性（検索・表示用）'},
    {id: 10, value: 'serviceAttribute', name: 'サービス属性（検索・表示用）'},
];

export const LIST_COLUMN_CRAWL_PLAN_RAKUTEN = [
    {id: 0, value: 'name', name: '宿泊プラン名称' },
    {id: 1, value: 'packageName', name: '宿泊プラン名称 (パッケージ)' },
    {id: 2, value: 'postPeriod', name: '掲載期間'},
    {id: 3, value: 'implementPeriod', name: '実施期間'},
    {id: 4, value: 'description', name: '宿泊プラン内容'},
    {id: 5, value: 'packageDescription', name: '宿泊プラン内容 (パッケージ)'},
    {id: 6, value: 'planPaymentMethod', name: '決済方法'},
    {id: 7, value: 'accept', name: '受付 終了日 時'},
    {id: 8, value: 'checkinHour', name: 'チェックイン時間 '},
    {id: 9, value: 'consecutiveDay', name: '宿泊可能連泊数'},
    {id: 10, value: 'cancelPolicy', name: 'キャンセルポリシー'},
    {id: 11, value: 'planImage', name: 'プラン画像設定'},
    {id: 12, value: 'kodomo', name: '子供料金設定'},
];


export const LIST_COLUMN_CRAWL_PLAN_JALAN = [
    {id: 0, value: 'planName', name: 'プラン名' },
    {id: 1, value: 'postingPeriod', name: '掲載期間の設定' },
    {id: 2, value: 'registrationPeriod', name: '登録期間の設定'},
    {id: 3, value: 'planDetail', name: 'プランの説明'},
    {id: 4, value: 'planDetailPackage', name: '提携先プラン説明'},
    {id: 5, value: 'paymentMethod', name: '決済'},
    {id: 6, value: 'reservationAcceptance', name: '予約受付・予約変更締切時間'},
    {id: 7, value: 'checkin', name: 'チェックイン開始時刻'},
    {id: 8, value: 'lastCheckin', name: 'チェックイン最終時刻'},
    {id: 9, value: 'checkout', name: 'チェックアウト時刻'},
    {id: 10, value: 'minimumConsecutiveNight', name: '最短連泊数'},
    {id: 11, value: 'maximumConsecutiveNight', name: '最長連泊数'},
    {id: 12, value: 'questionForCustomer', name: '予約者への質問'},
    {id: 13, value: 'accommodationRequesting', name: '宿への要望'},
    {id: 14, value: 'kodomo', name: '子供料金設定'}
];

export const LIST_COLUMN_CRAWL_PLAN_IKKYU = [
    {id: 0, value: 'planName', name: 'プラン名称' },
    {id: 1, value: 'planPackageName', name: 'ヤフーパック/プラン名称' },
    {id: 2, value: 'postingPeriod', name: '掲載期間' },
    {id: 3, value: 'registrationPeriod', name: '宿泊可能期間'},
    {id: 4, value: 'bookingPeriod', name: '予約可能期間'},
    {id: 5, value: 'planDetail', name: 'プランPR文'},
    {id: 6, value: 'planDetailPackage', name: 'ヤフーパック/プランPR文'},
    {id: 7, value: 'paymentMethod', name: '支払方法'},
    {id: 8, value: 'reservationAcceptance', name: '手仕舞い設定'},
    {id: 9, value: 'checkin', name: 'チェックイン時刻'},
    {id: 10, value: 'checkout', name: 'チェックアウト時刻'},
    {id: 11, value: 'numberOfConsecutive', name: '泊数限定'},
    {id: 12, value: 'cancelPolicy', name: 'キャンセルポリシー'},
    {id: 13, value: 'kodomo', name: '子供料金設定'}
];

export const API_OTA_ID = {
    rakuten: 10011,
    jalan: 10012,
    ikkyu: 10013,
}

export const TYPE_ACTION_CRAWL = {
    cancelPolicy : "cancelPolicy",
    meal: "meal",
    feature_settings: 'feature_settings',
    optional_settings: 'optional_settings'
}


export const MEAL_TYPE = {
    BREAKFAST: 1,
    LUNCH: 2,
    DINNER: 3,
    SNACK: 4,
}

export const MEAL_TYPE_CASE = {
    no_meals : "食事なし",
    breakfast_included : "朝食付",
    dinner_included : "夕食付",
    breakfast_and_dinner_included : "夕朝食付",
    breakfast_and_lunch_included : "朝昼食付",
    lunch_included : "昼食付",
    lunch_and_dinner_included: "昼夕食付",
    all_three_meals_included: "3食付"
}

export const LIST_FIELD_KEY_CRAWL_OPTION = ['meal', 'feature_settings', 'optional_settings'];