import React, { useState, useEffect } from "react";
import { Form } from "antd";
import { useIntl } from "react-intl";
import { InputNumber } from "formik-antd";


const InputSettingPrice = ({ name, valueInput, maxValueCondition, form }) => {
    const { messages } = useIntl();
    const [requiredField, setRequiredField] = useState(true);
    useEffect(() => {
        if ((valueInput != messages["page.room.moneyRegister.notAcceptable"])
            && (valueInput != messages["page.room.moneyRegister.sameAsAdult"])
        ) {
            setRequiredField(true)
        } else {
            setRequiredField(false)
            form.resetFields([name]);
        }
    }, [valueInput])

    return (
        <Form.Item
            name={name}
            rules={[
                {
                    validator(_, value) {
                        if ((value == null || value == undefined || value == "") && requiredField) {
                            return Promise.reject(
                                messages["form.error.mixed.required"].replace(
                                    "${path}",
                                    messages["page.room.moneyRegister.setting"]
                                )
                            );
                        }

                        return Promise.resolve();
                    },
                },
            ]}
        >
            <InputNumber
                name={name}
                min={0}
                max={maxValueCondition ? 100 : 999999}
                className="w-70"
            />
            
        </Form.Item>
    );
};

export default InputSettingPrice;
