import React, { memo, useState, useEffect, useCallback } from "react";
import { Checkbox, Radio } from "antd";
import { Form, Select, InputNumber } from "formik-antd";
import { useIntl } from "react-intl";
import { isEmpty } from "lodash";
import CheckBoxOnly from "./components/CheckBoxOnly";
import TableSettingPriceByPerson from "./components/TableSettingPriceByPerson";
import { CaretRightOutlined } from '@ant-design/icons';
import TableRowFeePersonAndDayOfWeek from "./components/TableRowFeePersonAndDayOfWeek";
import { ValidateField } from "@iso/validates/ValidateField";
import {searchSelect} from "@iso/constants/api.constant";

const PriceSettingByPersonAndDayOfWeek = ({
    values,
    dataOta,
    setFieldValue,
    tabKey,
    apiRoomId,
    optionValues,
    disabledMode,
    dataRoom
}) => {
    const { messages } = useIntl();
    const [isHoliday, setIsHoliday] = useState([]);

    const arrayOptionDayPrice = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Holiday']
    const arrayOptionDay = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'holiday']

    const [rakutenFee, setRakutenFee] = useState([]);
    const [packageFee, setPackageFee] = useState([]);
    const [regularFee, setRegularFee] = useState([]);

    const [activeStockType, setActiveStockType] = useState(messages["page.room.moneyRegister.saturday"]);
    const [activeSyosai, setActiveSyosai] = useState(false);

    useEffect(() => {
        if (dataRoom != undefined
            && dataRoom != null
            && dataRoom.apiRoomOtas != null
            && dataRoom.apiRoomOtas != undefined
            && dataRoom.apiRoomOtas[tabKey] != null
            && dataRoom.apiRoomOtas[tabKey] != undefined
            && !isEmpty(dataRoom.apiRoomOtas[tabKey])
            && !isEmpty(dataRoom.apiRoomOtas[tabKey].fieldContents)
            && !isEmpty(dataRoom.apiRoomOtas[tabKey].fieldContents.moneyRegister)
        ) {
            const newValues = dataRoom.apiRoomOtas[tabKey].fieldContents.moneyRegister;
            const updatedPriceSetting = { ...priceSettingByPersonAndDayOfWeekInitialValue, ...newValues };

            setFieldValue(`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']`, updatedPriceSetting);
        }
    }, [dataRoom])

    useEffect(() => {
        if (values != undefined
            && values != null
            && values.apiRoomOtas != null
            && values.apiRoomOtas != undefined
            && values.apiRoomOtas[tabKey] != null
            && values.apiRoomOtas[tabKey] != undefined
            && !isEmpty(values.apiRoomOtas[tabKey])
            && !isEmpty(values.apiRoomOtas[tabKey].fieldContents)
            && !isEmpty(values.apiRoomOtas[tabKey].fieldContents.moneyRegister)
        ) {
            setIsHoliday(values.apiRoomOtas[tabKey].fieldContents.moneyRegister.isHoliday);
            setActiveStockType(values.apiRoomOtas[tabKey].fieldContents.moneyRegister.stockType)
            setActiveSyosai(values.apiRoomOtas[tabKey].fieldContents.moneyRegister.syosaiCollapse);
        } else {
            setInitialValueMoneyRegisterCreate();
        }
    }, [values])

    const setInitialValueMoneyRegisterCreate = () => {
        setFieldValue(`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']`, priceSettingByPersonAndDayOfWeekInitialValue)
        setFieldValue(`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['stockType']`, messages["page.room.moneyRegister.saturday"])

        for (let index = 1; index <= 5; index++) {
            arrayOptionDayPrice.map((option) => {
                setFieldValue(`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister'][rakuten${option}][${index}]`, 0);
                setFieldValue(`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister'][package${option}][${index}]`, 0);
                setFieldValue(`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister'][regular${option}][${index}]`, 0);
            })
        }

        arrayOptionDayPrice.map((option, index) => {
            setFieldValue(`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister'][stock${option}]`, 0);
        })

        arrayOptionDay.map((option, index) => {
            setFieldValue(`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister'][${option}HasBreakfast]`, []);
            setFieldValue(`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister'][${option}HasDinner]`, []);
            setFieldValue(`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister'][${option}Point]`, 1);
            setFieldValue(`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister'][${option}TaxType]`, messages["page.room.moneyRegister.taxIncluded"]);
        })
    }

    useEffect(() => {
        if (values != undefined
            && values != null
            && values.apiRoomOtas != null
            && values.apiRoomOtas != undefined
            && values.apiRoomOtas[tabKey] != null
            && values.apiRoomOtas[tabKey] != undefined
            && !isEmpty(values.apiRoomOtas[tabKey])
            && !isEmpty(values.apiRoomOtas[tabKey].fieldContents)
        ) {
            const totalFrom = values.apiRoomOtas[tabKey].fieldContents.totalPeopleFrom;
            const totalTo = values.apiRoomOtas[tabKey].fieldContents.totalPeopleTo;

            if ((totalFrom > 0 && totalTo > 0)
                && (totalFrom != undefined && totalTo != undefined)
                && (totalFrom != null && totalTo != null)
                && (parseInt(totalFrom) <= parseInt(totalTo))
            ) {
                const limitPeople = 5;

                let rakutenContent = [];
                let packageContent = [];
                let regularContent = [];

                if (totalFrom >= limitPeople) {
                    rakutenContent.push(
                        <TableRowFeePersonAndDayOfWeek
                            bgColor="#BDDA84"
                            bgChildrenColor="#F1F7E5"
                            label={messages["page.room.moneyRegister.rakutenTravel"]}
                            type="rakuten"
                            numberUniquePeople={limitPeople}
                            tabKey={tabKey}
                            isHoliday={isHoliday}
                            disabledMode={disabledMode}
                            apiRoomId={apiRoomId}
                        />
                    );

                    packageContent.push(
                        <TableRowFeePersonAndDayOfWeek
                            bgColor="#f7dbdb"
                            bgChildrenColor="#FFEBE8"
                            label={messages["page.room.moneyRegister.package"]}
                            type="package"
                            numberUniquePeople={limitPeople}
                            tabKey={tabKey}
                            isHoliday={isHoliday}
                            disabledMode={disabledMode}
                            apiRoomId={apiRoomId}
                        />
                    );

                    regularContent.push(
                        <TableRowFeePersonAndDayOfWeek
                            bgColor="#CCCCCC"
                            bgChildrenColor="#F0F0F0"
                            label={messages["page.room.moneyRegister.regularPrice"]}
                            type="regular"
                            numberUniquePeople={limitPeople}
                            tabKey={tabKey}
                            isHoliday={isHoliday}
                            disabledMode={disabledMode}
                            apiRoomId={apiRoomId}
                        />
                    );
                } else {
                    if (parseInt(totalFrom) == parseInt(totalTo)) {
                        rakutenContent.push(
                            <TableRowFeePersonAndDayOfWeek
                                bgColor="#BDDA84"
                                bgChildrenColor="#F1F7E5"
                                label={messages["page.room.moneyRegister.rakutenTravel"]}
                                type="rakuten"
                                numberUniquePeople={totalFrom}
                                tabKey={tabKey}
                                isHoliday={isHoliday}
                                disabledMode={disabledMode}
                                apiRoomId={apiRoomId}
                            />
                        );
    
                        packageContent.push(
                            <TableRowFeePersonAndDayOfWeek
                                bgColor="#f7dbdb"
                                bgChildrenColor="#FFEBE8"
                                label={messages["page.room.moneyRegister.package"]}
                                type="package"
                                numberUniquePeople={totalFrom}
                                tabKey={tabKey}
                                isHoliday={isHoliday}
                                disabledMode={disabledMode}
                                apiRoomId={apiRoomId}
                            />
                        );
    
                        regularContent.push(
                            <TableRowFeePersonAndDayOfWeek
                                bgColor="#CCCCCC"
                                bgChildrenColor="#F0F0F0"
                                label={messages["page.room.moneyRegister.regularPrice"]}
                                type="regular"
                                numberUniquePeople={totalFrom}
                                tabKey={tabKey}
                                isHoliday={isHoliday}
                                disabledMode={disabledMode}
                                apiRoomId={apiRoomId}
                            />
                        );
                    } else {
                        let totalPeopleToUnique = (totalTo >= limitPeople) ? limitPeople : totalTo;
                        let numberDay = (totalTo > limitPeople) ? (limitPeople - totalFrom) + 1 : (totalTo - totalFrom) + 1;

                        for (let i = totalFrom; i <= totalPeopleToUnique; i++) {
                            if (i < limitPeople) {
                                rakutenContent.push(
                                    <tr>
                                        {(i == totalFrom) ?
                                            <>

                                                <td
                                                    align="center"
                                                    className="kihon01"
                                                    nowrap=""
                                                    rowSpan={numberDay * 3}
                                                    width="43"
                                                >
                                                    <span>{messages["page.room.moneyRegister.fee"]}</span>
                                                </td>
                                                <td
                                                    align="center"
                                                    bgcolor="#BDDA84"
                                                    className="kihon01"
                                                    rowSpan={numberDay}
                                                >
                                                    <span>{messages["page.room.moneyRegister.rakutenTravel"]}</span>
                                                </td>
                                            </>
                                            : ""
                                        }

                                        <td
                                            align="center"
                                            bgcolor="#F1F7E5"
                                            className="kihon01"
                                            nowrap=""
                                        >
                                            {i}<span>{messages["page.room.moneyRegister.people"]}</span>
                                        </td>
                                        {arrayOptionDayPrice.map((option, index) => {
                                            return (
                                                <td
                                                    align="center"
                                                    bgcolor={
                                                        (option == 'Sunday')
                                                            ? "#FFEBE8"
                                                            : (
                                                                ((option == 'Saturday') ? "#E4F1FF" : '') || ((option == 'Holiday') ? "#FFE8C7" : '')
                                                            )
                                                    }
                                                    key={index}
                                                >
                                                    <Form.Item
                                                        name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['rakuten${option}'][${i}]`}
                                                        validate={apiRoomId ? false : (
                                                            (value) => ValidateField(
                                                                value,
                                                                messages["page.room.moneyRegister.fee"],
                                                                {
                                                                    required: messages["form.error.mixed.required"],
                                                                    integer: messages["form.error.number.integer"],
                                    
                                                                },
                                                                {
                                                                    required : true,
                                                                    integer : true,
                                                                }
                                                            )
                                                        )}
                                                    >
                                                        <InputNumber
                                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['rakuten${option}'][${i}]`}
                                                            maxLength={10}
                                                            min={0}
                                                            max={9999999999}
                                                            defaultValue={0}
                                                            className="w-100"
                                                            disabled={(option == 'Holiday' ? isEmpty(isHoliday) : false) || disabledMode}
                                                        />
                                                    </Form.Item>
                                                </td>
                                            )
                                        })}
                                    </tr>
                                );
                            } else {
                                rakutenContent.push(
                                    <tr>
                                        <td
                                            align="center"
                                            bgcolor="#F1F7E5"
                                            className="kihon01"
                                            nowrap=""
                                        >
                                            {limitPeople}<span>{messages["page.room.moneyRegister.more"]}</span>
                                        </td>
                                        {arrayOptionDayPrice.map((option, index) => {
                                            return (
                                                <td
                                                    align="center"
                                                    bgcolor={
                                                        (option == 'Sunday')
                                                            ? "#FFEBE8"
                                                            : (
                                                                ((option == 'Saturday') ? "#E4F1FF" : '') || ((option == 'Holiday') ? "#FFE8C7" : '')
                                                            )
                                                    }
                                                    key={index}
                                                >
                                                    <Form.Item
                                                        name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['rakuten${option}'][${limitPeople}]`}
                                                        validate={apiRoomId ? false : (
                                                            (value) => ValidateField(
                                                                value,
                                                                messages["page.room.moneyRegister.fee"],
                                                                {
                                                                    required: messages["form.error.mixed.required"],
                                                                    integer: messages["form.error.number.integer"],
                                    
                                                                },
                                                                {
                                                                    required : true,
                                                                    integer : true,
                                                                }
                                                            )
                                                        )}
                                                    >
                                                        <InputNumber
                                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['rakuten${option}'][${limitPeople}]`}
                                                            maxLength={10}
                                                            min={0}
                                                            max={9999999999}
                                                            defaultValue={0}
                                                            className="w-100"
                                                            disabled={(option == 'Holiday' ? isEmpty(isHoliday) : false) || disabledMode}
                                                        />
                                                    </Form.Item>
                                                </td>
                                            )
                                        })}
                                    </tr>
                                )
                            }

                            if (i < limitPeople) {
                                packageContent.push(
                                    <tr>
                                        {(i == totalFrom) ?
                                            <>
                                                <td
                                                    align="center"
                                                    bgcolor="#f7dbdb"
                                                    className="kihon01"
                                                    nowrap=""
                                                    rowSpan={numberDay}
                                                >
                                                    <span>{messages["page.room.moneyRegister.package"]}</span>
                                                </td>
                                            </>
                                            : ""
                                        }

                                        <td
                                            align="center"
                                            bgcolor="#FFEBE8"
                                            className="kihon02"
                                        >
                                            {i}<span>{messages["page.room.moneyRegister.people"]}</span>
                                        </td>
                                        {arrayOptionDayPrice.map((option, index) => {
                                            return (
                                                <td
                                                    align="center"
                                                    bgcolor={
                                                        (option == 'Sunday')
                                                            ? "#FFEBE8"
                                                            : (
                                                                ((option == 'Saturday') ? "#E4F1FF" : '') || ((option == 'Holiday') ? "#FFE8C7" : '')
                                                            )
                                                    }
                                                    key={index}
                                                >
                                                    <Form.Item
                                                        name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['package${option}'][${i}]`}
                                                        validate={apiRoomId ? false : (
                                                            (value) => ValidateField(
                                                                value,
                                                                messages["page.room.moneyRegister.fee"],
                                                                {
                                                                    required: messages["form.error.mixed.required"],
                                                                    integer: messages["form.error.number.integer"],
                                    
                                                                },
                                                                {
                                                                    required : true,
                                                                    integer : true,
                                                                }
                                                            )
                                                        )}
                                                    >
                                                        <InputNumber
                                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['package${option}'][${i}]`}
                                                            maxLength={10}
                                                            min={0}
                                                            max={9999999999}
                                                            defaultValue={0}
                                                            className="w-100"
                                                            disabled={(option == 'Holiday' ? isEmpty(isHoliday) : false) || disabledMode}
                                                        />
                                                    </Form.Item>
                                                </td>
                                            )
                                        })}
                                    </tr>
                                )
                            } else {
                                packageContent.push(
                                    <tr>
                                        <td
                                            align="center"
                                            bgcolor="#FFEBE8"
                                            className="kihon02"
                                        >
                                            {limitPeople}<span>{messages["page.room.moneyRegister.more"]}</span>
                                        </td>
                                        {arrayOptionDayPrice.map((option, index) => {
                                            return (
                                                <td
                                                    align="center"
                                                    bgcolor={
                                                        (option == 'Sunday')
                                                            ? "#FFEBE8"
                                                            : (
                                                                ((option == 'Saturday') ? "#E4F1FF" : '') || ((option == 'Holiday') ? "#FFE8C7" : '')
                                                            )
                                                    }
                                                    key={index}
                                                >
                                                    <Form.Item
                                                        name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['package${option}'][${limitPeople}]`}
                                                        validate={apiRoomId ? false : (
                                                            (value) => ValidateField(
                                                                value,
                                                                messages["page.room.moneyRegister.fee"],
                                                                {
                                                                    required: messages["form.error.mixed.required"],
                                                                    integer: messages["form.error.number.integer"],
                                    
                                                                },
                                                                {
                                                                    required : true,
                                                                    integer : true,
                                                                }
                                                            )
                                                        )}
                                                    >
                                                        <InputNumber
                                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['package${option}'][${limitPeople}]`}
                                                            maxLength={10}
                                                            min={0}
                                                            max={9999999999}
                                                            defaultValue={0}
                                                            className="w-100"
                                                            disabled={(option == 'Holiday' ? isEmpty(isHoliday) : false) || disabledMode}
                                                        />
                                                    </Form.Item>
                                                </td>
                                            )
                                        })}
                                    </tr>
                                )
                            }

                            if (i < limitPeople) {
                                regularContent.push(
                                    <tr>
                                        {(i == totalFrom) ?
                                            <>
                                                <td
                                                    align="center"
                                                    bgcolor="#CCCCCC"
                                                    className="kihon01"
                                                    nowrap=""
                                                    rowSpan={numberDay}
                                                >
                                                    <span>{messages["page.room.moneyRegister.regularPrice"]}</span>
                                                    <br />
                                                    <span>{messages["page.room.moneyRegister.perPerson"]}</span>
                                                </td>
                                            </>
                                            : ""
                                        }

                                        <td
                                            align="center"
                                            bgcolor="#F0F0F0"
                                            className="kihon02"
                                        >
                                            {i}<span>{messages["page.room.moneyRegister.people"]}</span>
                                        </td>
                                        {arrayOptionDayPrice.map((option, index) => {
                                            return (
                                                <td
                                                    align="center"
                                                    bgcolor={
                                                        (option == 'Sunday')
                                                            ? "#FFEBE8"
                                                            : (
                                                                ((option == 'Saturday') ? "#E4F1FF" : '') || ((option == 'Holiday') ? "#FFE8C7" : '')
                                                            )
                                                    }
                                                    key={index}
                                                >
                                                    <Form.Item
                                                        name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['regular${option}'][${i}]`}
                                                        validate={apiRoomId ? false : (
                                                            (value) => ValidateField(
                                                                value,
                                                                messages["page.room.moneyRegister.fee"],
                                                                {
                                                                    required: messages["form.error.mixed.required"],
                                                                    integer: messages["form.error.number.integer"],
                                    
                                                                },
                                                                {
                                                                    required : true,
                                                                    integer : true,
                                                                }
                                                            )
                                                        )}
                                                    >
                                                        <InputNumber
                                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['regular${option}'][${i}]`}
                                                            maxLength={10}
                                                            min={0}
                                                            max={9999999999}
                                                            defaultValue={0}
                                                            className="w-100"
                                                            disabled={(option == 'Holiday' ? isEmpty(isHoliday) : false) || disabledMode}
                                                        />
                                                    </Form.Item>
                                                </td>
                                            )
                                        })}
                                    </tr>
                                )
                            } else {
                                regularContent.push(
                                    <tr>
                                        <td
                                            align="center"
                                            bgcolor="#F0F0F0"
                                            className="kihon02"
                                        >
                                            {limitPeople}<span>{messages["page.room.moneyRegister.more"]}</span>
                                        </td>
                                        {arrayOptionDayPrice.map((option, index) => {
                                            return (
                                                <td
                                                    align="center"
                                                    bgcolor={
                                                        (option == 'Sunday')
                                                            ? "#FFEBE8"
                                                            : (
                                                                ((option == 'Saturday') ? "#E4F1FF" : '') || ((option == 'Holiday') ? "#FFE8C7" : '')
                                                            )
                                                    }
                                                    key={index}
                                                >
                                                    <Form.Item
                                                        name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['regular${option}'][${limitPeople}]`}
                                                        validate={apiRoomId ? false : (
                                                            (value) => ValidateField(
                                                                value,
                                                                messages["page.room.moneyRegister.fee"],
                                                                {
                                                                    required: messages["form.error.mixed.required"],
                                                                    integer: messages["form.error.number.integer"],
                                    
                                                                },
                                                                {
                                                                    required : true,
                                                                    integer : true,
                                                                }
                                                            )
                                                        )}
                                                    >
                                                        <InputNumber
                                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['regular${option}'][${limitPeople}]`}
                                                            maxLength={10}
                                                            min={0}
                                                            max={999999999}
                                                            defaultValue={0}
                                                            className="w-100"
                                                            disabled={(option == 'Holiday' ? isEmpty(isHoliday) : false) || disabledMode}
                                                        />
                                                    </Form.Item>
                                                </td>
                                            )
                                        })}
                                    </tr>
                                )
                            }
                        }
                    }
                }
                setRakutenFee(rakutenContent);
                setPackageFee(packageContent);
                setRegularFee(regularContent);

            }
        }
    }, [values?.apiRoomOtas[tabKey]?.fieldContents?.totalPeopleFrom, values?.apiRoomOtas[tabKey]?.fieldContents?.totalPeopleTo, isHoliday]);

    useEffect(() => {
        if (!apiRoomId) {
            const totalFrom = values.apiRoomOtas[tabKey].fieldContents.totalPeopleFrom;
            const totalTo = values.apiRoomOtas[tabKey].fieldContents.totalPeopleTo;
            const limitPeople = 5;
            setFieldValuesPeople(tabKey, null, [null, 0, 0, 0, 0, 0])

            if (totalFrom >= limitPeople) {
                for (let index = 1; index < limitPeople; index++) {
                    setFieldValuesPeople(tabKey, index, null);
                }
            } else {
                for (let index = 1; index < parseInt(totalFrom); index++) {
                    setFieldValuesPeople(tabKey, index, null);
                }

                for (let index = parseInt(totalTo) + 1; index <= limitPeople; index++) {
                    setFieldValuesPeople(tabKey, index, null);
                }
            }
        }
    },[values?.apiRoomOtas[tabKey]?.fieldContents?.totalPeopleFrom, values?.apiRoomOtas[tabKey]?.fieldContents?.totalPeopleTo])

    const setFieldValuesPeople = (tabKey, index, value) => {
        if (index == null) {
            arrayOptionDayPrice.map((option) => {
                setFieldValue(`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister'][rakuten${option}]`, value);
                setFieldValue(`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister'][package${option}]`, value);
                setFieldValue(`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister'][regular${option}]`, value);
            })
        } else {
            arrayOptionDayPrice.map((option) => {
                setFieldValue(`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister'][rakuten${option}][${index}]`, value);
                setFieldValue(`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister'][package${option}][${index}]`, value);
                setFieldValue(`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister'][regular${option}][${index}]`, value);
            })
        }
    }

    const onChangeCheckBoxHoliday = (name, value) => {
        setFieldValue(name, value)
        setIsHoliday(value)
    }

    const onChangeStockType = (e, name) => {
        setFieldValue(name, e.target.value);
        setActiveStockType(e.target.value);
    };

    const onChangeActiveSyosai = useCallback(() => {
        setActiveSyosai(!activeSyosai);
        setFieldValue(`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['syosaiCollapse']`, !activeSyosai);
    }, [activeSyosai]);

    return (
        <table
            bgcolor="#FFFFFF"
            border="1"
            bordercolor="#666666"
            cellPadding="2"
            cellSpacing="0"
        >
            <tbody>
                <tr>
                    <td
                        align="center"
                        bgcolor="#EDEDED"
                        className="kihon01"
                        colSpan="3"
                    >
                        <span>{messages["page.room.moneyRegister.window"]}</span>
                    </td>
                    <td
                        align="center"
                        bgcolor="#EDEDED"
                        className="kihon01"
                    >
                        <font color="#FF0000">
                            <b>
                                {messages["page.room.moneyRegister.sunday"]}
                            </b>
                        </font>
                    </td>
                    <td
                        align="center"
                        bgcolor="#EDEDED"
                        className="kihon01"
                    >
                        <b>
                            {messages["page.room.moneyRegister.monday"]}
                        </b>
                    </td>
                    <td
                        align="center"
                        bgcolor="#EDEDED"
                        className="kihon01"
                    >
                        <b>
                            {messages["page.room.moneyRegister.tuesday"]}
                        </b>
                    </td>
                    <td
                        align="center"
                        bgcolor="#EDEDED"
                        className="kihon01"
                    >
                        <b>
                            {messages["page.room.moneyRegister.wednesday"]}
                        </b>
                    </td>
                    <td
                        align="center"
                        bgcolor="#EDEDED"
                        className="kihon01"
                    >
                        <b>
                            {messages["page.room.moneyRegister.thursday"]}
                        </b>
                    </td >
                    <td
                        align="center"
                        bgcolor="#EDEDED"
                        className="kihon01"
                    >
                        <b>
                            {messages["page.room.moneyRegister.friday"]}
                        </b>
                    </td >
                    <td
                        align="center"
                        bgcolor="#EDEDED"
                    >
                        <table border="0" style={{ border: "none" }}>
                            <tbody>
                                <tr>
                                    <td style={{ border: "none" }}>
                                        <Form.Item
                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['stockType']`}
                                        >
                                            <Radio.Group
                                                onChange={(e) => onChangeStockType(e, `apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['stockType']`)}
                                                value={activeStockType}
                                                name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['stockType']`}
                                                disabled={disabledMode}
                                            >
                                                <Radio value={messages["page.room.moneyRegister.saturday"]}>
                                                    <span>{messages["page.room.moneyRegister.saturday"]}</span>
                                                </Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ border: "none" }}>
                                        <Form.Item
                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['stockType']`}
                                        >
                                            <Radio.Group
                                                onChange={(e) => onChangeStockType(e, `apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['stockType']`)}
                                                value={activeStockType}
                                                name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['stockType']`}
                                                disabled={disabledMode}
                                            >
                                                <Radio value={messages["page.room.moneyRegister.dayBeforeHoliday"]}>
                                                    <span>{messages["page.room.moneyRegister.dayBeforeHoliday"]}</span>
                                                </Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                    <td
                        align="center"
                        bgcolor="#EDEDED"
                        className="kihon01"
                    >
                        <div className="flex-group-checkbox">
                            <Form.Item
                                name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['isHoliday']`}
                            >
                                <Checkbox.Group 
                                    onChange={(value) => onChangeCheckBoxHoliday(`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['isHoliday']`, value)}
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['isHoliday']`}
                                    value={isHoliday}
                                    disabled={disabledMode}
                                >
                                    <Checkbox
                                        value={messages["page.room.moneyRegister.publicHoliday"]}
                                    />
                                </Checkbox.Group>
                            </Form.Item>
                            <span className="ml-5 item-center">
                                <font color="#FF9933">
                                    <b>
                                        {messages["page.room.moneyRegister.publicHoliday"]}
                                    </b>
                                </font>
                            </span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td
                        align="center"
                        className="kihon01"
                        colSpan="3"
                    >
                        <span>{messages["page.room.moneyRegister.stockNumber"]}</span>
                    </td>
                    {arrayOptionDayPrice.map((option, key) => {
                        return (
                            <td
                                align="center"
                                className="kihon01"
                                bgcolor={
                                    (option == 'Sunday')
                                        ? "#FFEBE8"
                                        : (
                                            ((option == 'Saturday') ? "#E4F1FF" : '') || ((option == 'Holiday') ? "#FFE8C7" : '')
                                        )
                                }
                                key={key}
                            >
                                <Form.Item
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['stock${option}']`}
                                    validate={(value) => ValidateField(
                                        value,
                                        messages["page.room.moneyRegister.room"],
                                        {
                                            required: messages["form.error.mixed.required"],
                                            integer: messages["form.error.number.integer"],
            
                                        },
                                        {
                                            required : true,
                                            integer : true,
                                        }
                                    )}
                                >
                                    <InputNumber
                                        name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['stock${option}']`}
                                        maxLength={3}
                                        min={0}
                                        max={999}
                                        defaultValue={0}
                                        className="w-50"
                                        disabled={(option == 'Holiday' ? isEmpty(isHoliday) : false) || disabledMode}
                                    />
                                    <span className="ml-5">
                                        {messages["page.room.moneyRegister.room"]}
                                    </span>
                                </Form.Item>
                            </td>
                        )
                    })}
                </tr>
                {rakutenFee}
                {packageFee}
                {regularFee}
                <tr>
                    <td
                        align="center"
                        className="kihon01"
                        rowSpan="2"
                        width="43"
                    >
                        <span>{messages["page.room.moneyRegister.meal"]}</span>
                    </td>
                    <td
                        align="center"
                        className="kihon01"
                        rowSpan="2"
                    >
                        <span>{messages["page.room.moneyRegister.adult"]}</span>
                        <br />
                        <span>{messages["page.room.moneyRegister.elementarySchoolStudent"]}</span>
                    </td>
                    <td
                        align="center"
                        className="kihon02"
                    >
                        <span>{messages["page.room.moneyRegister.meal.breakfast"]}</span>
                    </td>
                    {arrayOptionDay.map((option, key) => {
                        return (
                            <td
                                align="center"
                                className="kihon01"
                                bgcolor={
                                    (option == 'sunday')
                                        ? "#FFEBE8"
                                        : (
                                            ((option == 'saturday') ? "#E4F1FF" : '') || ((option == 'holiday') ? "#FFE8C7" : '')
                                        )
                                }
                                key={key}
                            >
                                <CheckBoxOnly
                                    label={messages["page.room.moneyRegister.including"]}
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['${option}HasBreakfast']`}
                                    holidayCondition={option == 'holiday' ? true : false}
                                    isHoliday={isHoliday}
                                    setFieldValue={setFieldValue}
                                    disabledMode={disabledMode}
                                />
                            </td>
                        )
                    })}
                </tr>
                <tr>
                    <td
                        align="center"
                        className="kihon02"
                    >
                        <span>{messages["page.room.moneyRegister.meal.dinner"]}</span>
                    </td>
                    {arrayOptionDay.map((option, key) => {
                        return (
                            <td
                                align="center"
                                className="kihon01"
                                bgcolor={
                                    (option == 'sunday')
                                        ? "#FFEBE8"
                                        : (
                                            ((option == 'saturday') ? "#E4F1FF" : '') || ((option == 'holiday') ? "#FFE8C7" : '')
                                        )
                                }
                                key={key}
                            >
                                <CheckBoxOnly
                                    label={messages["page.room.moneyRegister.including"]}
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['${option}HasDinner']`}
                                    holidayCondition={option == 'Holiday' ? true : false}
                                    isHoliday={isHoliday}
                                    setFieldValue={setFieldValue}
                                    disabledMode={disabledMode}
                                />
                            </td>
                        )
                    })}
                </tr>
                <tr>
                    <td
                        align="center"
                        className="kihon01"
                        colSpan="3"
                    >
                        <span>{messages["page.room.moneyRegister.point"]}</span>
                    </td>
                    {arrayOptionDay.map((option, key) => {
                        return (
                            <td
                                align="center"
                                className="kihon01"
                                bgcolor={
                                    (option == 'sunday')
                                        ? "#FFEBE8"
                                        : (
                                            ((option == 'saturday') ? "#E4F1FF" : '') || ((option == 'holiday') ? "#FFE8C7" : '')
                                        )
                                }
                                key={key}
                            >
                                <Form.Item
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['${option}Point']`}
                                    validate={(value) => ValidateField(
                                        value,
                                        messages["page.room.moneyRegister.point"],
                                        {
                                            required: messages["form.error.mixed.required"],
                                            integer: messages["form.error.number.integer"],
            
                                        },
                                        {
                                            required : true,
                                            integer : true,
                                        }
                                    )}
                                >
                                    <InputNumber
                                        name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['${option}Point']`}
                                        maxLength={2}
                                        defaultValue={1}
                                        min={1}
                                        max={10}
                                        className="w-50"
                                        disabled={(option == 'holiday' ? isEmpty(isHoliday) : false) || disabledMode}
                                    />
                                    <span className="ml-5">{messages["page.room.moneyRegister.percent"]}</span>
                                </Form.Item>
                            </td>
                        )
                    })}
                </tr>
                <tr>
                    <td
                        align="center"
                        className="kihon01"
                        colSpan="3"
                        nowrap=""
                    >
                        {messages["page.room.moneyRegister.childPriceSettingTitle"]}
                        <br />
                        {messages["page.room.moneyRegister.packageReservationsTitle"]}
                        <br />
                        {messages["page.room.moneyRegister.noteBeddingTitle"]}
                    </td>
                    <td colSpan="8">
                        <TableSettingPriceByPerson
                            values={values}
                            setFieldValue={setFieldValue}
                            tabKey={tabKey}
                            apiRoomId={apiRoomId}
                            isHoliday={isHoliday}
                            disabledMode={disabledMode}
                        />
                    </td>
                </tr>
                <tr onClick={() => onChangeActiveSyosai()}>
                    <td
                        bgcolor="#FBF2C7"
                        className="kihon03"
                        colSpan="11"
                    >
                        <div className="c-poiter noselect">
                            <CaretRightOutlined rotate={!activeSyosai ? 90 : 0} />
                            <span>{messages["page.room.moneyRegister.otherDetailTitle"]}</span>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tbody id="syosai" className={activeSyosai ? 'display-item' : ''}>
                <tr className="non">
                    <td
                        align="center"
                        className="kihon01"
                        colSpan="3"
                    >
                        <span>{messages["page.room.moneyRegister.taxDistinction"]}</span>
                    </td>
                    {arrayOptionDay.map((option, key) => {
                        return (
                            <td
                                align="center"
                                className="kihon01"
                                bgcolor={
                                    (option == 'sunday')
                                        ? "#FFEBE8"
                                        : (
                                            ((option == 'saturday') ? "#E4F1FF" : '') || ((option == 'holiday') ? "#FFE8C7" : '')
                                        )
                                }
                                key={key}
                            >
                                <Form.Item
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['${option}TaxType']`}
                                >
                                    <Select
                                        name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['${option}TaxType']`}
                                        className="w-100"
                                        disabled={(option == 'holiday' ? isEmpty(isHoliday) : false) || disabledMode}
                                        defaultValue={messages["page.room.moneyRegister.taxIncluded"]}
                                        {...searchSelect}
                                    >
                                        {optionValues.map((option, index) => {
                                            if (option.fieldKey == "room_tax_included") {
                                                return (
                                                    <Select.Option
                                                        key={index}
                                                        value={option.value}
                                                    >
                                                        {option.value}
                                                    </Select.Option>
                                                );
                                            }
                                        })}
                                    </Select>
                                </Form.Item>
                            </td>
                        )
                    })}
                </tr>
            </tbody>
        </table >
    )
}

const priceSettingByPersonAndDayOfWeekInitialValue = {
    isHoliday: [],
    juniorHighRate: null,
    juniorLowRate: null,
    rakutenSunday: [null, 0, 0, 0, 0, 0],
    packageSunday: [null, 0, 0, 0, 0, 0],
    regularSunday: [null, 0, 0, 0, 0, 0],
    rakutenMonday: [null, 0, 0, 0, 0, 0],
    packageMonday: [null, 0, 0, 0, 0, 0],
    regularMonday: [null, 0, 0, 0, 0, 0],
    rakutenTuesday: [null, 0, 0, 0, 0, 0],
    packageTuesday: [null, 0, 0, 0, 0, 0],
    regularTuesday: [null, 0, 0, 0, 0, 0],
    rakutenWednesday: [null, 0, 0, 0, 0, 0],
    packageWednesday: [null, 0, 0, 0, 0, 0],
    regularWednesday: [null, 0, 0, 0, 0, 0],
    rakutenThursday: [null, 0, 0, 0, 0, 0],
    packageThursday: [null, 0, 0, 0, 0, 0],
    regularThursday: [null, 0, 0, 0, 0, 0],
    rakutenFriday: [null, 0, 0, 0, 0, 0],
    packageFriday: [null, 0, 0, 0, 0, 0],
    regularFriday: [null, 0, 0, 0, 0, 0],
    rakutenSaturday: [null, 0, 0, 0, 0, 0],
    packageSaturday: [null, 0, 0, 0, 0, 0],
    regularSaturday: [null, 0, 0, 0, 0, 0],
    rakutenHoliday: [null, 0, 0, 0, 0, 0],
    packageHoliday: [null, 0, 0, 0, 0, 0],
    regularHoliday: [null, 0, 0, 0, 0, 0],
    infrantMealPalletRate: null,
    infrantMealOnlyRate: null,
    infrantPalletOnlyRate: null,
    infrantNoMealPalletRate: null,
    juniorHighAcceptDate: "％",
    juniorLowAcceptDate: "％",
    infrantMealPalletAcceptDate: "％",
    infrantMealOnlyAcceptDate: "％",
    infrantPalletOnlyAcceptDate: "％",
    infrantNoMealPalletAcceptDate: "％",
    juniorHightMoney: [],
    juniorLowMoney: [],
    infrantMealPalletMoney: [],
    infrantMealOnlyMoney: [],
    infrantPalletOnlyMoney: [],
    infrantNoMealPalletMoney: [],
    infrantBreakfastType: null,
    infrantDinnerType: null
}

export default memo(PriceSettingByPersonAndDayOfWeek);