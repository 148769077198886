import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col, Radio, Input, Checkbox, InputNumber } from "antd";
import { useIntl } from "react-intl";
import { colFullApi, leftLabelFullItem, itemLeftLabelFullApi } from "@iso/assets/styles/form.style";
import { LayoutContentWrapper } from "@iso/components/PlanOta/PlanOta.styles";
import {
    NONE_ACCEPT,
    PERCENT,
    PRICE_SET_PER_PERSON,
    PRICE_SET_PER_ROOM,
    SERVICE_NOT_INCLUDED,
    fieldKeyJalanApiPlanOption
} from "@iso/constants/api.constant";
import { isEmpty, values } from "lodash";

const KodomoJalan = ({
    setFieldValue,
    dataRoom,
    tabKey,
    dataOtaTab,
    closeMoneyRegisterPopup,
    setEventKodomoSubmit,
    optionValues,
    selectedRowKeys
}) => {
    const { messages } = useIntl();
    const [form] = Form.useForm();

    const [initialValueFormKodomo, setInitialValueFormKodomo] = useState(kodomoJalanInitialValue)

    const [feeSetting, setFeeSetting] = useState(null)
    const [disableFeeSetting, setDisableFeeSetting] = useState(false)
    const [serviceCharge, setServiceCharge] = useState(null)
    const [serviceChargeRateValue, setServiceChargeRateValue] = useState(null)

    const [primaryStudents, setPrimaryStudents] = useState(null)
    const [mealAndBedding, setMealAndBedding] = useState(null)
    const [mealIncluded, setMealIncluded] = useState(null)
    const [futonAvailable, setFutonAvailable] = useState(null)
    const [noMealAndBedding, setNoMealAndBedding] = useState(null)

    const [primaryStudentsUnit, setPrimaryStudentsUnit] = useState(null)
    const [mealAndBeddingUnit, setMealAndBeddingUnit] = useState(null)
    const [mealIncludedUnit, setMealIncludedUnit] = useState(null)
    const [futonAvailableUnit, setFutonAvailableUnit] = useState(null)
    const [noMealAndBeddingUnit, setNoMealAndBeddingUnit] = useState(null)

    useEffect(() => {
        const dataKodomo = dataOtaTab.kodomo[dataRoom.siteRoomId];
        if (!isEmpty(dataKodomo)) {
            form.resetFields();
            form.setFieldsValue(dataKodomo);
            setFeeSetting(dataKodomo.feeSetting);
            setDisableFeeSetting(true);
            setServiceCharge(dataKodomo.serviceCharge);
            setServiceChargeRateValue(dataKodomo.serviceChargeRate);
            setPrimaryStudents(dataKodomo.primaryStudents);
            setMealAndBedding(dataKodomo.mealAndBedding);
            setMealIncluded(dataKodomo.mealIncluded);
            setFutonAvailable(dataKodomo.futonAvailable);
            setNoMealAndBedding(dataKodomo.noMealAndBedding);
            
            setPrimaryStudentsUnit(dataKodomo.primaryStudentsUnit);
            setMealAndBeddingUnit(dataKodomo.mealAndBeddingUnit);
            setMealIncludedUnit(dataKodomo.mealIncludedUnit);
            setFutonAvailableUnit(dataKodomo.futonAvailableUnit);
            setNoMealAndBeddingUnit(dataKodomo.noMealAndBeddingUnit);
        } else {
            setFeeSetting(PRICE_SET_PER_PERSON)
            setDisableFeeSetting(false);
            setServiceChargeRateValue(null);
            setServiceCharge(null);
            form.setFieldsValue(initialValueFormKodomo)
            form.setFieldsValue({feeSetting : PRICE_SET_PER_PERSON})
        }
    }, [dataOtaTab, dataRoom.siteRoomId])

    const handleFeeSetting = (e) => {
        const valueFeeSetting = e.target.value;
        if (valueFeeSetting == PRICE_SET_PER_ROOM) {
            form.resetFields(["primaryStudentsPrice"]);
            form.resetFields(["primaryStudentsUnit"]);
            form.resetFields(["mealAndBeddingPrice"]);
            form.resetFields(["mealAndBeddingUnit"]);
            form.resetFields(["mealIncludedPrice"]);
            form.resetFields(["mealIncludedUnit"]);
            form.resetFields(["futonAvailablePrice"]);
            form.resetFields(["futonAvailableUnit"]);
            form.resetFields(["noMealAndBeddingPrice"]);
            form.resetFields(["noMealAndBeddingUnit"]);
        }
        setFeeSetting(valueFeeSetting);
    }

    const handleServiceCharge = (e) => {
        const valueServiceCharge = e.target.value;
        if (valueServiceCharge != SERVICE_NOT_INCLUDED) {
            form.resetFields(["serviceChargeRate"]);
        }
        setServiceCharge(valueServiceCharge);
    }

    const handleChangeRadio = (e) => {
        const nameField = e.target.name;
        const valueField = e.target.value;

        if (nameField == "primaryStudents") {
            setPrimaryStudents(valueField);
            if (valueField == NONE_ACCEPT) {
                form.resetFields(["primaryStudentsCount"]);
                form.resetFields(["primaryStudentsPrice"]);
                form.resetFields(["primaryStudentsUnit"]);
            }
        }

        if (nameField == "mealAndBedding") {
            setMealAndBedding(valueField);
            if (valueField == NONE_ACCEPT) {
                form.resetFields(["mealAndBeddingCount"]);
                form.resetFields(["mealAndBeddingPrice"]);
                form.resetFields(["mealAndBeddingUnit"]);
            }
        }

        if (nameField == "mealIncluded") {
            setMealIncluded(valueField);
            if (valueField == NONE_ACCEPT) {
                form.resetFields(["mealIncludedCount"]);
                form.resetFields(["mealIncludedPrice"]);
                form.resetFields(["mealIncludedUnit"]);
            }
        }

        if (nameField == "futonAvailable") {
            setFutonAvailable(valueField);
            if (valueField == NONE_ACCEPT) {
                form.resetFields(["futonAvailableCount"]);
                form.resetFields(["futonAvailablePrice"]);
                form.resetFields(["futonAvailableUnit"]);
            }
        }

        if (nameField == "noMealAndBedding") {
            setNoMealAndBedding(valueField);
            if (valueField == NONE_ACCEPT) {
                form.resetFields(["noMealAndBeddingCount"]);
                form.resetFields(["noMealAndBeddingPrice"]);
                form.resetFields(["noMealAndBeddingUnit"]);
            }
        }
    }

    const handleSubmitKodomo = (data) => {
        const arrayKodomo = dataOtaTab.kodomo;
        const dataSubmit = { ...initialValueFormKodomo, ...data };
        if (!isEmpty(selectedRowKeys) && selectedRowKeys.length > 1) {
            selectedRowKeys.map((val) => {
                arrayKodomo[`${val}`] = dataSubmit;
            })
        } else {
            arrayKodomo[`${dataRoom.siteRoomId}`] = dataSubmit;
        }
        
        setFieldValue(`otaFieldContent.${tabKey}.kodomo`, arrayKodomo)
        closeMoneyRegisterPopup();
    }
    return (
        <LayoutContentWrapper>
            <Form
                onFinish={handleSubmitKodomo}
                colon={false}
                form={form}
                initialValues={initialValueFormKodomo}
            >
                <Row justify="start" className="mb-25px">
                    <Col {...colFullApi}>
                        <Form.Item
                            name="feeSettingGroup"
                            label={
                                <div>
                                    <div className="custom-required">{messages["page.apiPlanAddAndEdit.Jalan.feeSetting"]}</div>
                                    <div>
                                        <span className="text-note-row">{messages["page.apiPlanAddAndEdit.Jalan.required"]}</span>
                                    </div>
                                </div>
                            }
                            className="custom-label kodomo-jalan"
                            {...itemLeftLabelFullApi}
                        >
                            <div className="mb-25px">
                                {messages["page.apiPlanAddAndEdit.Jalan.feeSetting.note.method"]}
                            </div>
                            <Form.Item
                                name="feeSetting"
                                style={{ margin: "0px", padding: "0px" }}
                                rules={[
                                    {
                                        validator(_, value) {
                                            if (!value) {
                                                return Promise.reject(
                                                    messages["form.error.mixed.required"].replace(
                                                        "${path}",
                                                        messages["page.apiPlanAddAndEdit.Jalan.feeSetting"]
                                                    )
                                                );
                                            }
                                            return Promise.resolve();
                                        },
                                    },
                                ]}
                                className="custom-height"
                            >
                                <Radio.Group
                                    name="feeSetting"
                                    onChange={(e) => { handleFeeSetting(e) }}
                                    disabled={disableFeeSetting && dataRoom.siteRoomId}
                                >
                                    {optionValues.map((option, index) => {
                                        if (option.fieldKey === fieldKeyJalanApiPlanOption.settingFee) {
                                            return (
                                                <Radio value={option.value} key={index}>
                                                    <span style={{ marginLeft: 10, marginRight: 10 }}>
                                                        {option.value}
                                                    </span>
                                                    <div>
                                                        （{messages["page.apiPlanAddAndEdit.Jalan.feeSetting.example"]}
                                                        <span className="text-note-row">{messages["page.apiPlanAddAndEdit.Jalan.feeSetting.example.pricePerson"]}</span>）
                                                    </div>
                                                </Radio>
                                            )
                                        }
                                    }
                                    )}
                                </Radio.Group>
                            </Form.Item>
                            <div className="text-note-row mt-15px">
                                {messages["page.apiPlanAddAndEdit.Jalan.feeSetting.note.rule1"]}
                            </div>
                            <div className="text-note-row">
                                {messages["page.apiPlanAddAndEdit.Jalan.feeSetting.note.rule2"]}
                            </div>
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="start" className="mb-25px">
                    <Col {...colFullApi}>
                        <Form.Item
                            name={`maximumNumberOfConsecutiveNight`}
                            label={
                                <div>
                                    <div className="custom-required">{messages["page.apiPlanAddAndEdit.Jalan.childRate"]}</div>
                                    <div>
                                        <span className="text-note-row">{messages["page.apiPlanAddAndEdit.Jalan.required"]}</span>
                                    </div>
                                </div>
                            }
                            className="custom-label kodomo-jalan"
                            {...leftLabelFullItem}
                        >
                            <table
                                cellSpacing="0"
                                className="kodomo-jalan-table"
                            >
                                <thead>
                                    <tr className="tac">
                                        <th
                                            className="space"
                                            colSpan="2"
                                            style={{
                                                width: '20%'
                                            }}
                                        >
                                            <p>
                                            </p>
                                        </th>
                                        <th
                                            style={{
                                                width: '15%'
                                            }}
                                        >
                                            <p>
                                                {messages["page.apiPlanAddAndEdit.Jalan.accepted"]}
                                            </p>
                                        </th>
                                        <th
                                            style={{
                                                width: '20%'
                                            }}
                                        >
                                            <p>
                                                {messages["page.apiPlanAddAndEdit.Jalan.calculatingAdult"]}
                                            </p>
                                        </th>
                                        <th
                                            style={{
                                                width: '10%'
                                            }}
                                        >
                                            <p>
                                                {messages["page.apiPlanAddAndEdit.Jalan.pricesRates"]}
                                            </p>
                                        </th>
                                        <th
                                            style={{
                                                width: '25%'
                                            }}
                                        >
                                            <p>
                                                {messages["page.apiPlanAddAndEdit.Jalan.units"]}
                                            </p>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody id="val-c">
                                    <tr className="tac js-cset-par">
                                        <th
                                            className="tal"
                                            colSpan="2"
                                        >
                                            <p>
                                                {messages["page.apiPlanAddAndEdit.Jalan.elementarySchool"]}
                                            </p>
                                        </th>
                                        <td>
                                            <Form.Item
                                                name="primaryStudents"
                                                style={{ margin: "0px", padding: "0px" }}
                                                rules={[
                                                    {
                                                        validator(_, value) {
                                                            if (!value) {
                                                                return Promise.reject(
                                                                    messages["form.error.mixed.required"].replace(
                                                                        "${path}",
                                                                        messages["page.apiPlanAddAndEdit.Jalan.feeSetting"]
                                                                    )
                                                                );
                                                            }
                                                            return Promise.resolve();
                                                        },
                                                    },
                                                ]}
                                            >
                                                <Radio.Group
                                                    name="primaryStudents"
                                                    onChange={(e) => handleChangeRadio(e)}
                                                >
                                                    <Row>
                                                        {optionValues.map((option, index) => {
                                                            if (option.fieldKey === fieldKeyJalanApiPlanOption.childRateAccepted) {
                                                                return (
                                                                    <Col lg={12} key={index}>
                                                                        <Radio value={option.value}>
                                                                            {option.value}
                                                                        </Radio>
                                                                    </Col>
                                                                )
                                                            }
                                                        }
                                                        )}
                                                    </Row>
                                                </Radio.Group>
                                            </Form.Item>
                                        </td>
                                        <td>
                                            <Form.Item
                                                name="primaryStudentsCount"
                                                style={{ margin: "0px", padding: "0px" }}
                                            >
                                                <Checkbox.Group
                                                    name="primaryStudentsCount"
                                                    disabled={primaryStudents == NONE_ACCEPT}
                                                >
                                                    {optionValues.map((option, index) => {
                                                        if (option.fieldKey === fieldKeyJalanApiPlanOption.childRateCalculatingAdult) {
                                                            return (
                                                                <Checkbox value={option.value}>{option.value}</Checkbox>
                                                            )
                                                        }
                                                    }
                                                    )}
                                                </Checkbox.Group>
                                            </Form.Item>
                                        </td>
                                        <td className="p-5px">
                                            <Form.Item
                                                name="primaryStudentsPrice"
                                                style={{ margin: "0px", padding: "0px" }}
                                                rules={[
                                                    {
                                                        validator(_, value) {
                                                            if (!value && (feeSetting != PRICE_SET_PER_ROOM) && (primaryStudents != NONE_ACCEPT)) {
                                                                return Promise.reject(
                                                                    messages["form.error.mixed.required"].replace(
                                                                        "${path}",
                                                                        messages["page.apiPlanAddAndEdit.Jalan.feeSetting"]
                                                                    )
                                                                );
                                                            }
                                                            return Promise.resolve();
                                                        },
                                                    },
                                                ]}
                                            >
                                                <InputNumber
                                                    name="primaryStudentsPrice"
                                                    style={{ width: "100%" }}
                                                    maxLength={(primaryStudentsUnit == PERCENT) ? 3 : 6}
                                                    max={(primaryStudentsUnit == PERCENT) ? 100 : 999999}
                                                    disabled={(feeSetting == PRICE_SET_PER_ROOM) || (primaryStudents == NONE_ACCEPT)}
                                                />
                                            </Form.Item>
                                        </td>
                                        <td>
                                            <Form.Item
                                                name="primaryStudentsUnit"
                                                style={{ margin: "0px", padding: "0px" }}
                                                rules={[
                                                    {
                                                        validator(_, value) {
                                                            if (!value && (feeSetting != PRICE_SET_PER_ROOM) && (primaryStudents != NONE_ACCEPT)) {
                                                                return Promise.reject(
                                                                    messages["form.error.mixed.required"].replace(
                                                                        "${path}",
                                                                        messages["page.apiPlanAddAndEdit.Jalan.feeSetting"]
                                                                    )
                                                                );
                                                            }
                                                            return Promise.resolve();
                                                        },
                                                    },
                                                ]}
                                            >
                                                <Radio.Group
                                                    name="primaryStudentsUnit"
                                                    onChange={(e) => setPrimaryStudentsUnit(e.target.value)}
                                                    disabled={(feeSetting == PRICE_SET_PER_ROOM) || (primaryStudents == NONE_ACCEPT)}
                                                >
                                                    {optionValues.map((option, index) => {
                                                        if (option.fieldKey === fieldKeyJalanApiPlanOption.childRateUnits) {
                                                            return (
                                                                <Radio value={option.value} key={index}>
                                                                    {option.value}
                                                                </Radio>
                                                            )
                                                        }
                                                    }
                                                    )}
                                                </Radio.Group>
                                            </Form.Item>
                                        </td>
                                    </tr>
                                    <tr className="tac js-cset-par">
                                        <th
                                            className="sub tac"
                                            rowSpan="4"
                                            style={{
                                                width: '32px'
                                            }}
                                        >
                                            <p>
                                                {messages["page.apiPlanAddAndEdit.Jalan.infants"]}
                                                <br />
                                                <br />
                                                {messages["page.apiPlanAddAndEdit.Jalan.children"]}
                                            </p>
                                        </th>
                                        <th className="tal">
                                            <p>
                                                {messages["page.apiPlanAddAndEdit.Jalan.mealsAndBedding"]}
                                            </p>
                                        </th>
                                        <td>
                                            <Form.Item
                                                name="mealAndBedding"
                                                style={{ margin: "0px", padding: "0px" }}
                                                rules={[
                                                    {
                                                        validator(_, value) {
                                                            if (!value) {
                                                                return Promise.reject(
                                                                    messages["form.error.mixed.required"].replace(
                                                                        "${path}",
                                                                        messages["page.apiPlanAddAndEdit.Jalan.feeSetting"]
                                                                    )
                                                                );
                                                            }
                                                            return Promise.resolve();
                                                        },
                                                    },
                                                ]}
                                            >
                                                <Radio.Group
                                                    name="mealAndBedding"
                                                    onChange={(e) => handleChangeRadio(e)}
                                                >
                                                    <Row>
                                                        {optionValues.map((option, index) => {
                                                            if (option.fieldKey === fieldKeyJalanApiPlanOption.childRateAccepted) {
                                                                return (
                                                                    <Col lg={12} key={index}>
                                                                        <Radio value={option.value}>
                                                                            {option.value}
                                                                        </Radio>
                                                                    </Col>
                                                                )
                                                            }
                                                        }
                                                        )}
                                                    </Row>
                                                </Radio.Group>
                                            </Form.Item>
                                        </td>
                                        <td>
                                            <Form.Item
                                                name="mealAndBeddingCount"
                                                style={{ margin: "0px", padding: "0px" }}
                                            >
                                                <Checkbox.Group
                                                    name="mealAndBeddingCount"
                                                    disabled={(mealAndBedding == NONE_ACCEPT)}
                                                >
                                                    {optionValues.map((option, index) => {
                                                        if (option.fieldKey === fieldKeyJalanApiPlanOption.childRateCalculatingAdult) {
                                                            return (
                                                                <Checkbox value={option.value}>{option.value}</Checkbox>
                                                            )
                                                        }
                                                    }
                                                    )}
                                                </Checkbox.Group>
                                            </Form.Item>
                                        </td>
                                        <td className="p-5px">
                                            <Form.Item
                                                name="mealAndBeddingPrice"
                                                style={{ margin: "0px", padding: "0px" }}
                                                rules={[
                                                    {
                                                        validator(_, value) {
                                                            if (!value && (feeSetting != PRICE_SET_PER_ROOM) && (mealAndBedding != NONE_ACCEPT)) {
                                                                return Promise.reject(
                                                                    messages["form.error.mixed.required"].replace(
                                                                        "${path}",
                                                                        messages["page.apiPlanAddAndEdit.Jalan.feeSetting"]
                                                                    )
                                                                );
                                                            }
                                                            return Promise.resolve();
                                                        },
                                                    },
                                                ]}
                                            >
                                                <InputNumber
                                                    name="mealAndBeddingPrice"
                                                    style={{ width: "100%" }}
                                                    maxLength={(mealAndBeddingUnit == PERCENT) ? 3 : 6}
                                                    max={(mealAndBeddingUnit == PERCENT) ? 100 : 999999}
                                                    disabled={(feeSetting == PRICE_SET_PER_ROOM) || (mealAndBedding == NONE_ACCEPT)}
                                                />
                                            </Form.Item>
                                        </td>
                                        <td>
                                            <Form.Item
                                                name="mealAndBeddingUnit"
                                                style={{ margin: "0px", padding: "0px" }}
                                                rules={[
                                                    {
                                                        validator(_, value) {
                                                            if (!value && (feeSetting != PRICE_SET_PER_ROOM) && (mealAndBedding != NONE_ACCEPT)) {
                                                                return Promise.reject(
                                                                    messages["form.error.mixed.required"].replace(
                                                                        "${path}",
                                                                        messages["page.apiPlanAddAndEdit.Jalan.feeSetting"]
                                                                    )
                                                                );
                                                            }
                                                            return Promise.resolve();
                                                        },
                                                    },
                                                ]}
                                            >
                                                <Radio.Group
                                                    name="mealAndBeddingUnit"
                                                    onChange={(e) => setMealAndBeddingUnit(e.target.value)}
                                                    disabled={(feeSetting == PRICE_SET_PER_ROOM) || (mealAndBedding == NONE_ACCEPT)}
                                                >
                                                    {optionValues.map((option, index) => {
                                                        if (option.fieldKey === fieldKeyJalanApiPlanOption.childRateUnits) {
                                                            return (
                                                                <Radio value={option.value} key={index}>
                                                                    {option.value}
                                                                </Radio>
                                                            )
                                                        }
                                                    }
                                                    )}
                                                </Radio.Group>
                                            </Form.Item>
                                        </td>
                                    </tr>
                                    <tr className="tac js-cset-par">
                                        <th className="tal">
                                            <p>
                                                {messages["page.apiPlanAddAndEdit.Jalan.mealProvided"]}
                                            </p>
                                        </th>
                                        <td>
                                            <Form.Item
                                                name="mealIncluded"
                                                style={{ margin: "0px", padding: "0px" }}
                                                rules={[
                                                    {
                                                        validator(_, value) {
                                                            if (!value) {
                                                                return Promise.reject(
                                                                    messages["form.error.mixed.required"].replace(
                                                                        "${path}",
                                                                        messages["page.apiPlanAddAndEdit.Jalan.feeSetting"]
                                                                    )
                                                                );
                                                            }
                                                            return Promise.resolve();
                                                        },
                                                    },
                                                ]}
                                            >
                                                <Radio.Group
                                                    name="mealIncluded"
                                                    onChange={(e) => handleChangeRadio(e)}
                                                >
                                                    <Row>
                                                        {optionValues.map((option, index) => {
                                                            if (option.fieldKey === fieldKeyJalanApiPlanOption.childRateAccepted) {
                                                                return (
                                                                    <Col lg={12} key={index}>
                                                                        <Radio value={option.value}>
                                                                            {option.value}
                                                                        </Radio>
                                                                    </Col>
                                                                )
                                                            }
                                                        }
                                                        )}
                                                    </Row>
                                                </Radio.Group>
                                            </Form.Item>
                                        </td>
                                        <td className="dabled">
                                            <p className="js-cset-elem">
                                                {messages["page.apiPlanAddAndEdit.Jalan.noCounted"]}
                                            </p>
                                        </td>
                                        <td className="p-5px">
                                            <Form.Item
                                                name="mealIncludedPrice"
                                                style={{ margin: "0px", padding: "0px" }}
                                                rules={[
                                                    {
                                                        validator(_, value) {
                                                            if (!value && (feeSetting != PRICE_SET_PER_ROOM) && (mealIncluded != NONE_ACCEPT)) {
                                                                return Promise.reject(
                                                                    messages["form.error.mixed.required"].replace(
                                                                        "${path}",
                                                                        messages["page.apiPlanAddAndEdit.Jalan.feeSetting"]
                                                                    )
                                                                );
                                                            }
                                                            return Promise.resolve();
                                                        },
                                                    },
                                                ]}
                                            >
                                                <InputNumber
                                                    name="mealIncludedPrice"
                                                    style={{ width: "100%" }}
                                                    maxLength={(mealIncludedUnit == PERCENT) ? 3 : 6}
                                                    max={(mealIncludedUnit == PERCENT) ? 100 : 999999}
                                                    disabled={(feeSetting == PRICE_SET_PER_ROOM) || (mealIncluded == NONE_ACCEPT)}
                                                />
                                            </Form.Item>
                                        </td>
                                        <td>
                                            <Form.Item
                                                name="mealIncludedUnit"
                                                style={{ margin: "0px", padding: "0px" }}
                                                rules={[
                                                    {
                                                        validator(_, value) {
                                                            if (!value && (feeSetting != PRICE_SET_PER_ROOM) && (mealIncluded != NONE_ACCEPT)) {
                                                                return Promise.reject(
                                                                    messages["form.error.mixed.required"].replace(
                                                                        "${path}",
                                                                        messages["page.apiPlanAddAndEdit.Jalan.feeSetting"]
                                                                    )
                                                                );
                                                            }
                                                            return Promise.resolve();
                                                        },
                                                    },
                                                ]}
                                            >
                                                <Radio.Group
                                                    name="mealIncludedUnit"
                                                    onChange={(e) => setMealIncludedUnit(e.target.value)}
                                                    disabled={(feeSetting == PRICE_SET_PER_ROOM) || (mealIncluded == NONE_ACCEPT)}
                                                >
                                                    {optionValues.map((option, index) => {
                                                        if (option.fieldKey === fieldKeyJalanApiPlanOption.childRateUnits) {
                                                            return (
                                                                <Radio value={option.value} key={index}>
                                                                    {option.value}
                                                                </Radio>
                                                            )
                                                        }
                                                    }
                                                    )}
                                                </Radio.Group>
                                            </Form.Item>
                                        </td>
                                    </tr>
                                    <tr className="tac js-cset-par">
                                        <th className="tal">
                                            <p>
                                                {messages["page.apiPlanAddAndEdit.Jalan.bedProvided"]}
                                            </p>
                                        </th>
                                        <td>
                                            <Form.Item
                                                name="futonAvailable"
                                                style={{ margin: "0px", padding: "0px" }}
                                                rules={[
                                                    {
                                                        validator(_, value) {
                                                            if (!value) {
                                                                return Promise.reject(
                                                                    messages["form.error.mixed.required"].replace(
                                                                        "${path}",
                                                                        messages["page.apiPlanAddAndEdit.Jalan.feeSetting"]
                                                                    )
                                                                );
                                                            }
                                                            return Promise.resolve();
                                                        },
                                                    },
                                                ]}
                                            >
                                                <Radio.Group
                                                    name="futonAvailable"
                                                    onChange={(e) => handleChangeRadio(e)}
                                                >
                                                    <Row>
                                                        {optionValues.map((option, index) => {
                                                            if (option.fieldKey === fieldKeyJalanApiPlanOption.childRateAccepted) {
                                                                return (
                                                                    <Col lg={12} key={index}>
                                                                        <Radio value={option.value}>
                                                                            {option.value}
                                                                        </Radio>
                                                                    </Col>
                                                                )
                                                            }
                                                        }
                                                        )}
                                                    </Row>
                                                </Radio.Group>
                                            </Form.Item>
                                        </td>
                                        <td>
                                            <Form.Item
                                                name="futonAvailableCount"
                                                style={{ margin: "0px", padding: "0px" }}
                                            >
                                                <Checkbox.Group
                                                    name="futonAvailableCount"
                                                    disabled={(futonAvailable == NONE_ACCEPT)}
                                                >
                                                    {optionValues.map((option, index) => {
                                                        if (option.fieldKey === fieldKeyJalanApiPlanOption.childRateCalculatingAdult) {
                                                            return (
                                                                <Checkbox value={option.value}>{option.value}</Checkbox>
                                                            )
                                                        }
                                                    }
                                                    )}
                                                </Checkbox.Group>
                                            </Form.Item>
                                        </td>
                                        <td className="p-5px">
                                            <Form.Item
                                                name="futonAvailablePrice"
                                                style={{ margin: "0px", padding: "0px" }}
                                                rules={[
                                                    {
                                                        validator(_, value) {
                                                            if (!value && (feeSetting != PRICE_SET_PER_ROOM) && (futonAvailable != NONE_ACCEPT)) {
                                                                return Promise.reject(
                                                                    messages["form.error.mixed.required"].replace(
                                                                        "${path}",
                                                                        messages["page.apiPlanAddAndEdit.Jalan.feeSetting"]
                                                                    )
                                                                );
                                                            }
                                                            return Promise.resolve();
                                                        },
                                                    },
                                                ]}
                                            >
                                                <InputNumber
                                                    name="futonAvailablePrice"
                                                    style={{ width: "100%" }}
                                                    maxLength={(futonAvailableUnit == PERCENT) ? 3 : 6}
                                                    max={(futonAvailableUnit == PERCENT) ? 100 : 999999}
                                                    disabled={(feeSetting == PRICE_SET_PER_ROOM) || (futonAvailable == NONE_ACCEPT)}
                                                />
                                            </Form.Item>
                                        </td>
                                        <td>
                                            <Form.Item
                                                name="futonAvailableUnit"
                                                style={{ margin: "0px", padding: "0px" }}
                                                rules={[
                                                    {
                                                        validator(_, value) {
                                                            if (!value && (feeSetting != PRICE_SET_PER_ROOM) && (futonAvailable != NONE_ACCEPT)) {
                                                                return Promise.reject(
                                                                    messages["form.error.mixed.required"].replace(
                                                                        "${path}",
                                                                        messages["page.apiPlanAddAndEdit.Jalan.feeSetting"]
                                                                    )
                                                                );
                                                            }
                                                            return Promise.resolve();
                                                        },
                                                    },
                                                ]}
                                            >
                                                <Radio.Group
                                                    name="futonAvailableUnit"
                                                    onChange={(e) => setFutonAvailableUnit(e.target.value)}
                                                    disabled={(feeSetting == PRICE_SET_PER_ROOM) || (futonAvailable == NONE_ACCEPT)}
                                                >
                                                    {optionValues.map((option, index) => {
                                                        if (option.fieldKey === fieldKeyJalanApiPlanOption.childRateUnits) {
                                                            return (
                                                                <Radio value={option.value} key={index}>
                                                                    {option.value}
                                                                </Radio>
                                                            )
                                                        }
                                                    }
                                                    )}
                                                </Radio.Group>
                                            </Form.Item>
                                        </td>
                                    </tr>
                                    <tr className="tac js-cset-par">
                                        <th className="tal">
                                            <p>
                                                {messages["page.apiPlanAddAndEdit.Jalan.noMealOrbedding"]}
                                            </p>
                                        </th>
                                        <td>
                                            <Form.Item
                                                name="noMealAndBedding"
                                                style={{ margin: "0px", padding: "0px" }}
                                                rules={[
                                                    {
                                                        validator(_, value) {
                                                            if (!value) {
                                                                return Promise.reject(
                                                                    messages["form.error.mixed.required"].replace(
                                                                        "${path}",
                                                                        messages["page.apiPlanAddAndEdit.Jalan.feeSetting"]
                                                                    )
                                                                );
                                                            }
                                                            return Promise.resolve();
                                                        },
                                                    },
                                                ]}
                                            >
                                                <Radio.Group
                                                    name="noMealAndBedding"
                                                    onChange={(e) => handleChangeRadio(e)}
                                                >
                                                    <Row>
                                                        {optionValues.map((option, index) => {
                                                            if (option.fieldKey === fieldKeyJalanApiPlanOption.childRateAccepted) {
                                                                return (
                                                                    <Col lg={12} key={index}>
                                                                        <Radio value={option.value}>
                                                                            {option.value}
                                                                        </Radio>
                                                                    </Col>
                                                                )
                                                            }
                                                        }
                                                        )}
                                                    </Row>
                                                </Radio.Group>
                                            </Form.Item>
                                        </td>
                                        <td className="dabled">
                                            <p className="js-cset-elem">
                                                {messages["page.apiPlanAddAndEdit.Jalan.noCounted"]}
                                            </p>
                                        </td>
                                        <td className="p-5px">
                                            <Form.Item
                                                name="noMealAndBeddingPrice"
                                                style={{ margin: "0px", padding: "0px" }}
                                                rules={[
                                                    {
                                                        validator(_, value) {
                                                            if (!value && (feeSetting != PRICE_SET_PER_ROOM) && (noMealAndBedding != NONE_ACCEPT)) {
                                                                return Promise.reject(
                                                                    messages["form.error.mixed.required"].replace(
                                                                        "${path}",
                                                                        messages["page.apiPlanAddAndEdit.Jalan.feeSetting"]
                                                                    )
                                                                );
                                                            }
                                                            return Promise.resolve();
                                                        },
                                                    },
                                                ]}
                                            >
                                                <InputNumber
                                                    name="noMealAndBeddingPrice"
                                                    style={{ width: "100%" }}
                                                    maxLength={(noMealAndBeddingUnit == PERCENT) ? 3 : 6}
                                                    max={(noMealAndBeddingUnit == PERCENT) ? 100 : 999999}
                                                    disabled={(feeSetting == PRICE_SET_PER_ROOM) || (noMealAndBedding == NONE_ACCEPT)}
                                                />
                                            </Form.Item>
                                        </td>
                                        <td>
                                            <Form.Item
                                                name="noMealAndBeddingUnit"
                                                style={{ margin: "0px", padding: "0px" }}
                                                rules={[
                                                    {
                                                        validator(_, value) {
                                                            if (!value && (feeSetting != PRICE_SET_PER_ROOM) && (noMealAndBedding != NONE_ACCEPT)) {
                                                                return Promise.reject(
                                                                    messages["form.error.mixed.required"].replace(
                                                                        "${path}",
                                                                        messages["page.apiPlanAddAndEdit.Jalan.feeSetting"]
                                                                    )
                                                                );
                                                            }
                                                            return Promise.resolve();
                                                        },
                                                    },
                                                ]}
                                            >
                                                <Radio.Group
                                                    name="noMealAndBeddingUnit"
                                                    onChange={(e) => setNoMealAndBeddingUnit(e.target.value)}
                                                    disabled={(feeSetting == PRICE_SET_PER_ROOM) || (noMealAndBedding == NONE_ACCEPT)}
                                                >
                                                    {optionValues.map((option, index) => {
                                                        if (option.fieldKey === fieldKeyJalanApiPlanOption.childRateUnits) {
                                                            return (
                                                                <Radio value={option.value} key={index}>
                                                                    {option.value}
                                                                </Radio>
                                                            )
                                                        }
                                                    }
                                                    )}
                                                </Radio.Group>
                                            </Form.Item>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="start">
                    <Col {...colFullApi}>
                        <Form.Item
                            name="serviceCharge"
                            label={
                                <div>
                                    <div className="custom-required">{messages["page.apiPlanAddAndEdit.Jalan.serviceCharge"]}</div>
                                    <div>
                                        <span className="text-note-row">{messages["page.apiPlanAddAndEdit.Jalan.required"]}</span>
                                    </div>
                                </div>
                            }
                            className="custom-label kodomo-jalan"
                            {...itemLeftLabelFullApi}
                            rules={[
                                {
                                    validator(_, value) {
                                        if (!value) {
                                            return Promise.reject(
                                                messages["form.error.mixed.required"].replace(
                                                    "${path}",
                                                    messages["page.apiPlanAddAndEdit.Jalan.serviceCharge"]
                                                )
                                            );
                                        }
                                        return Promise.resolve();
                                    },
                                },
                            ]}
                        >
                            <Radio.Group
                                name="serviceCharge"
                                onChange={(e) => { handleServiceCharge(e) }}
                            >
                                <Row>
                                    {optionValues.map((option, index) => {
                                        if (option.fieldKey === fieldKeyJalanApiPlanOption.serviceCharge) {
                                            return (
                                                <Radio value={option.value} key={index}>
                                                    <span style={{ marginLeft: 10, marginRight: 10 }}>
                                                        {option.value}
                                                    </span>
                                                </Radio>
                                            )
                                        }
                                    })}

                                    {serviceCharge == SERVICE_NOT_INCLUDED && (
                                        <Form.Item
                                            name="serviceChargeRate"
                                            style={{ margin: "0px", padding: "0px" }}
                                            rules={[
                                                {
                                                    validator(_, value) {
                                                        if (!value && serviceCharge != SERVICE_NOT_INCLUDED) {
                                                            return Promise.reject(
                                                                messages["form.error.mixed.required"].replace(
                                                                    "${path}",
                                                                    messages["page.apiPlanAddAndEdit.Jalan.serviceCharge"]
                                                                )
                                                            );
                                                        }
                                                        return Promise.resolve();
                                                    },
                                                },
                                            ]}
                                        >
                                            <div className="d-flex align-items-center">
                                                <InputNumber
                                                    name="serviceChargeRate"
                                                    style={{ width: "60px" }}
                                                    maxLength={2}
                                                    defaultValue={serviceChargeRateValue}
                                                />
                                                <div className="text-note-row">
                                                    {messages["page.apiPlanAddAndEdit.Jalan.serviceCharge.note"]}
                                                </div>
                                            </div>
                                        </Form.Item>
                                    )}
                                </Row>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>

                <Row justify="center" style={{ marginTop: "20px" }}>
                    <Button onClick={closeMoneyRegisterPopup} style={{ marginRight: "10px" }}>
                        {messages["page.Account.buttonCancel"]}
                    </Button>
                    <Button type="primary" htmlType="submit" onClick={() => setEventKodomoSubmit(true)}>
                        {messages["popup.selectStore.decision"]}
                    </Button>
                </Row>
            </Form>
        </LayoutContentWrapper>
    );
};

const kodomoJalanInitialValue = {
    feeSetting: null,
    primaryStudents: null,
    primaryStudentsCount: [],
    primaryStudentsPrice: null,
    primaryStudentsUnit: null,
    mealAndBedding: null,
    mealAndBeddingCount: [],
    mealAndBeddingPrice: null,
    mealAndBeddingUnit: null,
    mealIncluded: null,
    mealIncludedPrice: null,
    mealIncludedUnit: null,
    futonAvailable: null,
    futonAvailableCount: [],
    futonAvailablePrice: null,
    futonAvailableUnit: null,
    noMealAndBedding: null,
    noMealAndBeddingPrice: null,
    noMealAndBeddingUnit: null,
    serviceCharge: null,
    serviceChargeRate: null
}

export default KodomoJalan;
