export function ValidateField(
    value,
    message,
    messageRule,
    rule,
    valueFrom = "",
    valueTo = "",
    ojPeople = {}
) {
    let error = null;

    if (rule.required) {
        if (value === "" || value === null || value === undefined) {
            error = messageRule.required.replace("${path}", message);
            return error;
        }
    }

    if (rule.number && value !== undefined && value !== null) {
        if (isNaN(value) || value < 0) {
            error = messageRule.number.replace("${path}", message);
            return error;
        }
    }

    if (rule.integer && value !== undefined && value !== null && value !== "") {
        const regex = /^\d+$/;
        if (!regex.test(value)) {
            error = messageRule.integer.replace("${path}", message);
            return error;
        }
    }

    if (rule.space) {
        const regex = /\s/;
        const isSpace = regex.test(value);
        if (isSpace) {
            error = messageRule.valueInvalid.replace("${path}", message);
            return error;
        }
    }

    if (rule.symbols) {
        const regex = /[A-Z.-]/;
        const isSymbols = regex.test(value);
        if (isSymbols) {
            error = messageRule.valueInvalid.replace("${path}", message);
            return error;
        }
    }
    
    if (rule.character) {
        const regex =  /^[A-Za-z0-9\s]+$/;
        const isCharacter = regex.test(value);
        if (!isCharacter) {
            error = messageRule.valueInvalid.replace("${path}", message);
            return error;
        }
    }

     if (rule.rangeValid) {
         if (!valueFrom || !valueTo) return;
         if (parseInt(valueFrom) > parseInt(valueTo)) {
             error = messageRule.valueInvalid.replace("${path}", message);
             return error;
         }
     }

     if (rule.customRulePeople) {
            if (ojPeople.people == "from" && (ojPeople.valueHistory != null && ojPeople.valueHistory !== undefined)) {
                if (parseInt(value) > parseInt(ojPeople.valueHistory)) {
                    error = messageRule.valueInvalid.replace("${path}", message);
                    return error;
                }

                if ((parseInt(ojPeople.valueHistory) == 1) && (parseInt(value) < parseInt(ojPeople.valueHistory))) {
                    error = messageRule.valueInvalid.replace("${path}", message);
                    return error;
                }
            }

            if (ojPeople.people == "to" && (ojPeople.valueHistory != null && ojPeople.valueHistory !== undefined)) {
                if (parseInt(value) < parseInt(ojPeople.valueHistory)) {
                    error = messageRule.valueInvalid.replace("${path}", message);
                    return error;
                }
            }
     }

    if (rule.imageUrl) {
        const regex = /[^a-zA-Z0-9,?/\\:.&=_-]/;
        const isSpace = regex.test(value);
        if (isSpace) {
            error = messageRule.valueInvalid.replace("${path}", message);
            return error;
        }
    }

    if (rule.maxlengthFullWidth) {
        let count = 0;
        if (value !== "" && value !== null && value !== undefined) {
            let text = value.replace(/\r\n/g, "aa").replace(/\n/g, "aa");
            for (let i = 0; i < text.length; i++) {
                if (encodeURIComponent(value.charAt(i)).length >= 4) {
                    count += 2;
                } else {
                    count += 1;
                }
            }
        }

        if ((ojPeople?.maxLength * 2) < count) {
            error = messageRule?.maxlengthFullWidth?.replace("${path}", message).replace("${max}", ojPeople?.maxLength)
            return error;
        }
    }

    return error;
}