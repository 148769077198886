import styled from "styled-components";

export const UploadFileImageOtaStyle = styled.div`
  .mb-0 {
    margin-bottom: 0px;
  }

  .mb-5 {
    margin-bottom: 5px;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .mb-25 {
    margin-bottom: 25px;
  }

  .my-5 {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .my-10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .mx-10 {
    margin-left: 10px;
    margin-right: 10px;
  }

  .my-20 {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .my-30 {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  
  .mx-20 {
    margin-left: 20px;
    margin-right: 20px;
  }

  .mt-10 {
    margin-top: 10px;
  }
  
  .mt-100 {
    margin-top: 100px;
  }
  
  .ml-5 {
    margin-left: 5px;
  }

  .mr-10 {
    margin-right: 10px;
  }

  .image {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
  }
  
  .middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
  
  .container:hover .image {
    opacity: 0.3;
    -webkit-filter: grayscale(10%);
    filter: grayscale(10%);
  }
  
  .container:hover .middle {
    opacity: 1;
  }

  .icon-remove {
    background: white;
    border-radius: 15px;
    border: 1px solid white;
    font-size: 1rem;
    color: black;
    cursor: pointer;
    font-weight: 800;
  }

  .icon-remove:hover {
    background: black;
    border: 1px solid black;
    color: white;
  }

  @media only screen and (min-width: 992px) {
    
  }

  @media only screen and (min-width: 1200px) {
    
  }

  @media only screen and (min-width: 1600px) {
    
  }
`;