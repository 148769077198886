import styled from "styled-components";

export const MoneyRegisterStyle = styled.div`
  .mb-0 {
    margin-bottom: 0px;
  }

  .mb-5 {
    margin-bottom: 5px;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .mb-25 {
    margin-bottom: 25px;
  }

  .my-5 {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .my-10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .mx-10 {
    margin-left: 10px;
    margin-right: 10px;
  }

  .my-20 {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .my-30 {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  
  .mx-20 {
    margin-left: 20px;
    margin-right: 20px;
  }

  .mt-10 {
    margin-top: 10px;
  }
  
  .mt-100 {
    margin-top: 100px;
  }
  
  .ml-5 {
    margin-left: 5px;
  }

  .mr-10 {
    margin-right: 10px;
  }

  .w-50 {
    width: 50px !important;
  }
  
  .w-70 {
    width: 70px !important;
  }

  .w-100 {
    width: 100px !important;
  }

  .w-full {
    width: 100% !important;
  }

  .flex-group-checkbox {
    display: flex;
    justify-content: center;
  }

  .ant-col-lg-4 {
    max-width: 15.666667%;
  }

  table, th, td {
    border: 1px solid #e1dede;
    border-collapse: collapse;
    padding: 5px;
  }

  .display-item {
    display:none;
  }

  .c-poiter {
    cursor: pointer;
  }

  .noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .kihon_c table, .kihon_c th, .kihon_c td {
    border: none;
  }
  
  .text-inline {
    white-space: nowrap;
  }

  .accommodation {
    text-align: center;
  }

  .kodomo-table .ant-form-item {
    margin: 0px;
  }

  @media only screen and (min-width: 992px) {
    
  }

  @media only screen and (min-width: 1200px) {
    
  }

  @media only screen and (min-width: 1600px) {
    
  }

  .table-point .checkbox-meal {
    border: none !important;
    justify-content: center !important;
  }
    
  .table-point .ant-form-item {
    margin: 0px !important;
  }

  .rate-format .ant-form-item-label {
    text-align: left !important;
  }
`;