import React, { useState, useEffect } from "react";
import { Form, InputNumber } from "formik-antd";
import { ValidateField } from "@iso/validates/ValidateField";
import { useIntl } from "react-intl";

const InputSettingPrice = ({ name, valueInput, maxValueCondition, disabledMode, setFieldValue }) => {
    const { messages } = useIntl();
    const [requiredField, setRequiredField] = useState(true);
    useEffect(() => {
        if ((valueInput == messages["page.room.moneyRegister.percent"])
            || (valueInput == messages["page.room.moneyRegister.yen"])
        ) {
            setRequiredField(true)
        } else {
            setRequiredField(false)
            setFieldValue(name, 0)
        }
    }, [valueInput])

    return (
        <Form.Item
            name={name}
            validate={disabledMode ? false : (
                (value) => ValidateField(
                    value,
                    messages["page.room.moneyRegister.setting"],
                    {
                        required: messages["form.error.mixed.required"],
                        integer: messages["form.error.number.integer"],

                    },
                    {
                        required: requiredField,
                        integer: true,
                    }
                )
            )}
        >
            <InputNumber
                name={name}
                min={0}
                max={maxValueCondition ? 100 : 999999}
                className="w-70"
                disabled={disabledMode}
            />
            
        </Form.Item>
    );
};

export default InputSettingPrice;
