import React, { useCallback, useEffect, useState } from "react";
import { Formik } from "formik";
import { useIntl } from "react-intl";
import { Form } from "formik-antd";
import {Row, Button, Col, Space, Table, DatePicker, Select} from "antd";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import { useHistory, useLocation } from "react-router-dom";
import { ROLES } from "@iso/constants/common.constant";
import { DATE_FORMAT } from "@iso/constants/common.constant";
import taskActions from "@iso/redux/task/actions";
import taskAddEdit from "@iso/redux/taskAddEdit/actions";
import queryString from "query-string";
import moment from "moment-timezone";
import { tableColumnData } from "@iso/containers/TaskLog/tableColumnData";
import PageHeader from "@iso/components/utility/pageHeader";
import LayoutWrapper from "@iso/components/utility/layoutWrapper";
import LayoutContent from "@iso/components/utility/layoutContent";
import TaskLogStyle from "./TaskLog.styles";
import { Helmet } from "react-helmet";

const TaskLog = () => {
  const { messages } = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();
  const { taskLogs, loading } = useSelector((state) => state.Task);
  const { dashboardRoute } = useSelector((state) => state.Auth);
  const { userAssigneeList } = useSelector((state) => state.TaskAddEdit);
  const [initialValues, setInitialValue] = useState(initialValueDefault);
  const todayMoment = moment();
  const todayDate = todayMoment.format(DATE_FORMAT);
  const location = queryString.parse(search);
  const { RangePicker } = DatePicker;
  const [dateSearch,setDateSearch] = useState(initialDateSearch);


  useEffect(() => {
    dispatch(taskAddEdit.getListUser({ values: initialValueRequest }));
  }, [dispatch]);

  const handleUserNameChange = useCallback(
      (setFieldValue, name) => (value) => {
        setFieldValue(name, value);
      },
      []
  );

  useEffect(() => {
    if (search === "") {
      dispatch(
        taskActions.getTaskLog({
          params: `?startDate=${todayDate}`,
        })
      );
      setDateSearch({
        startDate: todayDate,
        endDate: todayDate,
      });
    } else {
      dispatch(
        taskActions.getTaskLog({
          params: search,
        })
      );
      setInitialValue({
        username: location.username,
      });
      setDateSearch({
        startDate: location.startDate,
        endDate: location.endDate,
      })
    }
  }, [
    dispatch,
    history,
    location.endDate,
    location.startDate,
    location.username,
    search,
    todayDate,
  ]);

  const handleDateFieldChange = useCallback((value) => {
      const formatValueStartDate = value ? moment(value[0]).format(DATE_FORMAT) : todayDate;
      const formatValueEndDate = value ? moment(value[1]).format(DATE_FORMAT) : todayDate;
      setDateSearch({
        startDate: formatValueStartDate,
        endDate: formatValueEndDate
      })}, []);

  const onFinish = (fieldsValue) => {
    const { username} = fieldsValue;
      const paramStartDate = !isEmpty(dateSearch) ? `startDate=${dateSearch.startDate}` : "";
      const paramEndDate = !isEmpty(dateSearch) ? `&endDate=${dateSearch.endDate}` : "";
      const paramUsername = !isEmpty(username) ? `&username=${username}` : "";
      history.push(
        `${dashboardRoute}/tasklogs?${paramStartDate}${paramEndDate}${paramUsername}`
      );
  };

  return (
    <LayoutWrapper>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{messages["page.TaskLog.title"]} | HLS</title>
      </Helmet>
      <PageHeader>{messages["page.TaskLog.title"]}</PageHeader>
      <LayoutContent>
        <TaskLogStyle>
          <Formik
            onSubmit={onFinish}
            initialValues={initialValues}
            enableReinitialize={true}
          >
            {({ values, setFieldValue }) => {
              return (
                <Form colon={false} className="mb-5 hls-form" labelAlign="left">
                  <Row justify="start">
                    <Col xs={24} lg={{ span: 17, offset: 1 }}>
                      <Row>
                        <Col {...colLeft}>
                          <Form.Item
                              {...itemLeftLabel}
                              label="検索日"
                              name="registerDate"
                          >
                            <RangePicker
                                format={DATE_FORMAT}
                                name="registerDate"
                                className="rangeDatePicker"
                                onChange={handleDateFieldChange}
                            />
                          </Form.Item>
                        </Col>
                        <Col {...colLeft}>
                          <Form.Item
                            {...itemRightLabel}
                            label={messages["page.taskLog.user"]}
                            name="username"
                          >
                            <Select
                                name="username"
                                showSearch
                                filterOption={(input, option) =>
                                    option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={handleUserNameChange(
                                    setFieldValue,
                                    "username"
                                )}
                            >
                              <Select.Option value={null}>
                                {messages["input.select.all"]}
                              </Select.Option>
                              {userAssigneeList.map((select) => (
                                  <Select.Option
                                      key={select.accountId}
                                      value={select.displayName}
                                      label={select.displayName}
                                  >
                                    {select.displayName}
                                  </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={24} lg={{ span: 5 }}>
                      <Space
                        direction="vertical"
                        className="space-btn"
                        align="end"
                        size={24}
                      >
                        <Button
                          className="button"
                          type={"primary"}
                          htmlType="submit"
                          block
                        >
                          {messages["page.Account.buttonSearch"]}
                        </Button>
                      </Space>
                    </Col>
                  </Row>
                </Form>
              );
            }}
          </Formik>
          <Row justify="center">
            <Col xs={24} md={24} xl={22}>
              <Table
                bordered
                loading={loading}
                rowKey={(record, idx) => idx}
                columns={tableColumnData(
                  messages,
                  search ? location : { startDate: todayDate },
                  dashboardRoute
                )}
                dataSource={taskLogs}
                scroll={{ x: "max-content" }}
                pagination={false}
              />
            </Col>
          </Row>
        </TaskLogStyle>
      </LayoutContent>
    </LayoutWrapper>
  );
};

const initialValueRequest = {
  list_role: `${ROLES.ADMIN},${ROLES.SUBADMIN},${ROLES.USER}`,
  sort_by: "display_name",
};

export const colLeft = {
  xs: { span: 24, offset: 0 },
  lg: { span: 10, offset: 0 },
};

export const colRight = {
  xs: { span: 24, offset: 0 },
  lg: { span: 8, offset: 0 },
};

export const colCenter = {
  xs: { span: 24, offset: 0 },
  lg: { span: 8, offset: 0 },
};

export const colFull = {
  xs: { span: 24, offset: 0 },
  lg: { span: 24, offset: 0 },
};

export const itemFullLabel = {
  labelCol: { lg: 2, xs: 24 },
  wrapperCol: { lg: 22, xs: 24 },
};

export const itemLeftLabel = {
  labelCol: { lg: 6, xs: 24 },
  wrapperCol: { lg: 12, xs: 24 },
};

export const itemRightLabel = {
  labelCol: { lg: { span: 8, offset: 4 }, xs: 24 },
  wrapperCol: { lg: 12, xs: 24 },
};

const initialValueDefault = {
  username: "",
};
const initialDateSearch = {
  startDate: "",
  endDate: "",
}

export default TaskLog;
