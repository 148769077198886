import { all, takeLatest, takeEvery, put, fork, call } from "redux-saga/effects";

import { apiCrawlOptionService } from "@iso/services";
import actions from "@iso/redux/apiCrawlOption/actions";

function* getListMeal() {
  yield takeLatest(actions.GET_LIST_MEAL_REQUEST, function* ({ payload }) {
    const {
      storeId,
      otaId,
      fieldKey
    } = payload;
    try {
      const response = yield call(apiCrawlOptionService.getListCrawlOption, {
        storeId,
        otaId,
        fieldKey
      });
      
      yield put({
        type: actions.GET_LIST_MEAL_SUCCESS,
        listMeals: response.listCrawlOption
        ? response.listCrawlOption
        : [],
        statusMeal: response.statusCrawlOption ? response.statusCrawlOption : null,
      });
    } catch (e) {
      yield put({
        type: actions.GET_LIST_MEAL_ERROR,
        error: e.message,
      });
    }
  });
}

function* getListFeature() {
  yield takeLatest(actions.GET_LIST_FEATURE_REQUEST, function* ({ payload }) {
    const {
      storeId,
      otaId,
      fieldKey
    } = payload;
    try {
      const response = yield call(apiCrawlOptionService.getListCrawlOption, {
        storeId,
        otaId,
        fieldKey
      });
      
      yield put({
        type: actions.GET_LIST_FEATURE_SUCCESS,
        listFeatures: response.listCrawlOption
        ? response.listCrawlOption
        : [],
        statusFeature: response.statusCrawlOption ? response.statusCrawlOption : null,
      });
    } catch (e) {
      yield put({
        type: actions.GET_LIST_FEATURE_ERROR,
        error: e.message,
      });
    }
  });
}

function* getListOptional() {
  yield takeLatest(actions.GET_LIST_OPTIONAL_REQUEST, function* ({ payload }) {
    const {
      storeId,
      otaId,
      fieldKey
    } = payload;
    try {
      const response = yield call(apiCrawlOptionService.getListCrawlOption, {
        storeId,
        otaId,
        fieldKey
      });
      
      yield put({
        type: actions.GET_LIST_OPTIONAL_SUCCESS,
        listOptionals: response.listCrawlOption
        ? response.listCrawlOption
        : [],
        statusOptional: response.statusCrawlOption ? response.statusCrawlOption : null,
      });
    } catch (e) {
      yield put({
        type: actions.GET_LIST_OPTIONAL_ERROR,
        error: e.message,
      });
    }
  });
}

export function* crawlDataMeal() {
  yield takeLatest(actions.CRAWL_DATA_MEAL_REQUEST, function* ({payload}) {
    const { data, resolve, reject } = payload;
    try {
      const response = yield call(apiCrawlOptionService.crawlDataOption, {
        ...data
      });
      yield put({
        type: actions.CRAWL_DATA_MEAL_SUCCESS,
      });
      yield resolve(response)
    } catch (e) {
      yield put({
        type: actions.CRAWL_DATA_MEAL_ERROR,
      });
      yield reject(e)
    }
  })
}

export function* crawlDataFeature() {
  yield takeLatest(actions.CRAWL_DATA_FEATURE_REQUEST, function* ({payload}) {
    const { data, resolve, reject } = payload;
    try {
      const response = yield call(apiCrawlOptionService.crawlDataOption, {
        ...data
      });
      yield put({
        type: actions.CRAWL_DATA_FEATURE_SUCCESS,
      });
      yield resolve(response)
    } catch (e) {
      yield put({
        type: actions.CRAWL_DATA_FEATURE_ERROR,
      });
      yield reject(e)
    }
  })
}

export function* crawlDataOptional() {
  yield takeLatest(actions.CRAWL_DATA_OPTIONAL_REQUEST, function* ({payload}) {
    const { data, resolve, reject } = payload;
    try {
      const response = yield call(apiCrawlOptionService.crawlDataOption, {
        ...data
      });
      yield put({
        type: actions.CRAWL_DATA_OPTIONAL_SUCCESS,
      });
      yield resolve(response)
    } catch (e) {
      yield put({
        type: actions.CRAWL_DATA_OPTIONAL_ERROR,
      });
      yield reject(e)
    }
  })
}

export default function* rootSaga() {
  yield all([
      fork(getListMeal),
      fork(getListFeature),
      fork(getListOptional),
      fork(crawlDataMeal),
      fork(crawlDataFeature),
      fork(crawlDataOptional),
  ]);
}
