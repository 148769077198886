import actions from "./actions";

const initialState = {
  accounts: [],
  account: {},
  total: 0,
  loading: true,
  error: null,
  chains: [],
  stores: [],
  loadingTeamAchievement: false,
  loadingTeamContentAchievement: false,
  teamAchievement: [],
  teamContentAchievement: [],
};

export default function accountReducer(state = initialState, action) {
  switch (action.type) {
    case actions.GET_ACCOUNTS_REQUEST:
      return {
        ...state,
        accounts: [],
        account: {},
        loading: true,
      };
    case actions.GET_ACCOUNTS_SUCCESS:
      return {
        ...state,
        accounts: action.accounts,
        total: action.total,
        loading: false,
      };
    case actions.GET_ACCOUNTS_ERROR:
      return {
        ...state,
        accounts: [],
        total: 0,
        loading: false,
        error: action.error,
      };
    case actions.GET_ACCOUNT_SUCCESS: {
      return {
        ...state,
        account: action.account,
      };
    }
    case actions.GET_CHAINS_SUCCESS: {
      return {
        ...state,
        chains: action.chains,
      };
    }
    case actions.GET_STORES_SUCCESS: {
      return {
        ...state,
        stores: action.stores,
      };
    }
    case actions.ADD_ACCOUNT_SUCCESS: {
      return {
        ...state,
        account: action.account,
      };
    }
    case actions.EDIT_ACCOUNT_SUCCESS: {
      return {
        ...state,
        account: action.account,
      };
    }
    case actions.RESET_ACCOUNTS_STORE: {
      return initialState;
    }

    case actions.GET_TEAM_ACHIEVEMENT_REQUEST:
      return {
        ...state,
        loadingTeamAchievement: true,
      };
    case actions.GET_TEAM_ACHIEVEMENT_SUCCESS:
      return {
        ...state,
        teamAchievement: action.teamAchievement,
        total: action.total,
        loadingTeamAchievement: false,
      };
    case actions.GET_TEAM_ACHIEVEMENT_ERROR:
      return {
        ...state,
        loadingTeamAchievement: false,
        error: action.error,
      };

    case actions.GET_TEAM_CONTENT_ACHIEVEMENT_REQUEST:
      return {
        ...state,
        loadingTeamContentAchievement: true,
      };
    case actions.GET_TEAM_CONTENT_ACHIEVEMENT_SUCCESS:
      return {
        ...state,
        teamContentAchievement: action.teamContentAchievement,
        total: action.total,
        loadingTeamContentAchievement: false,
      };
    case actions.GET_TEAM_CONTENT_ACHIEVEMENT_ERROR:
      return {
        ...state,
        loadingTeamContentAchievement: false,
        error: action.error,
      };
    case actions.RESET_TABLE:
      return {
        ...state,
        total: 0,
        teamContentAchievement: [],
      };
    case actions.RESET_TEAM_ACHIEVEMENT_TABLE:
      return {
        ...state,
        total: 0,
        teamAchievement: [],
      };
    default:
      return state;
  }
}
