import React, { useState, useEffect, useCallback } from "react";
import { Radio } from "antd";
import { Form, InputNumber } from "formik-antd";
import CheckBoxOnly from "./CheckBoxOnly";
import { useIntl } from "react-intl";
import { isEmpty } from "lodash";
import { ValidateField } from "@iso/validates/ValidateField";
import InputSettingPrice from "./InputSettingPrice";

const TableSettingPriceByPerson = ({
    values,
    setFieldValue,
    tabKey,
    isHoliday,
    disabledMode,
    apiRoomId
}) => {
    const { messages } = useIntl();

    const [activeJuniorHigh, setActiveJuniorHigh] = useState(messages["page.room.moneyRegister.percent"]);
    const [activeJuniorLow, setActiveJuniorLow] = useState(messages["page.room.moneyRegister.percent"]);
    const [activeInfrantMealPallet, setActiveInfrantMealPallet] = useState(messages["page.room.moneyRegister.percent"]);
    const [activeInfrantMealOnly, setActiveInfrantMealOnly] = useState(messages["page.room.moneyRegister.percent"]);
    const [activeInfrantPalletOnly, setActiveInfrantPalletOnly] = useState(messages["page.room.moneyRegister.percent"]);
    const [activeInfrantNoMealPallet, setActiveInfrantNoMealPallet] = useState(messages["page.room.moneyRegister.percent"]);
    const [activeInfrantBreakfastType, setActiveInfrantBreakfastType] = useState(null);
    const [activeInfrantDinnerType, setActiveInfrantDinnerType] = useState(null);
    const [activeMeal, setActiveMeal] = useState(true);

    useEffect(() => {
        if (values != undefined
            && values != null
            && values.apiRoomOtas != null
            && values.apiRoomOtas != undefined
            && values.apiRoomOtas[tabKey] != null
            && values.apiRoomOtas[tabKey] != undefined
            && !isEmpty(values.apiRoomOtas[tabKey])
            && !isEmpty(values.apiRoomOtas[tabKey].fieldContents)
            && !isEmpty(values.apiRoomOtas[tabKey].fieldContents.moneyRegister)
        ) {
            setActiveJuniorHigh(values.apiRoomOtas[tabKey].fieldContents.moneyRegister.juniorHighAcceptDate ?? messages["page.room.moneyRegister.percent"])
            setActiveJuniorLow(values.apiRoomOtas[tabKey].fieldContents.moneyRegister.juniorLowAcceptDate ?? messages["page.room.moneyRegister.percent"])

            setActiveInfrantMealPallet(values.apiRoomOtas[tabKey].fieldContents.moneyRegister.infrantMealPalletAcceptDate ?? messages["page.room.moneyRegister.percent"])
            setActiveInfrantMealOnly(values.apiRoomOtas[tabKey].fieldContents.moneyRegister.infrantMealOnlyAcceptDate ?? messages["page.room.moneyRegister.percent"])

            setActiveInfrantPalletOnly(values.apiRoomOtas[tabKey].fieldContents.moneyRegister.infrantPalletOnlyAcceptDate ?? messages["page.room.moneyRegister.percent"])
            setActiveInfrantNoMealPallet(values.apiRoomOtas[tabKey].fieldContents.moneyRegister.infrantNoMealPalletAcceptDate ?? messages["page.room.moneyRegister.percent"])

            setActiveInfrantBreakfastType(values.apiRoomOtas[tabKey].fieldContents.moneyRegister.infrantBreakfastType)
            setActiveInfrantDinnerType(values.apiRoomOtas[tabKey].fieldContents.moneyRegister.infrantDinnerType)
        }
    }, [values])

    const handleChangeRadio = (e, name, stateSetter) => {
        setFieldValue(name, e.target.value);
        stateSetter(e.target.value);
    };

    const onChangeInfrantMealPallet = useCallback((e, name) => {
        const value = e.target.value;
        setActiveMeal(true)

        if (value == messages["page.room.moneyRegister.notAcceptable"]
            && activeInfrantMealOnly == messages["page.room.moneyRegister.notAcceptable"]
        ){
            setActiveMeal(false)
        }

        handleChangeRadio(e, name, setActiveInfrantMealPallet)
    }, [activeInfrantMealOnly]);

    const onChangeInfrantMealOnly = useCallback((e, name) => {
        const value = e.target.value;
        setActiveMeal(true)

        if (value == messages["page.room.moneyRegister.notAcceptable"]
            && activeInfrantMealPallet == messages["page.room.moneyRegister.notAcceptable"]
        ){
            setActiveMeal(false)
        }

        handleChangeRadio(e, name, setActiveInfrantMealOnly)
    }, [activeInfrantMealPallet]);

    const onChangeJuniorHigh = (e, name) => handleChangeRadio(e, name, setActiveJuniorHigh);
    const onChangeJuniorLow = (e, name) => handleChangeRadio(e, name, setActiveJuniorLow);
    const onChangeInfrantPalletOnly = (e, name) => handleChangeRadio(e, name, setActiveInfrantPalletOnly);
    const onChangInfrantNoMealPallet = (e, name) => handleChangeRadio(e, name, setActiveInfrantNoMealPallet);
    const onChangeInfrantBreakfastType = (e, name) => handleChangeRadio(e, name, setActiveInfrantBreakfastType);
    const onChangeInfrantDinnerType = (e, name) => handleChangeRadio(e, name, setActiveInfrantDinnerType);

    return (
        <table
            border="1"
            cellPadding="0"
            cellSpacing="0"
            width="90%"
        >
            <tbody>
                <tr
                    align="center"
                    bgcolor="#EDEDED"
                    className="kihon_c"
                >
                    <td colSpan="2">
                        <span>{messages["page.room.moneyRegister.child"]}</span>
                    </td>
                    <td width="45">
                        <span>{messages["page.room.moneyRegister.capacity"]}</span>
                    </td>
                    <td width="100">
                        <span>{messages["page.room.moneyRegister.meal"]}</span>
                    </td>
                    <td width="301">
                        <span>{messages["page.room.moneyRegister.setting"]}</span>
                    </td>
                    <td width="93">
                        <span>{messages["page.room.moneyRegister.priceCalculationNumber"]}</span>
                    </td>
                </tr>
                <tr>
                    <td
                        align="center"
                        bgcolor="#FFEFBB"
                        className="kihon_c"
                        rowSpan="2"
                        width="50"
                    >
                        <span>{messages["page.room.moneyRegister.elementarySchoolStudent"]}</span>
                    </td>
                    <td
                        className="kihon_c"
                        width="85"
                    >
                        <span>{messages["page.room.moneyRegister.upperGrade"]}</span>
                    </td>
                    <td
                        align="center"
                        className="kihon_c"
                        width="36"
                    >
                        <span>{messages["page.room.moneyRegister.contains"]}</span>
                    </td>
                    <td
                        align="center"
                        className="kihon_c"
                        rowSpan="2"
                        width="100"
                    >
                        <span>{messages["page.room.moneyRegister.sameAsAdult"]}</span>
                    </td>
                    <td nowrap="">
                        <table className="kihon_c" style={{ border: "none" }}>
                            <tbody>
                                <tr>
                                    <td
                                        rowSpan="2"
                                        width="50"
                                    >
                                        <InputSettingPrice
                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['juniorHighRate']`}
                                            valueInput={activeJuniorHigh}
                                            maxValueCondition={activeJuniorHigh == messages["page.room.moneyRegister.percent"]}
                                            disabledMode={disabledMode}
                                            setFieldValue={setFieldValue}
                                        />
                                    </td>
                                    <td width="54">
                                        <Form.Item
                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['juniorHighAcceptDate']`}
                                        >
                                            <Radio.Group
                                                onChange={(e) => onChangeJuniorHigh(e, `apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['juniorHighAcceptDate']`)}
                                                value={activeJuniorHigh}
                                                name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['juniorHighAcceptDate']`}
                                                disabled={disabledMode}
                                            >
                                                <Radio value={messages["page.room.moneyRegister.percent"]}>
                                                    <span>{messages["page.room.moneyRegister.percent"]}</span>
                                                </Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </td>
                                    <td
                                        nowrap=""
                                        rowSpan="2"
                                    >
                                        <Form.Item
                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['juniorHighAcceptDate']`}
                                        >
                                            <Radio.Group
                                                onChange={(e) => onChangeJuniorHigh(e, `apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['juniorHighAcceptDate']`)}
                                                value={activeJuniorHigh}
                                                name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['juniorHighAcceptDate']`}
                                                disabled={disabledMode}
                                            >
                                                <Radio value={messages["page.room.moneyRegister.sameAsAdult"]}>
                                                    <span>{messages["page.room.moneyRegister.sameAsAdult"]}</span>
                                                </Radio>
                                                <Radio value={messages["page.room.moneyRegister.notAcceptable"]}>
                                                    <span>{messages["page.room.moneyRegister.notAcceptable"]}</span>
                                                </Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Form.Item
                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['juniorHighAcceptDate']`}
                                        >
                                            <Radio.Group
                                                onChange={(e) => onChangeJuniorHigh(e, `apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['juniorHighAcceptDate']`)}
                                                value={activeJuniorHigh}
                                                name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['juniorHighAcceptDate']`}
                                                disabled={disabledMode}
                                            >
                                                <Radio value={messages["page.room.moneyRegister.yen"]}>
                                                    <span>{messages["page.room.moneyRegister.yen"]}</span>
                                                </Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                    <td
                        className="kihon_c"
                        width="83"
                    >
                        <div className="flex-group-checkbox">
                            <CheckBoxOnly
                                label={messages["page.room.moneyRegister.include"]}
                                name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['juniorHightMoney']`}
                                holidayCondition={false}
                                isHoliday={isHoliday}
                                setFieldValue={setFieldValue}
                                disabledMode={disabledMode}
                            />
                        </div>
                    </td>
                </tr>
                <tr>
                    <td
                        className="kihon_c"
                        width="85"
                    >
                        <span>{messages["page.room.moneyRegister.lowerGrades"]}</span>
                    </td>
                    <td
                        align="center"
                        className="kihon_c"
                        width="36"
                    >
                        <span>{messages["page.room.moneyRegister.contains"]}</span>
                    </td>
                    <td nowrap="">
                        <table className="kihon_c" style={{ border: "none" }}>
                            <tbody>
                                <tr>
                                    <td
                                        rowSpan="2"
                                        width="50"
                                    >
                                        <InputSettingPrice
                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['juniorLowRate']`}
                                            valueInput={activeJuniorLow}
                                            maxValueCondition={activeJuniorLow == messages["page.room.moneyRegister.percent"]}
                                            disabledMode={disabledMode}
                                            setFieldValue={setFieldValue}
                                        />
                                    </td>
                                    <td width="54">
                                        <Form.Item
                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['juniorLowAcceptDate']`}
                                        >
                                            <Radio.Group
                                                onChange={e => onChangeJuniorLow(e, `apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['juniorLowAcceptDate']`)}
                                                value={activeJuniorLow}
                                                name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['juniorLowAcceptDate']`}
                                                disabled={disabledMode}
                                            >
                                                <Radio value={messages["page.room.moneyRegister.percent"]}>
                                                    <span>{messages["page.room.moneyRegister.percent"]}</span>
                                                </Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </td>
                                    <td
                                        nowrap=""
                                        rowSpan="2"
                                    >
                                        <Form.Item
                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['juniorLowAcceptDate']`}
                                        >
                                            <Radio.Group
                                                onChange={e => onChangeJuniorLow(e, `apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['juniorLowAcceptDate']`)}
                                                value={activeJuniorLow}
                                                name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['juniorLowAcceptDate']`}
                                                disabled={disabledMode}
                                            >
                                                <Radio value={messages["page.room.moneyRegister.sameAsAdult"]}>
                                                    <span>{messages["page.room.moneyRegister.sameAsAdult"]}</span>
                                                </Radio>
                                                <Radio value={messages["page.room.moneyRegister.notAcceptable"]}>
                                                    <span>{messages["page.room.moneyRegister.notAcceptable"]}</span>
                                                </Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Form.Item
                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['juniorLowAcceptDate']`}
                                        >
                                            <Radio.Group
                                                onChange={e => onChangeJuniorLow(e, `apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['juniorLowAcceptDate']`)}
                                                value={activeJuniorLow}
                                                name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['juniorLowAcceptDate']`}
                                                disabled={disabledMode}
                                            >
                                                <Radio value={messages["page.room.moneyRegister.yen"]}>
                                                    <span>{messages["page.room.moneyRegister.yen"]}</span>
                                                </Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                    <td
                        className="kihon_c"
                        width="83"
                    >
                        <div className="flex-group-checkbox">
                            <CheckBoxOnly
                                label={messages["page.room.moneyRegister.include"]}
                                name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['juniorLowMoney']`}
                                holidayCondition={false}
                                isHoliday={isHoliday}
                                setFieldValue={setFieldValue}
                                disabledMode={disabledMode}
                            />
                        </div>
                    </td>
                </tr>
                <tr>
                    <td
                        align="center"
                        bgcolor="#FFEFBB"
                        className="kihon_c"
                        rowSpan="4"
                        width="50"
                    >
                        <span>{messages["page.room.moneyRegister.infant"]}</span>
                    </td>
                    <td
                        className="kihon_c"
                        width="85"
                    >
                        <span>{messages["page.room.moneyRegister.mealAndFutonIncluded"]}</span>
                    </td>
                    <td
                        align="center"
                        className="kihon_c"
                        width="36"
                    >
                        <span>{messages["page.room.moneyRegister.contains"]}</span>
                    </td>
                    <td
                        align="center"
                        className="kihon_c"
                        rowSpan="2"
                        width="100"
                    >
                        <div>
                            <span>{messages["page.room.moneyRegister.meal.breakfast"]}：</span>
                            <br />
                            <Form.Item
                                name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantBreakfastType']`}
                                validate={apiRoomId ? false : (
                                    (value) => ValidateField(
                                        value,
                                        messages["page.room.moneyRegister.meal.breakfast"],
                                        {
                                            required: messages["form.error.mixed.required"],
            
                                        },
                                        {
                                            required : activeMeal,
                                        }
                                    )
                                )}
                            >
                                <Radio.Group
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantBreakfastType']`}
                                    onChange={e => onChangeInfrantBreakfastType(e, `apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantBreakfastType']`)}
                                    value={activeInfrantBreakfastType}
                                    style={{ display: "flex" }}
                                    disabled={disabledMode}
                                >
                                    <Radio value={messages["page.room.moneyRegister.including"]}>
                                        <span>{messages["page.room.moneyRegister.including"]}</span>
                                    </Radio>
                                    <Radio value={messages["page.room.moneyRegister.another"]}>
                                        <span>{messages["page.room.moneyRegister.another"]}</span>
                                    </Radio>
                                </Radio.Group>
                            </Form.Item>
                        </div>
                        <div>
                            <span>{messages["page.room.moneyRegister.meal.dinner"]}：</span>
                            <br />
                            <Form.Item
                                name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantDinnerType']`}
                                validate={apiRoomId ? false : (
                                    (value) => ValidateField(
                                        value,
                                        messages["page.room.moneyRegister.meal.dinner"],
                                        {
                                            required: messages["form.error.mixed.required"],
            
                                        },
                                        {
                                            required : activeMeal,
                                        }
                                    )
                                )}
                            >
                                <Radio.Group
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantDinnerType']`}
                                    onChange={e => onChangeInfrantDinnerType(e, `apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantDinnerType']`)}
                                    value={activeInfrantDinnerType}
                                    style={{ display: "flex" }}
                                    disabled={disabledMode}
                                >
                                    <Radio value={messages["page.room.moneyRegister.including"]}>
                                        <span>{messages["page.room.moneyRegister.including"]}</span>
                                    </Radio>
                                    <Radio value={messages["page.room.moneyRegister.another"]}>
                                        <span>{messages["page.room.moneyRegister.another"]}</span>
                                    </Radio>
                                </Radio.Group>
                            </Form.Item>
                        </div>
                    </td>
                    <td nowrap="">
                        <table className="kihon_c" style={{ border: "none" }}>
                            <tbody>
                                <tr>
                                    <td
                                        rowSpan="2"
                                        width="50"
                                    >
                                        <InputSettingPrice
                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantMealPalletRate']`}
                                            valueInput={activeInfrantMealPallet}
                                            maxValueCondition={activeInfrantMealPallet == messages["page.room.moneyRegister.percent"]}
                                            disabledMode={disabledMode}
                                            setFieldValue={setFieldValue}
                                        />
                                    </td>
                                    <td width="54">
                                        <Form.Item
                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantMealPalletAcceptDate']`}
                                        >
                                            <Radio.Group
                                                onChange={e => onChangeInfrantMealPallet(e, `apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantMealPalletAcceptDate']`)}
                                                value={activeInfrantMealPallet}
                                                name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantMealPalletAcceptDate']`}
                                                disabled={disabledMode}
                                            >
                                                <Radio value={messages["page.room.moneyRegister.percent"]}>
                                                    <span>{messages["page.room.moneyRegister.percent"]}</span>
                                                </Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </td>
                                    <td
                                        nowrap=""
                                        rowSpan="2"
                                    >
                                        <Form.Item
                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantMealPalletAcceptDate']`}
                                        >
                                            <Radio.Group
                                                onChange={e => onChangeInfrantMealPallet(e, `apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantMealPalletAcceptDate']`)}
                                                value={activeInfrantMealPallet}
                                                name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantMealPalletAcceptDate']`}
                                                disabled={disabledMode}
                                            >
                                                <Radio value={messages["page.room.moneyRegister.sameAsAdult"]}>
                                                    <span>{messages["page.room.moneyRegister.sameAsAdult"]}</span>
                                                </Radio>
                                                <Radio value={messages["page.room.moneyRegister.notAcceptable"]}>
                                                    <span>{messages["page.room.moneyRegister.notAcceptable"]}</span>
                                                </Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Form.Item
                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantMealPalletAcceptDate']`}
                                        >
                                            <Radio.Group
                                                onChange={e => onChangeInfrantMealPallet(e, `apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantMealPalletAcceptDate']`)}
                                                value={activeInfrantMealPallet}
                                                name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantMealPalletAcceptDate']`}
                                                disabled={disabledMode}
                                            >
                                                <Radio value={messages["page.room.moneyRegister.yen"]}>
                                                    <span>{messages["page.room.moneyRegister.yen"]}</span>
                                                </Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                    <td
                        className="kihon_c"
                        width="83"
                    >
                        <div className="flex-group-checkbox">
                            <CheckBoxOnly
                                label={messages["page.room.moneyRegister.include"]}
                                name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantMealPalletMoney']`}
                                holidayCondition={false}
                                isHoliday={isHoliday}
                                setFieldValue={setFieldValue}
                                disabledMode={disabledMode}
                            />
                        </div>
                    </td>
                </tr>
                <tr>
                    <td
                        className="kihon_c"
                        width="85"
                    >
                        <span>{messages["page.room.moneyRegister.mealOnly"]}</span>
                    </td>
                    <td
                        align="center"
                        width="36"
                    >
                    </td>
                    <td nowrap="">
                        <table className="kihon_c" style={{ border: "none" }}>
                            <tbody>
                                <tr>
                                    <td
                                        rowSpan="2"
                                        width="50"
                                    >
                                        <InputSettingPrice
                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantMealOnlyRate']`}
                                            valueInput={activeInfrantMealOnly}
                                            maxValueCondition={activeInfrantMealOnly == messages["page.room.moneyRegister.percent"]}
                                            disabledMode={disabledMode}
                                            setFieldValue={setFieldValue}
                                        />
                                    </td>
                                    <td width="54">
                                        <Form.Item
                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantMealOnlyAcceptDate']`}
                                        >
                                            <Radio.Group
                                                onChange={e => onChangeInfrantMealOnly(e, `apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantMealOnlyAcceptDate']`)}
                                                value={activeInfrantMealOnly}
                                                name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantMealOnlyAcceptDate']`}
                                                disabled={disabledMode}
                                            >
                                                <Radio value={messages["page.room.moneyRegister.percent"]}>
                                                    <span>{messages["page.room.moneyRegister.percent"]}</span>
                                                </Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </td>
                                    <td
                                        nowrap=""
                                        rowSpan="2"
                                    >
                                        <Form.Item
                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantMealOnlyAcceptDate']`}
                                        >
                                            <Radio.Group
                                                onChange={e => onChangeInfrantMealOnly(e, `apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantMealOnlyAcceptDate']`)}
                                                value={activeInfrantMealOnly}
                                                name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantMealOnlyAcceptDate']`}
                                                disabled={disabledMode}
                                            >
                                                <Radio value={messages["page.room.moneyRegister.sameAsAdult"]}>
                                                    <span>{messages["page.room.moneyRegister.sameAsAdult"]}</span>
                                                </Radio>
                                                <Radio value={messages["page.room.moneyRegister.notAcceptable"]}>
                                                    <span>{messages["page.room.moneyRegister.notAcceptable"]}</span>
                                                </Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Form.Item
                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantMealOnlyAcceptDate']`}
                                        >
                                            <Radio.Group
                                                onChange={e => onChangeInfrantMealOnly(e, `apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantMealOnlyAcceptDate']`)}
                                                value={activeInfrantMealOnly}
                                                name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantMealOnlyAcceptDate']`}
                                                disabled={disabledMode}
                                            >
                                                <Radio value={messages["page.room.moneyRegister.yen"]}>
                                                    <span>{messages["page.room.moneyRegister.yen"]}</span>
                                                </Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                    <td
                        className="kihon_c"
                        width="83"
                    >
                        <div className="flex-group-checkbox">
                            <CheckBoxOnly
                                label={messages["page.room.moneyRegister.include"]}
                                name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantMealOnlyMoney']`}
                                holidayCondition={false}
                                isHoliday={isHoliday}
                                setFieldValue={setFieldValue}
                                disabledMode={disabledMode}
                            />
                        </div>
                    </td>
                </tr>
                <tr>
                    <td
                        className="kihon_c"
                        width="85"
                    >
                        <span>{messages["page.room.moneyRegister.futonOnly"]}</span>
                    </td>
                    <td
                        align="center"
                        className="kihon_c"
                        width="36"
                    >
                        <span>{messages["page.room.moneyRegister.contains"]}</span>
                    </td>
                    <td
                        align="center"
                        className="kihon_c"
                        rowSpan="2"
                        width="100"
                    >
                        <span>{messages["page.room.moneyRegister.none"]}</span>
                    </td>
                    <td nowrap="">
                        <table className="kihon_c" style={{ border: "none" }}>
                            <tbody>
                                <tr>
                                    <td
                                        rowSpan="2"
                                        width="50"
                                    >
                                        <InputSettingPrice
                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantPalletOnlyRate']`}
                                            valueInput={activeInfrantPalletOnly}
                                            maxValueCondition={activeInfrantPalletOnly == messages["page.room.moneyRegister.percent"]}
                                            disabledMode={disabledMode}
                                            setFieldValue={setFieldValue}
                                        />
                                    </td>

                                    <td width="54">
                                        <Form.Item
                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantPalletOnlyAcceptDate']`}
                                        >
                                            <Radio.Group
                                                onChange={e => onChangeInfrantPalletOnly(e, `apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantPalletOnlyAcceptDate']`)}
                                                value={activeInfrantPalletOnly}
                                                name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantPalletOnlyAcceptDate']`}
                                                disabled={disabledMode}
                                            >
                                                <Radio value={messages["page.room.moneyRegister.percent"]}>
                                                    <span>{messages["page.room.moneyRegister.percent"]}</span>
                                                </Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </td>
                                    <td
                                        nowrap=""
                                        rowSpan="2"
                                    >
                                        <Form.Item
                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantPalletOnlyAcceptDate']`}
                                        >
                                            <Radio.Group
                                                onChange={e => onChangeInfrantPalletOnly(e, `apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantPalletOnlyAcceptDate']`)}
                                                value={activeInfrantPalletOnly}
                                                name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantPalletOnlyAcceptDate']`}
                                                disabled={disabledMode}
                                            >
                                                <Radio value={messages["page.room.moneyRegister.sameAsAdult"]}>
                                                    <span>{messages["page.room.moneyRegister.sameAsAdult"]}</span>
                                                </Radio>
                                                <Radio value={messages["page.room.moneyRegister.notAcceptable"]}>
                                                    <span>{messages["page.room.moneyRegister.notAcceptable"]}</span>
                                                </Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Form.Item
                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantPalletOnlyAcceptDate']`}
                                        >
                                            <Radio.Group
                                                onChange={e => onChangeInfrantPalletOnly(e, `apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantPalletOnlyAcceptDate']`)}
                                                value={activeInfrantPalletOnly}
                                                name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantPalletOnlyAcceptDate']`}
                                                disabled={disabledMode}
                                            >
                                                <Radio value={messages["page.room.moneyRegister.yen"]}>
                                                    <span>{messages["page.room.moneyRegister.yen"]}</span>
                                                </Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                    <td
                        className="kihon_c"
                        width="83"
                    >
                        <div className="flex-group-checkbox">
                            <CheckBoxOnly
                                label={messages["page.room.moneyRegister.include"]}
                                name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantPalletOnlyMoney']`}
                                holidayCondition={false}
                                isHoliday={isHoliday}
                                setFieldValue={setFieldValue}
                                disabledMode={disabledMode}
                            />
                        </div>
                    </td>
                </tr>
                <tr>
                    <td
                        className="kihon_c"
                        width="85"
                    >
                        <span>{messages["page.room.moneyRegister.noMealsOrBeddingRequired"]}</span>
                    </td>
                    <td
                        align="center"
                        width="36"
                    >
                    </td>
                    <td nowrap="">
                        <table className="kihon_c" style={{ border: "none" }}>
                            <tbody>
                                <tr>
                                    <td
                                        rowSpan="2"
                                        width="50"
                                    >
                                        <InputSettingPrice
                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantNoMealPalletRate']`}
                                            valueInput={activeInfrantNoMealPallet}
                                            maxValueCondition={activeInfrantNoMealPallet == messages["page.room.moneyRegister.percent"]}
                                            disabledMode={disabledMode}
                                            setFieldValue={setFieldValue}
                                        />
                                    </td>
                                    <td width="54">
                                        <Form.Item
                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantNoMealPalletAcceptDate']`}
                                        >
                                            <Radio.Group
                                                name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantNoMealPalletAcceptDate']`}
                                                onChange={e => onChangInfrantNoMealPallet(e, `apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantNoMealPalletAcceptDate']`)}
                                                value={activeInfrantNoMealPallet}
                                                disabled={disabledMode}
                                            >
                                                <Radio value={messages["page.room.moneyRegister.percent"]}>
                                                    <span>{messages["page.room.moneyRegister.percent"]}</span>
                                                </Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </td>
                                    <td
                                        nowrap=""
                                        rowSpan="2"
                                    >
                                        <Form.Item
                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantNoMealPalletAcceptDate']`}
                                        >
                                            <Radio.Group
                                                name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantNoMealPalletAcceptDate']`}
                                                onChange={e => onChangInfrantNoMealPallet(e, `apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantNoMealPalletAcceptDate']`)}
                                                value={activeInfrantNoMealPallet}
                                                disabled={disabledMode}
                                            >
                                                <Radio value={messages["page.room.moneyRegister.sameAsAdult"]}>
                                                    <span>{messages["page.room.moneyRegister.sameAsAdult"]}</span>
                                                </Radio>
                                                <Radio value={messages["page.room.moneyRegister.notAcceptable"]}>
                                                    <span>{messages["page.room.moneyRegister.notAcceptable"]}</span>
                                                </Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Form.Item
                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantNoMealPalletAcceptDate']`}
                                        >
                                            <Radio.Group
                                                name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantNoMealPalletAcceptDate']`}
                                                onChange={e => onChangInfrantNoMealPallet(e, `apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantNoMealPalletAcceptDate']`)}
                                                value={activeInfrantNoMealPallet}
                                                disabled={disabledMode}
                                            >
                                                <Radio value={messages["page.room.moneyRegister.yen"]}>
                                                    <span>{messages["page.room.moneyRegister.yen"]}</span>
                                                </Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                    <td
                        className="kihon_c"
                        width="83"
                    >
                        <div className="flex-group-checkbox">
                            <CheckBoxOnly
                                label={messages["page.room.moneyRegister.include"]}
                                name={`apiRoomOtas[${tabKey}]['fieldContents']['moneyRegister']['infrantNoMealPalletMoney']`}
                                holidayCondition={false}
                                isHoliday={isHoliday}
                                setFieldValue={setFieldValue}
                                disabledMode={disabledMode}
                            />
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

export default TableSettingPriceByPerson;
