import React, { useState, useEffect, memo, useCallback } from "react";
import { useIntl } from "react-intl";
import { Col, Row, Button, Checkbox, Radio, Modal, Empty, message } from "antd";
import { Form, Input, Select, DatePicker, InputNumber } from "formik-antd";
import { LayoutContentWrapper } from "./PlanOta.styles";
import PropTypes from "prop-types";
import moment from "moment";
import {
    ALL,
    AUTO_COPY_STANDARD_RATE,
    CHECK_IN_BEFORE_TIME_HIDDEN_OPTION,
    DO_NOT_SET,
    ERROR_STATUS,
    fieldKeyIkkyuApiPlanOption,
    LIST_CODE_STATUS_COMMON_MESSAGE,
    LIST_CODE_STATUS_SHOW_MESSAGE,
    LIST_FIELD_KEY_CRAWL_OPTION,
    LIST_OPEN_SALE_STATUS,
    LIST_STATUS_CRAWL,
    LIST_STATUS_SALE_PROCESSING,
    LIST_STATUS_SYNC,
    MEAL_TYPE,
    MEAL_TYPE_CASE,
    SELECT,
    SET_IN_PLAN,
    TOTAL_NUMBER_SETTING,
    TYPE_ACTION_CRAWL,
    USED,
} from "@iso/constants/api.constant";
import {
    colFullApi,
    colLeft,
    colLeftInputWithLabelApi,
    itemCheckBoxApi,
    itemLeftLabel,
    itemLeftLabelFullApi
} from "@iso/assets/styles/form.style";
import { colRight } from "@iso/containers/TaskLog";
import { useDispatch, useSelector } from "react-redux";
import { ValidateField } from "@iso/validates/ValidateField";
import { DATE_FORMAT, searchSelect } from "@iso/constants/common.constant";
import { isEmpty } from "lodash";
import { MinusOutlined, PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import apiPlanAction from "@iso/redux/apiPlan/actions";
import UploadFileImageOta from "@iso/containers/ApiPlanAddAndEdit/extend-fields/UploadFileImageOta";
import apiCancelPolicyAction from "@iso/redux/apiCancelPolicy/actions";
import apiCrawlOptionAction from "@iso/redux/apiCrawlOption/actions";
import RoomIkkyuExtendFields from "@iso/containers/ApiPlanAddAndEdit/Components/Ikkyu/RoomIkkyuExtendFields";

const Ikkyu = ({
    tabKey,
    setFieldValue,
    setFieldTouched,
    apiPlanId,
    values,
    otaId,
    storeId,
    apiOptionValue,
    isCloneData,
    dataPlanOta,
    setEventKodomoSubmit,
    activeTabKey
}) => {
    const { messages } = useIntl();
    const { Option } = Select;
    const dispatch = useDispatch();

    const {
        ApiPlan: { loadingSyncStatus, syncPlanStatus },
        ApiCancelPolicy: { listCancelPolicy, statusCrawlCancelPolicy },
        ApiCrawlOption: {
            listMeals,
            listFeatures,
            listOptionals,
            statusMeal,
            statusFeature,
            statusOptional
        },
    } = useSelector((state) => state);

    const [optionValues, setOptionValues] = useState([])
    const [apiPlanOtaId, setApiPlanOtaId] = useState(null);
    const [packageExclusivePricingContentHeader, setPackageExclusivePricingContentHeader] = useState([]);
    const [packageExclusivePricingContentBody, setPackageExclusivePricingContentBody] = useState([]);
    const [questionSettingContent, setQuestionSettingContent] = useState([]);
    const [syncErrorContent, setSyncErrorContent] = useState([]);
    const [showLog, setShowLog] = useState(false);
    const [listMealData, setListMealData] = useState([]);
    const [isUpdateDataFromSite, setIsUpdateDataFromSite] = useState(false);
    const [isDisableButtonSync, setIsDisableButtonSync] = useState(false);
    const [isSyncDataToSite, setIsSyncDataToSite] = useState(false);

    const [ikkyuExclusiveValue, setIkkyuExclusiveValue] = useState([]);
    const [includePaypayValue, setIncludePaypayValue] = useState([]);
    const [advanceForDiamondMemberValue, setAdvanceForDiamondMemberValue] = useState([]);
    const [planAttributesValue, setPlanAttributesValue] = useState([]);
    const [amenityAndEquipmentValue, setAmenityAndEquipmentValue] = useState([]);
    const [usageCategoryValue, setUsageCategoryValue] = useState(ikkyuInitialValue.usageCategory);
    const [feeRegistrationUnitValue, setFeeRegistrationUnitValue] = useState(ikkyuInitialValue.feeRegistrationUnit);
    const [paymentMethodValue, setPaymentMethodValue] = useState(ikkyuInitialValue.paymentMethod);
    const [settingCheckinCheckoutTimeValue, setSettingCheckinCheckoutTimeValue] = useState(ikkyuInitialValue.settingCheckinCheckoutTime);
    const [mealCategoryValue, setMealCategoryValue] = useState(null);
    const [usagePackageCategoryValue, setUsagePackageCategoryValue] = useState(ikkyuInitialValue.usagePackageCategory);
    const [salePackageCategoryValue, setSalePackageCategoryValue] = useState(ikkyuInitialValue.salePackageCategory);
    const [packageExclusivePricingValue, setPackageExclusivePricingValue] = useState(ikkyuInitialValue.packageExclusivePricing);
    const [planInventoryTypeValue, setPlanInventoryTypeValue] = useState(ikkyuInitialValue.planInventoryType);
    const [limitedNightsTypeValue, setLimitedNightsTypeValue] = useState(ikkyuInitialValue.limitedNightsType);
    const [limitedRoomsTypeValue, setLimitedRoomsTypeValue] = useState(ikkyuInitialValue.limitedRoomsType);
    const [closingCheckinBeforeDateValue, setClosingCheckinBeforeDateValue] = useState(ikkyuInitialValue.closingCheckinBeforeDate);
    const [answerGroupHidden, setAnswerGroupHidden] = useState({
        answerGroup1: true,
        answerGroup2: false,
        answerGroup3: false,
        answerGroup4: false,
        answerGroup5: false,
    });

    const [answerTypeGroupValue, setAnswerTypeGroupValue] = useState({
        answerType1: ikkyuInitialValue.answerType1,
        answerType2: ikkyuInitialValue.answerType2,
        answerType3: ikkyuInitialValue.answerType3,
        answerType4: ikkyuInitialValue.answerType4,
        answerType5: ikkyuInitialValue.answerType5
    });

    const [answerClassificationGroupValue, setAnswerClassificationGroupValue] = useState({
        answerClassification1: ikkyuInitialValue.answerClassification1,
        answerClassification2: ikkyuInitialValue.answerClassification2,
        answerClassification3: ikkyuInitialValue.answerClassification3,
        answerClassification4: ikkyuInitialValue.answerClassification4,
        answerClassification5: ikkyuInitialValue.answerClassification5
    });

    useEffect(() => {
        if (!isEmpty(apiOptionValue)) {
            setOptionValues(apiOptionValue)
        }
    }, [apiOptionValue]);

    useEffect(() => {
        if (dataPlanOta &&
            dataPlanOta.otaFieldContent !== null && dataPlanOta.otaFieldContent !== undefined &&
            dataPlanOta.otaFieldContent[tabKey] !== null && dataPlanOta.otaFieldContent[tabKey] !== undefined &&
            Object.keys(dataPlanOta.otaFieldContent[tabKey]).length > 0
        ) {
            const ikkyuDataOta = dataPlanOta?.otaFieldContent[tabKey];
            setIkkyuExclusiveValue(ikkyuDataOta.ikkyuExclusive);
            setIncludePaypayValue(ikkyuDataOta.includePaypay);
            setAdvanceForDiamondMemberValue(ikkyuDataOta.advanceForDiamondMember);
            setPlanAttributesValue(ikkyuDataOta.planAttributes);
            setAmenityAndEquipmentValue(ikkyuDataOta.amenityAndEquipment);
            setUsageCategoryValue(ikkyuDataOta.usageCategory);
            setFeeRegistrationUnitValue(ikkyuDataOta.feeRegistrationUnit);
            setPaymentMethodValue(ikkyuDataOta.paymentMethod);
            setSettingCheckinCheckoutTimeValue(ikkyuDataOta.settingCheckinCheckoutTime);
            setMealCategoryValue(ikkyuDataOta.mealCategory);
            setUsagePackageCategoryValue(ikkyuDataOta.usagePackageCategory);
            setSalePackageCategoryValue(ikkyuDataOta.salePackageCategory);
            setPackageExclusivePricingValue(ikkyuDataOta.packageExclusivePricing);
            setPlanInventoryTypeValue(ikkyuDataOta.planInventoryType);
            setLimitedNightsTypeValue(ikkyuDataOta.limitedNightsType);
            setLimitedRoomsTypeValue(ikkyuDataOta.limitedRoomsType);
            setAnswerGroupHidden({
                answerGroup1: true,
                answerGroup2: (ikkyuDataOta.question2 != "") ? true : false,
                answerGroup3: (ikkyuDataOta.question3 != "") ? true : false,
                answerGroup4: (ikkyuDataOta.question4 != "") ? true : false,
                answerGroup5: (ikkyuDataOta.question5 != "") ? true : false,
            })

            setAnswerTypeGroupValue({
                answerType1: ikkyuDataOta.answerType1,
                answerType2: ikkyuDataOta.answerType2,
                answerType3: ikkyuDataOta.answerType3,
                answerType4: ikkyuDataOta.answerType4,
                answerType5: ikkyuDataOta.answerType5
            })

            setAnswerClassificationGroupValue({
                answerClassification1: ikkyuDataOta.answerClassification1,
                answerClassification2: ikkyuDataOta.answerClassification2,
                answerClassification3: ikkyuDataOta.answerClassification3,
                answerClassification4: ikkyuDataOta.answerClassification4,
                answerClassification5: ikkyuDataOta.answerClassification5
            })
        }
    }, [dataPlanOta]);

    useEffect(() => {
        if (!values.otaFieldContent[tabKey] || Object.keys(values.otaFieldContent[tabKey]).length === 0) {
            ikkyuInitialValue.otaId = otaId;
            values.otaFieldContent[tabKey] = ikkyuInitialValue;
            if (!apiPlanId) {
                setFieldValue(`otaFieldContent.${tabKey}`, ikkyuInitialValue);
            }
        } else {
            const ikkyuDataOta = values.otaFieldContent[tabKey];
            const parentFieldKey = `otaFieldContent.${tabKey}`;

            setApiPlanOtaId(ikkyuDataOta.apiPlanOtaId)
        }
    }, [values])

    useEffect(() => {
        const contentHeader = [];
        const contentBody = [];
        for (let i = 1; i <= 6; i++) {
            contentHeader.push(
                <th>{messages["page.apiPlanAddAndEdit.ikkyu.name"] + i}</th>
            )

            contentBody.push(
                <td>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.setting${i}Person`}
                        style={styleWidthSmallInputCommon}
                    >
                        <InputNumber
                            name={`otaFieldContent.${tabKey}.setting${i}Person`}
                            disabled={packageExclusivePricingValue != AUTO_COPY_STANDARD_RATE}
                        />
                    </Form.Item>
                </td>
            )
        }

        setPackageExclusivePricingContentHeader(contentHeader)
        setPackageExclusivePricingContentBody(contentBody)
    }, [packageExclusivePricingValue]);

    useEffect(() => {
        const questionSettingContent = [];
        for (let i = 1; i <= 5; i++) {
            const answerGroupKey = `answerGroup${i}`;
            const answerTypeKey = `answerType${i}`;
            const answerClassificationKey = `answerClassification${i}`;
            questionSettingContent.push(
                <Row justify="start" className="mb-25px">
                    <Col {...colFullApi}>
                        <Form.Item
                            name={`otaFieldContent.${tabKey}.answerGroup`}
                            label={messages["page.apiPlanAddAndEdit.ikkyu.answerGroup.title"] + "[" + i + "]"}
                            className="custom-label"
                            {...itemLeftLabelFullApi}
                        >

                            {(i > 1) && (
                                <Button
                                    type="primary"
                                    onClick={
                                        () => setAnswerGroupHidden((prev) => ({
                                            ...prev,
                                            [answerGroupKey]: !prev[answerGroupKey]
                                        }))
                                    }
                                    icon={answerGroupHidden[answerGroupKey] ? <MinusOutlined /> : <PlusOutlined />}
                                />
                            )}
                            {(answerGroupHidden[answerGroupKey]) && (
                                <>
                                    <Col>
                                        <div className="d-flex align-item-baseline">
                                            <span class="mr-1">{messages["page.apiPlanAddAndEdit.ikkyu.question.title"]}</span>
                                            <Form.Item
                                                wrapperCol={{ lg: 22, xs: 22 }}
                                                name={`otaFieldContent.${tabKey}.question${i}`}
                                            >
                                                <Input
                                                    name={`otaFieldContent.${tabKey}.question${i}`}
                                                    maxLength={100}
                                                />
                                            </Form.Item>
                                            <span class="mr-1">{messages["page.apiPlanAddAndEdit.ikkyu.question.note"]}</span>
                                        </div>
                                    </Col>

                                    <Col className="mt-1">
                                        <div className="d-flex align-item-baseline">
                                            <span class="mr-1">{messages["page.apiPlanAddAndEdit.ikkyu.answerGroup.answerMethod"]}</span>
                                            <Form.Item
                                                wrapperCol={{ lg: 22, xs: 22 }}
                                                name={`otaFieldContent.${tabKey}.answerType${i}`}
                                            >
                                                <Radio.Group
                                                    name={`otaFieldContent.${tabKey}.answerType${i}`}
                                                    onChange={(e) => {
                                                        handleChangeRadio(e, `answerType${i}`)
                                                        setAnswerTypeGroupValue((prev) => ({
                                                            ...prev,
                                                            [answerTypeKey]: e.target.value,
                                                        }))
                                                    }}
                                                    value={answerTypeGroupValue[answerTypeKey]}
                                                >
                                                    <Row>
                                                        <Radio value={messages["page.apiPlanAddAndEdit.ikkyu.answerGroup.freeText"]}>
                                                            <span style={{ marginLeft: 10, marginRight: 10 }}>
                                                                {messages["page.apiPlanAddAndEdit.ikkyu.answerGroup.freeText"]}
                                                            </span>
                                                        </Radio>

                                                        <Radio value={messages["page.apiPlanAddAndEdit.ikkyu.answerGroup.selection"]}>
                                                            <span style={{ marginLeft: 10, marginRight: 10 }}>
                                                                {messages["page.apiPlanAddAndEdit.ikkyu.answerGroup.selection"]}
                                                            </span>

                                                            {(answerTypeGroupValue[answerTypeKey] == SELECT) && (
                                                                <>
                                                                    <div className="d-flex align-item-baseline">
                                                                        <span class="mr-1">{messages["page.apiPlanAddAndEdit.ikkyu.answerForQuestion.title1"]}</span>
                                                                        <Form.Item
                                                                            wrapperCol={{ lg: 22, xs: 22 }}
                                                                            name={`otaFieldContent.${tabKey}.answer1ForQuestion${i}`}
                                                                        >
                                                                            <Input
                                                                                name={`otaFieldContent.${tabKey}.answer1ForQuestion${i}`}
                                                                                maxLength={50}
                                                                            />
                                                                        </Form.Item>
                                                                        <span class="mr-1">{messages["page.apiPlanAddAndEdit.ikkyu.answer.note"]}</span>
                                                                    </div>

                                                                    <div className="d-flex align-item-baseline mt-1">
                                                                        <span class="mr-1">{messages["page.apiPlanAddAndEdit.ikkyu.answerForQuestion.title2"]}</span>
                                                                        <Form.Item
                                                                            wrapperCol={{ lg: 22, xs: 22 }}
                                                                            name={`otaFieldContent.${tabKey}.answer2ForQuestion${i}`}
                                                                        >
                                                                            <Input
                                                                                name={`otaFieldContent.${tabKey}.answer2ForQuestion${i}`}
                                                                                maxLength={50}
                                                                            />
                                                                        </Form.Item>
                                                                        <span class="mr-1">{messages["page.apiPlanAddAndEdit.ikkyu.answer.note"]}</span>
                                                                    </div>

                                                                    <div className="d-flex align-item-baseline mt-1">
                                                                        <span class="mr-1">{messages["page.apiPlanAddAndEdit.ikkyu.answerForQuestion.title3"]}</span>
                                                                        <Form.Item
                                                                            wrapperCol={{ lg: 22, xs: 22 }}
                                                                            name={`otaFieldContent.${tabKey}.answer3ForQuestion${i}`}
                                                                        >
                                                                            <Input
                                                                                name={`otaFieldContent.${tabKey}.answer3ForQuestion${i}`}
                                                                                maxLength={50}
                                                                            />
                                                                        </Form.Item>
                                                                        <span class="mr-1">{messages["page.apiPlanAddAndEdit.ikkyu.answer.note"]}</span>
                                                                    </div>

                                                                    <div className="d-flex align-item-baseline mt-1">
                                                                        <span class="mr-1">{messages["page.apiPlanAddAndEdit.ikkyu.answerForQuestion.title4"]}</span>
                                                                        <Form.Item
                                                                            wrapperCol={{ lg: 22, xs: 22 }}
                                                                            name={`otaFieldContent.${tabKey}.answer4ForQuestion${i}`}
                                                                        >
                                                                            <Input
                                                                                name={`otaFieldContent.${tabKey}.answer4ForQuestion${i}`}
                                                                                maxLength={50}
                                                                            />
                                                                        </Form.Item>
                                                                        <span class="mr-1">{messages["page.apiPlanAddAndEdit.ikkyu.answer.note"]}</span>
                                                                    </div>

                                                                    <div className="d-flex align-item-baseline mt-1">
                                                                        <span class="mr-1">{messages["page.apiPlanAddAndEdit.ikkyu.answerForQuestion.title5"]}</span>
                                                                        <Form.Item
                                                                            wrapperCol={{ lg: 22, xs: 22 }}
                                                                            name={`otaFieldContent.${tabKey}.answer5ForQuestion${i}`}
                                                                        >
                                                                            <Input
                                                                                name={`otaFieldContent.${tabKey}.answer5ForQuestion${i}`}
                                                                                maxLength={50}
                                                                            />
                                                                        </Form.Item>
                                                                        <span class="mr-1">{messages["page.apiPlanAddAndEdit.ikkyu.answer.note"]}</span>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </Radio>
                                                    </Row>
                                                </Radio.Group>
                                            </Form.Item>
                                        </div>
                                    </Col>

                                    <Col>
                                        <div className="d-flex align-item-baseline">
                                            <span class="mr-1">{messages["page.apiPlanAddAndEdit.ikkyu.answerGroup.answerCategory"]}</span>
                                            <Form.Item
                                                wrapperCol={{ lg: 22, xs: 22 }}
                                                name={`otaFieldContent.${tabKey}.answerClassification${i}`}
                                            >
                                                <Radio.Group
                                                    name={`otaFieldContent.${tabKey}.answerClassification${i}`}
                                                    onChange={(e) => {
                                                        handleChangeRadio(e, `answerClassification${i}`)
                                                        setAnswerClassificationGroupValue((prev) => ({
                                                            ...prev,
                                                            [answerClassificationKey]: e.target.value,
                                                        }))
                                                    }}
                                                    value={answerClassificationGroupValue[answerClassificationKey]}
                                                >
                                                    <Row>
                                                        <Radio value={messages["page.apiPlanAddAndEdit.ikkyu.answerGroup.optional"]}>
                                                            <span style={{ marginLeft: 10, marginRight: 10 }}>
                                                                {messages["page.apiPlanAddAndEdit.ikkyu.answerGroup.optional"]}
                                                            </span>
                                                        </Radio>

                                                        <Radio value={messages["page.apiPlanAddAndEdit.ikkyu.answerGroup.required"]}>
                                                            <span style={{ marginLeft: 10, marginRight: 10 }}>
                                                                {messages["page.apiPlanAddAndEdit.ikkyu.answerGroup.required"]}
                                                            </span>
                                                        </Radio>
                                                    </Row>
                                                </Radio.Group>
                                            </Form.Item>
                                        </div>
                                    </Col>
                                </>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
            )
        }

        setQuestionSettingContent(questionSettingContent)
    }, [answerGroupHidden, answerTypeGroupValue, answerClassificationGroupValue]);

    const handleChangeCheckbox = (nameField, valueField, field = null) => {
        setFieldValue(nameField, valueField)
    }

    const handleChangeRadio = (e, field = null, key = null) => {
        const nameField = e.target.name;
        const valueField = e.target.value;
        const parentFieldKey = `otaFieldContent.${tabKey}`;
        setFieldValue(nameField, valueField);

        if ((field == fieldKeyIkkyuApiPlanOption.usageCategory)
            && valueField != USED
        ) {
            setFieldValue(`${parentFieldKey}.rate`, null);
        }

        if ((field == fieldKeyIkkyuApiPlanOption.settingCheckinCheckoutTime)) {
            const dataValues = { ...values.otaFieldContent[tabKey] };
            dataValues[field] = valueField;

            const conditionValueField = (valueField != SET_IN_PLAN);
            const dataDefault = {
                checkinFrom: conditionValueField ? null : "15:00",
                checkinTo: conditionValueField ? null : "24:00",
                checkoutTime: conditionValueField ? null : "10:00"
            }

            const newDataOtaPlan = { ...dataValues, ...dataDefault }
            setFieldValue(parentFieldKey, newDataOtaPlan);
        }

        if ((field == fieldKeyIkkyuApiPlanOption.usagePackageCategory)) {
            const dataValues = { ...values.otaFieldContent[tabKey] };
            dataValues[field] = valueField;
            const conditionValueField = (valueField != USED);
            const dataDefault = {
                salePackageCategory: conditionValueField ? null : ALL,
                planPackageName: null,
                planPackagePR: null,
                planPackageDescription: null,
                breakfastFee: conditionValueField ? null : "",
                dinnerFee: 0,
                packageExclusivePricing: conditionValueField ? null : AUTO_COPY_STANDARD_RATE,
                setting1Person: 0,
                setting2Person: 0,
                setting3Person: 0,
                setting4Person: 0,
                setting5Person: 0,
                setting6Person: 0,
            }

            const newDataOtaPlan = { ...dataValues, ...dataDefault }
            setFieldValue(parentFieldKey, newDataOtaPlan);
        }

        if ((field == fieldKeyIkkyuApiPlanOption.packageExclusivePricing)) {
            const dataValues = { ...values.otaFieldContent[tabKey] };
            dataValues[field] = valueField;
            const dataDefault = {
                setting1Person: 0,
                setting2Person: 0,
                setting3Person: 0,
                setting4Person: 0,
                setting5Person: 0,
                setting6Person: 0,
            }

            const newDataOtaPlan = { ...dataValues, ...dataDefault }
            setFieldValue(parentFieldKey, newDataOtaPlan);
        }

        if (field = fieldKeyIkkyuApiPlanOption.mealCategory) {
            setFieldValue(`${parentFieldKey}.detailMeal`, []);
        }
    }

    const handleChangeSelect = (field, valueField) => {
        const parentFieldKey = `otaFieldContent.${tabKey}`;
        if ((field == fieldKeyIkkyuApiPlanOption.planInventoryType)) {
            setFieldValue(`${parentFieldKey}.numberOfRoom`, null);
        }

        if ((field == fieldKeyIkkyuApiPlanOption.limitedNightsType)) {
            setFieldValue(`${parentFieldKey}.rangeLimitedNightFrom`, null);
            setFieldValue(`${parentFieldKey}.rangeLimitedNightTo`, null);
        }

        if ((field == fieldKeyIkkyuApiPlanOption.limitedRoomsType)) {
            setFieldValue(`${parentFieldKey}.rangeRoomFrom`, null);
            setFieldValue(`${parentFieldKey}.rangeRoomTo`, null);
        }

        if ((field == fieldKeyIkkyuApiPlanOption.closingCheckinBeforeDate)) {
            setFieldValue(`${parentFieldKey}.closingCheckinBeforeTime`, null);
        }
    }

    const onChangeDate = (name, value) => {
        if (value) {
            setFieldValue(name, moment(value).format(DATE_FORMAT))
            setFieldTouched(name, false);
        } else {
            setFieldValue(name, '')
        }
    }

    const handleInputBlur = (event) => {
        const nameField = event.target.name;
        const valueField = event.target.value;
        const trimmedValue = trimSpaces(valueField);

        setFieldValue(nameField, null)
        if (trimmedValue !== "" || trimmedValue == undefined) {
            setFieldValue(nameField, trimmedValue)
        }
    };

    useEffect(() => {
        refreshSyncPlan();
    }, [dispatch])

    const trimSpaces = (inputString) => {
        if (typeof inputString !== 'string') {
            return '';
        }
        let elements = inputString.split(',');
        let trimmedElements = elements.map(element => element.trim());
        return trimmedElements.join(',');
    }

    useEffect(() => {
        if (syncPlanStatus !== null && syncPlanStatus !== undefined && !isCloneData && apiPlanOtaId) {
            for (let i = 0; i < syncPlanStatus.length; i++) {
                if (syncPlanStatus[i].otaId === tabKey) {
                    if (LIST_CODE_STATUS_SHOW_MESSAGE.includes(syncPlanStatus[i].codeStatus)) {
                        let syncErrorContentData = syncPlanStatus[i].syncErrorContent ?? '';
                        syncErrorContentData = syncPlanStatus[i]?.codeStatus === ERROR_STATUS.LOGIN_ERROR ? messages['page.apiPlanAddAndEdit.loginFail'] : syncErrorContentData;
                        if (syncErrorContentData !== '' && syncErrorContentData !== null && syncErrorContentData !== undefined) {
                            let syncErrorContentArray = syncErrorContentData.trim().split("\n").map(item => item.trim());
                            setSyncErrorContent(syncErrorContentArray);
                        } else {
                            setSyncErrorContent([])
                        }
                    } else if (LIST_CODE_STATUS_COMMON_MESSAGE.includes(syncPlanStatus[i].codeStatus)) {
                        setSyncErrorContent([messages['page.apiPlanAddAndEdit.somethingWrong']])
                    } else {
                        setSyncErrorContent([])
                    }
                    setFieldValue(`otaFieldContent.${tabKey}.syncStatus`, syncPlanStatus[i].syncStatus);
                    setFieldValue(`otaFieldContent.${tabKey}.updateStatus`, syncPlanStatus[i].updateStatus);
                    setFieldValue(`otaFieldContent.${tabKey}.openSaleStatus`, syncPlanStatus[i].openSaleStatus);
                }
            }
        }
    }, [syncPlanStatus])

    const refreshSyncPlan = () => {
        if (apiPlanId === null || apiPlanId === undefined) {
            return;
        }
        dispatch(apiPlanAction.getSyncPlanStatus({ apiPlanId }))
    }

    useEffect(() => {
        if (storeId !== null && storeId !== undefined && otaId !== null && otaId !== undefined) {
            dispatch(apiCancelPolicyAction.getListCancelPolicy({ storeId, otaId }));
            LIST_FIELD_KEY_CRAWL_OPTION.map((fieldKey) => {
                switch (fieldKey) {
                    case TYPE_ACTION_CRAWL.meal:
                        dispatch(apiCrawlOptionAction.getListMeal({ storeId, otaId, fieldKey }));
                        break;
                    case TYPE_ACTION_CRAWL.feature_settings:
                        dispatch(apiCrawlOptionAction.getListFeature({ storeId, otaId, fieldKey }));
                        break;
                    case TYPE_ACTION_CRAWL.optional_settings:
                        dispatch(apiCrawlOptionAction.getListOptional({ storeId, otaId, fieldKey }));
                        break;
                    default:
                        break;
                }

            })
        }
    }, [storeId, otaId])

    const crawlOption = (fieldKey) => {
        if (otaId === null || otaId === undefined) {
            Modal.error({
                title: messages["page.apiPlanAddAndEdit.modal.errorTitle"],
                content: messages["form.error.mixed.required"].replace(
                    "${path}",
                    messages["page.apiPlanAddAndEdit.otas"]
                ),
            });
            return;
        }
        if (storeId === null || storeId === undefined) {
            Modal.error({
                title: messages["page.apiPlanAddAndEdit.modal.errorTitle"],
                content: messages["form.error.mixed.required"].replace(
                    "${path}",
                    messages["page.apiPlanAddAndEdit.storeId"]
                ),
            });
            return;
        }

        let action;
        let successMessage;
        let failureMessage;
        switch (fieldKey) {
            case TYPE_ACTION_CRAWL.cancelPolicy:
                action = apiCancelPolicyAction.crawlDataCancelPolicy;
                successMessage = messages["page.apiPlanAddAndEdit.crawlCancelPolicy.success"];
                failureMessage = messages[`page.apiPlanAddAndEdit.crawlCancelPolicy.failed`];
                break;
            case TYPE_ACTION_CRAWL.meal:
                action = apiCrawlOptionAction.crawlDataMeal;
                successMessage = messages["page.apiPlanAddAndEdit.crawlMeal.success"];
                failureMessage = messages[`page.apiPlanAddAndEdit.crawlMeal.failed`];
                break;
            case TYPE_ACTION_CRAWL.feature_settings:
                action = apiCrawlOptionAction.crawlDataFeature;
                successMessage = messages["page.apiPlanAddAndEdit.featureSetting.success"];
                failureMessage = messages[`page.apiPlanAddAndEdit.featureSetting.failed`];
                break;
            case TYPE_ACTION_CRAWL.optional_settings:
                action = apiCrawlOptionAction.crawlDataOptional;
                successMessage = messages["page.apiPlanAddAndEdit.optionalSetting.success"];
                failureMessage = messages[`page.apiPlanAddAndEdit.optionalSetting.failed`];
                break;
            default:
                break;
        }

        new Promise((resolve, reject) => {
            return dispatch(action({ data: { storeId, otaId, fieldKey }, resolve, reject }))
        })
            .then(() => message.success(successMessage))
            .catch((e) => {
                const messageError = e.response?.status === ERROR_STATUS.IN_PROGRESS_SYNC
                    ? messages[`page.apiPlanAddAndEdit.inProgressSyncError`]
                    : failureMessage;
                message.error(messageError);
            });
    }

    const syncDataToSite = useCallback(() => {
        new Promise((resolve, reject) => {
            setIsSyncDataToSite(true)
            return dispatch(apiPlanAction.syncDataPlanToSite({ apiPlanOtaId, resolve, reject }));
        }).then(() => {
            Modal.success({
                title: messages["page.apiPlanAddAndEdit.modal.addSuccessTitle"],
                content: messages[`page.apiPlanAddAndEdit.updateSuccess`],
            });
        }).catch((e) => {
            let messageError = e.response != null && e.response.status === ERROR_STATUS.IN_PROGRESS_SYNC
                ? messages[`page.apiPlanAddAndEdit.inProgressSyncError`]
                : messages[`page.apiPlanAddAndEdit.updateError`]
            Modal.error({
                title: messages["page.apiPlanAddAndEdit.modal.errorTitle"],
                content: messageError,
            });
        }).finally(() => {
            setIsSyncDataToSite(false)
        })
    });

    const updateDataFromSite = useCallback(() => {
        new Promise((resolve, reject) => {
            setIsUpdateDataFromSite(true)
            return dispatch(apiPlanAction.updateDataFromSite({ apiPlanOtaId, resolve, reject }));
        }).then(() => {
            Modal.success({
                title: messages["page.apiPlan.modal.updateSuccessTitle"],
                content: messages[`page.apiPlan.modal.updateSuccess`],
            });
        }).catch((e) => {
            let messageError = e.response != null && e.response.status === ERROR_STATUS.IN_PROGRESS_SYNC
                ? messages[`page.apiPlan.modal.inProgressSyncError`]
                : messages[`page.apiPlan.modal.updateError`]
            Modal.error({
                title: messages["page.apiPlan.modal.errorTitle"],
                content: messageError,
            });
        }).finally(() => {
            setIsUpdateDataFromSite(false)
        })
    });

    const refreshOption = (fieldKey) => {
        if (otaId === null || otaId === undefined) {
            return;
        }
        if (storeId === null || storeId === undefined) {
            return;
        }
        switch (fieldKey) {
            case TYPE_ACTION_CRAWL.cancelPolicy:
                dispatch(apiCancelPolicyAction.getListCancelPolicy({ storeId, otaId }))
                break;
            case TYPE_ACTION_CRAWL.meal:
                dispatch(apiCrawlOptionAction.getListMeal({ storeId, otaId, fieldKey }))
                break;
            case TYPE_ACTION_CRAWL.feature_settings:
                dispatch(apiCrawlOptionAction.getListFeature({ storeId, otaId, fieldKey }))
                break;
            case TYPE_ACTION_CRAWL.optional_settings:
                dispatch(apiCrawlOptionAction.getListOptional({ storeId, otaId, fieldKey }))
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        let typesToFilter = [];

        switch (mealCategoryValue) {
            case MEAL_TYPE_CASE.no_meals:
                typesToFilter = [MEAL_TYPE.SNACK];
                break;
            case MEAL_TYPE_CASE.breakfast_included:
                typesToFilter = [MEAL_TYPE.BREAKFAST, MEAL_TYPE.SNACK];
                break;
            case MEAL_TYPE_CASE.dinner_included:
                typesToFilter = [MEAL_TYPE.DINNER, MEAL_TYPE.SNACK];
                break;
            case MEAL_TYPE_CASE.breakfast_and_dinner_included:
                typesToFilter = [MEAL_TYPE.BREAKFAST, MEAL_TYPE.DINNER, MEAL_TYPE.SNACK];
                break;
            case MEAL_TYPE_CASE.breakfast_and_lunch_included:
                typesToFilter = [MEAL_TYPE.BREAKFAST, MEAL_TYPE.LUNCH, MEAL_TYPE.SNACK];
                break;
            case MEAL_TYPE_CASE.lunch_included:
                typesToFilter = [MEAL_TYPE.LUNCH, MEAL_TYPE.SNACK];
                break;
            case MEAL_TYPE_CASE.lunch_and_dinner_included:
                typesToFilter = [MEAL_TYPE.LUNCH, MEAL_TYPE.DINNER, MEAL_TYPE.SNACK];
                break;
            case MEAL_TYPE_CASE.all_three_meals_included:
                typesToFilter = [MEAL_TYPE.BREAKFAST, MEAL_TYPE.LUNCH, MEAL_TYPE.DINNER, MEAL_TYPE.SNACK];
                break;
            default:
                break;
        }

        const listMealDataFilter = typesToFilter.length
            ? listMeals.filter(meal => typesToFilter.includes(meal.valueType))
            : [];

        setListMealData(listMealDataFilter);
    }, [listMeals, mealCategoryValue])


    return (
        <LayoutContentWrapper>
            <Row justify="start" className="mb-25px">
                <Col xs={24} lg={{ span: 24 }}>
                    <Row>
                        <Col {...colLeft}>
                            <Form.Item
                                label={messages["page.apiPlanAddAndEdit.Rakuten.sitePlanId"]}
                                name={`otaFieldContent.${tabKey}.sitePlanId`}
                                {...itemLeftLabel}
                            >
                                <Input
                                    name={`otaFieldContent.${tabKey}.sitePlanId`}
                                    disabled={true}
                                />
                            </Form.Item>
                        </Col>
                        <Col {...colRight} style={{ display: 'flex', justifyContent: 'end' }}>
                            <Button
                                type="primary"
                                className="mr-10 mb-0 button"
                                block
                                hidden={apiPlanOtaId === null || apiPlanOtaId === undefined}
                                loading={isSyncDataToSite}
                                disabled={isDisableButtonSync}
                                onClick={syncDataToSite}
                            >
                                {messages["page.apiPlanAddAndEdit.buttonSync"]}
                            </Button>

                            <Button
                                type="primary"
                                className="mr-10 button"
                                block
                                hidden={apiPlanOtaId === null || apiPlanOtaId === undefined}
                                loading={isUpdateDataFromSite}
                                onClick={updateDataFromSite}
                            >
                                {messages["page.apiPlan.crawlUpdate"]}
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row justify="start" className="mb-25px">
                <Col xs={24} lg={12}>
                    <Form.Item
                        label={messages["page.apiPlanAddAndEdit.Jalan.syncStatus"]}
                        name={`otaFieldContent.${tabKey}.syncStatus`}
                        labelCol={{ lg: 8, xs: 24 }}
                        wrapperCol={{ lg: 12, xs: 24 }}
                    >
                        <Select
                            className="select"
                            name={`otaFieldContent.${tabKey}.syncStatus`}
                            disabled={true}
                        >
                            {LIST_STATUS_SYNC.map((select, index) => {
                                return (
                                    <Select.Option
                                        key={index}
                                        value={select.id}
                                    >
                                        {select.name}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} lg={12} className={'position-error-message'}>
                    <Row>
                        <Col xs={24} lg={2}>
                            <Button
                                type="primary"
                                className="mr-2"
                                loading={loadingSyncStatus}
                                icon={<ReloadOutlined />}
                                onClick={() => refreshSyncPlan()}
                            >
                            </Button>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                name={`otaFieldContent.${tabKey}.syncErrorContent`}
                            >
                                <Input
                                    className="styleInputLog"
                                    onClick={() => setShowLog(true)}
                                    name={`otaFieldContent.${tabKey}.syncErrorContent`}
                                    value={
                                        syncErrorContent !== null &&
                                            syncErrorContent !== undefined &&
                                            syncErrorContent.length > 0 ? syncErrorContent[0] : ''
                                    }
                                    readOnly
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row justify="start" className="mb-25px">
                <Col xs={24} lg={{ span: 24 }}>
                    <Form.Item
                        labelCol={{ lg: 4, xs: 24 }}
                        wrapperCol={{ lg: 6, xs: 24 }}
                        label={messages["page.apiPlan.ota.updateStatus"]}
                        name={`otaFieldContent.${tabKey}.updateStatus`}
                    >
                        <Select
                            className="select"
                            name={`otaFieldContent.${tabKey}.updateStatus`}
                            disabled={true}
                        >
                            {LIST_STATUS_SYNC.map((select, index) => {
                                return (
                                    <Select.Option
                                        key={index}
                                        value={select.id}
                                    >
                                        {select.name}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <Row justify="start" className="mb-25px">
                <Col xs={24} lg={{ span: 24 }}>
                    <Form.Item
                        labelCol={{ lg: 4, xs: 24 }}
                        wrapperCol={{ lg: 6, xs: 24 }}
                        label={messages["page.apiPlanAddAndEdit.openSale"]}
                        name={`otaFieldContent.${tabKey}.openSaleStatus`}
                    >
                        <Select
                            className="select"
                            name={`otaFieldContent.${tabKey}.openSaleStatus`}
                            disabled={true}
                        >
                            {LIST_STATUS_SALE_PROCESSING.map((select, index) => {
                                return (
                                    <Select.Option
                                        key={index}
                                        value={select.id}
                                    >
                                        {select.name}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <Row justify="start" className="mb-25px">
                <Col xs={24} lg={{ span: 24 }}>
                    <Form.Item
                        labelCol={{ lg: 4, xs: 24 }}
                        wrapperCol={{ lg: 6, xs: 24 }}
                        label={messages["page.crawlPlan.openSaleType"]}
                        name={`otaFieldContent.${tabKey}.openSaleType`}
                    >
                        <Select
                            className="select"
                            name={`otaFieldContent.${tabKey}.openSaleType`}
                            disabled={true}
                        >
                            {LIST_OPEN_SALE_STATUS.map((select, index) => {
                                return (
                                    <Select.Option
                                        key={index}
                                        value={select.id}
                                    >
                                        {select.name}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <Row justify="start" className="mb-25px">
                <Col xs={24} lg={{ span: 24 }}>
                    <Form.Item
                        labelCol={{ lg: 4, xs: 24 }}
                        wrapperCol={{ lg: 6, xs: 24 }}
                        label={
                            <div className="custom-required">
                                {messages["page.apiPlanAddAndEdit.ikkyu.planCode.title"]}
                            </div>
                        }
                        className="custom-label"
                        name={`otaFieldContent.${tabKey}.planCode`}
                        validate={(value) => ValidateField(
                            value,
                            messages["page.apiPlanAddAndEdit.ikkyu.planCode.title"],
                            {
                                required: messages["form.error.mixed.required"],
                                valueInvalid: messages["page.apiPlanAddAndEdit.ikkyu.valueInvalid"]
                            },
                            {
                                required: true,
                                space: true,
                                character: true
                            }
                        )}
                    >
                        <Input
                            name={`otaFieldContent.${tabKey}.planCode`}
                            maxLength={10}
                            onBlur={(e) => handleInputBlur(e)}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row justify="start" className="mb-25px">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.ikkyuExclusive`}
                        label={
                            <div>
                                <div className="custom-required">
                                    {messages["page.apiPlanAddAndEdit.ikkyu.planName.title"]}
                                </div>
                                <div>
                                    <span className="text-note-row">
                                        {messages["page.apiPlanAddAndEdit.ikkyu.planName.title.note"]}
                                    </span>
                                </div>
                            </div>
                        }
                        className="custom-label"
                        {...itemLeftLabelFullApi}
                    >
                        <Row>
                            <Col xs={24} lg={{ span: 24 }}>
                                <Checkbox.Group
                                    name={`otaFieldContent.${tabKey}.ikkyuExclusive`}
                                    onChange={(value) => {
                                        handleChangeCheckbox(`otaFieldContent.${tabKey}.ikkyuExclusive`, value)
                                        setIkkyuExclusiveValue(value)
                                    }}
                                    value={ikkyuExclusiveValue}
                                >
                                    {optionValues.map((option, index) => {
                                        if (option.fieldKey === fieldKeyIkkyuApiPlanOption.ikkyuExclusive) {
                                            return (
                                                <Checkbox value={option.value}>
                                                    {option.value}
                                                    <span className="ml-1">
                                                        {messages["page.apiPlanAddAndEdit.ikkyu.ikkyuExclusive.note"]}
                                                    </span>
                                                </Checkbox>
                                            )
                                        }
                                    })}
                                </Checkbox.Group>
                            </Col>

                        </Row>
                        <Row className="mt-1">
                            <Col xs={24} lg={12}>
                                <Form.Item
                                    name={`otaFieldContent.${tabKey}.planName`}
                                    className="row-reverse mr-1 gap-10px"
                                    label={
                                        <span>
                                            {messages["page.apiPlanAddAndEdit.ikkyu.planName.rule.note"]}
                                        </span>
                                    }
                                    validate={(value) => ValidateField(
                                        value,
                                        messages["page.apiPlanAddAndEdit.ikkyu.planName.title"],
                                        {
                                            required: messages["form.error.mixed.required"],
                                        },
                                        {
                                            required: true,
                                        }
                                    )}
                                >
                                    <Input
                                        name={`otaFieldContent.${tabKey}.planName`}
                                        maxLength={50}
                                        onBlur={(e) => handleInputBlur(e)}
                                    />

                                    <div>{messages["page.apiPlanAddAndEdit.ikkyu.planName.input.note"]}</div>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form.Item>
                </Col>
            </Row>

            <Row justify="start" className="mb-25px">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.planSubtitle`}
                        label={
                            <div>
                                <div>
                                    {messages["page.apiPlanAddAndEdit.ikkyu.planSubtitle.title"]}
                                </div>
                                <div>
                                    <span className="text-note-row">
                                        {messages["page.apiPlanAddAndEdit.ikkyu.planSubtitle.title.note"]}
                                    </span>
                                </div>
                            </div>
                        }
                        className="custom-label"
                        {...itemLeftLabelFullApi}
                    >
                        <Row>
                            <Col lg={12}>
                                <Form.Item
                                    name={`otaFieldContent.${tabKey}.planSubtitle`}
                                    className="row-reverse mr-1 gap-10px"
                                    label={
                                        <span>
                                            {messages["page.apiPlanAddAndEdit.ikkyu.planSubtitle.rule.note"]}
                                        </span>
                                    }
                                >
                                    <Input
                                        name={`otaFieldContent.${tabKey}.planSubtitle`}
                                        maxLength={50}
                                        onBlur={(e) => handleInputBlur(e)}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form.Item>
                </Col>
            </Row>

            <h3 className="mb-1">{messages["page.apiPlanAddAndEdit.ikkyu.pointFacilityCharge"]}</h3>

            <Row justify="start" className="mb-25px">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.usageCategory`}
                        label={
                            <div>
                                {messages["page.apiPlanAddAndEdit.ikkyu.usageCategory.title"]}
                            </div>
                        }
                        className="custom-label"
                        {...itemLeftLabelFullApi}
                    >
                        <Row justify="start" className="mb-10px">
                            <Col {...colFullApi}>
                                <Form.Item
                                    name={`otaFieldContent.${tabKey}.usageCategory`}
                                    {...itemCheckBoxApi}
                                >
                                    <Radio.Group
                                        name={`otaFieldContent.${tabKey}.usageCategory`}
                                        onChange={(e) => {
                                            handleChangeRadio(e, fieldKeyIkkyuApiPlanOption.usageCategory)
                                            setUsageCategoryValue(e.target.value)
                                        }}
                                        value={usageCategoryValue}
                                    >
                                        <Row>
                                            {optionValues.map((option, index) => {
                                                if (option.fieldKey === fieldKeyIkkyuApiPlanOption.usageCategory) {
                                                    return (
                                                        <Radio value={option.value} key={index}>
                                                            <span style={{ marginLeft: 10, marginRight: 10 }}>
                                                                {option.value}
                                                            </span>
                                                        </Radio>
                                                    )
                                                }
                                            })}

                                            <span>{messages["page.apiPlanAddAndEdit.ikkyu.usageCategory.note"]}</span>
                                        </Row>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form.Item>
                </Col>
            </Row>

            <Row justify="start" className="mb-25px">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.rate`}
                        label={
                            <div>
                                {messages["page.apiPlanAddAndEdit.ikkyu.rate.title"]}
                            </div>
                        }
                        className="custom-label"
                        {...itemLeftLabelFullApi}
                    >
                        <div className="d-flex align-item-baseline">
                            <span class="mr-1">{messages["page.apiPlanAddAndEdit.ikkyu.rate.title"]}</span>
                            <Form.Item
                                wrapperCol={{ lg: 22, xs: 24 }}
                                name={`otaFieldContent.${tabKey}.rate`}
                            >
                                <InputNumber
                                    name={`otaFieldContent.${tabKey}.rate`}
                                    max={40}
                                    style={styleWidthSmallInputCommon}
                                    disabled={usageCategoryValue != USED}
                                />
                            </Form.Item>
                            <span class="ml-1">{messages["page.apiPlanAddAndEdit.ikkyu.rate.percent"]}</span>
                            <span>{messages["page.apiPlanAddAndEdit.ikkyu.rate.note1"]}</span>
                        </div>

                        <div className="text-note-row">{messages["page.apiPlanAddAndEdit.ikkyu.rate.note2"]}</div>
                        <div className="text-note-row">{messages["page.apiPlanAddAndEdit.ikkyu.rate.note3"]}</div>
                        <div className="text-note-row">{messages["page.apiPlanAddAndEdit.ikkyu.rate.note4"]}</div>
                    </Form.Item>
                </Col>
            </Row>

            <Row justify="start" className="mb-25px">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.feeRegistrationUnit`}
                        label={
                            <div>
                                <div className="custom-required">{messages["page.apiPlanAddAndEdit.ikkyu.feeRegistrationUnit.title"]}</div>
                                <div>
                                    <span className="text-note-row">{messages["page.apiPlanAddAndEdit.ikkyu.feeRegistrationUnit.title.note"]}</span>
                                </div>
                            </div>
                        }
                        className="custom-label"
                        {...itemLeftLabelFullApi}
                    >
                        <Row justify="start" className="mb-10px">
                            <Col {...colFullApi}>
                                <Row>
                                    <Col {...colLeftInputWithLabelApi}>
                                        <Form.Item
                                            name={`otaFieldContent.${tabKey}.feeRegistrationUnit`}
                                            {...itemCheckBoxApi}
                                            validate={(value) => ValidateField(
                                                value,
                                                messages["page.apiPlanAddAndEdit.ikkyu.feeRegistrationUnit.title"],
                                                {
                                                    required: messages["form.error.mixed.required"]
                                                },
                                                {
                                                    required: true,
                                                }
                                            )}
                                        >
                                            <Radio.Group
                                                name={`otaFieldContent.${tabKey}.feeRegistrationUnit`}
                                                onChange={(e) => {
                                                    handleChangeRadio(e, fieldKeyIkkyuApiPlanOption.feeRegistrationUnit)
                                                    setFeeRegistrationUnitValue(e.target.value)
                                                }}
                                                value={feeRegistrationUnitValue}
                                            >
                                                <Row>
                                                    {optionValues.map((option, index) => {
                                                        if (option.fieldKey === fieldKeyIkkyuApiPlanOption.feeRegistrationUnit) {
                                                            return (
                                                                <Radio value={option.value} key={index}>
                                                                    <span style={{ marginLeft: 10, marginRight: 10 }}>
                                                                        {option.value}
                                                                    </span>
                                                                </Radio>
                                                            )
                                                        }
                                                    })}
                                                </Row>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form.Item>
                </Col>
            </Row>

            <Row justify="start" className="mb-25px">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.minimumNumberOfConsecutiveNight`}
                        label={
                            <div className="custom-required">
                                {messages["page.apiPlanAddAndEdit.ikkyu.paymentMethod.title"]}
                            </div>
                        }
                        className="custom-label"
                        {...itemLeftLabelFullApi}
                    >
                        <Row justify="start" className="mb-10px">
                            <Col {...colFullApi}>
                                <Row>
                                    <Col {...colLeftInputWithLabelApi}>
                                        <Form.Item
                                            name={`otaFieldContent.${tabKey}.paymentMethod`}
                                            {...itemCheckBoxApi}
                                            validate={(value) => ValidateField(
                                                value,
                                                messages["page.apiPlanAddAndEdit.ikkyu.paymentMethod.title"],
                                                {
                                                    required: messages["form.error.mixed.required"]
                                                },
                                                {
                                                    required: true,
                                                }
                                            )}
                                        >
                                            <Radio.Group
                                                name={`otaFieldContent.${tabKey}.paymentMethod`}
                                                onChange={(e) => {
                                                    handleChangeRadio(e, fieldKeyIkkyuApiPlanOption.paymentMethod)
                                                    setPaymentMethodValue(e.target.value)
                                                }}
                                                value={paymentMethodValue}
                                            >
                                                <Row>
                                                    {optionValues.map((option, index) => {
                                                        if (option.fieldKey === fieldKeyIkkyuApiPlanOption.paymentMethod) {
                                                            return (
                                                                <Radio value={option.value} key={index}>
                                                                    <span style={{ marginLeft: 10, marginRight: 10 }}>
                                                                        {option.value}
                                                                    </span>
                                                                </Radio>
                                                            )
                                                        }
                                                    })}
                                                </Row>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={24} lg={{ span: 24 }}>
                                        <Checkbox.Group
                                            name={`otaFieldContent.${tabKey}.includePaypay`}
                                            onChange={(value) => {
                                                handleChangeCheckbox(`otaFieldContent.${tabKey}.includePaypay`, value)
                                                setIncludePaypayValue(value)
                                            }}
                                            value={includePaypayValue}
                                        >
                                            {optionValues.map((option, index) => {
                                                if (option.fieldKey === fieldKeyIkkyuApiPlanOption.includePaypay) {
                                                    return (
                                                        <Checkbox value={option.value}>
                                                            {option.value}
                                                        </Checkbox>
                                                    )
                                                }
                                            })}
                                        </Checkbox.Group>
                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </Form.Item>
                </Col>
            </Row>

            <Row justify="start" className="mb-25px">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.closingCheckinBefore`}
                        label={
                            <div>{messages["page.apiPlanAddAndEdit.ikkyu.closingCheckinBefore.title"]}</div>
                        }
                        className="custom-label"
                        {...itemLeftLabelFullApi}
                    >
                        <Col className="d-flex flex-wrap">
                            <div className="d-flex align-item-baseline">
                                <span class="mr-1">{messages["page.apiPlanAddAndEdit.ikkyu.closingCheckinBeforeDate.title"]}</span>
                                <Form.Item
                                    wrapperCol={{ lg: 22, xs: 22 }}
                                    name={`otaFieldContent.${tabKey}.closingCheckinBeforeDate`}
                                >
                                    <Select
                                        name={`otaFieldContent.${tabKey}.closingCheckinBeforeDate`}
                                        listHeight={320}
                                        style={styleSelectCommon}
                                        showSearch
                                        {...searchSelect}
                                        onChange={(value) => {
                                            handleChangeSelect(fieldKeyIkkyuApiPlanOption.closingCheckinBeforeDate, value)
                                            setClosingCheckinBeforeDateValue(value)
                                        }}
                                    >
                                        {optionValues.map((option, index) => {
                                            if (option.fieldKey === fieldKeyIkkyuApiPlanOption.closingCheckinBeforeDate) {
                                                return (
                                                    <Option key={index} value={option.value}>
                                                        {option.value}
                                                    </Option>
                                                )
                                            }
                                        })}
                                    </Select>
                                </Form.Item>
                            </div>

                            <div className="d-flex align-item-baseline">
                                <span class="mr-1">{messages["page.apiPlanAddAndEdit.ikkyu.closingCheckinBeforeTime.title"]}</span>
                                <Form.Item
                                    wrapperCol={{ lg: 22, xs: 22 }}
                                    name={`otaFieldContent.${tabKey}.closingCheckinBeforeTime`}
                                >
                                    <Select
                                        name={`otaFieldContent.${tabKey}.closingCheckinBeforeTime`}
                                        listHeight={320}
                                        style={styleSelectCommon}
                                        allowClear
                                        showSearch
                                        {...searchSelect}
                                    >
                                        {optionValues.map((option, index) => {
                                            if (option.fieldKey === fieldKeyIkkyuApiPlanOption.closingCheckinBeforeTime) {
                                                if (closingCheckinBeforeDateValue == "000") {
                                                    return (
                                                        <Option key={index} value={option.value}>
                                                            {option.value}
                                                        </Option>
                                                    )
                                                } else {
                                                    if (!CHECK_IN_BEFORE_TIME_HIDDEN_OPTION.includes(option.value)) {
                                                        return (
                                                            <Option key={index} value={option.value}>
                                                                {option.value}
                                                            </Option>
                                                        )
                                                    }
                                                }
                                            }
                                        })}
                                    </Select>
                                </Form.Item>
                                <span class="mr-1">{messages["page.apiPlanAddAndEdit.ikkyu.closingCheckinBeforeTime.note"]}</span>
                            </div>
                        </Col>

                    </Form.Item>
                </Col>
            </Row>

            <Row justify="start" className="mb-25px">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.stayPeriod`}
                        label={
                            <div>
                                <div>{messages["page.apiPlanAddAndEdit.ikkyu.stayPeriod.title"]}</div>
                                <div>{messages["page.apiPlanAddAndEdit.ikkyu.stayPeriod.title.note"]}</div>
                            </div>
                        }
                        className="custom-label"
                        {...itemLeftLabelFullApi}
                    >
                        <Col className="d-flex flex-wrap">
                            <div className="d-flex align-item-baseline">
                                <Form.Item
                                    wrapperCol={{ lg: 22, xs: 22 }}
                                    name={`otaFieldContent.${tabKey}.stayPeriodFrom`}
                                >
                                    <DatePicker
                                        format={DATE_FORMAT}
                                        name={`otaFieldContent.${tabKey}.stayPeriodFrom`}
                                        style={styleInputCommon}
                                        onChange={(date) => onChangeDate(`otaFieldContent.${tabKey}.stayPeriodFrom`, date)}
                                    />
                                </Form.Item>
                                <span
                                    className="mb-8px min-width-30px">{messages["page.apiPlanAddAndEdit.ikkyu.stayPeriodFrom.note"]}</span>
                            </div>
                        </Col>

                        <Col className="d-flex flex-wrap mt-1">
                            <div className="d-flex align-item-baseline">
                                <Form.Item
                                    wrapperCol={{ lg: 22, xs: 22 }}
                                    name={`otaFieldContent.${tabKey}.stayPeriodTo`}
                                >
                                    <DatePicker
                                        format={DATE_FORMAT}
                                        name={`otaFieldContent.${tabKey}.stayPeriodTo`}
                                        style={styleInputCommon}
                                        onChange={(date) => onChangeDate(`otaFieldContent.${tabKey}.stayPeriodTo`, date)}
                                    />
                                </Form.Item>
                                <span
                                    className="mb-8px min-width-30px">{messages["page.apiPlanAddAndEdit.ikkyu.stayPeriodTo.note"]}</span>
                            </div>
                        </Col>

                        <Col>{messages["page.apiPlanAddAndEdit.ikkyu.stayPeriod.note"]}</Col>
                        <Col className="mt-1">
                            <div className="d-flex align-item-baseline">
                                <span className="mr-1">{messages["page.apiPlanAddAndEdit.ikkyu.rangeDisplay.title"]}</span>
                                <Form.Item
                                    wrapperCol={{ lg: 22, xs: 22 }}
                                    name={`otaFieldContent.${tabKey}.rangeDisplay`}
                                >
                                    <InputNumber
                                        name={`otaFieldContent.${tabKey}.rangeDisplay`}
                                        max={366}
                                        style={styleWidthSmallInputCommon}
                                    />
                                </Form.Item>
                                <span className="ml-1">{messages["page.apiPlanAddAndEdit.ikkyu.rangeDisplay.note"]}</span>
                            </div>
                        </Col>
                    </Form.Item>
                </Col>
            </Row>


            <h3 className="mb-1">{messages["page.apiPlanAddAndEdit.ikkyu.timeSaleSettings"]}</h3>

            <Row justify="start" className="mb-25px">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.stayPeriod`}
                        label={
                            <div>{messages["page.apiPlanAddAndEdit.ikkyu.publicationPeriod.title"]}</div>
                        }
                        className="custom-label"
                        {...itemLeftLabelFullApi}
                    >
                        <Col className="d-flex flex-wrap">
                            <div className="d-flex align-item-baseline">
                                <Form.Item
                                    wrapperCol={{ lg: 22, xs: 22 }}
                                    name={`otaFieldContent.${tabKey}.publicationPeriodFrom`}
                                >
                                    <DatePicker
                                        format={DATE_FORMAT}
                                        name={`otaFieldContent.${tabKey}.publicationPeriodFrom`}
                                        style={styleInputCommon}
                                        onChange={(date) => onChangeDate(`otaFieldContent.${tabKey}.publicationPeriodFrom`, date)}
                                    />
                                </Form.Item>
                            </div>

                            <div className="d-flex align-item-baseline">
                                <Form.Item
                                    wrapperCol={{ lg: 22, xs: 22 }}
                                    name={`otaFieldContent.${tabKey}.publicationPeriodFromTime`}
                                >
                                    <Select
                                        name={`otaFieldContent.${tabKey}.publicationPeriodFromTime`}
                                        listHeight={320}
                                        allowClear
                                        showSearch
                                        {...searchSelect}
                                        style={styleWidthSmallInputCommon}
                                    >
                                        {optionValues.map((option, index) => {
                                            if (option.fieldKey === fieldKeyIkkyuApiPlanOption.periodTime) {
                                                return (
                                                    <Option key={index} value={option.value}>
                                                        {option.value}
                                                    </Option>
                                                )
                                            }
                                        })}
                                    </Select>
                                </Form.Item>
                                <span className="ml-1">
                                    {messages["page.apiPlanAddAndEdit.ikkyu.publicationPeriodFromTime.note"]}
                                </span>
                            </div>
                        </Col>

                        <Col className="d-flex flex-wrap mt-1">
                            <div className="d-flex align-item-baseline">
                                <Form.Item
                                    wrapperCol={{ lg: 22, xs: 22 }}
                                    name={`otaFieldContent.${tabKey}.publicationPeriodTo`}
                                >
                                    <DatePicker
                                        format={DATE_FORMAT}
                                        name={`otaFieldContent.${tabKey}.publicationPeriodTo`}
                                        style={styleInputCommon}
                                        onChange={(date) => onChangeDate(`otaFieldContent.${tabKey}.publicationPeriodTo`, date)}
                                    />
                                </Form.Item>
                            </div>

                            <div className="d-flex align-item-baseline">
                                <Form.Item
                                    wrapperCol={{ lg: 22, xs: 22 }}
                                    name={`otaFieldContent.${tabKey}.publicationPeriodToTime`}
                                >
                                    <Select
                                        name={`otaFieldContent.${tabKey}.publicationPeriodToTime`}
                                        listHeight={320}
                                        allowClear
                                        showSearch
                                        {...searchSelect}
                                        style={styleWidthSmallInputCommon}
                                    >
                                        {optionValues.map((option, index) => {
                                            if (option.fieldKey === fieldKeyIkkyuApiPlanOption.periodTime) {
                                                return (
                                                    <Option key={index} value={option.value}>
                                                        {option.value}
                                                    </Option>
                                                )
                                            }
                                        })}
                                    </Select>
                                </Form.Item>
                                <span className="ml-1">
                                    {messages["page.apiPlanAddAndEdit.ikkyu.publicationPeriodToTime.note"]}
                                </span>
                                <span className="ml-1">
                                    {messages["page.apiPlanAddAndEdit.ikkyu.publicationPeriodToTime.note2"]}
                                </span>
                            </div>
                        </Col>

                        <div className="mt-1">{messages["page.apiPlanAddAndEdit.ikkyu.publicationPeriod.note"]}</div>
                    </Form.Item>
                </Col>
            </Row>

            <Row justify="start" className="mb-25px">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.stayPeriod`}
                        label={
                            <div>{messages["page.apiPlanAddAndEdit.ikkyu.reservationPeriod.title"]}</div>
                        }
                        className="custom-label"
                        {...itemLeftLabelFullApi}
                    >
                        <Col className="d-flex flex-wrap">
                            <div className="d-flex align-item-baseline">
                                <Form.Item
                                    wrapperCol={{ lg: 22, xs: 22 }}
                                    name={`otaFieldContent.${tabKey}.reservationPeriodFrom`}
                                >
                                    <DatePicker
                                        format={DATE_FORMAT}
                                        name={`otaFieldContent.${tabKey}.reservationPeriodFrom`}
                                        style={styleInputCommon}
                                        onChange={(date) => onChangeDate(`otaFieldContent.${tabKey}.reservationPeriodFrom`, date)}
                                    />
                                </Form.Item>
                            </div>

                            <div className="d-flex align-item-baseline">
                                <Form.Item
                                    wrapperCol={{ lg: 22, xs: 22 }}
                                    name={`otaFieldContent.${tabKey}.reservationPeriodFromTime`}
                                >
                                    <Select
                                        name={`otaFieldContent.${tabKey}.reservationPeriodFromTime`}
                                        listHeight={320}
                                        allowClear
                                        showSearch
                                        {...searchSelect}
                                        style={styleWidthSmallInputCommon}
                                    >
                                        {optionValues.map((option, index) => {
                                            if (option.fieldKey === fieldKeyIkkyuApiPlanOption.periodTime) {
                                                return (
                                                    <Option key={index} value={option.value}>
                                                        {option.value}
                                                    </Option>
                                                )
                                            }
                                        })}
                                    </Select>
                                </Form.Item>
                                <span className="ml-1">
                                    {messages["page.apiPlanAddAndEdit.ikkyu.reservationPeriodFromTime.note"]}
                                </span>
                            </div>
                        </Col>

                        <Col className="d-flex flex-wrap mt-1">
                            <div className="d-flex align-item-baseline">
                                <Form.Item
                                    wrapperCol={{ lg: 22, xs: 22 }}
                                    name={`otaFieldContent.${tabKey}.reservationPeriodTo`}
                                >
                                    <DatePicker
                                        style={styleInputCommon}
                                        format={DATE_FORMAT}
                                        name={`otaFieldContent.${tabKey}.reservationPeriodTo`}
                                        onChange={(date) => onChangeDate(`otaFieldContent.${tabKey}.reservationPeriodTo`, date)}
                                    />
                                </Form.Item>
                            </div>

                            <div className="d-flex align-item-baseline">
                                <Form.Item
                                    wrapperCol={{ lg: 22, xs: 22 }}
                                    name={`otaFieldContent.${tabKey}.reservationPeriodToTime`}
                                >
                                    <Select
                                        name={`otaFieldContent.${tabKey}.reservationPeriodToTime`}
                                        listHeight={320}
                                        allowClear
                                        showSearch
                                        {...searchSelect}
                                        style={styleWidthSmallInputCommon}
                                    >
                                        {optionValues.map((option, index) => {
                                            if (option.fieldKey === fieldKeyIkkyuApiPlanOption.periodTime) {
                                                return (
                                                    <Option key={index} value={option.value}>
                                                        {option.value}
                                                    </Option>
                                                )
                                            }
                                        })}
                                    </Select>
                                </Form.Item>
                                <span className="ml-1">
                                    {messages["page.apiPlanAddAndEdit.ikkyu.reservationPeriodToTime.note"]}
                                </span>
                                <span className="ml-1">
                                    {messages["page.apiPlanAddAndEdit.ikkyu.reservationPeriodToTime.note2"]}
                                </span>
                            </div>
                        </Col>

                        <div className="mt-1">{messages["page.apiPlanAddAndEdit.ikkyu.reservationPeriod.note"]}</div>

                        <Col xs={24} lg={{ span: 24 }} className="mt-1">
                            <Checkbox.Group
                                name={`otaFieldContent.${tabKey}.advanceForDiamondMember`}
                                onChange={(value) => {
                                    handleChangeCheckbox(`otaFieldContent.${tabKey}.advanceForDiamondMember`, value)
                                    setAdvanceForDiamondMemberValue(value)
                                }}
                                value={advanceForDiamondMemberValue}
                            >
                                {optionValues.map((option, index) => {
                                    if (option.fieldKey === fieldKeyIkkyuApiPlanOption.advanceForDiamondMember) {
                                        return (
                                            <Checkbox value={option.value}>
                                                {option.value}
                                            </Checkbox>
                                        )
                                    }
                                })}
                            </Checkbox.Group>
                        </Col>

                    </Form.Item>
                </Col>
            </Row>

            <h3 className="mb-1">{messages["page.apiPlanAddAndEdit.ikkyu.restrictionSettings"]}</h3>

            <Row justify="start" className="mb-25px">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.planInventoryType`}
                        label={
                            <div>
                                <div className="custom-required">
                                    {messages["page.apiPlanAddAndEdit.ikkyu.planInventoryType.title"]}
                                </div>
                                <div className="text-note-row">{messages["page.apiPlanAddAndEdit.ikkyu.planInventoryType.title.note1"]}</div>
                                <div className="text-note-row">{messages["page.apiPlanAddAndEdit.ikkyu.planInventoryType.title.note2"]}</div>
                            </div>
                        }
                        className="custom-label"
                        {...itemLeftLabelFullApi}
                    >
                        <Col xs={24} lg={10}>
                            <Form.Item
                                name={`otaFieldContent.${tabKey}.planInventoryType`}
                                className="row-reverse mr-1 gap-10px"
                                validate={(value) => ValidateField(
                                    value,
                                    messages["page.apiPlanAddAndEdit.ikkyu.planInventoryType.title"],
                                    {
                                        required: messages["form.error.mixed.required"],
                                    },
                                    {
                                        required: true,
                                    }
                                )}
                            >
                                <Select
                                    name={`otaFieldContent.${tabKey}.planInventoryType`}
                                    listHeight={320}
                                    style={styleSelectCommon}
                                    allowClear
                                    showSearch
                                    {...searchSelect}
                                    onChange={(value) => {
                                        handleChangeSelect(fieldKeyIkkyuApiPlanOption.planInventoryType, value)
                                        setPlanInventoryTypeValue(value)
                                    }}
                                >
                                    {optionValues.map((option, index) => {
                                        if (option.fieldKey === fieldKeyIkkyuApiPlanOption.planInventoryType) {
                                            return (
                                                <Option key={index} value={option.value}>
                                                    {option.value}
                                                </Option>
                                            )
                                        }
                                    })}
                                </Select>

                                <div className="text-note-row">{messages["page.apiPlanAddAndEdit.ikkyu.planInventoryType.field.note"]}</div>
                                <div className="mt-1">{messages["page.apiPlanAddAndEdit.ikkyu.numberOfRoom.note1"]}</div>
                                <div>{messages["page.apiPlanAddAndEdit.ikkyu.numberOfRoom.note2"]}</div>
                                <div>{messages["page.apiPlanAddAndEdit.ikkyu.numberOfRoom.note3"]}</div>
                                <div className="d-flex align-item-baseline">
                                    <Form.Item
                                        wrapperCol={{ lg: 22, xs: 22 }}
                                        name={`otaFieldContent.${tabKey}.numberOfRoom`}
                                    >
                                        <InputNumber
                                            name={`otaFieldContent.${tabKey}.numberOfRoom`}
                                            style={styleWidthSmallInputCommon}
                                            disabled={planInventoryTypeValue != TOTAL_NUMBER_SETTING}
                                            maxLength={3}
                                        />
                                    </Form.Item>
                                    <span className="ml-1">{messages["page.apiPlanAddAndEdit.ikkyu.numberOfRoom.field.note"]}</span>
                                </div>

                                <div className="mt-1">{messages["page.apiPlanAddAndEdit.ikkyu.numberOfRoom.note4"]}</div>
                                <div>{messages["page.apiPlanAddAndEdit.ikkyu.numberOfRoom.note5"]}</div>
                                <div>{messages["page.apiPlanAddAndEdit.ikkyu.numberOfRoom.note6"]}</div>
                                <div className="text-note-row">{messages["page.apiPlanAddAndEdit.ikkyu.numberOfRoom.note7"]}</div>
                            </Form.Item>
                        </Col>
                    </Form.Item>
                </Col>
            </Row>

            <Row justify="start" className="mb-25px">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.limitedNightsType`}
                        label={
                            <div>{messages["page.apiPlanAddAndEdit.ikkyu.limitedNightsType.title"]}</div>
                        }
                        className="custom-label"
                        {...itemLeftLabelFullApi}
                    >
                        <Col className="d-flex flex-wrap">
                            <div className="d-flex align-item-baseline">
                                <Form.Item
                                    wrapperCol={{ lg: 22, xs: 22 }}
                                    name={`otaFieldContent.${tabKey}.limitedNightsType`}
                                >
                                    <Select
                                        name={`otaFieldContent.${tabKey}.limitedNightsType`}
                                        listHeight={320}
                                        style={styleSelectCommon}
                                        allowClear
                                        showSearch
                                        {...searchSelect}
                                        onChange={(value) => {
                                            handleChangeSelect(fieldKeyIkkyuApiPlanOption.limitedNightsType, value)
                                            setLimitedNightsTypeValue(value)
                                        }}
                                    >
                                        {optionValues.map((option, index) => {
                                            if (option.fieldKey === fieldKeyIkkyuApiPlanOption.limitedNightsType) {
                                                return (
                                                    <Option key={index} value={option.value}>
                                                        {option.value}
                                                    </Option>
                                                )
                                            }
                                        })}
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="d-flex align-item-baseline">
                                <Form.Item
                                    wrapperCol={{ lg: 22, xs: 22 }}
                                    name={`otaFieldContent.${tabKey}.rangeLimitedNightFrom`}
                                >
                                    <Select
                                        name={`otaFieldContent.${tabKey}.rangeLimitedNightFrom`}
                                        listHeight={320}
                                        allowClear
                                        showSearch
                                        {...searchSelect}
                                        style={styleWidthSmallInputCommon}
                                        disabled={limitedNightsTypeValue == DO_NOT_SET}
                                    >
                                        {optionValues.map((option, index) => {
                                            if (option.fieldKey === fieldKeyIkkyuApiPlanOption.rangeLimitedNight) {
                                                return (
                                                    <Option key={index} value={option.value}>
                                                        {option.value}
                                                    </Option>
                                                )
                                            }
                                        })}
                                    </Select>
                                </Form.Item>
                                <span className="ml-1">
                                    {messages["page.apiPlanAddAndEdit.ikkyu.rangeLimitedNightFrom.note"]}
                                </span>
                            </div>

                            <div className="d-flex align-item-baseline">
                                <span className="ml-1 mr-1">～</span>
                                <Form.Item
                                    wrapperCol={{ lg: 22, xs: 22 }}
                                    name={`otaFieldContent.${tabKey}.rangeLimitedNightTo`}
                                >
                                    <Select
                                        name={`otaFieldContent.${tabKey}.rangeLimitedNightTo`}
                                        listHeight={320}
                                        allowClear
                                        showSearch
                                        {...searchSelect}
                                        style={styleWidthSmallInputCommon}
                                        disabled={limitedNightsTypeValue == DO_NOT_SET}
                                    >
                                        {optionValues.map((option, index) => {
                                            if (option.fieldKey === fieldKeyIkkyuApiPlanOption.rangeLimitedNight) {
                                                return (
                                                    <Option key={index} value={option.value}>
                                                        {option.value}
                                                    </Option>
                                                )
                                            }
                                        })}
                                    </Select>
                                </Form.Item>
                                <span className="ml-1">{messages["page.apiPlanAddAndEdit.ikkyu.rangeLimitedNightTo.note1"]}</span>
                                <span className="ml-1">{messages["page.apiPlanAddAndEdit.ikkyu.rangeLimitedNightTo.note2"]}</span>
                            </div>
                        </Col>
                    </Form.Item>
                </Col>
            </Row>

            <Row justify="start" className="mb-25px">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.limitedRoomsType`}
                        label={
                            <div>{messages["page.apiPlanAddAndEdit.ikkyu.limitedRoomsType.title"]}</div>
                        }
                        className="custom-label"
                        {...itemLeftLabelFullApi}
                    >
                        <Col className="d-flex flex-wrap">
                            <div className="d-flex align-item-baseline">
                                <Form.Item
                                    wrapperCol={{ lg: 22, xs: 22 }}
                                    name={`otaFieldContent.${tabKey}.limitedRoomsType`}
                                >
                                    <Select
                                        name={`otaFieldContent.${tabKey}.limitedRoomsType`}
                                        listHeight={320}
                                        style={styleSelectCommon}
                                        allowClear
                                        showSearch
                                        {...searchSelect}
                                        onChange={(value) => {
                                            handleChangeSelect(fieldKeyIkkyuApiPlanOption.limitedRoomsType, value)
                                            setLimitedRoomsTypeValue(value)
                                        }}
                                    >
                                        {optionValues.map((option, index) => {
                                            if (option.fieldKey === fieldKeyIkkyuApiPlanOption.limitedRoomsType) {
                                                return (
                                                    <Option key={index} value={option.value}>
                                                        {option.value}
                                                    </Option>
                                                )
                                            }
                                        })}
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="d-flex align-item-baseline">
                                <Form.Item
                                    wrapperCol={{ lg: 22, xs: 22 }}
                                    name={`otaFieldContent.${tabKey}.rangeRoomFrom`}
                                >
                                    <Select
                                        name={`otaFieldContent.${tabKey}.rangeRoomFrom`}
                                        listHeight={320}
                                        allowClear
                                        showSearch
                                        {...searchSelect}
                                        style={styleWidthSmallInputCommon}
                                        disabled={limitedRoomsTypeValue == DO_NOT_SET}
                                    >
                                        {optionValues.map((option, index) => {
                                            if (option.fieldKey === fieldKeyIkkyuApiPlanOption.rangeLimitedRoom) {
                                                return (
                                                    <Option key={index} value={option.value}>
                                                        {option.value}
                                                    </Option>
                                                )
                                            }
                                        })}
                                    </Select>
                                </Form.Item>
                                <span className="ml-1">{messages["page.apiPlanAddAndEdit.ikkyu.rangeRoomFrom.note"]}</span>
                            </div>

                            <div className="d-flex align-item-baseline">
                                <span className="ml-1 mr-1">～</span>
                                <Form.Item
                                    wrapperCol={{ lg: 22, xs: 22 }}
                                    name={`otaFieldContent.${tabKey}.rangeRoomTo`}
                                >
                                    <Select
                                        name={`otaFieldContent.${tabKey}.rangeRoomTo`}
                                        listHeight={320}
                                        allowClear
                                        showSearch
                                        {...searchSelect}
                                        style={styleWidthSmallInputCommon}
                                        disabled={limitedRoomsTypeValue == DO_NOT_SET}
                                    >
                                        {optionValues.map((option, index) => {
                                            if (option.fieldKey === fieldKeyIkkyuApiPlanOption.rangeLimitedRoom) {
                                                return (
                                                    <Option key={index} value={option.value}>
                                                        {option.value}
                                                    </Option>
                                                )
                                            }
                                        })}
                                    </Select>
                                </Form.Item>
                                <span className="ml-1">{messages["page.apiPlanAddAndEdit.ikkyu.rangeRoomTo.note1"]}</span>
                                <span className="ml-1">{messages["page.apiPlanAddAndEdit.ikkyu.rangeRoomTo.note2"]}</span>
                            </div>
                        </Col>
                    </Form.Item>
                </Col>
            </Row>

            <h3 className="mb-1">{messages["page.apiPlanAddAndEdit.ikkyu.detailedSettings"]}</h3>

            <Row justify="start" className="mb-25px">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.settingCheckinCheckoutTime`}
                        label={
                            <div>{messages["page.apiPlanAddAndEdit.ikkyu.settingCheckinCheckoutTime.title"]}</div>
                        }
                        className="custom-label"
                        {...itemLeftLabelFullApi}
                    >
                        <Row justify="start" className="mb-10px">
                            <Col {...colFullApi}>
                                <Row>
                                    <Col {...colLeftInputWithLabelApi}>
                                        <Form.Item
                                            name={`otaFieldContent.${tabKey}.settingCheckinCheckoutTime`}
                                            {...itemCheckBoxApi}
                                        >
                                            <Radio.Group
                                                name={`otaFieldContent.${tabKey}.settingCheckinCheckoutTime`}
                                                onChange={(e) => {
                                                    handleChangeRadio(e, fieldKeyIkkyuApiPlanOption.settingCheckinCheckoutTime)
                                                    setSettingCheckinCheckoutTimeValue(e.target.value)
                                                }}
                                                value={settingCheckinCheckoutTimeValue}
                                            >
                                                <Row>
                                                    {optionValues.map((option, index) => {
                                                        if (option.fieldKey === fieldKeyIkkyuApiPlanOption.settingCheckinCheckoutTime) {
                                                            return (
                                                                <Radio value={option.value} key={index}>
                                                                    <span style={{ marginLeft: 10, marginRight: 10 }}>
                                                                        {option.value}
                                                                    </span>
                                                                </Radio>
                                                            )
                                                        }
                                                    })}
                                                </Row>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form.Item>
                </Col>
            </Row>

            <Row justify="start" className="mb-25px">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.checkInStartTime`}
                        label={
                            <div>{messages["page.apiPlanAddAndEdit.ikkyu.checkin.title"]}</div>
                        }
                        className="custom-label"
                        {...itemLeftLabelFullApi}
                    >
                        <Col className="d-flex flex-wrap">
                            <div className="d-flex align-item-baseline">
                                <Form.Item
                                    wrapperCol={{ lg: 22, xs: 22 }}
                                    name={`otaFieldContent.${tabKey}.checkinFrom`}
                                >
                                    <Select
                                        name={`otaFieldContent.${tabKey}.checkinFrom`}
                                        listHeight={320}
                                        showSearch
                                        {...searchSelect}
                                        disabled={settingCheckinCheckoutTimeValue != SET_IN_PLAN}
                                        style={styleWidthSmallInputCommon}
                                    >
                                        {optionValues.map((option, index) => {
                                            if (option.fieldKey === fieldKeyIkkyuApiPlanOption.checkinTime) {
                                                return (
                                                    <Option key={index} value={option.value}>
                                                        {option.value}
                                                    </Option>
                                                )
                                            }
                                        })}
                                    </Select>
                                </Form.Item>
                                <span className="ml-1">{messages["page.apiPlanAddAndEdit.ikkyu.checkinFrom.note"]}</span>
                            </div>
                            <div className="d-flex align-item-baseline ml-1">
                                <Form.Item
                                    wrapperCol={{ lg: 22, xs: 22 }}
                                    name={`otaFieldContent.${tabKey}.checkinTo`}
                                >
                                    <Select
                                        name={`otaFieldContent.${tabKey}.checkinTo`}
                                        listHeight={320}
                                        showSearch
                                        {...searchSelect}
                                        disabled={settingCheckinCheckoutTimeValue != SET_IN_PLAN}
                                        style={styleWidthSmallInputCommon}
                                    >
                                        {optionValues.map((option, index) => {
                                            if (option.fieldKey === fieldKeyIkkyuApiPlanOption.checkinTime) {
                                                return (
                                                    <Option key={index} value={option.value}>
                                                        {option.value}
                                                    </Option>
                                                )
                                            }
                                        })}
                                    </Select>
                                </Form.Item>
                                <span className="ml-1">{messages["page.apiPlanAddAndEdit.ikkyu.checkinTo.note"]}</span>
                            </div>
                        </Col>
                        <Col>{messages["page.apiPlanAddAndEdit.ikkyu.checkin.note"]}</Col>
                    </Form.Item>
                </Col>
            </Row>

            <Row justify="start" className="mb-25px">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.checkoutTime`}
                        label={
                            <div>{messages["page.apiPlanAddAndEdit.ikkyu.checkoutTime.title"]}</div>
                        }
                        className="custom-label"
                        {...itemLeftLabelFullApi}
                    >
                        <Col className="d-flex flex-wrap">
                            <Form.Item
                                wrapperCol={{ lg: 22, xs: 22 }}
                                name={`otaFieldContent.${tabKey}.checkoutTime`}
                            >
                                <Select
                                    name={`otaFieldContent.${tabKey}.checkoutTime`}
                                    listHeight={320}
                                    showSearch
                                    {...searchSelect}
                                    disabled={settingCheckinCheckoutTimeValue != SET_IN_PLAN}
                                    style={styleWidthSmallInputCommon}
                                >
                                    {optionValues.map((option, index) => {
                                        if (option.fieldKey === fieldKeyIkkyuApiPlanOption.checkoutTime) {
                                            return (
                                                <Option key={index} value={option.value}>
                                                    {option.value}
                                                </Option>
                                            )
                                        }
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Form.Item>
                </Col>
            </Row>

            <Row justify="start" className="mb-25px">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.mealCategory`}
                        label={
                            <div>{messages["page.apiPlanAddAndEdit.ikkyu.mealCategory.title"]}</div>
                        }
                        className="custom-label"
                        {...itemLeftLabelFullApi}
                    >
                        <Row justify="start" className="mb-10px">
                            <Col {...colLeftInputWithLabelApi}>
                                <Form.Item
                                    name={`otaFieldContent.${tabKey}.mealCategory`}
                                    {...itemCheckBoxApi}
                                >
                                    <Radio.Group
                                        name={`otaFieldContent.${tabKey}.mealCategory`}
                                        onChange={(e) => {
                                            handleChangeRadio(e, fieldKeyIkkyuApiPlanOption.mealCategory)
                                            setMealCategoryValue(e.target.value)
                                        }}
                                        value={mealCategoryValue}
                                    >
                                        <Row>
                                            {optionValues.map((option, index) => {
                                                if (option.fieldKey === fieldKeyIkkyuApiPlanOption.mealCategory) {
                                                    return (
                                                        <Col xs={24} lg={6}>
                                                            <Radio value={option.value} key={index}>
                                                                <span style={{ marginLeft: 10, marginRight: 10 }}>
                                                                    {option.value}
                                                                </span>
                                                            </Radio>
                                                        </Col>
                                                    )
                                                }
                                            })}
                                        </Row>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form.Item>
                </Col>
            </Row>

            <Row justify="start" className="mb-25px">
                <Col xs={24} lg={{ span: 4, offset: 0 }}>
                    <label>{messages["page.apiPlanAddAndEdit.ikkyu.detailMeal.title"]}</label>
                </Col>
                <Col xs={24} lg={{ span: 20, offset: 0 }} className="d-flex">
                    <Form.Item
                        wrapperCol={{ lg: 6, xs: 24 }}
                        label={""}
                        name={`otaFieldContent.${tabKey}.detailMeal`}
                    >
                        <Select
                            name={`otaFieldContent.${tabKey}.detailMeal`}
                            listHeight={320}
                            style={styleSelectCommon}
                            showSearch
                            {...searchSelect}
                            mode="multiple"
                            allowClear
                        >
                            {listMealData.map((option, index) => {
                                return (
                                    <Option key={index} value={option.value}>
                                        {option.name}
                                    </Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        wrapperCol={{ lg: 4, xs: 24, offset: 1 }}
                        label={""}
                        name={`otaFieldContent.${tabKey}.mealStatus`}
                    >
                        <Select
                            name={`otaFieldContent.${tabKey}.mealStatus`}
                            listHeight={320}
                            style={styleSelectCommon}
                            disabled={true}
                            value={statusMeal}
                        >
                            {LIST_STATUS_CRAWL.map((select, index) => {
                                return (
                                    <Select.Option
                                        key={index}
                                        value={select.id}
                                    >
                                        {select.name}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                    <Button type="primary"
                        style={{ marginLeft: "15px", width: "32px" }}
                        block
                        icon={<ReloadOutlined />}
                        onClick={() => refreshOption(TYPE_ACTION_CRAWL.meal)}
                    ></Button>
                    <Button type="primary"
                        style={{ marginLeft: "10px", width: "120px" }}
                        block
                        onClick={() => crawlOption(TYPE_ACTION_CRAWL.meal)}
                    >
                        {messages["page.apiPlanAddAndEdit.ikkyu.button.update"]}
                    </Button>
                </Col>
            </Row>

            <Row justify="start" className="mb-25px">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.planPR`}
                        label={
                            <div>
                                <div>{messages["page.apiPlanAddAndEdit.ikkyu.planPR.title"]}</div>
                                <div>{messages["page.apiPlanAddAndEdit.ikkyu.planPR.title.note"]}</div>
                            </div>
                        }
                        {...itemLeftLabelFullApi}
                    >
                        <Row className="mb-10px">
                            <Col lg={12}>
                                <Input.TextArea
                                    rows="4"
                                    name={`otaFieldContent.${tabKey}.planPR`}
                                    maxLength={2000}
                                    onBlur={(e) => handleInputBlur(e)}
                                />
                                <span>{messages["page.apiPlanAddAndEdit.ikkyu.planPR.field.note"]}</span>
                            </Col>
                        </Row>

                    </Form.Item>
                </Col>
            </Row>

            <Row justify="start" className="mb-25px">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.planDescription`}
                        label={
                            <div>
                                <div>{messages["page.apiPlanAddAndEdit.ikkyu.planDescription.title"]}</div>
                                <div>{messages["page.apiPlanAddAndEdit.ikkyu.planDescription.title.note"]}</div>
                            </div>
                        }
                        {...itemLeftLabelFullApi}
                    >
                        <Row className="mb-10px">
                            <Col lg={12}>
                                <Input.TextArea
                                    rows="4"
                                    name={`otaFieldContent.${tabKey}.planDescription`}
                                    maxLength={500}
                                    onBlur={(e) => handleInputBlur(e)}
                                />
                                <span>{messages["page.apiPlanAddAndEdit.ikkyu.planDescription.field.note"]}</span>
                            </Col>
                        </Row>

                    </Form.Item>
                </Col>
            </Row>

            <Row justify="start" className="mb-25px">
                <Col xs={24} lg={{ span: 4, offset: 0 }}>
                    <label>{messages["page.apiPlanAddAndEdit.ikkyu.cancelPolicy.title"]}</label>
                </Col>
                <Col xs={24} lg={{ span: 20, offset: 0 }} className="d-flex">
                    <Form.Item
                        wrapperCol={{ lg: 6, xs: 24 }}
                        label={""}
                        name={`otaFieldContent.${tabKey}.cancelPolicy`}
                    >
                        <Select
                            name={`otaFieldContent.${tabKey}.cancelPolicy`}
                            listHeight={320}
                            style={styleSelectCommon}
                            showSearch
                            {...searchSelect}
                            allowClear
                        >
                            {listCancelPolicy.map((option, index) => {
                                return (
                                    <Option key={index} value={option.value}>
                                        {option.name}
                                    </Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        wrapperCol={{ lg: 4, xs: 24, offset: 1 }}
                        label={""}
                        name={`otaFieldContent.${tabKey}.cancelPolicyStatus`}
                    >
                        <Select
                            name={`otaFieldContent.${tabKey}.cancelPolicyStatus`}
                            listHeight={320}
                            style={styleSelectCommon}
                            disabled={true}
                            value={statusCrawlCancelPolicy}
                        >
                            {LIST_STATUS_CRAWL.map((select, index) => {
                                return (
                                    <Select.Option
                                        key={index}
                                        value={select.id}
                                    >
                                        {select.name}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                    <Button type="primary"
                        style={{ marginLeft: "15px", width: "32px" }}
                        block
                        icon={<ReloadOutlined />}
                        onClick={() => refreshOption(TYPE_ACTION_CRAWL.cancelPolicy)}
                    ></Button>
                    <Button type="primary"
                        style={{ marginLeft: "10px", width: "120px" }}
                        block
                        onClick={() => crawlOption(TYPE_ACTION_CRAWL.cancelPolicy)}
                    >
                        {messages["page.apiPlanAddAndEdit.ikkyu.button.update"]}
                    </Button>
                </Col>
            </Row>

            <h3 className="mb-1">{messages["page.apiPlanAddAndEdit.ikkyu.yahooPack"]}</h3>

            <Row justify="start" className="mb-25px">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.usagePackageCategory`}
                        label={
                            <div>{messages["page.apiPlanAddAndEdit.ikkyu.usagePackageCategory.title"]}</div>
                        }
                        className="custom-label"
                        {...itemLeftLabelFullApi}
                    >
                        <Row justify="start" className="mb-10px">
                            <Col {...colFullApi}>
                                <Row>
                                    <Col {...colLeftInputWithLabelApi}>
                                        <Form.Item
                                            name={`otaFieldContent.${tabKey}.usagePackageCategory`}
                                            {...itemCheckBoxApi}
                                        >
                                            <Radio.Group
                                                name={`otaFieldContent.${tabKey}.usagePackageCategory`}
                                                onChange={(e) => {
                                                    handleChangeRadio(e, fieldKeyIkkyuApiPlanOption.usagePackageCategory)
                                                    setUsagePackageCategoryValue(e.target.value)
                                                }}
                                                value={usagePackageCategoryValue}
                                            >
                                                <Row>
                                                    {optionValues.map((option, index) => {
                                                        if (option.fieldKey === fieldKeyIkkyuApiPlanOption.usagePackageCategory) {
                                                            return (
                                                                <Radio value={option.value} key={index}>
                                                                    <span style={{ marginLeft: 10, marginRight: 10 }}>
                                                                        {option.value}
                                                                    </span>
                                                                </Radio>
                                                            )
                                                        }
                                                    })}
                                                </Row>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form.Item>
                </Col>
            </Row>

            {(usagePackageCategoryValue == USED) && (
                <>
                    <Row justify="start" className="mb-25px">
                        <Col {...colFullApi}>
                            <Form.Item
                                name={`otaFieldContent.${tabKey}.salePackageCategory`}
                                label={
                                    <div>{messages["page.apiPlanAddAndEdit.ikkyu.salePackageCategory.title"]}</div>
                                }
                                className="custom-label"
                                {...itemLeftLabelFullApi}
                            >
                                <Row justify="start" className="mb-10px">
                                    <Col {...colFullApi}>
                                        <Row>
                                            <Col {...colLeftInputWithLabelApi}>
                                                <Form.Item
                                                    name={`otaFieldContent.${tabKey}.salePackageCategory`}
                                                    {...itemCheckBoxApi}
                                                >
                                                    <Radio.Group
                                                        name={`otaFieldContent.${tabKey}.salePackageCategory`}
                                                        onChange={(e) => {
                                                            handleChangeRadio(e, fieldKeyIkkyuApiPlanOption.salePackageCategory)
                                                            setSalePackageCategoryValue(e.target.value)
                                                        }}
                                                        value={salePackageCategoryValue}
                                                    >
                                                        <Row>
                                                            {optionValues.map((option, index) => {
                                                                if (option.fieldKey === fieldKeyIkkyuApiPlanOption.salePackageCategory) {
                                                                    return (
                                                                        <Radio value={option.value} key={index}>
                                                                            <span style={{ marginLeft: 10, marginRight: 10 }}>
                                                                                {option.value}
                                                                            </span>
                                                                        </Radio>
                                                                    )
                                                                }
                                                            })}
                                                        </Row>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row justify="start" className="mb-25px">
                        <Col {...colFullApi}>
                            <Form.Item
                                name={`otaFieldContent.${tabKey}.planPackageName`}
                                label={
                                    <div>
                                        <div className="custom-required">{messages["page.apiPlanAddAndEdit.ikkyu.planPackageName.title"]}</div>
                                        <div>{messages["page.apiPlanAddAndEdit.ikkyu.planPackageName.title.note"]}</div>
                                    </div>
                                }
                                className="custom-label"
                                {...itemLeftLabelFullApi}
                                validate={(value) => ValidateField(
                                    value,
                                    messages["page.apiPlanAddAndEdit.ikkyu.planPackageName.title"],
                                    {
                                        required: messages["form.error.mixed.required"],
                                    },
                                    {
                                        required: true,
                                    }
                                )}
                            >
                                <Row className="mb-10px">
                                    <Col lg={12}>
                                        <Input
                                            name={`otaFieldContent.${tabKey}.planPackageName`}
                                            maxLength={50}
                                            onBlur={(e) => handleInputBlur(e)}
                                        />
                                        <div>{messages["page.apiPlanAddAndEdit.ikkyu.planPackageName.field.note1"]}</div>
                                        <div>{messages["page.apiPlanAddAndEdit.ikkyu.planPackageName.field.note2"]}</div>
                                        <div>{messages["page.apiPlanAddAndEdit.ikkyu.planPackageName.field.note3"]}</div>
                                    </Col>
                                </Row>

                            </Form.Item>
                        </Col>
                    </Row>

                    <Row justify="start" className="mb-25px">
                        <Col {...colFullApi}>
                            <Form.Item
                                name={`otaFieldContent.${tabKey}.planPackagePR`}
                                label={
                                    <div>
                                        <div>{messages["page.apiPlanAddAndEdit.ikkyu.planPackagePR.title"]}</div>
                                        <div>{messages["page.apiPlanAddAndEdit.ikkyu.planPackagePR.title.note"]}</div>
                                    </div>
                                }
                                {...itemLeftLabelFullApi}
                            >
                                <Row className="mb-10px">
                                    <Col lg={12}>
                                        <Input.TextArea
                                            rows="4"
                                            name={`otaFieldContent.${tabKey}.planPackagePR`}
                                            maxLength={2000}
                                            onBlur={(e) => handleInputBlur(e)}
                                        />
                                        <div>{messages["page.apiPlanAddAndEdit.ikkyu.planPackagePR.field.note1"]}</div>
                                        <div>{messages["page.apiPlanAddAndEdit.ikkyu.planPackagePR.field.note2"]}</div>
                                        <div>{messages["page.apiPlanAddAndEdit.ikkyu.planPackagePR.field.note3"]}</div>
                                    </Col>
                                </Row>

                            </Form.Item>
                        </Col>
                    </Row>

                    <Row justify="start" className="mb-25px">
                        <Col {...colFullApi}>
                            <Form.Item
                                name={`otaFieldContent.${tabKey}.planPackageDescription`}
                                label={
                                    <div>
                                        <div>{messages["page.apiPlanAddAndEdit.ikkyu.planPackageDescription.title"]}</div>
                                        <div>{messages["page.apiPlanAddAndEdit.ikkyu.planPackageDescription.title.note"]}</div>
                                    </div>
                                }
                                {...itemLeftLabelFullApi}
                            >
                                <Row className="mb-10px">
                                    <Col lg={12}>
                                        <Input.TextArea
                                            rows="4"
                                            name={`otaFieldContent.${tabKey}.planPackageDescription`}
                                            maxLength={500}
                                            onBlur={(e) => handleInputBlur(e)}
                                        />
                                        <div>{messages["page.apiPlanAddAndEdit.ikkyu.planPackageDescription.field.note1"]}</div>
                                        <div>{messages["page.apiPlanAddAndEdit.ikkyu.planPackageDescription.field.note2"]}</div>
                                        <div className="text-note-row">{messages["page.apiPlanAddAndEdit.ikkyu.planPackageDescription.field.note3"]}</div>
                                        <div>{messages["page.apiPlanAddAndEdit.ikkyu.planPackageDescription.field.note4"]}</div>
                                    </Col>
                                </Row>

                            </Form.Item>
                        </Col>
                    </Row>

                    <Row justify="start" className="mb-25px">
                        <Col {...colFullApi}>
                            <Form.Item
                                name={`otaFieldContent.${tabKey}.breakfastFee`}
                                label={
                                    <div>
                                        <div>{messages["page.apiPlanAddAndEdit.ikkyu.breakfastFee.title"]}</div>
                                        <div>{messages["page.apiPlanAddAndEdit.ikkyu.breakfastFee.title.note"]}</div>
                                    </div>
                                }
                                className="custom-label"
                                {...itemLeftLabelFullApi}
                            >
                                <Col className="d-flex flex-wrap">
                                    <div className="d-flex align-item-baseline">
                                        <Form.Item
                                            wrapperCol={{ lg: 22, xs: 22 }}
                                            name={`otaFieldContent.${tabKey}.breakfastFee`}
                                        >
                                            <InputNumber
                                                name={`otaFieldContent.${tabKey}.breakfastFee`}
                                                style={{ width: "100px" }}
                                                maxLength={6}
                                            />
                                        </Form.Item>
                                        <span className="ml-1">{messages["page.apiPlanAddAndEdit.ikkyu.breakfastFee.note1"]}</span>
                                        <span className="text-note-row ml-1">{messages["page.apiPlanAddAndEdit.ikkyu.breakfastFee.note2"]}</span>
                                    </div>
                                </Col>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row justify="start" className="mb-25px">
                        <Col {...colFullApi}>
                            <Form.Item
                                name={`otaFieldContent.${tabKey}.dinnerFee`}
                                label={
                                    <div>
                                        <div>{messages["page.apiPlanAddAndEdit.ikkyu.dinnerFee.title"]}</div>
                                        <div>{messages["page.apiPlanAddAndEdit.ikkyu.dinnerFee.title.note"]}</div>
                                    </div>
                                }
                                className="custom-label"
                                {...itemLeftLabelFullApi}
                            >
                                <Col className="d-flex flex-wrap">
                                    <div className="d-flex align-item-baseline">
                                        <Form.Item
                                            wrapperCol={{ lg: 22, xs: 22 }}
                                            name={`otaFieldContent.${tabKey}.dinnerFee`}
                                        >
                                            <InputNumber
                                                name={`otaFieldContent.${tabKey}.dinnerFee`}
                                                style={{ width: "100px" }}
                                                maxLength={6}
                                            />
                                        </Form.Item>
                                        <span className="ml-1">{messages["page.apiPlanAddAndEdit.ikkyu.dinnerFee.note1"]}</span>
                                        <span className="text-note-row ml-1">{messages["page.apiPlanAddAndEdit.ikkyu.dinnerFee.note2"]}</span>
                                    </div>
                                </Col>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row justify="start" className="mb-25px">
                        <Col {...colFullApi}>
                            <Form.Item
                                name={`otaFieldContent.${tabKey}.packageExclusivePricingGroup`}
                                label={
                                    <div>{messages["page.apiPlanAddAndEdit.ikkyu.packageExclusivePricing.title"]}</div>
                                }
                                className="custom-label"
                                {...itemLeftLabelFullApi}
                            >
                                <Row justify="start" className="mb-10px">
                                    <Col {...colFullApi}>
                                        <Row>
                                            <Col {...colLeftInputWithLabelApi}>
                                                <Form.Item
                                                    name={`otaFieldContent.${tabKey}.packageExclusivePricing`}
                                                    {...itemCheckBoxApi}
                                                >
                                                    <Radio.Group
                                                        name={`otaFieldContent.${tabKey}.packageExclusivePricing`}
                                                        onChange={(e) => {
                                                            handleChangeRadio(e, fieldKeyIkkyuApiPlanOption.packageExclusivePricing)
                                                            setPackageExclusivePricingValue(e.target.value)
                                                        }}
                                                        value={packageExclusivePricingValue}
                                                    >
                                                        <Row>
                                                            {optionValues.map((option, index) => {
                                                                if (option.fieldKey === fieldKeyIkkyuApiPlanOption.packageExclusivePricing) {
                                                                    return (
                                                                        <Radio value={option.value} key={index}>
                                                                            <span style={{ marginLeft: 10, marginRight: 10 }}>
                                                                                {option.value}
                                                                            </span>
                                                                        </Radio>
                                                                    )
                                                                }
                                                            })}
                                                        </Row>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row className="mt-1 mb-1">
                                            <table className="table-setting-person">
                                                <thead>
                                                    <th></th>
                                                    {packageExclusivePricingContentHeader}
                                                </thead>
                                                <tbody>
                                                    <th>{messages["page.apiPlanAddAndEdit.ikkyu.packageExclusivePricing.title.difference"]}</th>
                                                    {packageExclusivePricingContentBody}
                                                </tbody>
                                            </table>
                                        </Row>
                                        <div>{messages["page.apiPlanAddAndEdit.ikkyu.packageExclusivePricing.note1"]}</div>
                                        <div>{messages["page.apiPlanAddAndEdit.ikkyu.packageExclusivePricing.note2"]}</div>
                                        <div>{messages["page.apiPlanAddAndEdit.ikkyu.packageExclusivePricing.note3"]}</div>
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Col>
                    </Row>
                </>
            )}

            <h3 className="mb-1">{messages["page.apiPlanAddAndEdit.ikkyu.planImageSettings"]}</h3>

            <Row justify="start" className="mb-25px">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.planAttributes`}
                        label={
                            <div>
                                <div>{messages["page.apiPlanAddAndEdit.ikkyu.planAttributes.title"]}</div>
                                <div>{messages["page.apiPlanAddAndEdit.ikkyu.planAttributes.title.note"]}</div>
                            </div>
                        }
                        className="custom-label"
                        {...itemLeftLabelFullApi}
                    >
                        <Row>
                            <Col xs={24} lg={{ span: 24 }}>
                                <Form.Item
                                    name={`otaFieldContent.${tabKey}.planAttributes`}
                                >
                                    <Checkbox.Group
                                        name={`otaFieldContent.${tabKey}.planAttributes`}
                                        onChange={(value) => {
                                            handleChangeCheckbox(`otaFieldContent.${tabKey}.planAttributes`, value)
                                            setPlanAttributesValue(value)
                                        }}
                                        value={planAttributesValue}
                                    >
                                        {optionValues.map((option, index) => {
                                            if (option.fieldKey === fieldKeyIkkyuApiPlanOption.planAttributes) {
                                                return (
                                                    <Row key={index}>
                                                        <Checkbox value={option.value}>
                                                            {option.value}
                                                        </Checkbox>
                                                    </Row>
                                                )
                                            }
                                        })}
                                    </Checkbox.Group>
                                </Form.Item>
                            </Col>

                        </Row>

                    </Form.Item>
                </Col>
            </Row>

            <Row justify="start" className="mb-25px">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.amenityAndEquipment`}
                        label={
                            <div>
                                <div>{messages["page.apiPlanAddAndEdit.ikkyu.amenityAndEquipment.title"]}</div>
                                <div>{messages["page.apiPlanAddAndEdit.ikkyu.amenityAndEquipment.title.note1"]}</div>
                                <div>{messages["page.apiPlanAddAndEdit.ikkyu.amenityAndEquipment.title.note2"]}</div>
                            </div>
                        }
                        className="custom-label"
                        {...itemLeftLabelFullApi}
                    >
                        <Row>
                            <Col xs={24} lg={{ span: 24 }}>
                                <Form.Item
                                    name={`otaFieldContent.${tabKey}.amenityAndEquipment`}
                                >

                                    <Checkbox.Group
                                        name={`otaFieldContent.${tabKey}.amenityAndEquipment`}
                                        onChange={(value) => {
                                            handleChangeCheckbox(`otaFieldContent.${tabKey}.amenityAndEquipment`, value)
                                            setAmenityAndEquipmentValue(value)
                                        }}
                                        value={amenityAndEquipmentValue}
                                    >
                                        <Row>
                                            {optionValues.map((option, index) => {
                                                if (option.fieldKey === fieldKeyIkkyuApiPlanOption.amenityAndEquipment) {
                                                    return (
                                                        <Col xs={24} lg={8} key={index}>
                                                            <Checkbox value={option.value}>
                                                                {option.value}
                                                            </Checkbox>
                                                        </Col>
                                                    )
                                                }
                                            })}
                                        </Row>
                                    </Checkbox.Group>

                                </Form.Item>
                            </Col>

                        </Row>

                    </Form.Item>
                </Col>
            </Row>

            <h3 className="mb-1">{messages["page.apiPlanAddAndEdit.ikkyu.questionSettings"]}</h3>

            {questionSettingContent}

            <h3 className="mb-1">{messages["page.apiPlanAddAndEdit.ikkyu.featureSettings"]}</h3>

            <Row justify="start" className="mb-25px">
                <Col xs={24} lg={{ span: 4, offset: 0 }}>
                    <label>{messages["page.apiPlanAddAndEdit.ikkyu.featureSettings.title"]}</label>
                </Col>
                <Col xs={24} lg={{ span: 20, offset: 0 }} className="d-flex">
                    <Form.Item
                        wrapperCol={{ lg: 6, xs: 24 }}
                        label={""}
                        name={`otaFieldContent.${tabKey}.featureSettings`}
                    >
                        <Select
                            name={`otaFieldContent.${tabKey}.featureSettings`}
                            listHeight={320}
                            style={styleSelectCommon}
                            showSearch
                            {...searchSelect}
                            mode="multiple"
                            allowClear
                        >
                            {listFeatures.map((option, index) => {
                                return (
                                    <Option key={index} value={option.name}>
                                        {option.name}
                                    </Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        wrapperCol={{ lg: 4, xs: 24, offset: 1 }}
                        label={""}
                        name={`otaFieldContent.${tabKey}.featureStatus`}
                    >
                        <Select
                            name={`otaFieldContent.${tabKey}.featureStatus`}
                            listHeight={320}
                            style={styleSelectCommon}
                            disabled={true}
                            value={statusFeature}
                        >
                            {LIST_STATUS_CRAWL.map((select, index) => {
                                return (
                                    <Select.Option
                                        key={index}
                                        value={select.id}
                                    >
                                        {select.name}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                    <Button type="primary"
                        style={{ marginLeft: "15px", width: "32px" }}
                        block
                        icon={<ReloadOutlined />}
                        onClick={() => refreshOption(TYPE_ACTION_CRAWL.feature_settings)}
                    ></Button>
                    <Button type="primary"
                        style={{ marginLeft: "10px", width: "120px" }}
                        block
                        onClick={() => crawlOption(TYPE_ACTION_CRAWL.feature_settings)}
                    >
                        {messages["page.apiPlanAddAndEdit.ikkyu.button.update"]}
                    </Button>
                </Col>
            </Row>

            <h3 className="mb-1">{messages["page.apiPlanAddAndEdit.ikkyu.optionSettings"]}</h3>

            <Row justify="start" className="mb-25px">
                <Col xs={24} lg={{ span: 4, offset: 0 }}>
                    <label>
                        <div>{messages["page.apiPlanAddAndEdit.ikkyu.optionalSettings.title"]}</div>
                        <div>{messages["page.apiPlanAddAndEdit.ikkyu.optionalSettings.title.note"]}</div>
                    </label>
                </Col>
                <Col xs={24} lg={{ span: 20, offset: 0 }} className="d-flex">
                    <Form.Item
                        wrapperCol={{ lg: 6, xs: 24 }}
                        label={""}
                        name={`otaFieldContent.${tabKey}.optionalSettings`}
                    >
                        <Select
                            name={`otaFieldContent.${tabKey}.optionalSettings`}
                            listHeight={320}
                            style={styleSelectCommon}
                            showSearch
                            {...searchSelect}
                            mode="multiple"
                            allowClear
                        >
                            {listOptionals.map((option, index) => {
                                return (
                                    <Option key={index} value={option.name}>
                                        {option.name}
                                    </Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        wrapperCol={{ lg: 4, xs: 24, offset: 1 }}
                        label={""}
                        name={`otaFieldContent.${tabKey}.optionalStatus`}
                    >
                        <Select
                            name={`otaFieldContent.${tabKey}.optionalStatus`}
                            listHeight={320}
                            style={styleSelectCommon}
                            disabled={true}
                            value={statusOptional}
                        >
                            {LIST_STATUS_CRAWL.map((select, index) => {
                                return (
                                    <Select.Option
                                        key={index}
                                        value={select.id}
                                    >
                                        {select.name}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                    <Button type="primary"
                        style={{ marginLeft: "15px", width: "32px" }}
                        block
                        icon={<ReloadOutlined />}
                        onClick={() => refreshOption(TYPE_ACTION_CRAWL.optional_settings)}
                    ></Button>
                    <Button type="primary"
                        style={{ marginLeft: "10px", width: "120px" }}
                        block
                        onClick={() => crawlOption(TYPE_ACTION_CRAWL.optional_settings)}
                    >
                        {messages["page.apiPlanAddAndEdit.ikkyu.button.update"]}
                    </Button>
                </Col>
            </Row>

            <h3 className="mb-1 mt-1">{messages["page.apiPlanAddAndEdit.ikkyu.creatorOrUpdaterInformation"]}</h3>

            <Row justify="start" className="mb-25px">
                <Col xs={24} lg={{ span: 24 }}>
                    <Row>
                        <Col {...colLeft}>
                            <Form.Item
                                label={
                                    <div className="custom-required">
                                        {messages["page.apiPlanAddAndEdit.ikkyu.creatorName.title"]}
                                    </div>
                                }
                                className="custom-label"
                                name={`otaFieldContent.${tabKey}.creatorName`}
                                {...itemLeftLabel}
                                validate={(value) => ValidateField(
                                    value,
                                    messages["page.apiPlanAddAndEdit.ikkyu.creatorName.title"],
                                    {
                                        required: messages["form.error.mixed.required"],
                                    },
                                    {
                                        required: true,
                                    }
                                )}
                            >
                                <Input
                                    name={`otaFieldContent.${tabKey}.creatorName`}
                                    onBlur={(e) => handleInputBlur(e)}
                                    maxLength={20}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row justify="start" className="mb-25px">
                <RoomIkkyuExtendFields
                    dataOtas={values}
                    setFieldValue={setFieldValue}
                    otaId={otaId}
                    storeId={storeId}
                    tabKey={tabKey}
                    apiPlanOtaId={apiPlanOtaId}
                    setEventKodomoSubmit={setEventKodomoSubmit}
                    isCloneData={isCloneData}
                    activeTabKey={activeTabKey}
                    optionValues={optionValues}
                />
            </Row>

            <Row justify="start" className="mb-25px">
                <UploadFileImageOta
                    values={values}
                    setFieldValue={setFieldValue}
                    otaId={otaId}
                    storeId={storeId}
                    tabKey={tabKey}
                    isCloneData={isCloneData}
                ></UploadFileImageOta>
            </Row>

            <Modal
                title={messages["page.apiPlanAddAndEdit.titleLog"]}
                style={{ top: 20 }}
                width={700}
                visible={showLog}
                footer={null}
                onCancel={() => setShowLog(false)}
            >
                <Row style={{ maxHeight: '80vh', overflowY: 'auto', display: 'block' }}>
                    {syncErrorContent != null && syncErrorContent.length > 0 && syncErrorContent.map((x, index) => (
                        <Row style={{ marginTop: '10px' }} key={index}>
                            <p key={index}>{x}</p>
                        </Row>
                    ))}
                </Row>
                {syncErrorContent == null || syncErrorContent.length <= 0 && (
                    <Row style={{ padding: '10px', justifyContent: 'center' }}>
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={
                                <span>{messages["antTable.emptyData"]}</span>
                            }
                        >
                        </Empty>
                    </Row>
                )}
            </Modal>
        </LayoutContentWrapper>
    );
}

Ikkyu.propTypes = {
    setFieldValue: PropTypes.any,
};

const ikkyuInitialValue = {
    planCode: null,
    ikkyuExclusive: [],
    planName: null,
    planSubtitle: null,
    usageCategory: "利用しない",
    rate: null,
    feeRegistrationUnit: "1名あたりの料金",
    paymentMethod: "現地＆オンライン",
    includePaypay: [],
    closingCheckinBeforeDate: "000",
    closingCheckinBeforeTime: "24:00",
    stayPeriodFrom: null,
    stayPeriodTo: null,
    rangeDisplay: "366",
    publicationPeriodFrom: null,
    publicationPeriodFromTime: "00:00",
    publicationPeriodTo: null,
    publicationPeriodToTime: "00:00",
    reservationPeriodFrom: null,
    reservationPeriodFromTime: "00:00",
    reservationPeriodTo: null,
    reservationPeriodToTime: "00:00",
    advanceForDiamondMember: [],
    planInventoryType: "限定なし",
    numberOfRoom: null,
    limitedNightsType: "設定しない",
    rangeLimitedNightFrom: null,
    rangeLimitedNightTo: null,
    limitedRoomsType: "設定しない",
    rangeRoomFrom: null,
    rangeRoomTo: null,
    settingCheckinCheckoutTime: "変更なし（部屋毎の設定に準ずる）",
    checkinFrom: null,
    checkinTo: null,
    checkoutTime: null,
    mealCategory: null,
    detailMeal: [],
    planPR: null,
    planDescription: null,
    cancelPolicy: null,
    usagePackageCategory: "利用する",
    salePackageCategory: "全て",
    planPackageName: null,
    planPackagePR: null,
    planPackageDescription: null,
    breakfastFee: null,
    dinnerFee: null,
    packageExclusivePricing: "通常料金を自動コピーする",
    setting1Person: 0,
    setting2Person: 0,
    setting3Person: 0,
    setting4Person: 0,
    setting5Person: 0,
    setting6Person: 0,
    planAttributes: null,
    amenityAndEquipment: null,
    question1: null,
    answerType1: "フリーテキスト",
    answer1ForQuestion1: null,
    answer2ForQuestion1: null,
    answer3ForQuestion1: null,
    answer4ForQuestion1: null,
    answer5ForQuestion1: null,
    answerClassification1: "任意",
    question2: null,
    answerType2: "フリーテキスト",
    answer1ForQuestion2: null,
    answer2ForQuestion2: null,
    answer3ForQuestion2: null,
    answer4ForQuestion2: null,
    answer5ForQuestion2: null,
    answerClassification2: "任意",
    question3: null,
    answerType3: "フリーテキスト",
    answer1ForQuestion3: null,
    answer2ForQuestion3: null,
    answer3ForQuestion3: null,
    answer4ForQuestion3: null,
    answer5ForQuestion3: null,
    answerClassification3: "任意",
    question4: null,
    answerType4: "フリーテキスト",
    answer1ForQuestion4: null,
    answer2ForQuestion4: null,
    answer3ForQuestion4: null,
    answer4ForQuestion4: null,
    answer5ForQuestion4: null,
    answerClassification4: "任意",
    question5: null,
    answerType5: "フリーテキスト",
    answer1ForQuestion5: null,
    answer2ForQuestion5: null,
    answer3ForQuestion5: null,
    answer4ForQuestion5: null,
    answer5ForQuestion5: null,
    answerClassification5: "任意",
    featureSettings: [],
    optionalSettings: [],
    creatorName: null,
}

const styleInputCommon = {
    minWidth: 168,
    marginRight: 15
}

const styleSelectCommon = {
    width: 168,
    marginRight: 15
}

const styleWidthSmallInputCommon = {
    width: "80px"
}

export default memo(Ikkyu);
