const actions = {
  GET_LIST_API_PLAN_REQUEST: "API_PLAN/GET_LIST_API_PLAN_REQUEST",
  GET_LIST_API_PLAN_SUCCESS: "API_PLAN/GET_LIST_API_PLAN_SUCCESS",
  GET_LIST_API_PLAN_ERROR: "API_PLAN/GET_LIST_API_PLAN_ERROR",

  GET_API_PLAN_DETAIL_REQUEST: "API_PLAN/GET_API_PLAN_DETAIL_REQUEST",
  GET_API_PLAN_DETAIL_SUCCESS: "API_PLAN/GET_API_PLAN_DETAIL_SUCCESS",
  GET_API_PLAN_DETAIL_ERROR: "API_PLAN/GET_API_PLAN_DETAIL_ERROR",

  ADD_API_PLAN_REQUEST: "API_PLAN/ADD_API_PLAN_REQUEST",
  ADD_API_PLAN_SUCCESS: "API_PLAN/ADD_API_PLAN_SUCCESS",
  ADD_API_PLAN_ERROR: "API_PLAN/ADD_API_PLAN_ERROR",

  UPDATE_API_PLAN_REQUEST: "API_PLAN/UPDATE_API_PLAN_REQUEST",
  UPDATE_API_PLAN_SUCCESS: "API_PLAN/UPDATE_API_PLAN_SUCCESS",
  UPDATE_API_PLAN_ERROR: "API_PLAN/UPDATE_API_PLAN_ERROR",

  DELETE_API_PLAN_OTA_REQUEST: "API_PLAN/DELETE_API_PLAN_OTA_REQUEST",
  DELETE_API_PLAN_OTA_SUCCESS: "API_PLAN/DELETE_API_PLAN_OTA_SUCCESS",
  DELETE_API_PLAN_OTA_ERROR: "API_PLAN/DELETE_API_PLAN_OTA_ERROR",

  SYNC_DATA_PLAN_OTA_REQUEST: "API_PLAN/SYNC_DATA_PLAN_OTA_REQUEST",
  SYNC_DATA_PLAN_OTA_SUCCESS: "API_PLAN/SYNC_DATA_PLAN_OTA_SUCCESS",
  SYNC_DATA_PLAN_OTA_ERROR: "API_PLAN/SYNC_DATA_PLAN_OTA_ERROR",

  GET_LIST_USER_REQUEST: "API_PLAN/GET_LIST_USER_REQUEST",
  GET_LIST_USER_SUCCESS: "API_PLAN/GET_LIST_USER_SUCCESS",
  GET_LIST_USER_ERROR: "API_PLAN/GET_LIST_USER_ERROR",

  GET_SYNC_PLAN_STATUS_REQUEST: "API_PLAN/GET_SYNC_PLAN_STATUS_REQUEST",
  GET_SYNC_PLAN_STATUS_SUCCESS: "API_PLAN/GET_SYNC_PLAN_STATUS_SUCCESS",
  GET_SYNC_PLAN_STATUS_ERROR: "API_PLAN/GET_SYNC_PLAN_STATUS_ERROR",

  UPDATE_DATA_PLAN_REQUEST: "API_PLAN/UPDATE_DATA_PLAN_REQUEST",
  UPDATE_DATA_PLAN_SUCCESS: "API_PLAN/UPDATE_DATA_PLAN_SUCCESS",
  UPDATE_DATA_PLAN_ERROR: "API_PLAN/UPDATE_DATA_PLAN_ERROR",

  CRAWL_ALL_PLAN_REQUEST: "API_PLAN/CRAWL_ALL_PLAN_REQUEST",
  CRAWL_ALL_PLAN_SUCCESS: "API_PLAN/CRAWL_ALL_PLAN_SUCCESS",
  CRAWL_ALL_PLAN_ERROR: "API_PLAN/CRAWL_ALL_PLAN_ERROR",

  UPDATE_MULTIPLE_PLAN_REQUEST: "API_PLAN/UPDATE_MULTIPLE_PLAN_REQUEST",
  UPDATE_MULTIPLE_PLAN_SUCCESS: "API_PLAN/UPDATE_MULTIPLE_PLAN_SUCCESS",
  UPDATE_MULTIPLE_PLAN_ERROR: "API_PLAN/UPDATE_MULTIPLE_PLAN_ERROR",

  OPEN_SALE_PLAN_REQUEST: "API_PLAN/OPEN_SALE_PLAN_REQUEST",
  OPEN_SALE_PLAN_SUCCESS: "API_PLAN/OPEN_SALE_PLAN_SUCCESS",
  OPEN_SALE_PLAN_ERROR: "API_PLAN/OPEN_SALE_PLAN_ERROR",

  STOP_SELLING_PLAN_REQUEST: "API_PLAN/STOP_SELLING_PLAN_REQUEST",
  STOP_SELLING_PLAN_SUCCESS: "API_PLAN/STOP_SELLING_PLAN_SUCCESS",
  STOP_SELLING_PLAN_ERROR: "API_PLAN/STOP_SELLING_PLAN_ERROR",

  RESET_API_PLAN_DETAIL_REQUEST: "API_PLAN/RESET_API_PLAN_DETAIL_REQUEST",

  getApiPlans: (payload) => ({
    type: actions.GET_LIST_API_PLAN_REQUEST,
    payload,
  }),

  getApiPlanDetail: (payload) => ({
    type: actions.GET_API_PLAN_DETAIL_REQUEST,
    payload
  }),

  createApiPlan: (payload) => ({
    type: actions.ADD_API_PLAN_REQUEST,
    payload
  }),

  updateApiPlan: (payload) => ({
    type: actions.UPDATE_API_PLAN_REQUEST,
    payload
  }),

  deleteApiPlanOta: (payload) => ({
    type: actions.DELETE_API_PLAN_OTA_REQUEST,
    payload
  }),

  syncDataPlanToSite: (payload) => ({
    type: actions.SYNC_DATA_PLAN_OTA_REQUEST,
    payload,
  }),

  getListUser: (payload) => ({ 
    type: actions.GET_LIST_USER_REQUEST,
    payload
  }),

  getSyncPlanStatus: (payload) => ({
    type: actions.GET_SYNC_PLAN_STATUS_REQUEST,
    payload: payload,
  }),

  updateDataFromSite: (payload) => ({
    type: actions.UPDATE_DATA_PLAN_REQUEST,
    payload,
  }),

  crawlAllPlan: (payload) => ({
    type: actions.CRAWL_ALL_PLAN_REQUEST, payload
  }),

  updateMultipleFromSite: (payload) => ({
    type: actions.UPDATE_MULTIPLE_PLAN_REQUEST,
    payload,
  }),

  openSaleFromSite: (payload) => ({
    type: actions.OPEN_SALE_PLAN_REQUEST,
    payload,
  }),

  stopSellingFromSite: (payload) => ({
    type: actions.STOP_SELLING_PLAN_REQUEST,
    payload,
  }),

  resetApiPlanDetail: () => ({
    type: actions.RESET_API_PLAN_DETAIL_REQUEST,
  }),
};
export default actions;
