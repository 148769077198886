import React, { useState, useEffect, memo, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { Row, Modal, Button, Table} from "antd";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import selectApiRoomService from "@iso/redux/selectApiRoom/actions";
import SelectApiRoomStyles from "./SelectApiRoom.styles";
import {isEmpty} from "lodash";

const SelectApiRoom = ({
  onSelect,
  selected,
  storeId,
  otaId,
  setListApiRoom,
  listApiRoom,
  apiRooms,
  activeTabKey,
  setFieldValue
}) => {
  const {
    Auth: {
      user: { role },
    },
    SelectApiRoom: { loading, show},
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  const { messages } = useIntl();
  const [apiRoomTemp, setApiRoomTemp] = useState([]);
  const [apiRoomSelected, setApiRoomSelected] = useState(selected || []);

  useEffect(() => {
    if (show) {
      setApiRoomSelected(selected ? [...selected] : null);
    }
  }, [selected, show]);

  const onDecision = useCallback(() => {
    onSelect(apiRoomSelected.length > 0 ? [...apiRoomSelected] : null);
    dispatch(selectApiRoomService.toggleSelectApiRoomOta());
    if (apiRoomTemp.length <= 0) {
      const defaultApiRoom = apiRooms.filter(x => apiRoomSelected.includes(x.apiRoomOtaId))
      setListApiRoom(defaultApiRoom)
      setFieldValue(`otaFieldContent.${activeTabKey}.kodomo`, {})
    } else {
      setListApiRoom(apiRoomTemp)
    }
  }, [apiRoomSelected, apiRooms, listApiRoom, setListApiRoom, apiRoomTemp, dispatch, activeTabKey]);

  const onCancel = () => dispatch(selectApiRoomService.toggleSelectApiRoomOta());



  const enhanceSelectedRows = (selectedRows) => {
    const apiRoomId = apiRooms.map((item) => item.apiRoomOtaId);
    const otherSelected = apiRoomSelected
      ? apiRoomSelected.filter((item) => !apiRoomId.includes(item))
      : [];
    const newApiRoomId = [];
    selectedRows.map((item) => {
      if (item && item.apiRoomOtaId) {
        newApiRoomId.push(item.apiRoomOtaId);
      }
    });
    return [...otherSelected, ...newApiRoomId];
  };

  const rowSelection = {
    onSelect: (record, selected, selectedRows) =>
    {
      setApiRoomTemp(selectedRows)
      setApiRoomSelected([...enhanceSelectedRows(selectedRows)])
    },
    onSelectAll: (selected, selectedRows) =>
    {
      setApiRoomTemp(selectedRows)
      setApiRoomSelected([...enhanceSelectedRows(selectedRows)])
    },
    selectedRowKeys: apiRoomSelected ? apiRoomSelected : [],
    selections: true,
  };

  return (
    <Modal
      title={messages["popup.selectStore.header"]}
      style={{ top: 20 }}
      width={768}
      visible={show}
      footer={null}
      onCancel={onCancel}
    >
      {show && (
        <SelectApiRoomStyles>
          <Table
            className="mt-24"
            bordered
            pagination={false}
            columns={generateColumns(messages, role)}
            loading={loading}
            rowSelection={{
              ...rowSelection,
            }}
            rowKey="apiRoomOtaId"
            scroll={{ x: 500, y: "calc(100vh - 540px)" }}
            dataSource={apiRooms}
          />
          <Row justify="center" className="mt-24">
            <Button onClick={onCancel}>
              {messages["page.Account.buttonCancel"]}
            </Button>
            <Button type="primary" htmlType="button" onClick={onDecision}>
              {messages["popup.selectStore.decision"]}
            </Button>
          </Row>
        </SelectApiRoomStyles>
      )}
    </Modal>
  );
};

const generateColumns = (messages, role) => {
  return [
    {
      title: "No.",
      dataIndex: "apiRoomOtaId",
      key: "apiRoomOtaId",
      align: "center",
      width: 70,
    },
    {
      title: messages["page.apiPlanAddAndEdit.apiRoom.roomName"],
      dataIndex: "roomName",
      key: "roomName",
      align: "center",
    },
    {
      title: messages["page.apiPlanAddAndEdit.apiRoom.createdAt"],
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
    },
    {
      title: messages["page.apiPlanAddAndEdit.apiRoom.createdName"],
      dataIndex: "createdName",
      key: "createdName",
      align: "center",
    },
  ];
};

SelectApiRoom.propTypes = {
  onSelect: PropTypes.func,
  selected: PropTypes.array,
  storeId: PropTypes.any,
  otaId: PropTypes.any,
};

export default memo(SelectApiRoom);
