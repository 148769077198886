import React from "react";
import { Checkbox } from "formik-antd";
import { Form } from "antd"
import { isEmpty } from "lodash";

const CheckBoxOnly = ({ label, name, form, dataKodomoByRoom, isHoliday = null, option = null }) => {
    const handleChangeCheckbox = (e) => {
        form.setFieldsValue({name : e})
    }
    return (
        <Form.Item
            name={name}
        >
            <Checkbox.Group
                name={name}
                onChange={(e) => handleChangeCheckbox(e)}
                disabled={(isHoliday != null && option == "holiday" && isEmpty(isHoliday))}
            >
                <Checkbox
                    value={label}
                />
                <span className="ml-5 text-inline">{label}</span>
            </Checkbox.Group>
        </Form.Item>
    );
};

export default CheckBoxOnly;
