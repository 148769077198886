import actions from "@iso/redux/task/actions";
import { findIndex } from "lodash";

const initialState = {
  tasks: [],
  taskAssigns: [],
  taskAchievement: [],
  taskLogs: [],
  childTasks: [],
  directors: [],
  assignees: [],
  stores: [],
  registerPersons: [],
  tasklogTasks: [],
  total: 0,
  loading: true,
  error: null,
  totalTaskGanttChart: 0,
  taskGanttChart: [],
  loadingTaskAchievement: false,
  loadingTaskDeliveryAchievement: false,
  loadingTaskContentAchievement: false,
  otas: [],
  contents: [],
  searchCondition: {},
  tasksCsv: [],
};

export default function taskReducer(state = initialState, action) {
  switch (action.type) {
    case actions.GET_TASKS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_TASKS_SUCCESS:
      return {
        ...state,
        tasks: action.tasks,
        total: action.total,
        loading: false,
        totalTaskGanttChart: action.totalTaskGanttChart,
      };
    case actions.GET_TASKS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actions.GET_TASKS_METADATA_REQUEST:
      return {
        ...state,
      };
    case actions.GET_TASKS_METADATA_SUCCESS:
      return {
        ...state,
        assignees: action.assignees,
        registerPersons: action.registerPersons,
        directors: action.directors,
        stores: action.stores,
      };
    case actions.GET_TASKS_METADATA_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case actions.GET_TASK_LOG_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_TASK_LOG_SUCCESS:
      return {
        ...state,
        loading: false,
        taskLogs: action.taskLogs,
      };
    case actions.GET_TASK_LOG_ERROR:
      return {
        ...state,
        loading: false,
      };
    case actions.GET_TASKLOG_TASKS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_TASKLOG_TASKS_SUCCESS:
      return {
        ...state,
        tasklogTasks: action.tasklogTasks,
        loading: false,
      };
    case actions.GET_TASKLOG_TASKS_ERROR:
      return {
        ...state,
        loading: false,
      };
    case actions.GET_CHILD_TASKS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_CHILD_TASKS_SUCCESS:
      const idx = findIndex(state.tasks, { taskId: action.taskId });
      if (idx >= 0) {
        state.tasks[idx].children = action.childTasks;
      }
      return {
        ...state,
        loading: false,
        tasks: state.tasks,
      };
    case actions.GET_CHILD_TASKS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actions.LOGOUT_RESET_LIST_TASK_SEARCH:
      return {
        ...state,
        tasks: [],
      };
    case actions.RESET_TASK_STORE:
      return initialState;
    case actions.GET_LIST_TASK_GANTT_CHART_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_LIST_TASK_GANTT_CHART_SUCCESS:
      return {
        ...state,
        taskGanttChart: action.taskGanttChart,
        tasks: action.tasks,
        total: action.total,
        loading: false,
      };
    case actions.GET_LIST_TASK_GANTT_CHART_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actions.GET_TASK_ASSIGN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_TASK_ASSIGN_SUCCESS:
      return {
        ...state,
        taskAssigns: action.tasks,
        total: action.total,
        loading: false,
      };
    case actions.GET_TASK_ASSIGN_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actions.GET_TASK_ACHIEVEMENT_REQUEST:
      return {
        ...state,
        loadingTaskAchievement: true,
      };
    case actions.GET_TASK_ACHIEVEMENT_SUCCESS:
      return {
        ...state,
        taskAchievement: action.taskAchievement,
        total: action.total,
        loadingTaskAchievement: false,
      };
    case actions.GET_TASK_ACHIEVEMENT_ERROR:
      return {
        ...state,
        loadingTaskAchievement: false,
        error: action.error,
      };
    case actions.GET_OTA_REQUEST:
      return {
        ...state,
        otas: [],
        loading: true,
      };
    case actions.GET_OTA_SUCCESS:
      return {
        ...state,
        otas: action.otas,
        loading: false,
      };
    case actions.GET_OTA_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case actions.GET_CONTENT_ACHIEVEMENT_REQUEST:
      return {
        ...state,
        loadingTaskContentAchievement: true
      };
    case actions.GET_CONTENT_ACHIEVEMENT_SUCCESS:
      return {
        ...state,
        contents: action.contents,
        total: action.total,
        loadingTaskContentAchievement: false
      };
    case actions.GET_CONTENT_ACHIEVEMENT_ERROR:
      return {
        ...state,
        error: action.error,
        loadingTaskContentAchievement: false
      };
    case actions.GET_TASK_DELIVERY_ACHIEVEMENT_REQUEST:
      return {
        ...state,
        loadingTaskDeliveryAchievement: true
      };
    case actions.GET_TASK_DELIVERY_ACHIEVEMENT_SUCCESS:
      return {
        ...state,
        tasks: action.tasks,
        total: action.total,
        loadingTaskDeliveryAchievement: false
      };
    case actions.GET_TASK_DELIVERY_ACHIEVEMENT_ERROR:
      return {
        ...state,
        error: action.error,
        loadingTaskDeliveryAchievement: false
      };
    case actions.ADD_DATA_TASKS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case actions.ADD_DATA_TASKS_SUCCESS:
      return {
        ...state,
        tasks: action.tasks,
        loading: false
      };
    case actions.ADD_DATA_TASKS_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    case actions.TASK_DELIVERY_ACHIEVEMENT_RESET_TABLE:
      return {
        ...state,
        tasks: [],
        total: 0,
        loadingTaskDeliveryAchievement: false
      };
    case actions.GET_TASK_ANALYSIS_ACHIEVEMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_TASK_ANALYSIS_ACHIEVEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actions.GET_TASK_ANALYSIS_ACHIEVEMENT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actions.ADD_SEARCH_CONDITION_REQUEST: {
      return {
        ...state,
      };
    }
    case actions.ADD_SEARCH_CONDITION_SUCCESS: {
      return {
        ...state,
      };
    }
    case actions.ADD_SEARCH_CONDITION_ERROR: {
      return {
        ...state,
      };
    }
    case actions.GET_SEARCH_CONDITION_REQUEST: {
      return {
        ...state,
      };
    }
    case actions.GET_SEARCH_CONDITION_SUCCESS: {
      return {
        ...state,
      };
    }
    case actions.GET_SEARCH_CONDITION_ERROR: {
      return {
        ...state,
      };
    }
    case actions.CHANGE_TASK_FIXED_DEADLINE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.CHANGE_TASK_FIXED_DEADLINE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actions.CHANGE_TASK_FIXED_DEADLINE_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case actions.REFRESH_LIST_TASK_REQUEST:
      return {
        ...state,
      };
    case actions.REFRESH_LIST_TASK_SUCCESS:
      return {
        ...state,
        tasks: action.tasks
      };
    case actions.REFRESH_LIST_TASK_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case actions.GET_TASKS_CSV_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_TASKS_CSV_SUCCESS:
      return {
        ...state,
        tasksCsv: action.tasks,
        total: action.total,
        loading: false,
      };
    case actions.GET_TASKS_CSV_ERROR:
      return {
        ...state,
        tasksCsv: [],
        loading: false,
        error: action.error,
      };
    case actions.RESET_CONTENT_ACHIEVEMENT_TABLE:
      return {
        ...state,
        contents: [],
      };
    case actions.RESET_TASK_ACHIEVEMENT_TABLE:
      return {
        ...state,
        taskAchievement: [],
      };
    default:
      return state;
  }
}
